import React, { useState, useContext, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Milestone from "./Milestone";
import { message } from "antd";
import axios from "axios";
import { CgFileDocument } from 'react-icons/cg';
import { RxCountdownTimer } from 'react-icons/rx';
import { useNavigate } from "react-router-dom"
import Selectant from "react-select";
import Rating from "../Rating";
import { MyContext } from "../../Mycontext";
import { Popup } from 'semantic-ui-react'

function Bidding({ detailjob, show, Applied, Balance, checkUser, disputeStatus }) {
  const navigate = useNavigate();
  const { user, endPointUrl, Token, socket } = useContext(MyContext);
  const [bid, setbid] = useState({
    BidAmount: "",
    Duration: "",
    DurationType: detailjob?.Budget_Type,
    Bid_ID: "",
    UserID: "",
    ShortDescription: "",
    MinHours: 0,
    MaxHours: 0,
  });
  const [bid_ID, setbid_ID] = useState(null);
  const [P_ID, setP_ID] = useState(null);
  const [reviewShow, setreviewShow] = useState(null);
  const [editable, seteditable] = useState(false);
  const [totalBids, setTotalBids] = useState(0);
  const [balance, setBalance] = useState(0);
  const [Proposal, setProposal] = useState("");
  const [jobPostTime, setJobPostTime] = useState();
  const [timeType, setTimeType] = useState("");
  const [showBiddingPage, setShowBiddingPage] = useState(detailjob?.page === "Invitation" ? true : false)
  const [forms, setForms] = useState([
    { Amount: "", Description: "", M_Due_Date: "", status: "" },
  ]);
  const [rejectInputShow, setRejectInputShow] = useState(false)
  const [rejectionError, setRejectionError] = useState('')
  const [rejectMsg, setRejectMsg] = useState('')
  const [showBidEdit, setShowBidEdit] = useState(false)
  const [milestonesAmountCheck, setMilestoneAmountCheck] = useState(0);
  const [editBid, setEditBid] = useState({
    BidAmount: bid.BidAmount,
    Duration: bid.Duration,
    Proposal: bid.Proposal
  })

  useEffect(() => {
    const currentTime = new Date();
    const uploadTimestamp = new Date(detailjob?.createdAt);
    const timeDifference = currentTime.getTime() - uploadTimestamp.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      setJobPostTime(days)
      setTimeType("day")
      setTimeType(hours > 1 ? "days" : "day")
    } else if (hours > 0) {
      setJobPostTime(hours)
      setTimeType(hours > 1 ? "hours" : "hour")
    } else if (minutes > 0) {
      setJobPostTime(minutes)
      setTimeType(hours > 1 ? "minutes" : "minute")
    } else {
      setJobPostTime(seconds)
      setTimeType(hours > 1 ? "seconds" : "second")
    }
  })

  const BidBalance = async () => {
    const { data } = await axios.post(endPointUrl + "/getuserByBidBalance", {
      Token,
      id: user.id,
    })
    setBalance(data.data.Bid_Balance);
  };

  const handleApplyBtn = async (status) => {
    const isAnyFieldEmpty = forms.some(
      (form) =>
        form.Amount === "" || form.Description === "" || form.M_Due_Date === ""
    );

    if (isAnyFieldEmpty) {
      message.warning("Please fill in all fields");
      return;
    }
    // message.success("bid Applied Successfully")
    let milamount = 0;
    forms.map((item) => (milamount += parseFloat(item.Amount)));
    // console.log(milamount + " " + bid.BidAmount);
    if (parseFloat(milamount) === parseFloat(bid.BidAmount)) {
      if (bid_ID == null) {
        if (Proposal.length <= 100) {
          message.warning("Proposal should have minmium 100 characters");
          return;
        }
        if (bid?.Duration?.length === 0) {
          message.warning("Duration is required");
          return;
        }
        try {
          const { data } = await axios.post(endPointUrl + "/user/applyBid", {
            ...bid,
            Token,
            Proposal,
            Bid_Status: status,
            UserID: user.id,
            JobID: detailjob?.JobID,
            Job_Name: detailjob?.Title,
            To_ID: detailjob?.Employer_ID,
            From_Name: user.Name + " " + user.Last_Name,
            Email: user.Email,
            imgURL: user.Photo === "" || user.Photo == null
              ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
              : user.Photo,
            Bid_date: status === "Draft" ? null : new Date(),
          });

          handlemilestone(forms, data.data.Bid_ID);
          updateBidBalance();
          message.success("Added to your Open Bids");
          socket.emit("notificationJobAssign", {
            Type: "Project",
            FromID: user?.id,
            ToID: detailjob?.Employer_ID,
            Message: `${user.Name + " " + user.Last_Name} bidding on your job ${detailjob?.Title}`,
            IsRead: "false",
            ItemID: detailjob?.JobID,
            JobTitle: detailjob?.Title,
          })
          seteditable(!editable);
          navigate("/freelancer-job-profile")
        } catch (error) {
          console.log('some error', error);
          message.error("Some Error Ocurr");
        }
      } else {
        try {
          await axios.post(endPointUrl + "/user/updateBid", {
            ...bid,
            Token,
            Proposal,
            Bid_Status: status,
            UserID: user.id,
            JobID: detailjob?.JobID,
            Bid_ID: bid_ID,
          });
          handlemilestone(forms, bid_ID);
          updateBidBalance();
          message.success("bid Applied Successfully");
        } catch {
          message.error("Some Error Ocurr");
        }
      }
    } else {
      message.warning("Milestone Amount Does not Match with Bid Amount");
    }
  };

  const handleUpdateBtn = async (status) => {
    const isAnyFieldEmpty = forms.some(
      (form) =>
        form.Amount === "" || form.Description === "" || form.M_Due_Date === ""
    );

    if (isAnyFieldEmpty) {
      message.warning("Please fill in all fields");
      return;
    }
    // message.success("bid Applied Successfully")
    let milamount = 0;
    forms.map((item) => (milamount += parseFloat(item.Amount)));
    // console.log(milamount + " " + bid.BidAmount);
    if (parseFloat(milamount) === parseFloat(bid.BidAmount)) {
      if (Proposal.length <= 100) {
        message.warning("Proposal should have minmium 100 characters");
        return;
      }
      try {
        await axios.post(endPointUrl + "/user/updateBid", {
          ...bid,
          Token,
          Proposal,
          Bid_Status: status,
          UserID: user.id,
          JobID: detailjob?.JobID,
          Bid_ID: bid.Bid_ID,
        });
        handleUpdatemilestone(forms, bid.Bid_ID);
        navigate("/freelancer-job-profile")
        message.success("bid Updated Successfully");
      } catch (error) {
        console.log("ERROR OF UPDATE BID", error)
        message.error("Some Error Ocurred");
      }
    } else {
      message.warning("Milestone Amount Does not Match with Bid Amount");
    }
  };



  const handleUpdateHourlymilestone = async (Bid_ID) => {
    forms.map(async (res) => {
      await axios.post(endPointUrl + "/user/updatemilestone", {
        ...res,
        Amount: parseInt(bid.BidAmount) * parseInt(bid.MinHours),
        Description: bid.ShortDescription,
        M_ID: res.mid,
        Token,
        Bid_ID,
      });
    });
  };

  const handleUpdateHourlyBtn = async (status) => {
    const isAnyFieldEmpty = bid.BidAmount === "" || bid.DurationType === "" || bid.MinHours === 0 || bid.MaxHours === 0 || bid.ShortDescription === ""

    if (isAnyFieldEmpty) {
      message.warning("Please fill in all fields");
      return;
    }
    // message.success("bid Applied Successfully")
    try {
      await axios.post(endPointUrl + "/user/updateBid", {
        ...bid,
        Token,
        Proposal,
        Bid_Status: status,
        UserID: user.id,
        JobID: detailjob?.JobID,
        Bid_ID: bid.Bid_ID,
      });
      handleUpdateHourlymilestone(bid.Bid_ID);
      navigate("/freelancer-job-profile")
      message.success("bid Updated Successfully");
    } catch (error) {
      console.log("ERROR OF UPDATE BID", error)
      message.error("Some Error Ocurred");
    }
  }


  function removeTags(str) {
    if (str || str === 0) {
      str = str.toString();
      // Regular expression to identify HTML tags in
      // the input string. Replacing the identified
      // HTML tag with a null string.
      return str.replace(/(<([^>]+)>)/ig, '');
    } else {
      return ''; // Return an empty string for null, undefined, or empty input
    }
  }

  function extractTextFromHTML(htmlData) {
    // Create a temporary element to parse HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = htmlData;

    // Extract text content
    const textContent = tempElement.textContent || tempElement.innerText;

    // Remove extra whitespace
    const cleanedText = textContent.trim().replace(/\s+/g, ' ');

    return cleanedText;
  }

  const updateBidBalance = async () => {
    if (Balance) {
      try {
        await axios.post(endPointUrl + "/updateBidBalance", {
          Token,
          id: user.id,
          value: -1,
        });
        console.log("Bid Update successful");
      } catch {
        console.log("Bid Update unsuccessful");
      }
    }
  };

  const handlemilestone = async (item, Bid_ID) => {
    if (bid_ID) {
      try {
        await axios.post(endPointUrl + "/user/removeMilestoneforBid", {
          Token,
          Bid_ID,
        });
        console.log("successfully deleted");
      } catch {
        console.log("error occurr");
      }
    }

    item.map(async (res) => {
      await axios.post(endPointUrl + "/user/createMilestone", {
        ...res,
        Token,
        Bid_ID,
        MilstoneStatus: "Pending",
      });
    });
  };

  const handleHourlymilestone = async (Bid_ID) => {
    // if (bid_ID) {
    //   try {
    //     await axios.post(endPointUrl + "/user/removeMilestoneforBid", {
    //       Token,
    //       Bid_ID,
    //     });
    //     console.log("successfully deleted");
    //   } catch {
    //     console.log("error occurr");
    //   }
    // }
    await axios.post(endPointUrl + "/user/createMilestone", {
      Amount: parseInt(bid.BidAmount) * parseInt(bid.MinHours),
      Description: bid.ShortDescription,
      Token,
      Bid_ID,
      MilstoneStatus: "Pending",
    });
  };


  const handleUpdatemilestone = async (item, Bid_ID) => {
    console.log('itme update', item);
    item.map(async (res) => {
      if (!res.mid) {
        await axios.post(endPointUrl + "/user/createMilestone", {
          ...res,
          Token,
          Bid_ID,
          MilstoneStatus: "Pending",
        });
      }
      else {
        await axios.post(endPointUrl + "/user/updatemilestone", {
          ...res,
          M_ID: res.mid,
          Token,
          Bid_ID,
        });
      }
    });
  };

  const onEditorChange = (evt, editor) => {
    const data = editor.getData();
    setProposal(data);
  };

  const getTotalBids = async (JobID) => {
    const { data } = await axios.post(endPointUrl + "/getallPraposal", {
      Token,
      JobID
    })
    setTotalBids(data.data);
  }

  const checkeditTable = async (JobID) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
        Token,
        UserID: user.id,
        JobID: JobID,
      });
      if (data.status === true && data.data != null) {
        const { BidAmount, Duration, DurationType, Bid_ID, Bid_Status, UserID, Reason, Proposal, ShortDescription, MinHours, MaxHours } = data.data;
        setbid({
          BidAmount,
          Duration,
          Bid_Status,
          UserID,
          Reason,
          Bid_ID,
          DurationType,
          ShortDescription,
          MinHours,
          MaxHours,
        });
        setEditBid({
          BidAmount: BidAmount,
          Duration: Duration,
          Proposal: Proposal
        })
        setProposal(data.data.Proposal);
        // console.log(data.data.Proposal);
        if (data.data.Bid_Status === "Active") {
          seteditable(false);
        } else {
          setbid_ID(Bid_ID);
        }

        try {
          const { data } = await axios.post(
            endPointUrl + "/project/getMilestoneforBid",
            { Bid_ID, Token }
          );
          if (data.milestones.length !== 0) {
            const mil = data.milestones;
            setreviewShow(mil[mil.length - 1].FreelancerPaymentStatus);
            setP_ID(mil[0].P_ID);
            let a = [];
            mil.map((res) => {
              a.push({
                Amount: res?.Amount,
                Description: res?.Description,
                M_Due_Date: res?.M_Due_Date,
                mid: res?.PM_ID,
                status: res?.MilstoneStatus,
                paymentStatus: res?.EmployerPaymentStatus,
                recieveStatus: res?.FreelancerPaymentStatus
              });
            });
            setForms(a);
          } else {
            try {
              const { data } = await axios.post(
                endPointUrl + "/user/getMilestoneforBid",
                { Bid_ID, Token }
              );
              const mil = data.milestones;
              let a = [];
              mil.map((res) => {
                a.push({
                  Amount: res.Amount,
                  Description: res.Description,
                  M_Due_Date: res.M_Due_Date,
                  mid: res.M_ID,
                  status: res.MilstoneStatus,
                  paymentStatus: res?.EmployerPaymentStatus,
                  recieveStatus: res?.FreelancerPaymentStatus
                });
              });
              setForms(a);
            } catch {
              console.log("error");
            }
          }
        } catch {
          console.log("error");
        }
      } else {
      }
    } catch { }
  };

  const checkProposallength = (content) => {
    const strippedContent = content.replace(/<[^>]+>/g, "");

    // Count the words in the content
    const wordCount = strippedContent.trim().split(/\s+/).length;

    // Check if the word count is less than 50
    if (wordCount.length < 20) {
      return true;
    }
    return false;
  };

  const LoginPageRedirect = () => {
    navigate("/login");
    message.warning("Login as a freelancer")
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (detailjob && user) {
      checkeditTable(detailjob?.JobID);
      getTotalBids(detailjob?.JobID);
      if (user.Type === 'Freelancer') {
        BidBalance()
      }
    }
  }, [detailjob, user]);

  console.log("detailjobinvitation", detailjob)

  const handleRejectInvitation = async (item) => {
    try {
      if (!rejectMsg) {
        setRejectionError("Enter Reason For Reject Invitation")
      }
      else if (rejectMsg.length > 500) {
        setRejectionError("Reason Maxiumm Length is 500 Characters")
      }
      else {
        const { data } = await axios.post(endPointUrl + "/rejectInvitation", { InviteID: item.InviteID, Invite_By: item.Invite_By, Invite_To: item.Invite_To, JobID: item.JobID, JobTitle: item.Title, Reason: rejectMsg, Token })
        if (data.status) {
          message.success("Invitation Rejected")
          navigate(-1)
        }
      }
    } catch (error) {
      message.warning("Something Went Wrong")
      console.log("error in rejection of invitation", error)
    }
  }


  const submitBiddingHour = async () => {
    const isAnyFieldEmpty = bid.BidAmount === "" || bid.DurationType === "" || bid.MinHours === 0 || bid.MaxHours === 0 || bid.ShortDescription === ""

    if (isAnyFieldEmpty) {
      message.warning("Please fill in all fields");
      return;
    }
    if (bid_ID == null) {
      if (bid.ShortDescription.length <= 50) {
        message.warning("Cover Letter should have minmium 50 characters");
        return;
      }
      if (Proposal.length <= 100) {
        message.warning("Proposal should have minmium 100 characters");
        return;
      }
      if (parseInt(bid.MinHours) > parseInt(bid.MaxHours)) {
        message.warning("Min Hours is not be greater than max hours");
        return;
      }
      try {
        const { data } = await axios.post(endPointUrl + "/user/applyBid", {
          ...bid,
          Duration: 0,
          Token,
          Proposal,
          Bid_Status: "Active",
          UserID: user.id,
          JobID: detailjob?.JobID,
          Job_Name: detailjob?.Title,
          To_ID: detailjob?.Employer_ID,
          From_Name: user.Name + " " + user.Last_Name,
          Email: user.Email,
          imgURL: user.Photo === "" || user.Photo == null
            ? 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png'
            : user.Photo,
          Bid_date: new Date(),
        });
        handleHourlymilestone(data.data.Bid_ID);
        updateBidBalance();
        message.success("Added to your Open Bids");
        socket.emit("notificationJobAssign", {
          Type: "Project",
          FromID: user?.id,
          ToID: detailjob?.Employer_ID,
          Message: `${user.Name + " " + user.Last_Name} bidding on your job ${detailjob?.Title}`,
          IsRead: "false",
          ItemID: detailjob?.JobID,
          JobTitle: detailjob?.Title,
        })
        seteditable(!editable);
        navigate("/freelancer-job-profile")
      } catch (error) {
        console.log('some error', error);
        message.error("Some Error Ocurr");
      }
    }
    // else {
    //   try {
    //     await axios.post(endPointUrl + "/user/updateBid", {
    //       ...bid,
    //       Token,
    //       Proposal,
    //       Bid_Status: status,
    //       UserID: user.id,
    //       JobID: detailjob?.JobID,
    //       Bid_ID: bid_ID,
    //     });
    //     handlemilestone(forms, bid_ID);
    //     updateBidBalance();
    //     message.success("bid Applied Successfully");
    //   } catch {
    //     message.error("Some Error Ocurr");
    //   }
    // }
  }

  const checkUserForHour = () => {
    if (user) {
      if (user.Type === "Employer") {
        message.warning("Your are not a Freelancer ")
      } else {
        balance ? submitBiddingHour() : navigate("/packages")
      }
    } else {
      navigate("/login")
      message.warning("Login As Freelancer First")
    }
  }

  return (
    <div style={{ padding: "20px 40px" }}>
      <Rating disputeStatus={disputeStatus} P_ID={P_ID} JobID={detailjob?.JobID} JobTitle={detailjob?.Title} show={reviewShow} />
      <h2 style={{ margin: 0 }}>About this Project</h2>
      <div style={{ display: "flex", alignItems: "center", gap: "50px" }}>
        <p style={{ color: "#5c5b5b" }}><span><CgFileDocument /></span> {totalBids} Proposals</p>
        <p style={{ color: "#5c5b5b" }}><span><RxCountdownTimer /></span> Posted {parseInt(jobPostTime)} {timeType} ago</p>
      </div>
      <div>
        <div className="typesandbudget">
          <span><strong>type: </strong>{detailjob?.ProjectType || ''}</span> {/* Add Project Type  in place of Budget_Type here... */}
          <span><strong>Budget: </strong>${detailjob?.Budget_From}-${detailjob?.Budget_To}</span>
          {/* <span><strong>Budget: </strong>${detailjob?.Budget_From}-{detailjob?.Budget_To} {detailjob?.Budget_Type === "Monthly" ? "Month" : detailjob?.Budget_Type === "Weekly" ? "Week" : "Hour"}</span> */}
        </div>
        <div className="typesandbudget">
          <span><strong>Category: </strong>{detailjob?.Category?.split(",").map((cat) => <span>{cat === "Other" ? `${detailjob.JobCategoryOther}, ` : `${cat}, `}</span>)}</span>
          <span><strong>technology: </strong>{detailjob?.Technology?.split(",").map((tech) => <span>{tech === "Other" ? `${detailjob.TechnologyOther}, ` : `${tech}, `}</span>)}</span>
        </div>
        <div className="typesandbudget">
          <span><strong>Location: </strong>{detailjob?.Location}</span>
          <span><strong>language: </strong>{detailjob?.Language || ""}</span>
        </div>
      </div>
      <div className="uploadDocs">
        <h4 style={{ margin: "0 0 5px 0" }}>
          {detailjob?.Document ? "Documents" : ""}{" "}
        </h4>
        {detailjob?.IsActive ? (
          <div className="uploadtags">
            {detailjob.Document
              ?
              <div
                style={{ width: "100%", background: '' }}
              >
                <a
                  href={`${endPointUrl}/images/${detailjob.Document}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {detailjob.Document.substring(detailjob.Document.lastIndexOf(".") + 1) === "docx" || detailjob.Document.substring(detailjob.Document.lastIndexOf(".") + 1) === "pdf"
                    ?
                    detailjob.Document
                    :
                    <img style={{ maxWidth: '200px', maxHeight: '100px' }} src={`${endPointUrl}/images/${detailjob.Document}`} />
                  }
                </a>
              </div> : <></>
            }
          </div>
        ) : (
          <></>
        )}
      </div>
      <hr />
      {user?.Type == "Freelancer" ?
        <div id="myElement" style={{ width: "100%" }}>
          {Applied ?
            <>
              {showBidEdit ? <></> :
                <>
                  <h4>Bid Info {(bid.Bid_Status === "Active" && detailjob?.IsActive) ? <span style={{ marginLeft: '1%', color: "#07b274", fontSize: '0.8em', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowBidEdit(!showBidEdit)}>Edit</span> : <></>}</h4>
                  <div class="bidInfo">
                    <p id="highlighted" style={{ marginInlineEnd: "15%" }}>Bid Amount : {bid.BidAmount}$</p>
                    {detailjob?.ProjectType === "Fixed" ?
                      <p id="highlighted" style={{ marginInlineEnd: "15%" }}>Duration : {bid.Duration} {bid?.DurationType === "Monthly" ? "Month" : bid?.DurationType === "Weekly" ? "Week" : "Hour"}</p>
                      :
                      <p id="highlighted" style={{ marginInlineEnd: "15%" }}>Payment Type : {bid?.DurationType}</p>
                    }
                    <p id="highlighted">Bid Status : {bid.Bid_Status === "Active" ? "Under Review" : bid.Bid_Status === "Closed" ? "Not Available" : bid.Bid_Status === "Reject" ? "Rejected" : bid.Bid_Status === "Approved" || bid.Bid_Status === "Awarded" && bid.UserID === user.id ? "Accepted" : ""}</p>
                    {detailjob?.ProjectType === "Fixed" ? <></> : <p id="highlighted">Working Hours : {bid.MinHours}-{bid.MaxHours}</p>}
                  </div>
                </>}
              {showBidEdit ?
                <div>
                  <h4>Bid Edit <span style={{ marginLeft: '1%', color: "#07b274", fontSize: '0.8em', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setShowBidEdit(!showBidEdit)}>Cancel</span></h4>
                  <div>
                    <form className="" contentEditable={false}>
                      <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                        <label htmlFor="BidAmount">
                          <p>{detailjob.ProjectType === "Hourly" ? "Hourly Rate" : "Bid Amount"}<span className="asterisk">*</span></p>
                          <input
                            type="text"
                            id="BidAmount"
                            name="BidAmount"
                            value={bid.BidAmount}
                            placeholder={detailjob.ProjectType === "Hourly" ? "Enter Hourly Rate" : "Enter Bid Amount"}
                            // onChange={(e) => setbid({ ...bid, BidAmount: e.target.value })}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 6)
                              if (/^\d+$/.test(inputValue) && parseInt(inputValue) >= 0) {
                                setbid({ ...bid, BidAmount: inputValue });
                              } else {
                                setbid({ ...bid, BidAmount: "" })
                              }
                            }}
                            disabled={editable}
                          />
                        </label>

                        {detailjob?.ProjectType === "Hourly" ?
                          <label htmlFor="paymentType">
                            <p>Payment Type<span className="asterisk">*</span></p>
                            <Selectant
                              id="PaymentType"
                              name="Budget_type"
                              // value={bid.DurationType}
                              value={bid.DurationType.split(",").map((item) => ({ value: item, label: item }))}
                              // className={form.Budget_Type != "" ? "errorborder" : ""}
                              onChange={(e) =>
                                setbid({ ...bid, DurationType: e.value })
                              }
                              options={[{ value: "Weekly", label: "Weekly" }, { value: "Monthly", label: "Monthly" }]}
                            />
                          </label> : <></>}
                      </div>

                      <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                        {detailjob?.ProjectType === "Fixed" ?
                          <label htmlFor="Duration">
                            {/* <p>Duration<span className="asterisk">*</span> ({bid.DurationType === "month" ? "Months" : bid.DurationType === "week" ? "Weeks" : "Hrs."})</p> */}
                            <p>Duration<span className="asterisk">*</span>{bid?.DurationType === "Monthly" ? "(Month)" : bid?.DurationType === "Weekly" ? "(Week)" : "(Hour)"}</p>
                            <input
                              type="text"
                              id="Duration"
                              name="Duration"
                              // step={0.1}
                              value={bid.Duration}
                              // onChange={(e) => setbid({ ...bid, Duration: e.target.value })}
                              onChange={(e) => {
                                const inputValue = e.target.value.slice(0, 6)
                                if (/^\d+$/.test(inputValue) && parseInt(inputValue) >= 0) {
                                  setbid({ ...bid, Duration: inputValue });
                                } else {
                                  setbid({ ...bid, Duration: "" })
                                }
                              }}
                              disabled={editable}
                            />
                          </label> :
                          <>
                            <div>
                              <label>Work Hours (Weekly)<span className="asterisk">*</span></label>
                              <div style={{ display: 'flex' }}>
                                <div className="bid_range" style={{ paddingRight: "2%", marginRight: "10%" }}>
                                  <label htmlFor="Min_Hours" style={{ color: "GrayText" }}>Min Hours </label>
                                  <input
                                    // value={job?.minHours}
                                    type="text"
                                    name="Min Hours"
                                    placeholder="Min Hours"
                                    value={bid?.MinHours}
                                    // className={form.Budget_From != "" ? "errorborder" : ""}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                        setbid({ ...bid, MinHours: input.slice(0, 6) });
                                      } else {
                                        setbid({ ...bid, MinHours: "" });
                                      }
                                    }}
                                  />
                                  {/* <small style={{ color: "#ec1c24" }}>{form.Budget_From}</small> */}
                                </div>
                                <div className="bid_range" style={{ paddingRight: "2%" }}>
                                  <label htmlFor="Max Hours" style={{ color: "GrayText" }}>Max Hours</label>
                                  <input
                                    // value={job?.Budget_To}
                                    type="text"
                                    name="Max_Hours"
                                    placeholder="Max Hours"
                                    value={bid?.MaxHours}
                                    // className={form.Budget_To != "" ? "errorborder" : ""}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                        setbid({ ...bid, MaxHours: input.slice(0, 6) });
                                      } else {
                                        setbid({ ...bid, MaxHours: "" });
                                      }
                                    }}
                                  />
                                  {/* <small style={{ color: "#ec1c24" }}>{form.Budget_To}</small> */}
                                </div>
                              </div>
                            </div>
                            <div>
                              <p>Cover Letter<span className="asterisk">*</span></p>
                              <input
                                type="text"
                                placeholder="Enter Short Description"
                                value={bid?.ShortDescription}
                                onChange={(event) => {
                                  if (event.target.value.length >= 100)
                                    message.warning("Short Description Not More than 50 Words")
                                  else
                                    setbid({ ...bid, ShortDescription: event.target.value })
                                }}
                              />
                            </div>
                          </>
                        }
                      </div>
                    </form>

                    <div className="userdeatail_container" style={{ marginTop: "3%", gridTemplateColumns: '100%' }}>
                      <p>Proposal<span className="asterisk">*</span></p>
                      <CKEditor
                        data={Proposal}
                        editor={ClassicEditor}
                        // readOnly={editable}
                        // config={{
                        //   toolbar: [
                        //     { name: "document", items: ["Undo", "Redo"] },
                        //     { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                        //     { name: "styles", items: ["Format"] },
                        //     {
                        //       name: "basicstyles",
                        //       items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                        //     },
                        //   ],
                        // }}
                        onChange={onEditorChange}
                      />
                    </div>
                    {show ? (
                      <>
                        <Milestone
                          forms={forms}
                          setForms={setForms}
                          editable={editable}
                          detailjob={detailjob}
                          milestonesAmountCheck={milestonesAmountCheck}
                          setMilestoneAmountCheck={setMilestoneAmountCheck}
                          Bid_Status={bid.Bid_Status}
                          disputeStatus={disputeStatus}
                        />
                        {!editable ? (
                          <>
                            <button
                              className="btn"
                              style={{ padding: "10px", margin: "10px 5px" }}
                              onClick={() => { detailjob.ProjectType === "Hourly" ? handleUpdateHourlyBtn("Active") : handleUpdateBtn("Active") }}
                            >
                              Update Bid
                            </button>
                          </>
                        ) : null}
                      </>
                    ) :
                      user.profile_progress >= 70 ?
                        <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={() => { checkUser(); }}>Add Milestone</button>
                        :
                        <Popup content='Your profile should be atleast 70% complete to apply' trigger={<button className="mil_buton disabled" style={{ padding: "15px", marginTop: "20px" }} >Add Milestone</button>
                        }
                        />
                    }
                  </div>
                </div>
                : <></>}

              {bid.Bid_Status === "Reject" ? <div>
                <h4>Reason For Rejection</h4>
                <span>{bid?.Reason}</span>
              </div> : <></>}

              <div>
                {showBidEdit ? <></> :
                  <><h4>Proposal</h4>
                    <div className="proposal">
                      <span>{extractTextFromHTML(Proposal)}</span>
                    </div>
                    <Milestone
                      forms={forms}
                      setForms={setForms}
                      editable={!editable}
                      detailjob={detailjob}
                      Bid_ID={bid_ID}
                      milestonesAmountCheck={milestonesAmountCheck}
                      setMilestoneAmountCheck={setMilestoneAmountCheck}
                      showBidEdit={showBidEdit}
                      Bid_Status={bid.Bid_Status}
                      disputeStatus={disputeStatus}
                    />
                  </>
                }
              </div>
              {/* {detailjob?.Status === "Closed" || !detailjob?.IsActive ? 
                <h2 style={{ margin: "5%", textAlign: 'center' }}>The employer has terminated the job.</h2> : <></>
              } */}
            </>
            :
            <>
              {showBiddingPage ?
                <div style={{ width: '100%', textAlign: 'center' }}>
                  {detailjob.inviteStatus === "Rejected" ? <></> : <div>
                    <button style={{ background: "green", border: 'none', color: 'white', padding: '10px', borderRadius: '10px', width: '30%', fontWeight: 'bold', marginTop: '2%', cursor: 'pointer' }}
                      onClick={() => {
                        setShowBiddingPage(!showBiddingPage);
                      }}
                    >
                      Accept
                    </button>
                  </div>}

                  {rejectInputShow ? <></> : detailjob.inviteStatus === "Rejected" ? <></> : <div>
                    <button style={{ background: "#d70404", border: 'none', color: 'white', padding: '10px', borderRadius: '10px', width: '30%', fontWeight: 'bold', marginTop: '2%', marginBottom: '1%', cursor: 'pointer' }}
                      onClick={() => {
                        setRejectInputShow(!rejectInputShow)
                      }}
                    >Reject</button>
                  </div>}

                  {rejectInputShow ?
                    <div style={{ marginTop: '5%' }}>
                      <textarea
                        style={{ boxSizing: 'border-box', width: '98%', padding: "10px" }}
                        name="reason"
                        className={rejectionError !== "" ? "errorborder" : ""}
                        id="reason"
                        placeholder="Enter Reason For Rejection"
                        onChange={(e) => {
                          setRejectMsg(e.target.value)
                        }}
                      />
                      <small style={{ color: "#ec1c24" }}>{rejectionError}</small>

                      <div style={{ width: '20%' }}>
                        <button style={{ background: "#d70404", border: 'none', color: 'white', padding: '10px', borderRadius: '10px', width: '80%', fontWeight: 'bold', marginTop: '5%', marginBottom: '5%', cursor: 'pointer' }}
                          onClick={() => { handleRejectInvitation(detailjob) }}>Reject</button>
                      </div>

                    </div>
                    : <></>}
                </div>
                :
                detailjob?.Status === "Draft" ?
                  <h2 style={{ margin: "5%", textAlign: 'center' }}>The employer has terminated the job.</h2>
                  :
                  <div>
                    <span>
                      {user?.profile_progress < 70 ? <p style={{ fontSize: "12px", color: "red", margin: 0 }}>your profile must be 70% complete</p> : null}
                    </span>
                    <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <h2 style={{ margin: 0 }}>Place Your Bid</h2>
                      <p> <strong>Bid-Balance</strong>({balance ? balance : 0})</p>
                    </span>

                    <form className="" contentEditable={false}>
                      <div className="userdeatail_container" style={{ marginTop: "3%" }}>
                        <label htmlFor="BidAmount">
                          <p>{detailjob.ProjectType === "Hourly" ? "Hourly Rate" : "Bid Amount"}<span className="asterisk">*</span></p>
                          <input
                            type="text"
                            id="BidAmount"
                            name="BidAmount"
                            value={bid.BidAmount}
                            placeholder={detailjob.ProjectType === "Hourly" ? "Enter Hourly Rate" : "Enter Bid Amount"}
                            // onChange={(e) => setbid({ ...bid, BidAmount: e.target.value })}
                            onChange={(e) => {
                              const inputValue = e.target.value.slice(0, 6)
                              if (/^\d+$/.test(inputValue) && parseInt(inputValue) >= 0) {
                                setbid({ ...bid, BidAmount: inputValue });
                              } else {
                                setbid({ ...bid, BidAmount: "" })
                              }
                            }}
                            disabled={editable}
                          />
                        </label>


                        {detailjob?.ProjectType === "Hourly" ?
                          <label htmlFor="paymentType">
                            <p>Payment Type<span className="asterisk">*</span></p>
                            <Selectant
                              id="PaymentType"
                              name="Budget_type"
                              // className={form.Budget_Type != "" ? "errorborder" : ""}
                              onChange={(e) =>
                                setbid({ ...bid, DurationType: e.value })
                              }
                              options={[{ value: "Weekly", label: "Weekly" }, { value: "Monthly", label: "Monthly" }]}
                            />
                          </label> : <></>}
                      </div>

                      <div className="userdeatail_container" style={{ marginTop: "3%", gridTemplateColumns: '100%' }}>
                        {detailjob?.ProjectType === "Fixed" ?
                          <label htmlFor="Duration">
                            {/* <p>Duration<span className="asterisk">*</span> ({bid.DurationType === "month" ? "Months" : bid.DurationType === "week" ? "Weeks" : "Hrs."})</p> */}
                            <p>Duration<span className="asterisk">*</span>{detailjob?.Budget_Type === "Monthly" ? "(Month)" : detailjob?.Budget_Type === "Weekly" ? "(Week)" : ""}</p>
                            <input
                              type="text"
                              id="Duration"
                              name="Duration"
                              // step={0.1}
                              value={bid.Duration}
                              // onChange={(e) => setbid({ ...bid, Duration: e.target.value })}
                              onChange={(e) => {
                                const inputValue = e.target.value.slice(0, 6)
                                if (/^\d+$/.test(inputValue) && parseInt(inputValue) >= 0) {
                                  setbid({ ...bid, Duration: inputValue });
                                } else {
                                  setbid({ ...bid, Duration: "" })
                                }
                              }}
                              disabled={editable}
                            />
                          </label> :
                          <div>
                            <div style={{ width: '100%' }}>
                              <label>Work Hours (Weekly)<span className="asterisk">*</span></label>
                              <div style={{ display: 'flex' }}>
                                <div className="bid_range" style={{ paddingRight: "2%", marginRight: "10%" }}>
                                  <label htmlFor="Min_Hours" style={{ color: "GrayText" }}>Min Hours </label>
                                  <input
                                    // value={job?.minHours}
                                    type="text"
                                    name="Min Hours"
                                    placeholder="Min Hours"
                                    value={bid.MinHours}
                                    // className={form.Budget_From != "" ? "errorborder" : ""}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                        setbid({ ...bid, MinHours: input.slice(0, 6) });
                                      } else {
                                        setbid({ ...bid, MinHours: "" });
                                      }
                                    }}
                                  />
                                  {/* <small style={{ color: "#ec1c24" }}>{form.Budget_From}</small> */}
                                </div>
                                <div className="bid_range" style={{ paddingRight: "2%" }}>
                                  <label htmlFor="Max Hours" style={{ color: "GrayText" }}>Max Hours</label>
                                  <input
                                    // value={job?.Budget_To}
                                    type="text"
                                    name="Max_Hours"
                                    placeholder="Max Hours"
                                    value={bid.MaxHours}
                                    // className={form.Budget_To != "" ? "errorborder" : ""}
                                    onChange={(e) => {
                                      const input = e.target.value;
                                      if (/^\d+$/.test(input) && parseInt(input) >= 0) {
                                        setbid({ ...bid, MaxHours: input.slice(0, 6) });
                                      } else {
                                        setbid({ ...bid, MaxHours: "" });
                                      }
                                    }}
                                  />
                                  {/* <small style={{ color: "#ec1c24" }}>{form.Budget_To}</small> */}
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100%" }}>
                              <p>Cover Letter<span className="asterisk">*</span></p>
                              <textarea
                                style={{ width: '100%' }}
                                placeholder="Enter Short Description"
                                value={bid.ShortDescription}
                                onChange={(event) => {
                                  if (event.target.value.length > 100) {
                                    message.warning("Cover Letter only 100 characters allowed!")
                                  }
                                  else {
                                    setbid({ ...bid, ShortDescription: event.target.value })
                                  }
                                }}>

                              </textarea>
                            </div>
                          </div>
                        }
                      </div>
                    </form>

                    <div className="userdeatail_container" style={{ marginTop: "3%", width: '100%', gridTemplateColumns: "91%" }}>
                      <div>
                        <p>Proposal<span className="asterisk">*</span></p>
                        <CKEditor
                          data={Proposal}
                          editor={ClassicEditor}
                          // readOnly={editable}
                          // config={{
                          //   toolbar: [
                          //     { name: "document", items: ["Undo", "Redo"] },
                          //     { name: "clipboard", items: ["Cut", "Copy", "Paste"] },
                          //     { name: "styles", items: ["Format"] },
                          //     {
                          //       name: "basicstyles",
                          //       items: ["Bold", "Italic", "Strike", "-", "RemoveFormat"],
                          //     },
                          //   ],
                          // }}
                          onChange={onEditorChange}
                        />
                      </div>
                    </div>
                    {show ? (
                      <>
                        <Milestone
                          forms={forms}
                          setForms={setForms}
                          editable={editable}
                          detailjob={detailjob}
                          milestonesAmountCheck={milestonesAmountCheck}
                          setMilestoneAmountCheck={setMilestoneAmountCheck}
                          Bid_Status={bid.Bid_Status}
                          disputeStatus={disputeStatus}
                        />
                        {!editable ? (
                          <>
                            <button
                              className="btn"
                              style={{ padding: "10px", margin: "10px 5px" }}
                              onClick={() => { handleApplyBtn("Active") }}
                            >
                              Apply For Bid
                            </button>
                            <button
                              className="btn"
                              style={{ padding: "10px", background: "#a0a046" }}
                              onClick={() => handleApplyBtn("Draft")}
                            >
                              Save As Draft
                            </button>
                          </>
                        ) : null}
                      </>
                    ) :
                      user.profile_progress >= 70 ?
                        detailjob?.ProjectType === "Fixed" ?
                          <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={() => { checkUser(); }}>Add Milestone</button>
                          :
                          <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={() => { checkUserForHour(); }}>Apply Bid</button>
                        :
                        <Popup content='your profile must be 70% complete' trigger={<button className="mil_buton disabled" style={{ padding: "15px", marginTop: "20px" }} >Add Milestone</button>
                        }
                        />
                    }
                  </div>
              }
            </>
          }
        </div>
        : user?.Type !== "Employer" ?
          <button className="btn" style={{ padding: "15px", marginTop: "20px" }} onClick={LoginPageRedirect}>Place your Bids</button>
          : null}

    </div>
  );
}

export default Bidding;