import React from 'react'
import Hire from '../images/Hire.png'
function HireFreelancer() {
  return (
    <div className="SignUpEmployee">
      <h1>Find best suite jobs for your profile  !</h1>
      <p className="sign-up-content">
        We make it simple to find and apply for jobs that match your qualifications and skills.
      </p>
      <div className="sign-up-content-img">
        <div className="sign-up-img"><img src={Hire} alt="" /></div>
        <div className="sign-up-points">
          <div className="sign-up-points-numbers">
            <div className="number">1</div>
            <div className="content"><h3>Check available job listings</h3>
              <p>Check through all available freelance jobs online or get recommendations best suited to your skills with Top Match and Good Match jobs.</p>
            </div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">2</div>
            <div className="content"><h3>Submit Your quotes</h3>
              <p>Select one of the available payment options and let us know how well you understand the job's scope. And yes, a perfectly created profile will do the rest for you !</p></div>
          </div>
          <div className="sign-up-points-numbers">
            <div className="number">3</div>
            <div className="content"><h3>Get Started</h3>
              <p>Complete the Agreement to Start Work by thoroughly going over the Quote with the Employer.
              </p>
            </div>
          </div>

        </div>

      </div>

    </div>
  )
}

export default HireFreelancer