import React, { useEffect, useContext, useState } from "react";
import { MyContext } from "../../Mycontext";
import axios from "axios";
import moment from "moment";
import BiddingUserInformation from "./BiddingUserInformation";
import Rating from "../Rating";

function BiddingCard({ disputeStatus,change, JobID, ProjectType, JobTitle, getDispute, setCurrentFreelancer }) {
  const { Token, endPointUrl, user } = useContext(MyContext);
  const [bits, setBits] = useState([]);
  const [freelancer, setfreelancer] = useState(null);
  const [BidID, setBidID] = useState(null);
  const [P_ID, setP_ID] = useState(null);
  const [Show, setShow] = useState(true);
  const [reviewShow, setreviewShow] = useState(null);
  const [employebit, setemployebit] = useState([]);
  const [totallength, settotallength] = useState(0);
  const [freelancerID, setFreelancerID] = useState('');
  const [closedDetail, setClosedDetail] = useState([]);

  const [Allbits, setAllBits] = useState({
    New: [],
    Review: [],
    Approved: [],
    Rejected: [],
  });
  const [activejob, setActivejob] = useState(0);
  const handlejobClick = (buttonIndex) => {
    setActivejob(buttonIndex);
  };
  const gettotalbits = async (JobID) => {
    const { data } = await axios.post(endPointUrl + "/user/job/totalBid", {
      Token,
      JobID,
    });
    settotallength((data?.data).length)
    const New = data.data.filter((item) => item?.View_Status === false);
    const Review = data.data.filter(
      (item) => (item?.Bid_Status === "Active" || item?.Bid_Status === "Submitted") && item?.View_Status
    );
    const Approved = data.data.filter(
      (item) => item?.Bid_Status === "Approved" && item?.View_Status
    );
    const Rejected = data.data.filter(
      (item) => (item?.Bid_Status === "Reject" || item?.Bid_Status === "Closed") && item?.View_Status
    );

    const ClosedDetail = data.data.filter(
      (item) => item?.Bid_Status === "Awarded" && item?.View_Status
    );

    setActivejob(0);
    setBits(New);
    setAllBits({ New, Review, Approved, Rejected });
    setemployebit(Approved);
    setClosedDetail(ClosedDetail);
    setfreelancer(data.data[0]?.UserID);
    setCurrentFreelancer(data.data[0]?.UserID);
    setBidID(Approved[0]?.Bid_ID);
    getDispute(data.data[0]?.UserID);

    const Users = data.data.filter(
      (item) => (item?.Bid_Status === "Approved" || item?.Bid_Status === "Awarded") && item?.View_Status
    )
    getBidStatusByJobIDForUser(Users[0]?.UserID)
    getprojectForUser(Users[0]?.Bid_ID, Users[0]?.UserID);
  };

  useEffect(() => {
    if (BidID) {
      getproject(BidID)
    }
  }, [BidID])

  const getproject = async (BidID) => {
    if (BidID) {
      const { data } = await axios.post(
        endPointUrl + "/project/getMilestoneforBid",
        { Bid_ID: BidID, Token }
      );
      const mil = data.milestones;
      setreviewShow(mil[mil.length - 1]?.FreelancerPaymentStatus);
      setP_ID(mil[0]?.P_ID);
    }
  };

  const getprojectForUser = async (BidID, UserID) => {
    if (BidID) {
      const { data } = await axios.post(
        endPointUrl + "/project/getMilestoneforBidForUser",
        { Bid_ID: BidID, Token, UserID }

      );
      const mil = data.milestones;
      setreviewShow(mil[mil.length - 1]?.FreelancerPaymentStatus);
      setP_ID(mil[0]?.P_ID);
    }
  };

  const getBidStatusByJobIDForUser = async (UserID) => {
    const { data } = await axios.post(
      endPointUrl + "/job/biddingForUser",
      { JobID, UserID, Token }
    );
    if (data.status === 'Approved' || data.status === "Awarded") {
      setShow(false);
    }
  };

  const getBidStatusByJobID = async (UserID) => {
    const { data } = await axios.post(
      endPointUrl + "/job/bidding",
      { JobID, UserID, Token }
    );
    if (data.status === 'Approved' || data.status === "Awarded") {
      setShow(false);
    }
  };

  // sorting based on price
  const handleSortByAmount = (value) => {
    const sortedBits = [...bits]; // Create a copy of the 'bits' array
    if (value === "low")
      sortedBits.sort((a, b) => a.BidAmount - b.BidAmount);
    else if (value === "high")
      sortedBits.sort((a, b) => b.BidAmount - a.BidAmount);
    else if (value === "new") {
      sortedBits.sort((a, b) => moment(a.Bid_date).diff(moment(b.Bid_date)));
    }
    else if (value === "old") {
      sortedBits.sort((a, b) => moment(b.Bid_date).diff(moment(a.Bid_date)));
    }
    // Sort by the 'Amount' property in ascending order
    setBits(sortedBits); // Update the state with the sorted array
  };

  useEffect(() => {
    if (JobID) {
      gettotalbits(JobID);
      getBidStatusByJobID(JobID)
    }
  }, [JobID, BidID]);


  console.log("Closed Bid detail", closedDetail)

  return (
    <>
      {Show ?
        <div className="jobContainer">
          <span>Bids({totallength})</span>
          <div style={{ display: "inline-block", float: 'right' }}>
            <select name="Bits" id="Bits" style={{
              background: "#ffffff",
              borderRadius: "3px",
              outline: "none", fontSize: "12px", padding: '5px 0'
            }} onChange={(e) => handleSortByAmount(e.target.value)} >
              <option>Sort</option>
              <option value="low">Low to High</option>
              <option value="high">High to Low</option>
              <option value="new">Latest</option>
              <option value="old">Oldest</option>
            </select></div>
          <div className="jobMatch">
            <span
              style={
                activejob === 0
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.New);
                handlejobClick(0);
              }}
            >
              New({Allbits.New.length})
            </span>
            <span
              style={
                activejob === 1
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.Review);
                handlejobClick(1);
              }}
            >
              Review({Allbits.Review.length})
            </span>
            <span
              style={
                activejob === 2
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.Approved);
                handlejobClick(2);
              }}
            >
              Approved({Allbits.Approved.length})
            </span>
            <span
              style={
                activejob === 3
                  ? {
                    borderBottom: "4px solid #07b273",
                  }
                  : null
              }
              onClick={() => {
                setBits(Allbits.Rejected);
                handlejobClick(3);
              }}
            >
              Rejected({Allbits.Rejected.length})
            </span>
          </div>
          <hr />
          {bits.length > 0 ? bits.map((item) => (
            <div className="bittingCard">
              <BiddingUserInformation disputeStatus={disputeStatus} change={change} JobID={JobID} ProjectType={ProjectType} item={item} Show={Show} JobTitle={JobTitle} />
            </div>
          )) : <span>No bids</span>}
        </div>
        : <div className="jobContainer" style={{ border: "none", marginTop: "0", padding: "0", paddingTop: "30px" }}>
          <Rating disputeStatus={disputeStatus} P_ID={P_ID} JobID={JobID} JobTitle={JobTitle} show={reviewShow} freelancer={freelancer} />
          <div className="jobContainer_header">
            <span>{employebit.length > 0 ? "Freelancer Details" : ""}</span>
          </div>
          <div className="jobContainer_header">
            <span>{closedDetail.length > 0 ? "Freelancer Details" : ""}</span>
          </div>
          {
            closedDetail.map((item) => (
              <div className="bitttingCard">
                <BiddingUserInformation disputeStatus={disputeStatus} ProjectType={ProjectType} change={change} item={item} Show={Show} JobID={JobID} JobTitle={JobTitle} />
              </div>
            ))
          }
          {employebit.map((item) => (
            <div className="bitttingCard">
              <BiddingUserInformation disputeStatus={disputeStatus} ProjectType={ProjectType} change={change} item={item} Show={Show} JobID={JobID} JobTitle={JobTitle} />
            </div>
          ))}
        </div>
      }
    </>
  );
}

export default BiddingCard;
