import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MyContext } from "../../Mycontext";
import editbutton from "../../images/edit.png";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import Selectant from "../Jobs/Select";
import { AiOutlineFile } from "react-icons/ai";
import Userskills from "./Userskills";
import TimezoneSelect from "react-timezone-select";
import add from "../../images/add.png";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getByTitle } from "@testing-library/react";


function Userprofileupdate({ userDetail }) {
  const [showmodel, setshowModel] = useState(false);
  const [showbankError, setShowbankError] = useState(false);
  const { user, setuser, endPointUrl, Token } = useContext(MyContext);
  const [Languagemaster, setLanguagemaster] = useState([]);
  const [freelancerStatus, setFreelancerStatus] = useState(true);
  const [File_Type, setFile_Type] = useState(userDetail.Verification_doc_type);
  const [preform, setPreform] = useState();
  const [allskills, setallskills] = useState([]);
  const [skillmaster, setskillmaster] = useState([]);
  const [edit, setedit] = useState({
    Name: userDetail.Name,
    Last_Name: userDetail.Last_Name,
    Address: userDetail.Address,
    Country: userDetail.Country,
    City: userDetail.City,
    State: userDetail.State,
    Zip: userDetail.Zip,
    Language: getLanguage(),
    Skills: userDetail.Skills?.split(","),
    About: userDetail.About,
    Gender: userDetail.Gender,
    Email: userDetail.Email,
    Mobile: userDetail.Mobile,
    rate_from: userDetail.rate_from,
    rate_type: userDetail.rate_type,
    Bank_Name: userDetail?.Bank_Name,
    Account_Number: userDetail?.Account_Number,
    Account_Name: userDetail?.Account_Name,
    IFSC_CODE: userDetail?.IFSC_CODE,
    SWIFT_CODE: userDetail?.SWIFT_CODE,
    BRANCH_DETAILS: userDetail?.BRANCH_DETAILS,
    BANK_COUNTRY: userDetail?.BANK_COUNTRY,
    Time_Zone: userDetail?.Time_Zone,
    Monthly_Availability: userDetail?.Monthly_Availability,
    Weekly_Availability: userDetail?.Weekly_Availability,
    Age: userDetail?.Age,
    ScreenName: userDetail?.ScreenName,
    currency_type: userDetail?.currency_type,
    HourlyRate: userDetail?.HourlyRate,
    WeeklyRate: userDetail?.WeeklyRate,
    MonthlyRate: userDetail?.MonthlyRate,
    IBAN: userDetail?.IBAN,
    SORT_CODE: userDetail?.SORT_CODE,
    Routing_Number: userDetail?.Routing_Number,
    Routing_Code: userDetail.Routing_Code,
    Contractual: userDetail?.Contractual,
    TimeZone: getTimeZone(userDetail?.TimeZone) || "",
  });

  function getTimeZone(timeZone) {
    try {
      return JSON.parse(timeZone);
    } catch (error) {
      return timeZone
    }
  }
  const [errors, setErrors] = useState({
    Name: "",
    Last_Name: "",
    Address: "",
    About: "",
    Gender: "",
    Email: "",
    Mobile: "",
    rate_from: "",
    rate_type: "",
    Age: "",
    ScreenName: "",
    Proficiency: "",
    Language: "",
    HourlyRate: "",
    WeeklyRate: "",
    MonthlyRate: "",
    Routing_Code: "",
    // Add other fields here
  });

  function getLanguage() {
    let languages;
    try {
      languages = JSON.parse(userDetail.Language);
    } catch (error) {
      return user?.Language;
    }
    return languages;
  }

  const [showLanguageContainer, setShowLanguagecontainer] = useState({});

  const [language, setLanguage] = useState({
    Language1: edit?.Language?.Language1 ? edit?.Language?.Language1 : "",
    Language2: edit?.Language?.Language2 ? edit?.Language?.Language2 : "",
    Language3: edit?.Language?.Language3 ? edit?.Language?.Language3 : "",
    Language4: edit?.Language?.Language4 ? edit?.Language?.Language4 : "",
    Language5: edit?.Language?.Language5 ? edit?.Language?.Language5 : "",
    Proficiency1: edit?.Language?.Proficiency1
      ? edit?.Language?.Proficiency1
      : "",
    Proficiency2: edit?.Language?.Proficiency2
      ? edit?.Language?.Proficiency2
      : "",
    Proficiency3: edit?.Language?.Proficiency3
      ? edit?.Language?.Proficiency3
      : "",
    Proficiency4: edit?.Language?.Proficiency4
      ? edit?.Language?.Proficiency4
      : "",
    Proficiency5: edit?.Language?.Proficiency5
      ? edit?.Language?.Proficiency5
      : "",
  });

  const validateForm = () => {
    const newErrors = {
      Name: "",
      Last_Name: "",
      Address: "",
      About: "",
      Gender: "",
      Email: "",
      Mobile: "",
      rate_from: "",
      rate_type: "",
      Age: "",
      ScreenName: "",
      Language: "",
      Proficiency: "",
      HourlyRate: "",
      WeeklyRate: "",
      MonthlyRate: "",
      Routing_Code: "",
      // Add other fields here
    };

    let hasErrors = false;

    if (!edit?.Name) {
      newErrors.Name = "Name is required";
      hasErrors = true;
    }

    if (!edit?.Last_Name) {
      newErrors.Last_Name = "Last Name is required";
      hasErrors = true;
    }

    if (!edit?.Email) {
      newErrors.Email = "Email is required";
      hasErrors = true;
    }

    if (!edit?.Age) {
      newErrors.Age = "Age is required";
      hasErrors = true;
    }
    else {
      if (edit.Age <= 17) {
        newErrors.Age = "Age must be 18 or greater";
        hasErrors = true;
      }
    }

    if (!edit?.ScreenName) {
      newErrors.ScreenName = "Screen Name is required";
      hasErrors = true;
    }

    if (edit.ScreenName && edit?.ScreenName.length >= 50) {
      newErrors.ScreenName = "Screen Name must be less than 50 characters";
      hasErrors = true;
    }

    if (!edit?.Address) {
      newErrors.Address = "Address is required";
      hasErrors = true;
    }

    if (edit?.Skills?.length <= 0) {
      newErrors.skills = "Skills is required";
      hasErrors = true;
    }

    if (!edit?.About) {
      newErrors.About = "About is required";
      hasErrors = true;
    }

    if (!language?.Language1) {
      newErrors.Language = "Atleast One Language is required";
      hasErrors = true;
    }

    if (!language?.Proficiency1) {
      newErrors.Proficiency = "Proficiency is required";
      hasErrors = true;
    }

    if (!edit?.Gender) {
      newErrors.Gender = "Gender is required";
      hasErrors = true;
    }
    if (!edit?.Country) {
      newErrors.country = "Country is required";
      hasErrors = true;
    }

    if (!edit?.Mobile) {
      newErrors.Mobile = "Mobile is required";
      hasErrors = true;
    }
    // } else if (edit?.Mobile?.length !== 10 || isNaN(parseInt(edit?.Mobile))) {
    //   newErrors.Mobile =
    //     "Mobile must be 10 digits long and contain only numbers";
    //   hasErrors = true;
    // }

    // if (!edit?.rate_from && user?.Type == "Freelancer") {
    //   newErrors.rate_from = "Rate is required";
    //   hasErrors = true;
    // }

    // if (!edit?.rate_type && user?.Type == "Freelancer") {
    //   newErrors.rate_to = "Rate is required";
    //   hasErrors = true;
    // }

    if (!edit?.HourlyRate && user?.Type == "Freelancer") {
      newErrors.HourlyRate = "Hourly Rate Type is required";
      hasErrors = true;
    }

    if (!edit?.WeeklyRate && user?.Type == "Freelancer") {
      newErrors.WeeklyRate = "Weekly Rate Type is required";
      hasErrors = true;
    }

    if (!edit?.MonthlyRate && user?.Type == "Freelancer") {
      newErrors.MonthlyRate = "Monthly Rate Type is required";
      hasErrors = true;
    }

    // if (!edit?.currency_type && user?.Type == "Freelancer") {
    //   newErrors.currency_type = "Currency Type is required";
    //   hasErrors = true;
    // }

    // if (
    //   (!edit?.Bank_Name &&
    //     !edit?.Account_Number &&
    //     !edit?.Account_Name &&
    //     !edit?.IFSC_CODE &&
    //     !edit?.SWIFT_CODE &&
    //     !edit?.BRANCH_DETAILS &&
    //     !edit?.BANK_COUNTRY) ||
    //   (edit?.Bank_Name &&
    //     edit?.Account_Number &&
    //     edit?.Account_Name &&
    //     edit?.IFSC_CODE &&
    //     edit?.SWIFT_CODE &&
    //     edit?.BRANCH_DETAILS &&
    //     edit?.BANK_COUNTRY)
    // ) {
    //   setShowbankError(false);
    // } else {
    //   setShowbankError(true);
    //   if (!edit?.Bank_Name) {
    //     newErrors.Bank_Name = "Bank Name is required";
    //     hasErrors = true;
    //   } else if (!edit?.Account_Name) {
    //     newErrors.Account_Name = "Account Name is required";
    //     hasErrors = true;
    //   } else if (!edit?.Account_Number) {
    //     newErrors.Account_Number = "Account Number is required";
    //     hasErrors = true;
    //   } else if (!edit?.IFSC_CODE) {
    //     newErrors.IFSC_CODE = "IFSC Code is required";
    //     hasErrors = true;
    //   } else if (!edit?.SWIFT_CODE) {
    //     newErrors.SWIFT_CODE = "Swift Code is required";
    //     hasErrors = true;
    //   } else if (!edit?.BRANCH_DETAILS) {
    //     newErrors.BRANCH_DETAILS = "Branch Details is required";
    //     hasErrors = true;
    //   } else if (!edit?.BANK_COUNTRY) {
    //     newErrors.BANK_COUNTRY = "Bank Country is required";
    //     hasErrors = true;
    //   }
    // }

    if (
      edit?.BANK_COUNTRY == "Australia" ||
      edit?.BANK_COUNTRY == "New Zealand"
    ) {
      newErrors.Routing_Code = "Routing Code must contain only 6 characters";
      hasErrors = true;
    }
    // Add validation for other fields as needed

    setErrors(newErrors);
    return !hasErrors;
  };

  const handleLanguageChange = (selectedLanguages) => {
    setLanguageSelected(selectedLanguages);
    setedit({ ...edit, Language: selectedLanguages });
  };
  const handleSkillChange = (selectedSkills) => {
    setSkillSelected(selectedSkills);
    setedit({ ...edit, Skills: selectedSkills });
  };

  const uploadfiles = async () => {
    console.log('uploadfiles')
    const formdata = new FormData();
    let filePath = "";
    formdata.append("photo", preform);
    setPreform();
    try {
      const { data } = await axios.post(
        endPointUrl + "/user/imageUpload",
        formdata //Documents array upload
      );
      filePath = data.fileName;
      try {
        await axios.post(endPointUrl + "/addverification", {
          Token,
          id: user.id,
          Verification_Doc: filePath,
          Verification_doc_type: File_Type,
          Verified_Profile: "Pending",
        });
        message.success("Document Added");
        // setuser({
        //   ...user,
        //   Verification_Doc: data.fileName,
        //   Verification_doc_type: File_Type,
        // });
      } catch {
        console.log("upload url produce error");
      }
    } catch {
      console.log("error occur during upload docs");
    }
  };

  const handleExpModel = () => {
    setshowModel(true);
  };
  const CloseModel = () => {
    setshowModel(false);
  };
  const handleStatusToggle = async (newStatusValue) => {
    try {
      await axios.post(endPointUrl + "/updateuserProfile", {
        Token,
        id: user.id,
        Status_by_Admin: newStatusValue,
      });

      setFreelancerStatus(newStatusValue);
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleLanguageMaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setLanguagemaster(a);
  };

  const handleskillsmaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setskillmaster(a);
  };


  const handleSubmitUseredit = async (data) => {
    const languages = JSON.stringify(language);

    data = { ...data, Language: languages, TimeZone: JSON.stringify(edit?.TimeZone) };

    if (validateForm()) {
      if (userDetail.Verified_Profile != "Verified" && preform) {
        if (preform === "InvalidDocument") {
          return message.error("Invalid file type");
        }
        uploadfiles();
      }

      try {
        await axios.post(endPointUrl + "/updateUserProfile", {
          ...data,
          id: userDetail.id,
          Token,
        });
        setuser({
          ...userDetail,
          Name: edit.Name,
          Last_Name: edit.Last_Name,
          Email: edit.Email,
          Address: edit.Address,
          Country: edit.Country,
          City: edit.City,
          State: edit.State,
          Zip: edit.Zip,
          Language: languages,
          Skills: edit.Skills?.join(","),
          About: edit.About,
          Gender: edit.Gender,
          Mobile: edit.Mobile,
          rate_from: edit.rate_from,
          rate_type: edit.rate_type,
          Bank_Name: edit?.Bank_Name,
          Account_Number: edit?.Account_Number,
          Account_Name: edit?.Account_Name,
          IFSC_CODE: edit?.IFSC_CODE,
          SWIFT_CODE: edit?.SWIFT_CODE,
          BRANCH_DETAILS: edit?.BRANCH_DETAILS,
          BANK_COUNTRY: edit?.BANK_COUNTRY,
          Time_Zone: edit?.Time_Zone,
          Monthly_Availability: edit?.Monthly_Availability,
          Weekly_Availability: edit?.Weekly_Availability,
          Age: edit?.Age,
          ScreenName: edit?.ScreenName,
          // currency_type: edit?.currency_type,
          HourlyRate: edit?.HourlyRate,
          WeeklyRate: edit?.WeeklyRate,
          MonthlyRate: edit?.MonthlyRate,
          IBAN: edit?.IBAN,
          SORT_CODE: edit?.SORT_CODE,
          Routing_Number: edit?.Routing_Number,
          Routing_Code: edit?.Routing_Code,
          Contractual: edit?.Contractual,
          TimeZone: edit?.TimeZone || "",
        });
        setshowModel(false);
        message.success("Profile has been updated");
      } catch (err) {
        console.log('error', err);
        message.error("profile is Not Updated");
      }

      // Clear the errors if the submission was successful
      setErrors({
        Name: "",
        Last_Name: "",
        Address: "",
        About: "",
        Gender: "",
        Email: "",
        Mobile: "",
        rate_from: "",
        rate_type: "",
        Age: "",
        ScreenName: "",
        currency_type: "",
        Language: "",
        Proficiency: "",
        HourlyRate: "",
        WeeklyRate: "",
        MonthlyRate: "",
        // Add other fields here
      });
    }
  };


  const [selectedCountry, setSelectedCountry] = useState(edit.Country || null);
  const [selectedState, setSelectedState] = useState(edit.State || null);
  const [selectedCity, setSelectedCity] = useState(edit.City || null);
  const [LanguageSelected, setLanguageSelected] = useState(null);
  const [SkillSelected, setSkillSelected] = useState(null);
  useEffect(() => {
    if (edit.Language != "") {
      setLanguageSelected(edit.Language);
    }
    if (edit.Skills != "") {
      setSkillSelected(edit.Skills);
    }

    handleLanguageMaster();
    handleskillsmaster();
  }, []);

  function showLanguage() {
    return Languagemaster.map((item) => {
      return <option>{item}</option>;
    });
  }

  function handleAddLanguageShow() {
    if (!showLanguageContainer.Language2) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language2: true });
    } else if (!showLanguageContainer.Language3) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language3: true });
    } else if (!showLanguageContainer.Language4) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language4: true });
    } else if (!showLanguageContainer.Language5) {
      setShowLanguagecontainer({ ...showLanguageContainer, Language5: true });
    }
  }

  function getMonthlyHours() {
    const option = [];
    for (let i = 1; i <= 720; i++) {
      option.push(<option>{i}</option>);
    }
    return option;
  }

  function getWeeklyHours() {
    const option = [];
    for (let i = 1; i <= 168; i++) {
      option.push(<option>{i}</option>);
    }
    return option;
  }

  function getAgeOptions() {
    const option = []
    option.push(<option value="">Select Age</option>)
    for (let i = 18; i <= 60; i++) {
      option.push(<option value={i}>{i}</option>)
    }
    return option;
  }

  return (
    <>
      {" "}
      <img
        src={editbutton}
        style={{
          paddingLeft: "15px",
          zIndex: "9",
          cursor: "pointer",
          width: "40px",
          position: "absolute",
          top: "5px",
          right: "7px",
        }}
        onClick={() => handleExpModel()}
      />
      <div id="myModal" className={showmodel ? "model-open" : "model"}>
        <div className="modal-content" style={{ padding: 0 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#ebebeb",
              padding: "0 40px",
              borderRadius: "20px 20px 0 0",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
              <h2 style={{ color: "#46c294" }}>Edit Profile</h2>

              <div
                className="radio-inputs-user"
                style={{
                  padding: 0,
                  width: "auto",
                  borderRadius: "25px",
                  background: "#fff",
                }}
              >
                <label className="radio" style={{ fontSize: "12px" }}>
                  <input
                    style={{ padding: "5px,15px" }}
                    type="radio"
                    name="radio"
                    checked={freelancerStatus === true}
                    onChange={() => handleStatusToggle(true)}
                  />
                  <span
                    className="status"
                    style={{ borderRadius: "25px", padding: "5px,15px" }}
                  >
                    Active
                  </span>
                </label>
                <label className="radio" style={{ fontSize: "12px" }}>
                  <input
                    type="radio"
                    name="radio"
                    style={{ padding: "5px,15px" }}
                    checked={freelancerStatus === false}
                    onChange={() => handleStatusToggle(false)}
                  />
                  <span
                    className="status"
                    style={{ borderRadius: "25px", padding: "5px,15px" }}
                  >
                    InActive
                  </span>
                </label>
              </div>
            </div>
            <span className="close" onClick={CloseModel}>
              &times;
            </span>
          </div>

          <div style={{ marginTop: "30px" }}>
            <form
              className="model-field userprofile exp-form"
              encType="multipart/form-data"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmitUseredit(edit);
              }}
            >
              <div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="Name">
                      First Name<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>{errors.Name}</small>
                    <input
                      type="text"
                      name="Name"
                      value={edit.Name}
                      placeholder="Enter username"
                      className={errors.Name != "" ? "errorborder" : ""}
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        const firstWord = inputValue.split(" ")[0].slice(0, 45);
                        setedit({ ...edit, Name: firstWord });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="Last_Name">
                      Last Name<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>
                      {errors.Last_Name}
                    </small>
                    <input
                      type="text"
                      name="Last_Name"
                      value={edit.Last_Name}
                      placeholder="Enter last name"
                      className={errors.Last_Name != "" ? "errorborder" : ""}
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        const firstWord = inputValue.split(" ")[0].slice(0, 45);
                        setedit({ ...edit, Last_Name: firstWord });
                      }}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="ScreenName">
                      Screen Name<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>
                      {errors.ScreenName}
                    </small>
                    <input
                      type="text"
                      name="ScreenName"
                      value={edit.ScreenName}
                      className={errors.ScreenName != "" ? "errorborder" : ""}
                      placeholder="Enter your screen name"
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        const firstWord = inputValue.split(" ")[0];
                        setedit({ ...edit, ScreenName: firstWord });
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="Gender" style={{ marginBottom: "10px" }}>
                      Gender<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>{errors.Gender}</small>
                    <div className="radio">
                      <span className="radio_span">
                        <input
                          type="radio"
                          name="Gender"
                          value="male"
                          defaultChecked={
                            edit?.Gender?.toLowerCase() === "male"
                          }
                          onClick={(e) =>
                            setedit({ ...edit, Gender: e.target.value })
                          }
                        />
                        <label>Male </label>
                      </span>

                      <span className="radio_span">
                        <input
                          type="radio"
                          name="Gender"
                          value="female"
                          defaultChecked={
                            edit?.Gender?.toLowerCase() === "female"
                          }
                          onClick={(e) =>
                            setedit({ ...edit, Gender: e.target.value })
                          }
                        />
                        <label>Female </label>
                      </span>

                      <span className="radio_span">
                        <input
                          type="radio"
                          name="Gender"
                          value="other"
                          defaultChecked={
                            edit?.Gender?.toLowerCase() === "other"
                          }
                          onClick={(e) =>
                            setedit({ ...edit, Gender: e.target.value })
                          }
                        />
                        <label>Other </label>
                      </span>
                    </div>
                    <small style={{ color: "#ec1c24" }}>{errors.Gender}</small>
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="Age">
                      Age<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>{errors.Age}</small>
                    <select
                      style={{ width: '100%' }}
                      name="Age"
                      value={edit.Age}
                      className={errors.Age != "" ? "errorborder" : ""}
                      placeholder="Enter Your Age"
                      // onChange={(e) => {
                      //   const inputValue = e.target.value.trim();
                      //   const firstWord = inputValue.split(' ')[0];
                      //   setedit({ ...edit, Age: firstWord });
                      // }}

                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 2);
                        setedit({ ...edit, Age: input });
                      }}
                    >
                      {getAgeOptions()}
                    </select>
                  </div>

                  <div>
                    <label htmlFor="Mobile">
                      Mobile<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>{errors.Mobile}</small>
                    {/* <input
                      type="tel"
                      name="Mobile"
                      value={edit.Mobile}
                      className={errors.Mobile != "" ? "errorborder" : ""}
                      placeholder="Enter Your Mobile Number"
                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 10);
                        setedit({ ...edit, Mobile: input });
                      }}
                    /> */}
                    <PhoneInput
                      international
                      className={errors.Mobile != "" ? "errorborder" : ""}
                      country={"in"}
                      value={edit.Mobile}
                      onChange={(phone) => {
                        setedit({ ...edit, Mobile: phone })
                      }}
                    />
                  </div>
                </div>

                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="Email">
                      Email<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>{errors.Email}</small>
                    <input
                      style={{ backgroundColor: "#e3e3e3" }}
                      readOnly={true}
                      type="email"
                      name="Email"
                      value={edit.Email}
                      className={errors.Email != "" ? "errorborder" : ""}
                      placeholder="Enter Your Email"
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        const firstWord = inputValue.split(" ")[0];
                        setedit({ ...edit, Email: firstWord });
                      }}
                    />
                  </div>
                </div>

                <div className="userdeatail_container"></div>
              </div>
              <hr />
              <div>
                <div
                  className="userdeatail_container"
                  style={{ gridTemplateColumns: "100%" }}
                >
                  <div>
                    <h5>Time Zone</h5>
                    <div
                      className="select-wrapper"
                      style={{ marginBottom: "10px" }}
                    >
                      <TimezoneSelect
                        value={edit?.TimeZone}
                        onChange={(val) => {
                          setedit({
                            ...edit,
                            TimeZone: val,
                            Time_Zone: val.label,
                          });

                        }}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: '100%',
                          }),
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "10px",
                      }}
                    >
                      {/* <label for="availability">Availability:</label> */}
                      <h5>Availability</h5>
                      <div style={{ display: "flex", gap: "20px" }}>
                        <div style={{ width: "100%" }}>
                          <label for="weekly">Weekly (in Hours):</label>
                          {/* <input
                            type="number"
                            min={0}
                            max={168}
                            name="weekly"
                            placeholder="Enter hours"
                            value={edit.Weekly_Availability}
                            onChange={(e) => {
                              if (e.target.value <= 168) {
                                console.log("setting state");
                                setedit({
                                  ...edit,
                                  Weekly_Availability: e.target.value,
                                });
                              }
                            }}
                            style={{
                              padding: "8px",
                              borderRadius: "8px",
                              width: "100%",
                              outline: "none",
                              border: "1px solid gray",
                            }}
                          /> */}
                          <select
                            name="weekly"
                            value={edit.Weekly_Availability}
                            onChange={(e) => {
                              if (e.target.value <= 168) {
                                console.log("setting state");
                                setedit({
                                  ...edit,
                                  Weekly_Availability: e.target.value,
                                });
                              }
                            }}
                            style={{
                              padding: "8px",
                              borderRadius: "8px",
                              width: "100%",
                              outline: "none",
                            }}
                          >
                            <option>Select Hours</option>
                            {getWeeklyHours()}
                          </select>
                        </div>
                        <div style={{ width: "100%" }}>
                          <label for="monthly">Monthly (in Hours):</label>
                          {/* <input
                            type="number"
                            min={0}
                            max={24 * 30}
                            name="monthly"
                            value={edit.Monthly_Availability}
                            placeholder="Enter hours"
                            onChange={(e) => {
                              if (e.target.value <= 720) {
                                console.log("setting state");
                                setedit({
                                  ...edit,
                                  Monthly_Availability: e.target.value,
                                });
                              }
                            }}
                            style={{
                              padding: "8px",
                              borderRadius: "8px",
                              width: "100%",
                              outline: "none",
                              border: "1px solid gray",
                            }}
                          /> */}
                          <select
                            name="monthly"
                            value={edit.Monthly_Availability}
                            placeholder="Enter hours"
                            onChange={(e) => {
                              if (e.target.value <= 720) {
                                console.log("setting state");
                                setedit({
                                  ...edit,
                                  Monthly_Availability: e.target.value,
                                });
                              }
                            }}
                            style={{
                              padding: "8px",
                              borderRadius: "8px",
                              width: "100%",
                              outline: "none",
                            }}
                          >
                            <option>Select Hours</option>
                            {getMonthlyHours()}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <label for="availability">
                        Open for contractual position / Projects:
                      </label>
                      <select
                        name="availability"
                        id="availability"
                        value={edit.Contractual}
                        onChange={(e) =>
                          setedit({ ...edit, Contractual: e.target.value })
                        }
                        style={{
                          padding: "5px",
                          borderRadius: "8px",
                          width: "100%",
                        }}
                      >
                        <option value="volvo">Select</option>
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </select>
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  {user.Type === "Freelancer" ? (
                    <div style={{ marginBottom: "30px" }}>
                      <div
                        className="userdeatail_container"
                        style={{ gridTemplateColumns: "100%" }}
                      >
                        <label style={{ marginTop: "20px" }}>
                          Rate<span className="asterisk">*</span>
                        </label>
                        <small style={{ color: "#ec1c24" }}>
                          {errors.rate_from}
                        </small>
                        <div style={{ display: "flex", gap: "20px" }}>
                          {/* <div >
                            <input
                              type="text"
                              style={{ marginBottom: 0, width: "auto" }}
                              name="rate_from"
                              id="rate_from"
                              value={edit.rate_from}
                              className={errors.rate_from != "" ? "errorborder" : ""}
                              placeholder="mn."
                              onChange={(e) => {
                                const input = e.target.value.replace(/\D/g, '').slice(0, 3);
                                setedit({ ...edit, rate_from: input });
                              }}

                            />

                          </div>
                          <div>/</div>
                          <div>
                             <label htmlFor="rate_type">rate_type:</label> 
                            <select
                              style={{ padding: "10px", margin: "0" }}
                              id="rate_type"
                              name="rate_type"
                              value={edit.rate_type}
                              className={errors.rate_type != "" ? "errorborder" : ""}
                              placeholder="Enter Your rate_type"
                              onChange={(e) => setedit({ ...edit, rate_type: e.target.value })}
                            >

                              <option value="">Select Rate Type</option>
                              <option value="hr.">hourly</option>
                              <option value="week">weekly</option>
                              <option value="month">monthly</option>
                            </select>
                            <small style={{ color: "#ec1c24" }}>{errors.rate_type}</small>
                          </div> */}

                          <div style={{ width: "33%" }}>
                            <label htmlFor="Hourly_Rate">Hourly ($)</label>
                            <input
                              style={{ marginBottom: 0, padding: "10px" }}
                              type="number"
                              name="Hourly_Rate"
                              id="Hourly_Rate"
                              value={edit.HourlyRate}
                              className={
                                errors.HourlyRate != "" ? "errorborder" : ""
                              }
                              onChange={(e) => {
                                const input = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 2);
                                setedit({ ...edit, HourlyRate: input });
                              }}
                            />
                            <small style={{ color: "#ec1c24" }}>
                              {errors.HourlyRate}
                            </small>
                          </div>

                          <div style={{ width: "33%" }}>
                            <label htmlFor="Weekly_Rate">Weekly ($)</label>
                            <input
                              style={{ marginBottom: 0, padding: "10px" }}
                              type="number"
                              name="Weekly_Rate"
                              id="Weekly_Rate"
                              value={edit.WeeklyRate}
                              className={
                                errors.WeeklyRate != "" ? "errorborder" : ""
                              }
                              onChange={(e) => {
                                const input = e.target.value
                                  .replace(/\D/g, "")
                                  .slice(0, 3);
                                setedit({ ...edit, WeeklyRate: input });
                              }}
                            />
                            <small style={{ color: "#ec1c24" }}>
                              {errors.WeeklyRate}
                            </small>
                          </div>

                          <div style={{ width: "33%" }}>
                            <label htmlFor="Monthly_Rate">Monthly ($)</label>
                            <input
                              style={{ marginBottom: 0, padding: "10px" }}
                              type="number" // Change type to "text"
                              name="Monthly_Rate"
                              id="Monthly_Rate"
                              value={edit.MonthlyRate}
                              className={
                                errors.MonthlyRate !== "" ? "errorborder" : ""
                              }
                              onChange={(e) => {
                                let input = e.target.value;
                                // Remove the letter 'e' if present
                                input = input.replace("e", "");
                                // Remove any non-digit characters
                                input = input.replace(/\D/g, "");
                                // Limit the input to 4 characters
                                input = input.slice(0, 4);
                                setedit({ ...edit, MonthlyRate: input });
                              }}
                            />
                            {/* <option value="">Select Monthly Rate</option> */}
                            {/* {getMonthlyOptions()} */}
                            {/* </select> */}
                            <small style={{ color: "#ec1c24" }}>
                              {errors.MonthlyRate}
                            </small>
                          </div>

                          {/* <div style={{ width: '25%' }}>
                            <label htmlFor="currency_type">Currency Type</label>
                            <select
                              style={{ padding: "10px", margin: "0" }}
                              id="currency_type"
                              name="currency_type"
                              value={edit.currency_type}
                              className={errors.currency_type != "" ? "errorborder" : ""}
                              placeholder="Enter Your currency_type"
                              onChange={(e) => setedit({ ...edit, currency_type: e.target.value })}
                            >
                              <option value="">Select Currency Type</option>
                              <option value="dollar" selected>$ Dollar</option>
                            </select>
                            <small style={{ color: "#ec1c24" }}>{errors.currency_type}</small>
                          </div> */}
                        </div>
                      </div>

                      <div
                        className="userdeatail_container"
                        style={{ gridTemplateColumns: "100%" }}
                      >
                        <div style={{ display: "flex", gap: "20px" }}></div>
                      </div>
                    </div>
                  ) : null}
                  <div style={{ padding: "0 40px", marginBottom: "30px" }}>
                    <label htmlFor="About">
                      About<span className="asterisk">*</span>
                    </label>
                    <textarea
                      style={{ width: "98%", padding: "10px" }}
                      name="About"
                      id="About"
                      value={edit.About}
                      placeholder="Enter about yourself"
                      className={errors.About !== "" ? "errorborder" : ""}
                      onChange={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue.length < 500) {
                          setedit({ ...edit, About: inputValue });
                        } else {
                          message.warning("Only 500 characters allowed!")
                        }
                      }}
                      required
                    />
                    <small style={{ color: "#ec1c24" }}>{errors.About}</small>
                  </div>
                  <div
                    className="userdeatail_container"
                    style={{ marginBottom: "0" }}
                  >
                    <div className="language">
                      <label htmlFor="Language">
                        Language<span className="asterisk">*</span>
                      </label>
                      <small style={{ color: "#ec1c24" }}>
                        {errors.Language}
                      </small>
                      <select
                        style={{ width: "100%" }}
                        value={language.Language1}
                        className={errors.Language != "" ? "errorborder" : ""}
                        onChange={(e) =>
                          setLanguage({
                            ...language,
                            Language1: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Language</option>
                        {showLanguage()}
                      </select>
                    </div>

                    <div className="language" style={{ padding: "0" }}>
                      <label htmlFor="proficiency">
                        Proficiency<span className="asterisk">*</span>
                      </label>
                      <small style={{ color: "#ec1c24" }}>
                        {errors.Proficiency}
                      </small>
                      <select
                        value={language.Proficiency1}
                        style={{ width: "100%" }}
                        className={
                          errors.Proficiency != "" ? "errorborder" : ""
                        }
                        onChange={(e) =>
                          setLanguage({
                            ...language,
                            Proficiency1: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Proficiency</option>
                        <option value="Expert">Expert</option>
                        <option value="Intermediate">Intermediate</option>
                        <option value="Beginner">Beginner</option>
                      </select>
                    </div>
                  </div>

                  {showLanguageContainer.Language2 ? (
                    <div
                      className="userdeatail_container"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="language">
                        <select
                          style={{ width: "100%" }}
                          value={language.Language2}
                          // className={errors.Language != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Language2: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Language</option>
                          {showLanguage()}
                        </select>
                        <small style={{ color: "#ec1c24" }}>
                          {errors.Language}
                        </small>
                      </div>

                      <div className="language" style={{ padding: "0" }}>
                        {/* <small style={{ color: "#ec1c24" }}>{errors.Proficiency}</small> */}
                        <select
                          value={language.Proficiency2}
                          style={{ width: "100%" }}
                          // className={errors.Proficiency != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency2: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {showLanguageContainer.Language3 ? (
                    <div
                      className="userdeatail_container"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="language">
                        <select
                          style={{ width: "100%" }}
                          value={language.Language3}
                          // className={errors.Language != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Language3: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Language</option>
                          {showLanguage()}
                        </select>
                        {/* <small style={{ color: "#ec1c24" }}>{errors.Language}</small> */}
                      </div>

                      <div className="language" style={{ padding: "0" }}>
                        {/* <small style={{ color: "#ec1c24" }}>{errors.Proficiency}</small> */}
                        <select
                          value={language.Proficiency3}
                          style={{ width: "100%" }}
                          // className={errors.Proficiency != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency3: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {showLanguageContainer.Language4 ? (
                    <div
                      className="userdeatail_container"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="language">
                        <select
                          style={{ width: "100%" }}
                          value={language.Language4}
                          // className={errors.Language != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Language4: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Language</option>
                          {showLanguage()}
                        </select>
                        {/* <small style={{ color: "#ec1c24" }}>{errors.Language}</small> */}
                      </div>

                      <div className="language" style={{ padding: "0" }}>
                        {/* <small style={{ color: "#ec1c24" }}>{errors.Proficiency}</small> */}
                        <select
                          value={language.Proficiency4}
                          style={{ width: "100%" }}
                          // className={errors.Proficiency != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency4: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  {showLanguageContainer.Language5 ? (
                    <div
                      className="userdeatail_container"
                      style={{ marginBottom: 0 }}
                    >
                      <div className="language">
                        <select
                          style={{ width: "100%" }}
                          value={language.Language5}
                          // className={errors.Language != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Language5: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Language</option>
                          {showLanguage()}
                        </select>
                        <small style={{ color: "#ec1c24" }}>
                          {errors.Language}
                        </small>
                      </div>

                      <div className="language" style={{ padding: "0" }}>
                        {/* <small style={{ color: "#ec1c24" }}>{errors.Proficiency}</small> */}
                        <select
                          value={language.Proficiency5}
                          style={{ width: "100%" }}
                          // className={errors.Proficiency != "" ? "errorborder" : ""}
                          onChange={(e) =>
                            setLanguage({
                              ...language,
                              Proficiency5: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Proficiency</option>
                          <option value="Expert">Expert</option>
                          <option value="Intermediate">Intermediate</option>
                          <option value="Beginner">Beginner</option>
                        </select>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div className="userdeatail_container">
                    <strong style={{ display: "flex", alignItems: "center" }}>
                      Add Language{" "}
                      <img src={add} alt="" onClick={handleAddLanguageShow} />
                    </strong>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div
                  className="userdeatail_container"
                  style={{ gridTemplateColumns: "100%" }}
                >
                  <label style={{ marginTop: "20px" }} htmlFor="Address">
                    Address<span className="asterisk">*</span>
                  </label>
                  <small style={{ color: "#ec1c24" }}>{errors.Address}</small>
                  <input
                    id="Addess"
                    type="text"
                    name="Address"
                    value={edit.Address}
                    className={errors.Address != "" ? "errorborder" : ""}
                    placeholder="Enter your address"
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      if (inputValue.length <= 100) {
                        setedit({ ...edit, Address: inputValue });
                      } else {
                        if (inputValue.length >= 100) {
                          message.warning(
                            "Address should have maximum 100 characters"
                          );
                        }
                      }
                    }}
                  />
                </div>
                <div className="userdeatail_container">
                  <div style={{ width: "96%" }}>
                    <label htmlFor="Country">
                      Country<span className="asterisk">*</span>
                    </label>
                    <small style={{ color: "#ec1c24" }}>{errors.country}</small>
                    <Select
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%',
                        }),
                      }}
                      options={Country.getAllCountries()}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={
                        Country.getAllCountries().find(
                          (country) => country.name === edit.Country
                        ) || null
                      }
                      onChange={(item) => {
                        setSelectedCountry(item);
                        setedit({
                          ...edit,
                          Country: item.name,
                          State: "",
                          City: "",
                          Zip: "",
                        });
                      }}
                    />
                  </div>
                  <div style={{ width: "96%" }}>
                    <label htmlFor="State">State</label>
                    <Select
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%',
                        }),
                      }}
                      options={State?.getStatesOfCountry(
                        selectedCountry?.isoCode
                      )}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={
                        State.getAllStates().find(
                          (state) => state.name === edit.State
                        ) || null
                      }
                      onChange={(item) => {
                        setSelectedState(item);
                        setedit({
                          ...edit,
                          State: item.name,
                          City: "",
                          Zip: "",
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div style={{ width: "96%" }}>
                    <label htmlFor="City">City</label>
                    <Select
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%',
                        }),
                      }}
                      options={City.getCitiesOfState(
                        selectedState?.countryCode,
                        selectedState?.isoCode
                      )}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={
                        City.getAllCities().find(
                          (city) => city.name === edit.City
                        ) || null
                      }
                      onChange={(item) => {
                        setSelectedCity(item);
                        setedit({ ...edit, City: item.name, Zip: "" });
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="Zip">Zip</label>
                    <input
                      type="text"
                      style={{ padding: "7px 10px", width: "91%" }}
                      name="Zip"
                      value={edit.Zip}
                      placeholder="Enter Your Zip code"
                      onChange={(e) => {
                        const inputValue = e.target.value.trim();
                        const input = inputValue
                          .replace(/\D/g, "")
                          .slice(0, 10);
                        setedit({ ...edit, Zip: input });
                      }}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="file">Verification Document Type</label>
                    <select
                      style={{ width: "96%", padding: "10px" }}
                      id="Verif_doc_type"
                      name="Verif_doc_type"
                      value={File_Type}
                      placeholder="Enter Your document type"
                      onChange={(e) => setFile_Type(e.target.value)}
                    >
                      <option value="">Options</option>
                      <option value="Aadhar Card">Aadhar Card</option>
                      <option value="PAN Card">PAN Card</option>
                      <option value="VoterID Card">VoterID Card</option>
                      <option value="VoterID Card">Other</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="file">Upload Verification Document</label>
                    <span style={{ fontSize: "12px", color: "#9b9b9b" }}>
                      maximum size 5mb*
                    </span>
                    <input
                      type="file"
                      name="photo"
                      onChange={(e) => {
                        const selectedFile = e.target.files[0];
                        const allowedTypes = [
                          "image/jpeg",
                          "image/png",
                          "image/jpg",
                          "application/pdf",
                        ];

                        if (
                          selectedFile &&
                          allowedTypes.includes(selectedFile.type)
                        ) {
                          // File type is allowed, set the selected file
                          if (selectedFile.size / (1024 * 1024) <= 5) {
                            setPreform(selectedFile);
                          } else {
                            event.target.value = null;
                            message.warning(
                              "File size exceeds 500 KB. Please upload a smaller file."
                            );
                          }
                        } else {
                          event.target.value = null;
                          setPreform("InvalidDocument");
                          message.warning(
                            "Please select a valid image (jpg, jpeg, png) or PDF file."
                          );
                          // You may also reset the input field here if needed
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {user.Type === "Freelancer" ? <hr /> : null}
              {user.Type === "Freelancer" ? (
                <div
                  className="skillsset-card"
                  style={{
                    boxShadow: "none",
                    border: "none",
                    marginTop: "10px",
                    paddingInline: "40px",
                  }}
                >
                  <h5 style={{ fontSize: "16px" }}>
                    Skills<span className="asterisk">*</span>
                  </h5>
                  <small style={{ color: "#ec1c24" }}>{errors.skills}</small>
                  {/* <Userskills allskills={allskills} user={user} /> */}
                  <div
                    className="language"
                    style={{ padding: "0 40px", marginBottom: "30px" }}
                  >
                    {/* <label htmlFor="Skill">Skills:</label> */}
                    <Selectant
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%',
                        }),
                      }}
                      label="Skill"
                      options={skillmaster}
                      selected={SkillSelected}
                      setselected={handleSkillChange}
                      onChange={(e) =>
                        setedit({ ...edit, Skills: e.target.value })
                      }
                    />
                  </div>
                </div>
              ) : null}
              <hr />
              {user.Type === "Freelancer" ? <div>
                {/* <h5 style={{ fontSize: "16px" }}>Bank Details</h5> */}
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="Bank_Name">
                      Bank Name
                    </label>

                    <input
                      type="text"
                      name="Bank_Name"
                      value={edit.Bank_Name}
                      placeholder="Enter bank name"
                      onChange={(e) => {
                        const inputValue = e.target.value.slice(0, 45);
                        if (inputValue.trim() === "") {
                          setedit({ ...edit, Bank_Name: "" });
                        } else {
                          setedit({ ...edit, Bank_Name: inputValue });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="Account_Name">
                      Account Name
                    </label>

                    <input
                      type="text"
                      name="Account_Name"
                      value={edit.Account_Name}
                      placeholder="Enter account name"
                      className={
                        errors.Account_Name != null ? "errorborder" : ""
                      }
                      onChange={(e) => {
                        const inputValue = e.target.value.slice(0, 45);
                        if (inputValue.trim() === "") {
                          setedit({ ...edit, Account_Name: "" });
                        } else {
                          // Input is valid, update the state
                          setedit({ ...edit, Account_Name: inputValue });
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="Account_Number">
                      Account Number
                    </label>

                    <input
                      type="tel"
                      name="Account_Number"
                      value={edit.Account_Number}
                      placeholder="Enter your account number"
                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 16);
                        const accountNumber = parseInt(input, 10);
                        if (!isNaN(accountNumber)) {
                          // Input is a valid integer, update the state
                          setedit({ ...edit, Account_Number: accountNumber });
                        } else {
                          setedit({ ...edit, Account_Number: "" });
                        }
                      }}
                    />
                  </div>
                  <div>
                    <label htmlFor="ifsccode">
                      IFSC CODE
                    </label>

                    <input
                      type="tel"
                      name="ifsccode"
                      value={edit.IFSC_CODE}
                      placeholder="Enter your IFSC code"
                      onChange={(e) => {
                        const input = e.target.value.slice(0, 11);
                        setedit({ ...edit, IFSC_CODE: input });
                      }}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div>
                    <label htmlFor="swiftcode">
                      SWIFT CODE
                    </label>

                    <input
                      type="tel"
                      name="swiftcode"
                      value={edit.SWIFT_CODE}
                      placeholder="Enter your swift code"
                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 11);
                        setedit({ ...edit, SWIFT_CODE: input });
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="IBAN">
                      IBAN
                    </label>
                    <input
                      type="tel"
                      name="IBAN"
                      value={edit.IBAN}
                      placeholder="Enter your IBAN number"
                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 11);
                        setedit({ ...edit, IBAN: input });
                      }}
                    />
                  </div>
                </div>
                <div className="userdeatail_container">
                  <div style={{ width: "96%" }}>
                    <label htmlFor="Routing_Number">
                      Routing Number
                    </label>

                    <input
                      type="tel"
                      name="Routing_Number"
                      value={edit.Routing_Number}
                      placeholder="Enter your routing number"
                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 11);
                        setedit({ ...edit, Routing_Number: input });
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="Routing_Code">
                      Routing Code
                    </label>
                    <small style={{ color: "#ec1c24" }}>
                      {errors.Routing_Code}
                    </small>
                    <input
                      type="tel"
                      name="Routing_Code"
                      value={edit.Routing_Code}
                      placeholder="Enter your routing code"
                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 11);
                        setedit({ ...edit, Routing_Code: input });
                      }}
                    />
                  </div>
                </div>

                <div className="userdeatail_container">
                  <div style={{ width: "96%" }}>
                    <label htmlFor="SORT_CODE">
                      SORT CODE
                    </label>

                    <input
                      type="tel"
                      name="SORT_CODE"
                      value={edit.SORT_CODE}
                      placeholder="Enter your sort code"
                      onChange={(e) => {
                        const input = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 11);
                        setedit({ ...edit, SORT_CODE: input });
                      }}
                    />
                  </div>

                  <div>
                    <label htmlFor="BranchDetails">
                      BRANCH DETAILS
                    </label>
                    <small style={{ color: "#ec1c24" }}>
                      {errors.BRANCH_DETAILS}
                    </small>
                    <input
                      type="text"
                      name="BranchDetails"
                      value={edit.BRANCH_DETAILS}
                      className={
                        errors.BRANCH_DETAILS != null ? "errorborder" : ""
                      }
                      placeholder="Enter your branch details"
                      onChange={(e) => {
                        const input = e.target.value.slice(0, 50);
                        if (input.trim() === "") {
                          setedit({ ...edit, BRANCH_DETAILS: "" });
                        } else {
                          // Input is valid, update the state
                          setedit({ ...edit, BRANCH_DETAILS: input });
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="userdeatail_container">
                  <div style={{ width: "96%" }}>
                    <label htmlFor="BankCountry">
                      BANK COUNTRY
                    </label>
                    <small style={{ color: "#ec1c24" }}>
                      {errors.BANK_COUNTRY}
                    </small>
                    <Select
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%',
                        }),
                      }}
                      options={Country.getAllCountries()}
                      getOptionLabel={(options) => {
                        return options["name"];
                      }}
                      getOptionValue={(options) => {
                        return options["name"];
                      }}
                      value={
                        Country.getAllCountries().find(
                          (country) => country.name === edit.BANK_COUNTRY
                        ) || null
                      }
                      onChange={(item) => {
                        setSelectedCountry(item);
                        setedit({ ...edit, BANK_COUNTRY: item.name });
                      }}
                    />
                  </div>
                </div>
              </div> : <></>}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="submit"
                  className="buton"
                  style={{ marginBlockStart: "20px", color: "white" }}
                  value={"Submit"}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Userprofileupdate;
