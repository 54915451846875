import React, { useState } from "react";
import { Link } from "react-router-dom";
import SignUpEmployee from "../components/SignUpEmployee.js";
import HireEmployee from "./HireEmployee.js";
import PayEmployee from "./PayEmployee.js";
import ManageEmployee from "./ManageEmployee.js";
import SignUpFreelancer from "../components/SignUpFreelancer.js";
import HireFreelancer from "./HireFreelancer.js";
import PayFreelancer from "./PayFreelancer.js";
import { motion } from "framer-motion";
import ManageFreelancer from "./ManageFreelancer.js";
function HowItWorks() {
  const [show, setshow] = useState(true);
  return (
    <div className="HowItWorks">
      <div className="home-howitwork">
        <Link to="/">Home </Link>/{" "}
        <Link to="/freelancers">How It Works</Link>
      </div>
      <div className="Apart">
        <h1>How It WorkS</h1>
        <p>
          Find and hire freelance talent using our secure, flexible and
          cost-effective online platform.
        </p>
        <div className="Apart-button">
          <div
            className={`employee ${show ? "Apart-button-click" : null}`}
            onClick={() => {
              setshow(true);
             
            }}
          >
            Employers
          </div>
          <div
            className={`freelancer ${!show ? "Apart-button-click" : null}`}
            onClick={() => {
              setshow(false);
            }}
          >
            Freelancers
          </div>
        </div>
      </div>
      <div className="sub-buttons">
        {show ? (
          <ul>
            <li><a href="#SignUp">Sign Up</a></li>
            <li><a href="#Hire">Hire</a></li>
            <li><a href="#Manage">Manage</a></li>
            <li><a href="#Pay">Pay</a></li>
            
          </ul>
        ) : (
          <ul>
            <li><a href="#SignUp">Sign Up</a></li>
            <li><a href="#Hire">Hire</a></li>
            <li><a href="#Manage">Manage</a></li>
            <li><a href="#Pay">Pay</a></li>
          </ul>
        )}
      </div>
      <hr className="hr" />
      {show ? (
        <>
           <div id="SignUp"><SignUpEmployee /></div>
          <div id="Hire"><HireEmployee /></div> 
         <div id="Manage"> <ManageEmployee /></div>
         <div id="Pay"> <PayEmployee /></div>
        </>
      ) : (
        <>
         <div id="SignUp"> <SignUpFreelancer /></div>
         <div id="Hire"> <HireFreelancer /></div>
         <div id="Manage"> <ManageFreelancer /></div>
         <div id="Pay"><PayFreelancer /></div>
         <div>

         </div>
        </>
      )}
    </div>
  );
}

export default HowItWorks;
