import React, { useEffect, useState, useContext } from "react";
import Profile from "../images/profile.jpg";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import axios from "axios";
import { AiFillStar } from 'react-icons/ai';
import { MyContext } from "../Mycontext";
import { MdVerified } from 'react-icons/md';
import { Popup } from "semantic-ui-react";
import img from '../images/veryfied.png'

function EmployerProfile() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [item, setitem] = useState({});
    const [analytics, setAnalytics] = useState([]);
    const [jobs, setjobs] = useState([]);
    const { id } = useParams();
    const Navigate = useNavigate();


    const getuser = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/getUserById", {
                Token,
                id,
            });
            setitem(data.data);

        } catch {
            console.log("error for geting the userProfile");
        }
    };


    const Analytics = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/analytics", {
                id: id,
                Token
            });
            setAnalytics(data.data);
        } catch {
            message.error("Error while fetching analytics")
        }
    };


    const getjobs = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/getJobsById", {
                Employer_ID: id,
                Token,
            });

            setjobs(data.data);
            //   setloading(false);
        } catch (err) {
            console.log(err);
        }

    };

    useEffect(() => {
        window.scroll(0, 0);
        getuser();
        getjobs();
        Analytics();
    }, []);
    return (
        <div className="HowItWorks">
            <div className="home-howitwork">
                <Link to="/">Home </Link>/{" "}
                <Link to="/jobs">Jobs</Link>/{" "}
                <Link to="/user-profile">{item.Name}</Link>
            </div>
            <div className="profile-Apart">
                <img
                    src={
                        item.Photo === "" || item.Photo == null
                            ? Profile
                            : `${endPointUrl}/images/${item.Photo}`
                    }
                    alt=""
                />
                <div className="user-name" style={{ position: "relative" }}>
                    <h2>{item.Name}</h2>
                    <span>{item.Title}</span>
                    <img src={img} alt="" style={{ filter: item.Verified_Profile == "Verified" ? "none" : "grayscale(1)", width: "23px", height: "auto", marginLeft: "auto", position: "absolute", top: "15px", right: "0px" }} />

                </div>
                <div className="user-reviews-address">
                    <span className="rating"
                        onClick={() => Navigate(`/user-profile/reviews/${item.Name + "-" + item.id}`)}
                        style={{ paddingLeft: "8px", fontSize: "16px", paddingBottom: "9px", cursor: 'pointer' }}
                    >
                        <AiFillStar className="featuredfreelancer-rating-star" />
                        {item.avg_Rating ? item.avg_Rating : 0} <p>({item.no_of_reviews ? item.no_of_reviews : 0} Reviews)</p></span>
                    {/* <span>|  <img src={Frame} alt="location" style={{ paddingRight: "3px", paddingLeft: "18px", width: "18px" }} /> {item?.City} {item?.City ? "," : null} {item?.Country}</span> */}
                </div>
            </div>


            <div className="about-rate">
                <div style={{ width: "69%" }}>
                    <div className="about">
                        <b>About</b>
                        <p>{item.About}</p>
                    </div>
                </div>
                <div style={{ width: "30%" }}>
                    <div className="rate-card" >
                        <div className="user-details">
                            <p>Job Post</p>
                            <span>{analytics.totalprojects}</span>
                        </div>
                        <div className="user-details">
                            <p>Completed Projects</p>
                            <span>{analytics.completedProject}</span>
                        </div>
                        <div className="user-details">
                            <p>Ongoing Projects</p>
                            <span>{analytics.ongoingprojects}</span>
                        </div>
                        <div className="user-details">
                            <p>Close Projects</p>
                            <span>{analytics.expiredprojects}</span>
                        </div>
                        <div className="user-details">
                            <p>Average project Payment</p>
                            <span>${(Math.floor(analytics.revenuegenerated / analytics.totalprojects)) ? (Math.floor(analytics.revenuegenerated / analytics.totalprojects)) : 0}</span>
                        </div>
                        <div className="user-details">
                            <p>Total Spent</p>
                            <span>${analytics.revenuegenerated}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmployerProfile;
