import React, { useState, useEffect, useContext } from 'react'
import { MyContext } from '../../Mycontext'
import axios from 'axios'
import { message } from 'antd'
import { Link } from 'react-router-dom'
export default function Contact() {
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [form, setform] = useState({
        Name: "",
        Email: "",
        Phone: "",
        Message: ""
    })
    const [errors, setErrors] = useState({});
    const sendMail = async () => {
        try {
            await axios.post(endPointUrl + '/user/contactMail', {
                Token,
                ...form
            });
            message.success("Message sent")
        } catch {
            message.error("Error sending message")
        }
    }
    // Function to handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length === 0) {
            sendMail();
        } else {
            // message._InternalPanelDoNotUseOrYouWillBeFired("not sent")
            console.log("exit");
            setErrors(validationErrors);
        }
    };

    // Function to validate the form
    const validateForm = () => {
        const validationErrors = {};
        if (!form.Name) {
            validationErrors.Name = "Name is required";
        }
        if (!form.Email) {
            validationErrors.Email = "Email is required";
        }
        if (!form.Phone) {
            validationErrors.Phone = "Phone is required";
        }
        if (!form.Message) {
            validationErrors.Message = "Message is required";
        }
        // You can add more specific validation rules as needed

        return validationErrors;
    };
    return (
        <div className='HowItWorks contact' >
            {/* <div className="home-howitwork">
                <Link to="/">Home </Link>/{" "}
                <Link to="/contact-us">Contact Page</Link>
            </div> */}
            {/* <span className="jobpostheading" style={{ backgroundColor: "#ceefe4", paddingRight: "0 40px 0 40px" }}>
                <div>
                    Contact Us
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur, perspiciatis.</p>
                </div>

            </span> */}
            <h2>Send us a Message</h2>
            <hr/>
            <div className='contact-container'>
                <div className='contact-mainpanel'>
                    <form
                        className='contact-form JobPostForm'
                        onSubmit={(e)=>handleSubmit(e)}
                    >
                        <div className='contact-form-upper'>
                            <div className='contact-form-detail'>
                                <label htmlFor="Name">Name<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="Name"
                                    onChange={(e) => setform({ ...form, Name: e.target.value })}
                                />
                                {errors.Name && <small style={{ color: "red" }}>{errors.Name}</small>}

                                <label htmlFor="Email">Email<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="Email"
                                    onChange={(e) => setform({ ...form, Email: e.target.value })}
                                />
                                {errors.Email && <small style={{ color: "red" }}>{errors.Email}</small>}

                                <label htmlFor="Phone">Phone<span style={{ color: "red" }}>*</span></label>
                                <input
                                    type="text"
                                    name="Phone"
                                    onChange={(e) => setform({ ...form, Phone: e.target.value })}
                                />
                                {errors.Phone && <small style={{ color: "red" }}>{errors.Phone}</small>}
                            </div>

                            <hr />
                            <label htmlFor="Message">Message<span style={{ color: "red" }}>*</span></label>
                            <textarea
                                name="Message"
                                cols="30"
                                rows="10"
                                onChange={(e) => setform({ ...form, Message: e.target.value })}
                            >
                            </textarea>
                            {errors.Message && <small style={{ color: "red" }}>{errors.Message}</small>}
                            <hr />
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <input
                                    type="submit"
                                    value="Send Message"
                                    className="buton"
                                    style={{ marginBlockStart: "20px" }}
                                />
                            </div>
                        </div>
                    </form>
                </div>

                <hr />
                <div className='contact-sidepanel'>
                    <div style={{ maxWidth: "100%", overflow: "hidden", color: "red", width: "300px", height: "300px" }}>
                        <div id="embedded-map-display" style={{ height: "100%", width: "100%", maxWidth: "100%" }}>
                            <iframe style={{ height: "100%", width: "100%", border: "0" }} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=5900+Balcones+Drive+suite+100,+Austin,+Texas+78731,+USA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8">
                            </iframe>
                        </div>
                    </div>
                    <p>5900 Balcones Drive STE 100
                        Austin
                        Texas-78731</p>
                    <p>Phone: 1-707-225-8072</p>
                    <p>Monday - Friday 8 AM - 10 PM EST</p>

                </div>
            </div>
        </div>
    )
}
