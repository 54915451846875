import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import Certificate from '../../images/Certificate.png'
import { MyContext } from '../../Mycontext'
import { BsArrowRightCircle } from 'react-icons/bs'
import { useNavigate } from 'react-router'
import { Link } from "react-router-dom";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CLIENT_ID } from '../../Config/Config'
import { FUNDING } from "@paypal/react-paypal-js";
import { Modal, Typography, Box, FormControlLabel, Checkbox, Button } from '@mui/material'
import { IoMdClose } from "react-icons/io";
import { message } from 'antd'
import { FaCheck } from "react-icons/fa6";
import CertificateDetailModal from '../CertificateDetailModal'
import PaymentModal from '../Jobs/PaymentModal'

export default function SkillTest() {
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [Tests, setTests] = useState([]);
    const [showmodel, setshowModel] = useState(false);
    const [Index, setIndex] = useState(0);
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState('');
    const [showPayPal, setShowPayPal] = useState(false);
    const [orderID, setOrderID] = useState(false);
    const [paymentDone, setPaymentDone] = useState(false);
    const [allCertificates, setAllCertificates] = useState([]);
    const [takenTest, setTakenTest] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [openPayment, setOpenPayment] = useState(false);
    const [Orderdata, setOrderdata] = useState({
        ID: "",
        time: ""
    });


    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);

    const handleOpen = (item) => {
        setOpen(true)
        setSelectedItem(item)
    };
    const handleClose = () => {
        setOpen(false);
        setShowPayPal(false);
    };
    const [assessment, setAssessment] = useState({
        Test_taken: 0,
        Badges: 0
    });
    const navigate = useNavigate();

    const getTests = async () => {

        const { data } = await axios.post(endPointUrl + '/getSkilltestforfreelancer', {
            Token,
            id: user?.id
        })
        setTests(data.data);
        setTakenTest(data.assesment);
        let Tests_taken = data.assesment.length;
        let Badges = data.assesment.filter((item) => {
            return item.Result === "Pass";
        });
        setAssessment({ Test_taken: Tests_taken, Badges: Badges.length });
    };
    const handleSkillModel = (index) => {
        setIndex(index);
        setshowModel(true);
    };
    const CloseModel = () => {
        setshowModel(false);
    };

    const getCertificatePayment = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/get-certificate-payment", {
                UserID: user.id,
                Token
            })

            setAllCertificates(data.data);

        } catch (error) {
            console.log("Error While Get Certificate Payment");
        }
    }

    useEffect(() => {
        window.scroll(0, 0)
        getTests();
        getCertificatePayment();
    }, [])

    const createOrder = (value, data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: "Certification",
                    amount: {
                        currency_code: "USD",
                        value: value,
                    },
                },
            ],
        }).then((orderID) => {
            setOrderID(orderID);
            return orderID;
        });
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 0,
        borderRadius: '20px'
    };

    const handleContinue = () => {
        setShowPayPal(true);
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (orderData) {
            setOrderdata({
                ID: orderData.id,
                time: orderData.create_time
            })
            Payment(orderData);
        })
    }

    const Payment = async (orderdata) => {
        try {
            const { data } = await axios.post(endPointUrl + "/create-certificate-payment", {
                Token,
                UserID: user?.id,
                Payment_date: orderdata?.create_time,
                SKT_ID: selectedItem?.SKT_ID,
                Amount: selectedItem?.Amount,
                PaymentReference: orderdata?.id,
                Attempt: false
            });
            setShowPayPal(false);
            handleClose();
            setPaymentDone(true);
            getCertificatePayment();
            message.success("confirmed")
        } catch {
            message.error("error while adding payment to logs");
        }
    };

    return (
        <div className='skillTest-container'>
            <div className="home-howitwork" style={{ position: "absolute", top: "101px", left: "51px" }}>
                <Link to="/freelancer-job-profile">Home </Link>/{" "}
                <Link to="/skill-test">Certification </Link>

            </div>
            <div className="skillTest-sidePanel">
                <div className="sidePanel-header">
                    <h3>My assessments</h3>
                    <span>Conducting assessments is a pivotal step in evaluating proficiency and capabilities. It involves delving into one's expertise, identifying strengths, and areas for growth. These assessments empower individuals to showcase their skills.</span>
                    <div className='sidePanel-stats'>
                        <div>
                            <span>Tests Taken</span> <p id='num'>{assessment.Test_taken}</p>
                        </div>
                        <hr />
                        <div>
                            <span>Badges Earned</span> <p id='num'>{assessment.Badges}</p>
                        </div>
                    </div>

                    <div>
                        <CertificateDetailModal takenTest={takenTest} />
                    </div>

                </div>
            </div>

            <div className="skillTest-mainPanel">
                <div className="mainPanel-header">
                    <h3>Skill assessments</h3>
                    <span>Assessing skills effectively involves gauging expertise through practical application. It's about uncovering the nuances of proficiency and competence within specific domains. Discovering individuals who excel in their craft contributes significantly to the journey of achieving exceptional results.</span>

                </div>
                <hr />
                <div className="mainPanel">
                    {Tests.map((item, index) =>
                        <div className="mainPanel-cards" key={index}>
                            <div className='mainPanel-image'>
                                <img src={Certificate} alt="badge" />
                            </div>
                            <div className='mainPanel-detail'>
                                <h4>{item.SKT_Title}</h4>
                                <div><span id='topic'>No. of Questions</span><p>{item.No_of_Questions}</p></div>
                                <div><span id='topic'>Skills</span><p>{item.Skills}s</p></div>
                                <div><span id='topic'>Time</span><p>{item.Duration} mins.</p></div>
                                <div><span id='topic'>fee</span><p>{item.isPaid ? `$${item.Amount}` : "Free"}</p></div>
                            </div>

                            <div>
                                <h4>
                                    {item?.isPaid ?
                                        <button onClick={allCertificates.some(payment => payment.SKT_ID === item.SKT_ID) ? () => handleSkillModel(index) : () => handleOpen(item)} style={{ width: '150px', fontSize: '14px', padding: '10px 0px', fontWeight: 'bold' }} className='invite'>{allCertificates.some(payment => payment.SKT_ID === item.SKT_ID) ? "Start" : "Enroll"}</button>
                                        :
                                        <button className='invite' style={{ width: '150px', fontSize: '14px', padding: '10px 0px', fontWeight: 'bold' }} onClick={() => handleSkillModel(index)}>Enroll for free</button>
                                    }
                                </h4>
                                <Modal
                                    sx={{ opacity: 0.5 }}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    <Box sx={style}>
                                        <Typography variant="h5" component="h2" sx={{ fontSize: "20px", fontWeight: 'bold', background: '#07b273', padding: "20px", borderRadius: '15px 15px 0px 0px', textAlign: 'center' }}>
                                            Enroll in {selectedItem.SKT_Title} professional certificate
                                        </Typography>
                                        <Box sx={{ padding: '20px' }}>
                                            <Typography>
                                                <Typography sx={{ fontSize: "22px", fontWeight: 'bold' }} variant="h6">{selectedItem.SKT_Title}</Typography>
                                                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                                                    <Box>
                                                        <Box><li style={{ fontSize: "16px", fontWeight: "700", padding: 0 }}>Skills required</li></Box>
                                                        {selectedItem?.Skills?.split(",").map((item) => {
                                                            return (<span style={{ display: 'flex', alignItems: 'center', gap: '5px', paddingLeft: '20px', color: "#606563" }}><FaCheck size={22} style={{ color: 'green' }} />{item}</span>)
                                                        })}
                                                    </Box>
                                                    {/* <Box mt={2}>
                                                        <Typography variant="body2"><li style={{ fontSize: "16px", fontWeight: "700" }}>Validity: 2 Months</li></Typography>
                                                        <Typography style={{ paddingLeft: '20px', fontSize: "12px", color: "#606563" }}>
                                                            (you may take the quiz at any time within the next 2 months)
                                                        </Typography>
                                                    </Box> */}
                                                    <Box mt={2}>
                                                        <Typography variant="body2"><li style={{ fontSize: "16px", fontWeight: "600" }}>Expiry of Certificate: 1 year</li></Typography>
                                                        <Typography style={{ paddingLeft: '20px', fontSize: "12px", color: "#606563" }}>
                                                            (This certificate will expire in 1 year)
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={2} style={{ display: 'flex', justifyContent: "space-between", alignItem: 'center', background: "#ede9e6", padding: '5px' }}>
                                                        <Typography variant="h6">Total Amount</Typography>
                                                        <Typography variant="h6" color="textPrimary">
                                                            ${selectedItem?.Amount}
                                                        </Typography>
                                                    </Box>
                                                </Typography>
                                            </Typography>

                                            {showPayPal ? (
                                                <div style={{ marginTop: "2%" }}>
                                                    <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
                                                        {/* <PayPalButtons
                                                            fundingSource={FUNDING.PAYPAL}
                                                            style={{ layout: "vertical", color: "white", shape: "pill" }}
                                                            createOrder={(data, actions) => createOrder(data, actions)}
                                                            onApprove={onApprove}
                                                        /> */}
                                                        {/* <div> */}
                                                        <button onClick={() => setOpenPayment(true)} className="btn" style={{ width: '100%', fontSize: '14px', padding: '10px 0px', fontWeight: 'bold' }}>Enroll</button>
                                                        <PaymentModal open={openPayment} setOpen={setOpenPayment} createOrder={createOrder} onApprove={onApprove} value={selectedItem?.Amount} />

                                                    </PayPalScriptProvider>
                                                </div>
                                            ) : (
                                                <div style={{ textAlign: 'center' }}><Button variant="contained" onClick={handleContinue} sx={{ mt: 2, background: '#07b273', color: 'black' }}>
                                                    Continue
                                                </Button>
                                                </div>)}
                                        </Box>
                                    </Box>
                                </Modal>
                            </div>

                        </div>
                    )}
                </div>
            </div>

            <div id="myModal" className={showmodel ? 'model-open' : 'model'}>
                {Tests.map((item, index) => Index === index ?
                    <div className="modal-content" style={{ width: "55%" }} key={item.SKT_Title} >
                        <span className="close" onClick={CloseModel}>&times;</span>
                        <h2>{item.SKT_Title}</h2>
                        <div className='instructions'>
                            <h4>Instructions</h4>
                            <ul>
                                <li>Total <span> {item.No_of_Questions} Questions</span></li>
                                <li><span>{((item.Duration / item.No_of_Questions).toString()).slice(0, 3)} mins</span> to answer each question </li>
                                <li>Must be finished in one sitting</li>
                                <li>Will not allow you to go back and change your answer</li>
                                <li>Attempting all questions is compulsary</li>
                            </ul>
                            <button className='buton view-btn' onClick={() => navigate("/skill-qa", { state: { SKT_ID: item.SKT_ID, duration: item.Duration } })}> Start<BsArrowRightCircle /></button>
                        </div>
                    </div> : null
                )}
            </div>
        </div >
    )
}