import { useState, useEffect, useContext } from "react";
import { Card, Form, Container, div, span } from "react-bootstrap";
import Selectant from 'react-select';
import axios from 'axios';
import { message } from "antd";
import { MyContext } from "../Mycontext";
import TimezoneSelect from "react-timezone-select";
import { useLocation, useNavigate } from "react-router-dom";
import Select from 'react-select'; // Assuming you're using react-select
import PhoneInput from 'react-phone-input-2'

function PartnerWithUs() {
    const Navigate = useNavigate();
    const Location = useLocation();
    const partnerData = Location?.state?.partner
    console.log('partnerData',)
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [name, setName] = useState(partnerData?.Name || user ? user.Name : '');
    const [companyName, setCompanyName] = useState(partnerData?.CompanyName || '');
    const [password, setPassword] = useState(user ? user.Password : '');
    const [rePassword, setRePassword] = useState(user ? user.Password : '');
    const [email, setEmail] = useState(user ? user.Email : '');
    const [phoneNumber, setPhoneNumber] = useState(user ? user.Mobile : '');
    const [establishment, setEstablishment] = useState(partnerData?.Establishment || '');
    const [companyPhoneNumber, setCompanyPhoneNumber] = useState(partnerData?.CompanyPhoneNumber || '');
    const [numberOfEmployees, setNumberOfEmployees] = useState(partnerData?.NumberOFEmployees || '');
    const [location, setLocation] = useState(partnerData?.Location || '');
    const [timeZone, setTimeZone] = useState(partnerData?.TimeZone || '');
    const [languages, setLanguages] = useState(partnerData?.Languages.split(",").map((item) => ({ 'label': item, 'value': item })) || []);
    const [numberOfOffices, setNumberOfOffices] = useState(partnerData?.OfficeLocations || '');
    const [hourlyRate, setHourlyRate] = useState(partnerData?.HourlyRate || '');
    // const [servicesOffered, setServicesOffered] = useState(partnerData?.ServiceOffered.split(",").map((item) => ({ 'label': item, 'value': item })) || '');
    // const [technologyOffered, setTechnologyOffered] = useState(partnerData?.TechnologyOffered.split(",").map((item) => ({ 'label': item, 'value': item })) || '');
    const [servicesOffered, setServicesOffered] = useState({ item: partnerData?.ServiceOffered, value: partnerData?.ServiceOffered } || '');
    const [technologyOffered, setTechnologyOffered] = useState({ item: partnerData?.TechnologyOffered, value: partnerData?.TechnologyOffered } || '');
    const [description, setDescription] = useState(partnerData?.Description || '');
    const [logo, setLogo] = useState(partnerData?.Logo || '');
    const [portfolio, setPortfolio] = useState(partnerData?.Portfolio || '');
    const [websiteURL, setWebsiteURL] = useState(partnerData?.WebsiteURL || '');
    const [InstagramUsername, setInstagramUsername] = useState(partnerData?.InstagramUsername || '');
    const [FacebookProfile, setFacebookProfile] = useState(partnerData?.FacebookProfile || '');
    const [LinkedinProfile, setLinkedinProfile] = useState(partnerData?.LinkedlinProfile || '');
    const [TwitterProfile, setTwitterProfile] = useState(partnerData?.TwitterProfile || '');

    // const [socialMediaPresence, setSocailMediaPresence] = useState(partnerData?.SocialMediaPresence.split(",").map((item) => ({ 'label': item, 'value': item })) || '');
    const [yearlyRevenue, setYearlyRevenue] = useState(partnerData?.YearlyRevenue || '');
    const [industryBasedRevenue, setIndustryBasedRevenue] = useState(partnerData?.IndustryBaseRevenue || '');
    const [clientBasedRevenue, setClientBasedRevenue] = useState(partnerData?.ClientBaseRevenue || '');
    const [languageMAster, setLanguagaeMaster] = useState([]);
    const [error, setError] = useState({});
    const [selectedTimezone, setSelectedTimezone] = useState(partnerData?.SelectedTimeZone || '');
    const [officeLocations, setOfficeLocations] = useState(partnerData?.OfficeLocations || {});
    const [isUserLoged, setIsUserLoged] = useState(user ? true : false);

    console.log('industryBasedRevenueindustryBasedRevenue', industryBasedRevenue);
    console.log('tservieoffered', servicesOffered);



    const LanguageMAster = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/Admin/getalllanguage", {
                Token,
            });
            if (data && data.data && Array.isArray(data.data)) {
                const languageNames = data.data.map((item) => ({
                    value: item.Name,
                    label: item.Name,
                }));
                setLanguagaeMaster(languageNames);
            } else {
                console.log('Failed to fetch language. Data may be empty or not in the expected format.');
            }
        } catch (error) {
            console.log('Failed to fetch skills:', error);
        }
    };

    const handleLanguageSelect = (selectedOption) => {
        setLanguages(selectedOption);
    };


    function handleError(label, error) {
        setError((prev) => ({ ...prev, [label]: error }))
    };

    console.log('error', error)

    function validate() {
        let error = false;
        if (name.length === 0) {
            error = true;
            handleError('Name', 'Name is Required');
        }
        if (companyName.length === 0) {
            error = true;
            handleError('companyName', 'Company Name is Required');
        }
        if (password.length === 0) {
            error = true;
            handleError('password', 'Password is Required');
        }
        if (rePassword.length === 0) {
            error = true;
            handleError('rePassword', 'Re-Enter Password is Required')
        }
        if (email.length === 0) {
            error = true;
            handleError('email', 'Email Address is Required');
        }
        if (phoneNumber && phoneNumber.length === 0) {
            error = true;
            handleError('phoneNumber', 'Phone Number is Required');
        }
        if (establishment.length === 0) {
            error = true;
            handleError('establishment', 'Date of Establishment is Required');
        }
        // if (numberOfEmployees.length === 0) {
        //     error = true;
        //     handleError('numberOfEmployees', 'Number Of Employees is Required');
        // }
        if (location.length === 0) {
            error = true;
            handleError('location', 'Location is Required');
        }
        // if (timeZone.length === 0) {
        //     error = true;
        //     handleError('timeZone', 'TimeZone is Required');
        // }
        if (languages.length === 0) {
            error = true;
            handleError('languages', 'Languages is Required');
        }
        if (hourlyRate.length === 0) {
            error = true;
            handleError('hourlyRate', 'Hourly Rate is Required');
        }
        if (servicesOffered.length === 0) {
            error = true;
            handleError('servicesOffered', 'Services Offered is Required');
        }
        if (technologyOffered.length === 0) {
            error = true;
            handleError('technologyOffered', 'Technology Offered is Required');
        }
        if (description.length === 0) {
            error = true;
            handleError('description', 'Description is Required');
        } if (logo === undefined || logo.length === 0) {
            error = true;
            handleError('logo', 'Logo is Required');
        }
        // if (portfolio === undefined || portfolio.length === 0) {
        //     error = true;
        //     handleError('portfolio', 'Portfolio is Required');
        // }
        if (websiteURL.length === 0) {
            error = true;
            handleError('websiteURL', 'WebsiteURL is Required');
        }
        if (yearlyRevenue.length === 0) {
            error = true;
            handleError('yearlyRevenue', 'Yearly Revenue is Required');
        }
        // if (industryBasedRevenue.length === 0) {
        //     error = true;
        //     handleError('industryBasedRevenue', 'Field is Required');
        // }
        // if (clientBasedRevenue.length === 0) {
        //     error = true;
        //     handleError('clientBasedRevenue', 'Field is Required');
        // }
        if (numberOfOffices.length === 0) {
            error = true;
            handleError("numberOfOffices", 'Number OF Offices is Required')
        }
        return error;
    };
    console.log('numberOfOfficesss', numberOfOffices)

    const handleSubmit = async (event) => {
        event.preventDefault();
        const error = validate()
        if (error === false) {
            try {
                const selectedLanguagesString = languages.map((language) => language.value).join(', ');
                // const socialMediaPresences = socialMediaPresence.map((item) => item.value).join(', ');
                // const yearlyRevenues = yearlyRevenue.map((item) => item.value).join(', ');
                const industryBasedRevenues = industryBasedRevenue.map((item) => item.value).join(', ');
                // const clientBasedRevenues = clientBasedRevenue.map((item) => item.value).join(', ');
                // const servicesOffereds = servicesOffered.map((item) => item.value).join(', ');
                // const technologyOffereds = technologyOffered.map((item) => item.value).join(', ');

                const formData = new FormData();
                formData.append("Name", name);
                formData.append("CompanyName", companyName)
                formData.append("Password", password);
                formData.append("Email", email);
                formData.append("Phone", phoneNumber);
                formData.append("Establishment", establishment);
                formData.append("CompanyPhoneNumber", companyPhoneNumber);
                formData.append("NumberOFEmployees", numberOfEmployees);
                formData.append("Location", location);
                formData.append("OfficeLocations", JSON.stringify(officeLocations));
                formData.append("TimeZone", timeZone);
                formData.append("SelectedTimeZone", JSON.stringify(selectedTimezone));
                formData.append("Languages", selectedLanguagesString);
                formData.append("HourlyRate", hourlyRate);
                formData.append("ServiceOffered", servicesOffered.value);
                formData.append("TechnologyOffered", technologyOffered.value);
                formData.append("Description", description);
                formData.append("Logo", logo);
                formData.append("Portfolio", portfolio);
                formData.append("WebsiteURL", websiteURL);
                formData.append("YearlyRevenue", yearlyRevenue.value);
                formData.append("IndustryBaseRevenue", industryBasedRevenues);
                formData.append("ClientBaseRevenue", clientBasedRevenue.value);
                formData.append("Facebook", FacebookProfile);
                formData.append("Linkedin", LinkedinProfile);
                formData.append("Twitter", TwitterProfile);
                formData.append("NumberOfOffices", numberOfOffices.value);


                await axios.post(endPointUrl + "/become-partner", formData)
                message.success("Data Submitted Successfully");
                Navigate("/partner-with-us")
            } catch (error) {
                console.log('eeee', error)
                if (error.response.data.message === "Email Already Exist") {
                    message.warning("You Are Already Partner")
                }
                console.log("Erro While Submitting Data in Database", error.response);
            }
        }
    }; 
    function getOfficesLocation(number) {
        if (number % 2 == 0) {
            var data = []
            for (let i = 0; i < number / 2; i++) {
                const label = `Location${i + 1}`
                data.push(<div className="userdeatail_container" style={{ gridTemplateColumns: '100%' }}>
                    <span>
                        <Form.Group>
                            <label>Location of Office</label>
                            <Form.Control
                                name="locationOFFice"
                                style={{ width: "100%" }}
                                placeholder="Location of Office"
                                type="text"
                                onChange={(event) => setOfficeLocations({ ...officeLocations, [label]: event.target.value })}
                            >
                            </Form.Control>
                        </Form.Group>
                    </span>

                    <span>
                        <Form.Group >
                            <label>Location of Office</label>
                            <Form.Control
                                name="locationOFFice"
                                placeholder="Location of Office"
                                style={{ width: "100%" }}
                                type="text"
                                onChange={(event) => setOfficeLocations({ ...officeLocations, [label]: event.target.value })}
                            >
                            </Form.Control>
                        </Form.Group>
                    </span>
                </div>)
            }
        }
        else {
            var data = []
            for (let i = 0; i < number; i++) {
                const label = `Location${i + 1}`
                data.push(<div className="userdeatail_container" style={{ gridTemplateColumns: '100%' }}>
                    <span>
                        <Form.Group>
                            <label>Location of Office</label>
                            <Form.Control
                                name="locationOFFice"
                                placeholder="Location of Office"
                                type="text"
                                style={{ width: "100%" }}
                                onChange={(event) => setOfficeLocations({ ...officeLocations, [label]: event.target.value })}
                            >
                            </Form.Control>
                        </Form.Group>
                    </span>
                </div>)
            }
        }
        return data;
    };


    useEffect(() => {
        LanguageMAster();
    }, []);

    return (
        <div className="HowItWorks">
            {partnerData ?
                <div>
                    <div><h2>Become Partner</h2></div>
                    <div>
                        <p><strong>Name:</strong> {name}</p>
                        <p><strong>Company Name:</strong> {companyName}</p>
                        <p><strong>Email:</strong> {email}</p>
                        <p><strong>Phone Number:</strong> {phoneNumber}</p>
                        <p><strong>Date of Establishment:</strong> {establishment}</p>
                        <p><strong>Company Phone Number:</strong> {companyPhoneNumber}</p>
                        <p><strong>Number of Employees:</strong> {numberOfEmployees}</p>
                        <p><strong>Location:</strong> {location}</p>
                        <p><strong>Website URL:</strong> {websiteURL}</p>
                        <p><strong>Timezone:</strong> {JSON.parse(selectedTimezone).label}</p>
                        <p><strong>Languages:</strong> {languages.map(lang => lang.label).join(', ')}</p>
                        <p><strong>Description:</strong> {description}</p>
                        <p><strong>Yearly Revenue:</strong> {yearlyRevenue}</p>
                        <p><strong>Hourly Rate:</strong> {hourlyRate}</p>
                        <p><strong>Services Offered:</strong> {servicesOffered.map(service => service.label).join(', ')}</p>
                        <p><strong>Technology Offered:</strong> {technologyOffered.map(tech => tech.label).join(', ')}</p>
                        {/* <p><strong>Social Media Presence:</strong> {socialMediaPresence.map(social => social.label).join(', ')}</p> */}
                        <p><strong>Industry Based Revenue:</strong> {industryBasedRevenue}</p>
                        <p><strong>Client Based Revenue:</strong> {clientBasedRevenue}</p>
                        {logo && (
                            <div style={{ display: 'flex', marginTop: '2%' }}>
                                <strong style={{ verticalAlign: "top" }}>Logo: </strong>
                                <img src={endPointUrl + "/images/" + logo} alt="Logo" style={{ maxWidth: "200px", height: '100px' }} />
                            </div>
                        )}
                        {portfolio && (
                            <div style={{ display: 'flex', marginTop: '2%' }}>
                                <strong style={{ verticalAlign: "top" }}>Portfolio: </strong>
                                <img src={endPointUrl + "/images/" + portfolio} alt="Portfolio" style={{ maxWidth: "200px", height: '100px' }} />
                            </div>
                        )}
                    </div>
                </div>
                : <div>
                    <div><h2>Become Partner</h2></div>
                    <div style={{ textAlign: "center", background: "#07B273", padding: '5px', boxSizing: 'border-box' }}><h3>Fill Details</h3></div>

                    <div>
                        {isUserLoged ? <></> : <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>
                                <Form.Group>
                                    <label>Name</label>
                                    <Form.Control
                                        name="Name"
                                        placeholder="Name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 20) {
                                                setName(e.target.value)
                                                setError({ ...error, Name: "" })
                                            }
                                            else
                                                setError({ ...error, Name: "Name must be smaller then 20 characters" })

                                        }}
                                        onFocus={() => setError({ ...error, Name: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.Name}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Company Name</label>
                                    <Form.Control
                                        name="CompanyName"
                                        placeholder="Company Name"
                                        type="text"
                                        value={companyName}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 50) {
                                                setCompanyName(e.target.value)
                                                setError({ ...error, companyName: "" })
                                            }
                                            else {
                                                setError({ ...error, companyName: "Company Name must be smaller then 50 characters" })
                                            }
                                        }}
                                        onFocus={() => setError({ ...error, companyName: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.companyName}</small>
                                </Form.Group>
                            </span>
                        </div>}

                        {isUserLoged ? <></> : <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>
                                <Form.Group>
                                    <label>Password</label>
                                    <Form.Control
                                        name="password"
                                        placeholder="Password"
                                        type="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        onFocus={() => setError({ ...error, password: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.password}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Re-Enter Password</label>
                                    <Form.Control
                                        name="repassword"
                                        placeholder="Re-Enter password"
                                        type="password"
                                        value={rePassword}
                                        onChange={(e) => {
                                            if (password != e.target.value) {
                                                setError({ ...error, rePassword: "Password Not Match" })
                                                setRePassword(e.target.value)
                                            }
                                            else {
                                                setRePassword(e.target.value)
                                                setError({ ...error, rePassword: "" })
                                            }
                                        }}
                                        onFocus={() => setError({ ...error, rePassword: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.rePassword}</small>
                                </Form.Group>
                            </span>
                        </div>}

                        {isUserLoged ? <></> : <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>
                                <Form.Group>
                                    <label>Email Address</label>
                                    <Form.Control
                                        name="email"
                                        placeholder="Email Address"
                                        type="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        onFocus={() => setError({ ...error, email: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.email}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Phone Number</label>
                                    <PhoneInput
                                        buttonStyle={{ marginTop: '0%' }}
                                        country={'in'}
                                        name="phoneNumber"
                                        placeholder="Phone Number"
                                        value={phoneNumber}
                                        onChange={(phone) => setPhoneNumber(phone)}
                                        onFocus={() => setError({ ...error, phoneNumber: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.phoneNumber}</small>
                                </Form.Group>
                            </span>
                        </div>
                        }

                        {isUserLoged ?
                            <div className="userdeatail_container" style={{ marginTop: '2%', gridTemplateColumns: "100%" }}>
                                <span>
                                    <Form.Group>
                                        <label>Company Name</label>
                                        <Form.Control
                                            style={{ width: '99%' }}
                                            name="CompanyName"
                                            placeholder="Company Name"
                                            type="text"
                                            value={companyName}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 50) {
                                                    setCompanyName(e.target.value)
                                                    setError({ ...error, companyName: "" })
                                                }
                                                else {
                                                    setError({ ...error, companyName: "Company Name must be smaller then 50 characters" })
                                                }
                                            }} onFocus={() => setError({ ...error, companyName: "" })}
                                        ></Form.Control>
                                        <small style={{ color: "#ec1c24" }}>{error.companyName}</small>
                                    </Form.Group>
                                </span>
                            </div> : <></>}

                        <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>
                                <Form.Group>
                                    <label>Date of Establishment</label>
                                    <Form.Control
                                        name="establishment"
                                        placeholder="Date of Establishment"
                                        type="date"
                                        value={establishment}
                                        onChange={(e) => {
                                            if (new Date(e.target.value) < new Date()) {
                                                setEstablishment(e.target.value)
                                                setError({ ...error, establishment: "" })
                                            } else
                                                setError({ ...error, establishment: "Can Not Select Future Date" })
                                        }}
                                        onFocus={() => setError({ ...error, establishment: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.establishment}</small>
                                </Form.Group>
                            </span>
                            {/* <span>
                                <Form.Group>
                                    <label>Other Phone Number</label>
                                    <Form.Control
                                        name="CompanyPhone"
                                        placeholder="Other Phone Number"
                                        type="text"
                                        value={companyPhoneNumber}
                                        onChange={(e) => {
                                            const numbersOnly = /^[0-9]+$/;
                                            if (numbersOnly.test(e.target.value)) {
                                                if (e.target.value.length <= 10) {
                                                    setCompanyPhoneNumber(e.target.value)
                                                    setError({ ...error, companyPhoneNumber: "" })
                                                }
                                                else {
                                                    setError({ ...error, companyPhoneNumber: "Phone Number Less than 10 Characters" })
                                                }
                                            }

                                        }}
                                        onFocus={() => setError({ ...error, companyPhoneNumber: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.companyPhoneNumber}</small>
                                </Form.Group> 
                            </span> */}
                            <span>
                                <Form.Group>
                                    <label>Number Of Employees</label>
                                    <Selectant
                                        name="noofemployees"
                                        label="Number Of Employees"
                                        options={[
                                            { value: "1-20", label: "1-20" },
                                            { value: "20-50", label: "20-50" },
                                            { value: "50-100", label: "50-100" },
                                            { value: "100-200", label: "100-200" },
                                            { value: "200-500", label: "200-500" },
                                            { value: "500-1000", label: "500-1000" },
                                            { value: "1000-5000", label: "1000-5000" },
                                            { value: "5000-10000", label: "5000-10000" },
                                            { value: "10000-50000", label: "10000-50000" },
                                            { value: "50000-100000", label: "50000-100000" },
                                        ]}
                                        value={{ value: numberOfEmployees, label: numberOfEmployees }}
                                        onChange={(value) => {
                                            setNumberOfEmployees(value.value)
                                        }}
                                        onFocus={() => setError({ ...error, numberOfEmployees: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.numberOfEmployees}</small>
                                </Form.Group>
                            </span>
                        </div>

                        <div style={{ marginTop: '2%' }} className="userdeatail_container">

                            <span>
                                <Form.Group>
                                    <label>Location</label>
                                    <Form.Control
                                        name="Location"
                                        placeholder="Location"
                                        type="text"
                                        value={location}
                                        onChange={(e) => setLocation(e.target.value)}
                                        onFocus={() => setError({ ...error, location: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.location}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Website URL</label>
                                    <Form.Control
                                        name="websiteURL"
                                        placeholder="Website URL"
                                        type="text"
                                        value={websiteURL}
                                        onChange={(e) => {
                                            var urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
                                            if (urlRegex.test(e.target.value)) {
                                                setWebsiteURL(e.target.value)
                                                setError({ ...error, websiteURL: "" })
                                            }
                                            else {
                                                setWebsiteURL(e.target.value)
                                                setError({ ...error, websiteURL: "Enter Valid URL (Eg. http/ftp/https)" })
                                            }
                                        }}
                                        onFocus={() => setError({ ...error, websiteURL: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.websiteURL}</small>

                                </Form.Group>
                            </span>
                        </div>


                        <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>
                                <Form.Group>
                                    <label>Number of Offices</label>
                                    <Selectant
                                        name="noofoffices"
                                        placeholder="Number Of Offices"
                                        options={[{ value: "1", label: "1" }, { value: "2", label: "2" }, { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "5", label: "5" },]}
                                        value={numberOfOffices}
                                        onChange={setNumberOfOffices}
                                        onFocus={() => setError({ ...error, numberOfOffices: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.numberOfOffices}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Time Zone</label>
                                    <TimezoneSelect
                                        value={selectedTimezone}
                                        onChange={(val) => {
                                            setTimeZone(val.label);
                                            setSelectedTimezone(val);
                                        }}
                                        onFocus={() => { setError({ ...error, timeZone: "" }) }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.timeZone}</small>
                                </Form.Group>
                            </span>
                        </div>

                        {numberOfOffices.value > 0 ? getOfficesLocation(numberOfOffices.value) : <></>}

                        <div style={{ marginTop: '2%' }} className="userdeatail_container">

                            <span>
                                <Form.Group>
                                    <label>Language</label>
                                    <Selectant
                                        name="Language"
                                        options={languageMAster}
                                        isMulti
                                        onChange={handleLanguageSelect}
                                        onFocus={() => setError({ ...error, languages: "" })}
                                        value={languages}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.languages}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Description</label>
                                    <br></br>
                                    <textarea
                                        style={{ width: "100%" }}
                                        name="description"
                                        placeholder="Description"
                                        type="text"
                                        value={description}
                                        onChange={(e) => {
                                            if (e.target.value.split(" ").length <= 50) {
                                                setDescription(e.target.value)
                                                setError({ ...error, description: "" })
                                            }
                                            else {
                                                setError({ ...error, description: "Description Less Than 50 Words" })
                                            }
                                        }}
                                        onFocus={() => setError({ ...error, description: "" })}
                                    ></textarea>
                                    <small style={{ color: "#ec1c24" }}>{error.description}</small>
                                </Form.Group>
                            </span>
                        </div>

                        <div className="userdeatail_container" >


                            <span>
                                <Form.Group>
                                    <label>Yearly Revenue</label>
                                    <Selectant
                                        name="yearlyRevenue"
                                        options={[{ value: "Less than $1L/annum", label: "Less than $1L/annum" }, { value: "$1L To $5L/annum", label: "$1L To $5L/annum" }, { value: "$5L To $10L/annum", label: "$5L To $10L/annum" }]}
                                        value={yearlyRevenue}
                                        onChange={setYearlyRevenue}
                                        onFocus={() => setError({ ...error, yearlyRevenue: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.yearlyRevenue}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Hourly Rate</label>
                                    <Form.Control
                                        name="HourlyRate"
                                        placeholder="Hourly Rate"
                                        type="number"
                                        value={hourlyRate}
                                        onChange={(e) => setHourlyRate(e.target.value)}
                                        onFocus={() => setError({ ...error, hourlyRate: "" })}
                                        min="0"
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.hourlyRate}</small>
                                </Form.Group>
                            </span>
                        </div>



                        <div style={{ marginTop: '2%' }} className="userdeatail_container">

                            <span>
                                <Form.Group>
                                    <label>Services Offered</label>
                                    <Select
                                        name="servicesOffered"
                                        placeholder="Services Offered"
                                        // isMulti
                                        options={[{ value: "Software Development", label: "Software Development" }, { value: "Mobile App Development", label: "Mobile App Development" }, { value: "Web Design", label: "Web Design" }, { value: "Web Development", label: "Web Development" }, { value: "AI Development", label: "AI Development" }, { value: "Cloud Consulting", label: "Cloud Consulting" }, { value: "SI, IT Managed Services", label: "SI, IT Managed Services" }, { value: "IT Strategy Consulting", label: "IT Strategy Consulting" }]}
                                        value={servicesOffered}
                                        onChange={setServicesOffered}
                                        onFocus={() => setError({ ...error, servicesOffered: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.servicesOffered}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Technology Offered</label>
                                    <Select
                                        name="TechnologyOffered"
                                        value={technologyOffered}
                                        // isMulti
                                        options={[{ value: "Dotnet", label: "Dotnet" }, { value: "PHP", label: "PHP" }, { value: "Mobile app development", label: "Mobile app development" }, { value: "IOS", label: "IOS" }, { value: "Android ", label: "Android " },]}
                                        onChange={setTechnologyOffered}
                                        onFocus={() => setError({ ...error, technologyOffered: "" })}
                                    ></Select>
                                    <small style={{ color: "#ec1c24" }}>{error.technologyOffered}</small>
                                </Form.Group>
                            </span>
                        </div>


                        <div style={{ marginTop: '2%' }} className="userdeatail_container">

                            {/* <span>
                                <Form.Group>
                                    <label>Social Media Presence</label>
                                    <Selectant

                                        name="socialMediaPresence"
                                        options={[{ value: "Facebook", label: "Facebook" }, { value: "Instagram", label: "Instagram" }, { value: "Twitter", label: "Twitter" }, { value: "Linkdlen", label: "Linkdlen" }]}

                                        value={socialMediaPresence}
                                        onChange={setSocailMediaPresence}
                                        onFocus={() => setError({ ...error, socialMediaPresence: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.socialMediaPresence}</small>
                                </Form.Group>
                            </span> */}

                        </div>
                        <div style={{ marginTop: '1%' }} className="userdeatail_container" >
                            <span>
                                <Form.Group>
                                    <label>Instagram Profile URL</label>
                                    <Form.Control
                                        name="InstagramUsername"
                                        placeholder="Instagram username"
                                        type="text"
                                        value={InstagramUsername} onChange={(e) => {

                                            setInstagramUsername(e.target.value)
                                            setError({ ...error, InstagramUsername: "" }) // Clear previous errors
                                        }}
                                        onFocus={() => setError({ ...error, InstagramUsername: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.InstagramUsername}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Facebook Profile URL</label>
                                    <Form.Control
                                        name="FacebookProfile"
                                        placeholder="Facebook profile"
                                        type="text"
                                        value={FacebookProfile} onChange={(e) => {
                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                            setFacebookProfile(e.target.value)
                                            setError({ ...error, FacebookProfile: "" }) // Clear previous errors
                                        }}
                                        onFocus={() => setError({ ...error, FacebookProfile: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.FacebookProfile}</small>
                                </Form.Group>
                            </span>
                        </div>
                        <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>
                                <Form.Group>
                                    <label>Linkedin Profile URL</label>
                                    <Form.Control
                                        name="LinkedinProfile"
                                        placeholder="Linkedin profile"
                                        type="text"
                                        value={LinkedinProfile} onChange={(e) => {
                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                            setLinkedinProfile(e.target.value)
                                            setError({ ...error, LinkedinProfile: "" }) // Clear previous errors
                                        }}
                                        onFocus={() => setError({ ...error, LinkedinProfile: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.LinkedinProfile}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label> Twitter Account</label>
                                    <Form.Control
                                        name="TwitterProfile"
                                        placeholder="Twitter Account"
                                        type="text"
                                        value={TwitterProfile} onChange={(e) => {
                                            // Optional: Validate username format (check for alphanumeric, underscores, etc.)
                                            setTwitterProfile(e.target.value)
                                            setError({ ...error, TwitterProfile: "" }) // Clear previous errors
                                        }}
                                        onFocus={() => setError({ ...error, TwitterProfile: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.TwitterProfile}</small>
                                </Form.Group>
                            </span>


                        </div>



                        <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>
                                <Form.Group>
                                    <label>Industry served based on Revenue</label>
                                    <Selectant
                                        isMulti
                                        name="IndustryservedbasedonRevenue"
                                        options={[{ value: "Automotive", label: "Automotive" }, { value: "Business services", label: "Business services" }, { value: "Dental", label: "Dental" }, { value: "Education", label: "Education" }, { value: "Financial services", label: "Financial services" }, { value: "Legal", label: "Legal" }, { value: "Medical", label: "Medical" }, { value: "Non-profit", label: "Non-profit" }, { value: "Real estate", label: "Real estate" }, { value: "ECommerce", label: "ECommerce" }]}
                                        value={industryBasedRevenue}
                                        onChange={setIndustryBasedRevenue}
                                        onFocus={() => setError({ ...error, industryBasedRevenue: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.industryBasedRevenue}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Client distribution Based on Revenue</label>
                                    <Selectant
                                        name="clientBasedRevenue"
                                        options={[{ value: "Small Business/Scale industry", label: "Small Business/Scale industry" }, { value: "Midsize Business/industry", label: "Midsize Business/industry" }, { value: "Enterprise level Business/industry", label: "Enterprise level Business/industry" }]}
                                        value={clientBasedRevenue}
                                        onChange={setClientBasedRevenue}
                                        onFocus={() => setError({ ...error, clientBasedRevenue: "" })}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{error.clientBasedRevenue}</small>
                                </Form.Group>
                            </span>
                        </div>



                        <div style={{ marginTop: '2%' }} className="userdeatail_container">
                            <span>

                                <Form.Group>
                                    <label>Upload Logo</label>
                                    <Form.Control
                                        name="logo"
                                        type="file"
                                        onChange={(e) => setLogo(e.target.files[0])}
                                        accept=".png, .jpg, .jpeg"
                                        onFocus={() => setError({ ...error, logo: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.logo}</small>
                                </Form.Group>
                            </span>
                            <span>
                                <Form.Group>
                                    <label>Upload Company Profile <div style={{ fontSize: "10px", float: "right" }}>(pdf only)</div></label>
                                    <Form.Control
                                        name="Company Profile"
                                        type="file"
                                        accept=".pdf"
                                        onChange={(e) => setPortfolio(e.target.files[0])}
                                        onFocus={() => setError({ ...error, portfolio: "" })}
                                    ></Form.Control>
                                    <small style={{ color: "#ec1c24" }}>{error.portfolio}</small>
                                </Form.Group>
                            </span>
                        </div>



                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <button
                                className="buton"
                                onClick={handleSubmit}
                                style={{ backgroundcolor: "#07b274", margin: "20px 0px 0px 20px", cursor: "pointer" }}
                            >
                                Submit
                            </button>
                        </div>
                    </div >
                </div >}
        </div >
    );
}

export default PartnerWithUs;