import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { MyContext } from '../../Mycontext'
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Lottie from "lottie-react";
import confettiAnimation from "../Animations/confetti.json"
import { motion } from "framer-motion";
import { PieChart } from 'react-minimal-pie-chart';
import { Popup, Progress } from 'semantic-ui-react';
import { Link } from "react-router-dom";
// import 'semantic-ui-css/semantic.min.css';
function Result({ UE_ID, SKT_ID }) {
    const navigate = useNavigate();
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [Score, setScore] = useState([]);
    const [result, setResult] = useState({
        TotalQuestions: 0,
        Correct: 0,
        Unattempted: 0,
        Score: 0
    })

    const getScore = async () => {
        const { data } = await axios.post(endPointUrl + "/getuserexam", {
            Token,
            SKT_ID: SKT_ID,
            UE_ID: UE_ID,
            User_ID: user.id,
        });
        setScore(data.data)

        let Total_Q = data.totalQuestionCount;
        let Correct_Q = data.correctQuestionCount;
        let Unattempted_Q = data.totalUnattemptedQuesCount;

        setResult({ TotalQuestions: Total_Q, Correct: Correct_Q, Unattempted: Unattempted_Q, Score: data.totalCorrectQuestionScore })
    };

    useEffect(() => {
        getScore();
    }, [])

    return (
        <div>
            <div className="home-howitwork" style={{ position: "absolute", top: "101px", left: "51px" }}>
                <Link to="/freelancer-job-profile">Home </Link>/{" "}
                <Link to="/skill-test">Skill Test </Link>/{" "}
                <Link to="/skill-qa">Result</Link>

            </div>
            <motion.div
                key={"result"}
                variants={{
                    initial: {
                        background: "#07b273",
                        clipPath: "circle(0% at 50% 50%)",
                    },
                    animate: {
                        background: Score.Result == "Pass" ? "#07b273" : "#FF6A66",
                        clipPath: "circle(100% at 50% 50%)",
                    },
                }}
                className="result-section-top"
                initial="initial"
                animate="animate"
                exit="exit"
                transition={{ duration: 1 }}
            >
                {Score.Result == "Pass" ? <div className='result-section-mid'><Lottie
                    animationData={confettiAnimation}
                    loop={false}
                    autoplay={true}
                    style={{ width: "170px", height: "170px", margin: "-35px", marginLeft: "-131px" }}
                />
                    <h3>
                        Congratulations!
                    </h3>
                </div> : <div className='result-section-mid'>
                    <h3 >
                        Better Luck Next Time!
                    </h3>
                </div>
                }

                <div className='result-section-bot'>
                    {Score.Result === "Pass" && <h4>
                        <span>
                            <button onClick={() => {
                                navigate("/generate-certificate", { state: { SKT_ID } })
                            }} className='mil_buton'>Get Certificate</button>
                        </span>
                    </h4>}

                    <h4>
                        {"You "} <span >{Score.Result}{"ed"}</span>
                    </h4>

                    {/* <p>Score : {(Score.Score * 100).toString().slice(0, 2)}%</p> */}
                    <p>Score : {(result.Correct / result.TotalQuestions ) * 100}</p>
                    <Popup trigger={<div className='piechart'>
                        <PieChart
                            data={[
                                { title: 'Correct ', value: result.Correct, color: '#07b274' },
                                { title: 'Incorrect', value: result.TotalQuestions - (result.Correct + result.Unattempted), color: '#FF6A66' },
                                { title: 'Unattempted', value: result.Unattempted, color: '#C1C7C9' },
                            ]}
                        />
                        <div className='chart_inside' style={{ cursor: 'pointer' }}>
                            <span style={{ fontSize: 'medium', color: 'white', fontWeight: 'bold' }}>{result.TotalQuestions}</span>
                            <span style={{ fontSize: 'small', color: 'white' }}>Total Questions</span>
                        </div>
                    </div>} position='right center' inverted style={{ backgroundColor: "#1b222a", borderRadius: "20px", padding: "10px" }}>
                        <div className='tooltipQA'>
                            <div className='tooltip_div' >
                                <input readOnly value='Correct Answers' />
                                <Progress value={result.Correct} total={result.TotalQuestions} inverted color='green' progress='ratio' />
                            </div>
                            <div className='tooltip_div' >
                                <input readOnly value='Incorrect Answers' />
                                <Progress value={result.TotalQuestions - (result.Correct + result.Unattempted)} inverted color='red' total={result.TotalQuestions} progress='ratio' />                            </div>
                            <div className='tooltip_div' >
                                <input readOnly value='Unattempted Questions' />
                                <Progress value={result.Unattempted} total={result.TotalQuestions} inverted color='black' progress='ratio' />                            </div>
                        </div>
                    </Popup>
                </div>
            </motion.div>
        </div>
    )
}

export default Result;