import GroupContainer from "../components/GroupContainer";
import comma1 from '../images/Icons/quote1.png'
import comma2 from '../images/Icons/quote2.png'
import React, { useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import { BsArrowRightCircle } from 'react-icons/bs'
import { useNavigate } from "react-router";
import { MyContext } from "../Mycontext";
import axios from "axios";


export default function ClientReviewContainer() {

  const { endPointUrl, Token } = useContext(MyContext);
  const [Reviews, setReviews] = useState([])

  const getReviews = async () => {
    const { data } = await axios.post(endPointUrl + '/admin/globalReviewfind', { Token, Status: "Active" });
    const shuffledReviews = data?.data?.sort(() => Math.random() - 0.5);
    const randomReviews = shuffledReviews.slice(0, 10);
    setReviews(randomReviews);
  }
  useEffect(() => {
    getReviews();
  }, [])

  const navigate = useNavigate();
  const handleClick = () => {
    document.documentElement.scrollTop = 0;
    navigate('/reviews')
  }
  return (
    <div className="Review_Slider">
      <div className="Review_Slider_Box">
        <div style={{ textAlign: "center", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <img src={comma1} alt="" />
          <div className="slider_box_title">
            <p >Testimonials</p>
            <h2 >CUSTOMER'S AWESOME</h2>
            <h3 >FeedBack</h3>
          </div>
          <img src={comma2} alt="" />
        </div>
        <Swiper
          modules={[Autoplay, Pagination]}
          pagination={{ clickable: true }}
          loop={true}
          slidesPerView={3} // Adjust this value
          slidesPerGroup={1} // Adjust this value
          autoplay={{
            delay: 3000, // Initial delay
          }}
        >
          {[...Array((Reviews?.length) + 1)].map((_, index) => (
            <SwiperSlide key={index}>
              <GroupContainer index={index} />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="jobs-card-open" style={{ marginTop: "20px" }}>
          <button className="btn-outline" onClick={handleClick}>Read all Reviews<BsArrowRightCircle /></button>
        </div>
      </div>

    </div>

  )
}

