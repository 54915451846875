import React, { useState, useContext } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { MyContext } from "../../Mycontext";
import axios from "axios";
import { Tag } from "antd";
function DocumentUpload({ detailjob }) {
  const { endPointUrl, Token } = useContext(MyContext);
  const [files, setFiles] = useState(
    detailjob?.Documents && detailjob.Documents.length > 0
      ? detailjob.Documents.split(",")
      : []
  );
  async function handleFileChange(event) {
    const fileList = event.target.files[0];
    const formdata = new FormData();
    formdata.append("photo", fileList);
    try {
      const { data } = await axios.post(
        endPointUrl + "/Jobposting/imageUpload",
        formdata
      );

      console.log(files);
      setFiles([...files, data.fileName]);
      let doc = files.join(",") + "," + data.fileName;
      try {
        await axios.post(endPointUrl + "/user/UpdateJobs", {
          Token,
          JobID: detailjob.JobID,
          Documents: doc,
        });
      } catch {
        console.log("upload url produce error");
      }
    } catch {
      console.log("error occur during upload docs");
    }
  }

  async function removeTag(indexToRemove) {
    let removefile = files[indexToRemove];
    let remove = files.filter((_, index) => index !== indexToRemove);
    setFiles(files.filter((_, index) => index !== indexToRemove));
    try {
      await axios.post(endPointUrl + "/Jobposting/RemoveDoc", {
        filename: removefile,
      });
      await axios.post(endPointUrl + "/user/UpdateJobs", {
        Token,
        JobID: detailjob.JobID,
        Documents: remove.join(","),
      });
    } catch {
      console.log("img not removed");
      console.log(removefile);
    }
  }

  return (
    <>
      <label htmlFor="file-input" className="custom-file-upload">
        <UploadOutlined /> Upload
        <input
          id="file-input"
          type="file"
          name="file"
          multiple
          onChange={handleFileChange}
          style={{ display: "none" }}
        />
      </label>
      <div className="uploadtags">
        {(files ? files : []).map((tag, index) => (
          <span key={index}>
            <a
              href={`${endPointUrl}/images/${tag}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {tag}
            </a>
            <button
              type="button"
              onClick={() => removeTag(index)}
              aria-label={`Remove ${tag}`}
            >
              ×
            </button>
          </span>
        ))}
      </div>
    </>
  );
}

export default DocumentUpload;
