import React from 'react'
import { Link } from 'react-router-dom'
export default function EscrowPaymentSustem() {
  return (
      <div className='HowItWorks privacy-footer'>
          <div className="home-howitwork">
              <Link to="/">Home </Link>/{" "}
              <Link to="/escrow-payment-system">Fees and Charges</Link>
          </div>
          <span className="jobpostheading" style={{ backgroundColor: "#ceefe4", paddingRight: "0 40px 0 40px" }}>
              <div>
                  Fees and Charges
                  {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur, perspiciatis.</p> */}
              </div>
          </span>
          <div className='footer-terms'>
              <div class="footer-terms-heading">
                  Financial fees and charges, providing you with a comprehensive understanding of the various types of fees you may encounter in banking, investing, and other financial transactions. We'll discuss the common fees and charges associated with Project. 
              </div>
              <section>
                  <div className='footer-terms-main'>
                      <h3>
                          For Freelancers
                      </h3>
                      <span>
                          Freelancers using the Digilanxer platform can take advantage of a straightforward fee policy that allows them to focus on their work and grow their business. Freelancers enjoy the benefit of refunds as bonus credit on projects where the employer pays in advance and cancels the project, allowing them to use the refunded amount for future projects. This feature is aimed at providing freelancers with financial security and peace of mind when working on the platform.
                      </span>
                  </div>
                  <div className='footer-terms-main'>
                      <h3>
                          Refunds as Bonus Credit
                      </h3>
                      <span>
                          One of the unique features of Digilanxer's fee policy is the option for refunds as bonus credit. This innovative approach ensures that both employers and freelancers have added flexibility and protection when engaging in projects on the platform. By offering refunds as bonus credit, Digilanxer demonstrates its commitment to creating a fair and supportive environment for all users.
                      </span>
                  </div>
                  <div className='footer-terms-main'>
                      <h3>
                          5% Quote Fee
                      </h3>
                      <span>
                          The 5% quote fee implemented by Digilanxer is a reasonable and competitive charge for employers seeking to access a diverse pool of skilled freelancers. This fee covers the administration and maintenance of the platform, allowing employers to efficiently connect with freelancers and manage their projects. With this fee, employers can benefit from the value-added services and support provided by Digilanxer without incurring substantial additional costs.
                      </span>
                  </div>
                  <div className='footer-terms-main'>
                      <h3>
                          Arbitration Fees
                      </h3>
                      <span>
                          In the event of a dispute between an employer and a freelancer, Digilanxer offers an arbitration service to help parties reach a fair resolution. Both parties share the cost of the arbitration fees, which underscores Digilanxer's commitment to impartial conflict resolution and ensuring a positive experience for all users. This approach provides a structured and fair process for resolving disputes and promotes a professional and respectful working environment.
                      </span>
                  </div>
                  <hr />
                  <section>
                      <h2 >Digilanxer Subscription and Bidding Terms and Conditions</h2>
                      <div class="footer-terms-heading">
                          Financial fees and charges, providing you with a comprehensive understanding of the various types of fees you may encounter in banking, investing, and other financial transactions. We'll discuss the common fees and charges associated with Project.
                      </div>
                      <div className='footer-terms-main'>
                          <h3>
                              Prohibited Direct Bidding
                          </h3>
                          <span>
                              Freelancers are prohibited from directly bidding on projects without a valid subscription. This measure ensures that freelancers are committed and invested in the platform, maintaining a professional and active community of skilled individuals.
                          </span>
                      </div>
                      <div className='footer-terms-main'>
                          <h3>
                              Subscription Flexibility and Value
                          </h3>
                          <span>
                              Digilanxer offers a range of subscription options, allowing freelancers to choose a plan that best suits their needs and budget. With flexible subscription terms and competitive pricing, freelancers can access value-added features and increase their visibility to potential clients.
                          </span>
                      </div>
                      <div className='footer-terms-main'>
                          <h3>
                              Compliance and Enforcement
                          </h3>
                          <span>
                              Freelancers are expected to comply with Digilanxer's subscription and bidding terms and conditions. Non-compliance may result in account suspension or other disciplinary actions, as the platform seeks to uphold standards of professionalism and fair competition within its community.
                          </span>
                      </div>
                  </section>
              </section>
          </div>
      </div>
  )
}
