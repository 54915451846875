import React, { useState, useContext } from 'react'
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Bidding from '../Jobs/Bidding';
import { MyContext } from "../../Mycontext";
import axios from "axios";
import { message } from "antd";
import { Collapse } from "antd";
const { Panel } = Collapse;
import { IoArrowBackSharp } from 'react-icons/io5';
import { scroller } from "react-scroll";
import DisputedJobs from '../Jobs/DisputedJobs';
import ImageModal from '../Jobs/ImageModal';


export default function DetailviewJob() {
    const { id } = useParams();
    const Navigate = useNavigate();
    const [detailjob, setdetailjob] = useState();
    const [balance, setBalance] = useState(0);
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [show, setshow] = useState(false);
    const [Applied, setapplied] = useState(true);
    const [showModal, setShowModal] = useState(false)
    const [emp, setEmp] = useState({})
    const [disputedData, setDisputedData] = useState(false)
    const [disputeAttach, setDisputeAttach] = useState([])
    const [alreadyRaised, setAlreadyRaised] = useState(false)
    const [imageModal, setImageModal] = useState(false)
    const [curImage, setCurImage] = useState({})
    const [analytics, setAnalytics] = useState([]);
    const [projectStatus, setProjectStatus] = useState();
    const [empName, setEmpName] = useState('');
    const [empId, setEmpID] = useState('');
    const [disputeStatus, setDisputeStatus] = useState('');

    const Analytics = async (empid) => {
        try {
            const { data } = await axios.post(endPointUrl + "/analytics", {
                id: empid,
                Token
            });
            setAnalytics(data.data);


            const res = await axios.post(endPointUrl + "/getUserById", {
                Token,
                id: empid,
            });

            setEmpName(`${res.data.data.Name} ${res.data.data.Last_Name}`);
            setEmpID(res.data.data.id)
        } catch {
            message.error("Error while fetching analytics")
        }
    };

    const getDispute = async (detailjobb) => {
        try {
            const { data } = await axios.post(endPointUrl + "/IsDisputeRaised", {
                UserID: user.id,
                EmployerID: detailjobb.Employer_ID,
                JobID: detailjobb.P_ID
            });

            const res = await axios.post(endPointUrl + "/getProjectbyId", {
                Token,
                P_ID: detailjobb.P_ID,
            });
            setProjectStatus(res?.data?.data?.Project_status);

            if (data.status === true) {
                setDisputedData(data.data);
                setDisputeStatus(data.data.Status)
                const att = data.data.Attachment.split(',');
                setDisputeAttach(att);
                setAlreadyRaised(true);
            } else {

            }
        } catch (error) {
            console.log("ERROR IN GET PROJECT BY ID : ", error)
        }
    }

    console.log("PROJECT SGTATUS :  : ", projectStatus)

    const handleimageClick = (item) => {
        setCurImage(item, () => {
            imageModal(true)
        });
    }

    const handleDisput = async () => {
        getDispute()
        try {
            const { data } = await axios.post(endPointUrl + "/getUserById", {
                Token,
                id: detailjob.Employer_ID,

            });
            if (data.status === true && data.data) {
                setEmp(data.data)

                setShowModal(!showModal)
            } else {

            }
        } catch {

        }
    }


    const scrollToElement = () => {
        scroller.scrollTo("myElement", {
            duration: 300,
            delay: 0,
        });
    };

    const checkeditTable = async (JobID) => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
                Token,
                UserID: user.id,
                JobID: JobID,
            });
            if (data.status === true && data.data) {
                setapplied(true);
                setshow(true);
            } else {
                setapplied(false);
                setshow(false);
            }
        } catch {
            setapplied(false);
            setshow(false);
        }
    };

    const BidBalance = async () => {
        const { data } = await axios.post(endPointUrl + "/getuserByBidBalance", {
            Token,
            id: user.id,
        })
        setBalance(data.data.Bid_Balance);
    };

    const checkUser = () => {
        if (user) {
            if (user.Type === "Employer") {
                message.warning("Your are not a Freelancer ")
            } else {
                scrollToElement();
                balance ? setshow(true) : Navigate("/packages")
            }
        } else {
            Navigate("/login")
            message.warning("Login As Freelancer First")
        }
    }

    useEffect(() => {
        const getjobs = async () => {
            try {
                const { data } = await axios.post(endPointUrl + "/user/getJobsByJobId", {
                    JobID: id,
                    Token,
                });
                setdetailjob(data.data);
                Analytics(data.data.Employer_ID);
                getDispute(data.data)
            } catch (err) {
                console.log(err);
            }

        };
        getjobs()
        BidBalance()
        checkeditTable(id);

    }, [])

    // function getLanguage(item) {
    //     // const languages = JSON.parse(item?.Language)

    //     let languages;
    //     try {
    //         languages = JSON.parse(item.Language);
    //     } catch (error) {
    //         return (<span>{item.Language}</span>);
    //     }
    //     return (
    //         <span>
    //             {languages?.Language1}
    //             {languages?.Language2 ? `, ${languages.Language2}` : ''}
    //             {languages?.Language3 ? `, ${languages.Language3}` : ''}
    //             {languages?.Language4 ? `, ${languages.Language4}` : ''}
    //             {languages?.Language5 ? `, ${languages.Language5}` : ''}
    //         </span>
    //     );
    // };

    return (
        <div className="jobProfileContainer">
            <div className='compo'>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header={
                        <div >
                            <IoArrowBackSharp style={{
                                backgroundColor: "white",
                                borderRadius: "50px",
                                stroke: "#073b",
                                padding: "2px",
                                margin: " -8px 10px -8px 0"
                            }}
                                onClick={() => {
                                    window.history.back({ activej: 1, onactivej: 0 });

                                }}
                            />
                            <span>
                                {detailjob?.Title}
                            </span>
                        </div>
                    }
                        key="1" showArrow={false} style={{ fontFamily: "poppins" }}>
                        <div
                            style={{ color: "#606563", wordBreak: "break-word" }}
                            dangerouslySetInnerHTML={{ __html: detailjob?.Description }}
                        ></div>
                    </Panel>
                </Collapse >

                <div className='reviews__container'>
                </div>

                <div className='biddata'>
                </div>

                <div id="myElement">
                    <Bidding
                        Balance={balance}
                        detailjob={detailjob}
                        show={show}
                        Applied={Applied}
                        checkUser={checkUser}
                        disputeStatus={disputeStatus}
                    />
                </div>

                {disputedData ?
                    <div className='disputed__container'>
                        <div>
                            <p className='disputeepara2' style={{ fontFamily: 'Poppins', fontWeight: '900', color: '#001', marginLeft: '1rem', marginTop: '1rem' }}>{disputedData.RaisedBy == 'Freelancer' ? `You have Raised a dispute for this project on ${new Date(disputedData.CreatedDate)}` : `Employer have Raised a dispute for this project on ${new Date(disputedData.CreatedDate)}`}</p>

                            <div style={{ marginLeft: '1rem' }}>
                                <p style={{ color: '#001', fontFamily: 'Poppins', fontWeight: 'bold', marginBottom: 0,wordBreak:"break-word" }}>Description</p>
                            </div>
                            <div style={{ marginLeft: '1.001rem' }}>
                                <div style={{ color: '#001', marginTop: '.3rem', wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: disputedData.Description }} />
                            </div>

                            <div style={{ marginLeft: '1rem' }}>
                                <p style={{ color: '#001', fontFamily: 'Poppins', fontWeight: 'bold' }}>Attachment</p>
                            </div>
                            <div>
                                {
                                    disputeAttach.map((item) => {
                                        console.log(item)
                                        return (
                                            <div style={{ width: 80, height: 60, overflow: 'hidden', marginLeft: '1rem', cursor: 'pointer' }}>
                                                <a href={`${endPointUrl}/images/${item}`} target='_blank' ><img onClick={() => { handleimageClick(item) }} style={{ width: '100%' }} src={`${endPointUrl}/images/${item}`} /></a></div>
                                        )
                                    })
                                }
                            </div>

                            <div className='disputee__para' style={{ marginTop: "3%" }}>
                                Admin Action : {disputedData.AdminAction || "Admin has not taken any action yet!"}
                            </div>
                            <p className='disputee__para'> Status: {disputedData.Status}
                            </p>
                        </div>
                    </div>
                    : <></>}

            </div>


            <div style={{ width: '21%' }}>
                <div className="sideComponent" style={{ width: '100%' }}>
                    <div className="sideComponent_bid">
                        <span>Bid Balance</span>
                        <span id="highlight">{balance ? balance : 0}</span>

                    </div>
                    <div className="see-other-post">
                        {!Applied ? (
                            <button
                                className="btn"
                                onClick={() => {
                                    scrollToElement();
                                    { balance ? setshow(true) : Navigate("/packages") }
                                }}
                            >
                                Apply
                            </button>
                        ) : null}
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className="uploadDocs">
                        <h4 style={{ margin: "0 0 5px 0" }}>
                            {detailjob?.IsActive ? "Documents" : "Upload Documents"}{" "}
                        </h4>
                        {
                            detailjob?.IsActive ? (
                                <div className="uploadtags">
                                    {detailjob.Document
                                        ?
                                        <span>
                                            <a
                                                href={`${endPointUrl}/images/${detailjob.Document}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <img style={{ width: '30%' }} src={`${endPointUrl}/images/${detailjob.Document}`} />
                                            </a>
                                        </span> : <></>
                                    }
                                </div>
                            ) : null
                            // <DocumentUpload detailjob={detailjob} getjobs={getjobs} />
                        }
                    </div>
                    <hr style={{ width: "100%", background: "" }} />
                    <div className="row-view">
                        <div>
                            <h4 style={{ margin: "5px 0 5px 0" }}>Budget Details</h4>
                            <small>{detailjob?.Budget_From}-</small>
                            <small>{detailjob?.Budget_To}$</small>
                            {/* <small>{detailjob?.Budget_Type}</small> */}
                        </div>
                        <div>
                            {" "}
                            <h4 style={{ margin: "5px 0 5px 0" }}>Job Type</h4>
                            <small>{detailjob?.Type}</small>
                        </div>
                    </div>
                    <hr style={{ width: "100%", background: "" }} />
                    <div>
                        {" "}
                        <h4 style={{ margin: "20px 0 5px 0" }}>Skills</h4>
                        <div className="skillview">
                            {detailjob?.SkillsRequired?.split(",")?.map((item) => (
                                <span>{item}</span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h4 style={{ margin: "20px 0 5px 0" }}>Technology</h4>
                        <div className="skillview">
                            {detailjob?.Technology?.split(",").map((item) => (
                                <span>{item === "Other" ? `${detailjob.TechnologyOther}` : `${item}`}</span>
                            ))}
                        </div>
                    </div>

                    <div>
                        <h4 style={{ margin: "20px 0 5px 0" }}>Category</h4>
                        <div className="skillview">
                            {detailjob?.Category?.split(",").map((item) => (
                                <span>{item === "Other" ? `${detailjob.JobCategoryOther}` : `${item}`}</span>
                            ))}
                        </div>
                    </div>


                    <div className="rate-card" style={{ borderRadius: "0 0 20px 20px" }}>
                        <h2 style={{ marginBlock: "auto", wordBreak: 'break-word' }}
                        >About Employer</h2>
                        <p
                            style={{ fontSize: "12px", color: "#606060" }}>{detailjob?.employer?.About}</p>
                        <div>

                        </div>
                        <hr />
                        <div
                            style={{ color: "#07b274", cursor: "pointer" }} onClick={() => Navigate(`/employer-profile/${empId}`)}
                            className="user-details"
                        >
                            <p>Employee Name</p>
                            <span>{empName}</span>
                        </div>
                        <div className="user-details">
                            <p>Job Post</p>
                            <span>{analytics?.totalprojects}</span>
                        </div>
                        <div className="user-details">
                            <p>Completed Projects</p>
                            <span>{analytics?.completedProject}</span>
                        </div>
                        <div className="user-details">
                            <p>Ongoing Projects</p>
                            <span>{analytics?.ongoingprojects}</span>
                        </div>
                        <div className="user-details">
                            <p>Close Projects</p>
                            <span>{analytics?.expiredprojects}</span>
                        </div>
                        <div className="user-details">
                            <p>Project Award</p>
                            <span>{Math.floor(((analytics?.completedProject + analytics?.ongoingprojects) / analytics?.totalprojects) * 100)}%</span>
                        </div>
                        <div className="user-details">
                            <p>Average Project Payment</p>
                            <span>${analytics?.completedProject > 0 ? (Math.floor(analytics?.revenuegenerated / (analytics?.completedProject + analytics?.ongoingprojects))) ? (Math.floor(analytics?.revenuegenerated / (analytics?.completedProject + analytics?.ongoingprojects))) : 0 : 0}</span>
                        </div>
                        <div className="user-details">
                            <p>Total Spent</p>
                            <span>${analytics?.revenuegenerated}</span>
                        </div>
                        {/* <div className="user-details">
                            <p>Language</p>
                            {getLanguage(detailjob?.employer)}
                        </div>

                        <div className="user-details">
                            <p>Gender</p>
                            <span>{detailjob?.employer?.Gender}</span>
                        </div> */}
                    </div>


                </div>

                {
                    projectStatus === "Completed" ? <></> : showModal && detailjob?.Status === "Approved" ?
                        <DisputedJobs detailjob={detailjob} showpublished={showModal} setshowPublished={setShowModal} emp={emp} mode={'Freelancer'} />
                        : <></>}
                {
                    projectStatus === "Completed" ? <></> : imageModal ?
                        <ImageModal showmodel={imageModal} setShowModal={setImageModal} /> : <></>
                }

                {projectStatus === "Completed" ? <></> : alreadyRaised ? <div style={{ width: 180, height: 500 }}>
                    <div style={{ width: 200, height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: 'rgba(128, 128, 128, 0.6)', color: '#fff', marginTop: '2rem', cursor: 'pointer', fontWeight: 600, border: '1px solid 2px solid rgba(128, 128, 128, 0.6)' }}
                    >
                        <p>Raise Dispute</p>
                    </div>
                </div> : detailjob?.Status === "Approved" ? <div style={{ width: 180, height: 500 }}>
                    <div style={{ width: 200, height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: '#07b273', color: '#fff', marginTop: '2rem', cursor: 'pointer', fontWeight: 600 }}>
                        <p onClick={handleDisput}>Raise Dispute</p>
                    </div>
                </div> : <></>
                }

            </div>



        </div>
    )
}
