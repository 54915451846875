import React, { useContext, useEffect, useState } from "react";
import { useTable, usePagination, useGlobalFilter, useSortBy, } from "react-table";
import { MyContext } from "../../Mycontext";
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Form } from "react-bootstrap";

function Master({ milestone, ProjectType }) {
  const { endPointUrl, Token } = useContext(MyContext)
  const data = React.useMemo(() => milestone, [milestone]);

  let columns = '';
  {
    ProjectType === "Hourly" ?
      columns = React.useMemo(
        () => [
          {
            Header: "Amount",
            accessor: "Amount", // accessor is the "key" in the data
          },
          {
            Header: "Description",
            accessor: "Description",
          },
          {
            Header: "Project Status",
            accessor: "MilstoneStatus",
          },
          {
            Header: "Work Upload",
            accessor: "timeSheetAttachment"
          },
          {
            Header: "Hour Invested",
            accessor: "timeSpend"
          },
          {
            Header: "Work Description",
            accessor: "descriptionWork"
          },
        ],
        []
      )
      :
      columns = React.useMemo(
        () => [
          {
            Header: "M_ID",
            accessor: "M_ID",
          },
          {
            Header: "Amount",
            accessor: "Amount", // accessor is the "key" in the data
          },
          {
            Header: "Description",
            accessor: "Description",
          },
          {
            Header: "M_Due_Date",
            accessor: "M_Due_Date",
          },
          {
            Header: "MilstoneStatus",
            accessor: "MilstoneStatus",
          },
          {
            Header: "TimeSheet",
            accessor: "timeSheetAttachment"
          },
          {
            Header: "Work Description",
            accessor: "descriptionWork"
          },
        ],
        []
      );
  }
  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter } = state;
  return (
    <>
      <input
        className="search-box"
        type="search"
        placeholder="Search"
        aria-label="Search"
        value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)}
      />
      {ProjectType === "Fixed" ?
        <table>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const { column } = cell;
                    if (column.id === 'M_Due_Date') {
                      const date = new Date(cell.value);
                      const formattedDate = date.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      });
                      return <td {...cell.getCellProps()}>{formattedDate}</td>;
                    }
                    if (column.id === "M_ID") {
                      return <td {...cell.getCellProps()}>{index + 1}</td>
                    }
                    if (column.id === "timeSheetAttachment") {
                      return milestone[index].timeSheetAttachment ?
                        <td {...cell.getCellProps()}><a href={`${endPointUrl}/images/${milestone[index].timeSheetAttachment}`} target="_blank">View Work</a></td>
                        :
                        <td {...cell.getCellProps()}><div>Work Not Uploaded</div></td>
                    }
                    if (column.id === "Description") {
                      return <td {...cell.getCellProps({ className: "work-description" })}>{cell.render('Cell')}</td>;
                    }
                    if (column.id === "descriptionWork") {
                      return <td {...cell.getCellProps({ className: "work-description" })}>{cell.render('Cell')}</td>;
                    }
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        :
        <table>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    const { column } = cell;
                    if (column.id === 'M_Due_Date') {
                      const date = new Date(cell.value);
                      const formattedDate = date.toLocaleDateString('en-GB', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      });
                      return <td {...cell.getCellProps()}>{formattedDate}</td>;
                    }
                    if (column.id === "M_ID") {
                      return <td {...cell.getCellProps()}>{index + 1}</td>
                    }
                    if (column.id === "timeSheetAttachment") {
                      return milestone[index].timeSheetAttachment ?
                        <td {...cell.getCellProps()}><a href={`${endPointUrl}/images/${milestone[index].timeSheetAttachment}`} target="_blank">View Work</a></td>
                        :
                        <td {...cell.getCellProps()}><div>Work Not Uploaded</div></td>
                    }
                    if (column.id === "descriptionWork") {
                      return <td {...cell.getCellProps({ className: "work-description" })}>{cell.render('Cell')}</td>;
                    }
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>}
    </>
  );
}

export default Master;
