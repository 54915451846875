import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { MyContext } from "../Mycontext";
import { useNavigate } from 'react-router';
import img from '../images/hire-freelancer.png'
import '../AllNotification.scss'

function AllNotification() {
    const navigate = useNavigate();
    const { Token, user, endPointUrl, socket, notificationCount, setNewNotificationCount, notificationArticles, setNotificationArticles } = useContext(MyContext);
    // const [notificationArticles, setNotificationArticles,] = useState([]);

    const timeDifference = (current, previous) => {
        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const msPerMonth = msPerDay * 30;
        const elapsed = current - previous;
        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + 's ago';
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + 'm ago';
        } else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + 'h ago';
        } else if (elapsed < msPerMonth) {
            const date = new Date(previous);
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        } else {
            const date = new Date(previous);
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        }
    };

    useEffect(() => {
        const getallNotifications = async () => {
            const { data } = await axios.post(`${endPointUrl}/admin/getNotifications`, { userId: user.id });
            console.log("sssssssssss", data.notificationData)
            const unreadNotifications = data.notificationData.filter(item => !item.notification.IsRead);
            setNewNotificationCount(unreadNotifications.length);
            setNotificationArticles(data.notificationData)

        }
        getallNotifications()
    }, [])
    //   console.log(articles);
    const markNotificationRead = async (Nid) => {
        try {
            const updatedArticles = notificationArticles.map(article => {
                if (article.notification.NotificationID === Nid) {
                    return { ...article, notification: { ...article.notification, IsRead: true } };
                }
                return article;
            });

            setNotificationArticles(updatedArticles);
            await axios.post(endPointUrl + "/admin/UserNotificationUpdate", { Token, NotificationID: Nid, IsRead: "true" })
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        
        // Ensure the socket exists before setting up listeners

        socket?.on('getNotificationJobAssign', data => {
            setNotificationArticles(prevNotifications => [data, ...prevNotifications]);
        });

    }, [socket]);

    // useEffect(() => {
    //     const eventSource = new EventSource(`${endPointUrl}/admin/UserNotificationFind/${user?.id}`);

    //     eventSource.onmessage = (event) => {
    //         const eventData = JSON.parse(event.data);
    //         setNotificationArticles([...eventData.notifications]);
    //     };

    //     return () => {
    //         eventSource.close();
    //     };
    // }, [endPointUrl, user?.id]);
    // console.log("Notification", notificationArticles)
    // console.log("NotificationCount", notificationCount)
    return (
        <div className='allNotification_container'>
            <div className='subNotification_Container'>
                <div className='listBox'>
                    <ul>
                        <li>All Notifications</li>
                    </ul>
                    <span>{notificationCount || 0}</span>
                </div>
                <div className='notification_container'>
                    {notificationArticles.map((article, index) => (

                        <div key={index} className={`userDetail_coantainer ${article?.notification?.IsRead ? 'read' : 'notread'}`}
                            // onClick={() => {
                            //     if (article.notification.IsRead === false) {
                            //         setNewNotificationCount(prevCount => prevCount - 1)
                            //         markNotificationRead(article.notification.NotificationID);
                            //     }
                            //     if (article.fromUser.Type == "Employer") {
                            //         navigate(`/detailviewJob/${article?.notification?.ItemID}`);
                            //     } else if (article.fromUser.Type == "Freelancer") {
                            //         navigate(`/DetailviewJobE/${article?.notification?.ItemID}`);
                            //     }
                            // }}
                        >
                            <div className='useDetail' >

                                {article?.fromUser?.Photo ? (
                                    <img src={`${endPointUrl + /images/ + article.fromUser.Photo}`} alt="User Photo" className='userImage' />
                                ) : (
                                    <img src={img} alt="Default User Photo" className='userImage' />
                                )}
                                <div className='userDetail_Box'>
                                    <p><span >{article?.notification?.Message}</span></p>
                                </div>
                            </div>
                            <div className='userDetail_Icon'>
                                <p>
                                    {timeDifference(new Date(), new Date(article?.notification?.createdAt))}
                                </p>
                                <p>...</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default AllNotification;