import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../Mycontext";
import axios from "axios";
import getCard from "./PartnerCard";
import CardComponentWithStyles from "./PartnerCard"



export default function PartnerWithUs() {
    const { endPointUrl, Token } = useContext(MyContext);
    const [partners, setPartners] = useState([])
    const navigate = useNavigate();

    const getPartnerWithUS = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/getPartners", { Token });
            if (data) {
                setPartners(data.data);
            }
        } catch (err) {
            console.log(err);
        }
    }


    // function getCard() {
    //     if (partners.length > 0) {
    //         return partners.map((item) => {
    //             return (
    //                 <div className="card" style={{ width: '20%', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer', padding: '60px' }} onClick={() => navigate('/become-partner', { state: { partner: item } })}>
    //                     <div style={{ width: '250px', height: '250px', padding: '40px', border: '2px solid #ddd', backgroundColor: '#f2f2f2', borderRadius: '3px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
    //                         <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
    //                             <img src={`${endPointUrl}/images/${item.Logo}`} style={{ width: '100%', height: "90px", objectFit: 'contain' }} className="card-img-top" alt="..." />
    //                         </div>
    //                         <div className="card-body" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '20px' }}>
    //                             <h5 className="card-title">{item.CompanyName}</h5>
    //                             <a href="/become-partner" className="btn btn-primary" style={{ padding: '10px', marginTop: '10px' }}>Details</a>
    //                         </div>
    //                     </div>
    //                 </div>

    //             )
    //         })
    //     }
    //     else {
    //         return (
    //             <div>No Partners Yet...</div>
    //         )
    //     }
    // }

    useEffect(() => {
        getPartnerWithUS()
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="HowItWorks">
            <div className="home-howitwork">
                <Link to="/">Home </Link>/{" "}
                <Link to="/freelancers">Partner With Us</Link>
            </div>

            <div className="Apart">
                <h1>Unity: What brings us together?</h1>
                <p>
                    Joining forces to drive positive change and achieve shared goals through cooperation and synergy.                </p>
            </div>

            <div className="aprt-containet-head" style={{ textAlign: 'center', marginTop: '3%' }}>
                Our Partners
            </div>

            <hr />

            <div style={{ marginTop: '3%' }}> <CardComponentWithStyles partners={partners} endPointUrl={endPointUrl} /></div>


            <div style={{ textAlign: 'center', marginTop: '5%' }}>
                <span>
                    <button className="buton" style={{ marginTop: '0px' }} onClick={() => navigate("/become-partner")}>Become Partner</button>
                </span>
            </div>
        </div>
    )
}