import React, { useState, useContext, useEffect } from 'react'
import axios from "axios";
import { message } from 'antd';
import { MyContext } from '../Mycontext';



function Rating({ disputeStatus, P_ID, JobID, JobTitle, show, freelancer }) {
  const { user, endPointUrl, Token, socket } = useContext(MyContext);
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [reviews, setReviews] = useState("");
  const [reviewd, setReviewd] = useState([]);
  const [employer, setEmployer] = useState(null);
  const [freelance, setFreelance] = useState('')
  const [status, setStatus] = useState("");
  const [EmployerStatus, setEmployerStatus] = useState("");
  const [FreelancerStatus, setFreelancerStatus] = useState("");
  const [review, setReview] = useState('');

  const getemployer = async (JobID) => {
    const { data } = await axios.post(endPointUrl + "/user/getemployerByjobID", {
      Token,
      JobID: JobID,
    })
    setEmployer(data.data);
  };


  const getFreelancer = async () => {
    if (freelancer) {
      const { data } = await axios.post(endPointUrl + "/getUserById", {
        Token,
        id: freelancer
      });

      setFreelance(data.data)
    }
  }

  const checkStatus = async (P_ID) => {
    const { data } = await axios.post(endPointUrl + "/getProjectbyId", {
      Token,
      P_ID: P_ID,
    });
    setStatus(data?.data?.Project_status);
    setEmployerStatus(data?.data?.Employer_Project_Status)
    setFreelancerStatus(data?.data?.Freelancer_Project_Status)
  };

  const getreviews = async (P_ID, freelancer) => {
    try {
      const { data } = await axios.post(endPointUrl + "/getReviewById", {
        Token,
        From_ID: user.id,
        To_ID: freelancer ? freelancer : employer.id,
        P_ID: P_ID,
      })

      setReviewd(data.data);
    }
    catch (error) {
      console.log("error", error)
    }
  };


  useEffect(() => {
    if (employer) {
      getreviews(P_ID)
    }
  }, [employer]);


  const updateRating = async (P_ID) => {

    if (rating === 0 || reviews === "") {
      message.warning("Rating or Review can not be empty")
    } else {
      try {
        if (user.Type === "Freelancer") {
          const { data } = await axios.post(endPointUrl + "/addreview", {
            Token,
            From_ID: user.id,
            From_Name: user?.Name,
            To_Name: employer?.Name,
            To_ID: employer.id,
            P_ID: P_ID,
            P_Name: JobTitle,
            Stars: rating,
            Review: reviews
          })

          socket.emit("Job_Expiry", {
            Type: "Rating",
            FromID: user.id,
            ToID: employer.id,
            Message: `${user.Name} is rating ${rating} star for job post ${JobTitle}`,
            IsRead: "false",
            ItemID: JobID
          })
        }
        else {
          const { data } = await axios.post(endPointUrl + "/addreview", {
            Token,
            From_ID: user.id,
            From_Name: user?.Name,
            To_Name: freelance?.Name,
            To_ID: freelance.id,
            P_ID: P_ID,
            P_Name: JobTitle,
            Stars: rating,
            Review: reviews
          })

          socket.emit("Job_Expiry", {
            Type: "Rating",
            FromID: user.id,
            ToID: freelance.id,
            Message: `${user.Name} is rating ${rating} star for job post ${JobTitle}`,
            IsRead: "false",
            ItemID: JobID
          })
        }
        message.success("Review submitted")

        window.location.reload(false);
      } catch (error) {
        console.log('which is error', error)
        message.error("some error occured")
      }
    }
  };
  const onChange = (e) => {
    setReviews(e.target.value);
  };
  const onSubmit = () => {
    updateRating(P_ID);
  }

  useEffect(() => {
    if (freelancer) {
      getreviews(P_ID, freelancer);
      getFreelancer()

    } else if (JobID && P_ID) {
      getemployer(JobID, P_ID);

    }
  }, [JobID, P_ID, freelancer])


  useEffect(() => {
    checkStatus(P_ID)
  })

  const handleMarkAsDone = async () => {
    try {
      if (FreelancerStatus === "Completed") {
        await axios.post(endPointUrl + "/UpdateProjects", {
          Token,
          P_ID: P_ID,
          Project_status: "Completed",
          Employer_Project_Status: "Completed"
        })
      }
      else if (EmployerStatus === "Completed") {
        await axios.post(endPointUrl + "/UpdateProjects", {
          Token,
          P_ID: P_ID,
          Project_status: "Completed",
          Freelancer_Project_Status: "Completed"
        })
      }
      else {
        if (user.Type === "Freelancer") {
          await axios.post(endPointUrl + "/UpdateProjects", {
            Token,
            P_ID: P_ID,
            Project_status: "Semi_Completed",
            Freelancer_Project_Status: "Completed"
          })
        }
        else {
          await axios.post(endPointUrl + "/UpdateProjects", {
            Token,
            P_ID: P_ID,
            Project_status: "Semi_Completed",
            Employer_Project_Status: "Completed"
          })
        }
      }

      checkStatus(P_ID);

      socket.emit("notificationJobAssign", {
        Type: "Project",
        FromID: user.id,
        ToID: employer.id,
        Message: `Project Completed for job ${JobTitle}`,
        IsRead: "false",
        ItemID: JobID
      })
      message.success("Project Completed successfully")

    } catch (error) {
      console.log("Error While Update Project Status", error)
    }
  }


  return (
    <>{
      show === "Released" && status === "Semi_Completed" && (user.Type === "Freelancer" ? FreelancerStatus != "Completed" : EmployerStatus != "Completed") ?
        disputeStatus === "Pending" || disputeStatus === "InProgress" ? <></> : <div className="Review-container">
          <div className="star-rating">
            <button onClick={handleMarkAsDone} style={{ borderRadius: "3px" }}>Mark As Done</button>
          </div>
        </div>
        : <>
          {show === "Released" || status === "Completed" ?
            reviewd?.length === 0 ?
              <div className="Review-container">
                <div className="star-rating">
                  {[...Array(5)].map((star, index) => {
                    index += 1;
                    return (
                      <button
                        type="button"
                        key={index}
                        className={index <= (hover || rating) ? "on" : "off"}
                        onClick={() => setRating(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                      >
                        <span className="star-rating-star">&#9733;</span>
                      </button>
                    );
                  })}




                </div>
                <div >
                  <div className='reviews-form' >
                    <span>Rate your Experience working with this {freelancer ? "Freelancer" : "Employer"}</span>
                    <textarea
                      className="reviews-form-input"
                      type="text"
                      cols={40}
                      rows={3}
                      placeholder="Enter your review"
                      value={reviews}
                      onChange={onChange}
                    />
                    <button className='buton' onClick={onSubmit}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
              :
              <div className='Review-container'>
                <h3>Review <span id='highlight'>Submitted !</span></h3>
                <h5 id='highlight'>{reviewd?.map((review) => {
                  if (review.From_ID === user.id) {
                    return (
                      <div>{review.Review}</div>
                    )
                  }
                })}</h5>
              </div>


            : null}
        </>}
    </>
  );
}

export default Rating