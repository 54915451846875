import React from "react";
import {Link} from 'react-router-dom';
import img1 from "../images/point-1.png";
import img2 from "../images/point-2.png";
import img3 from "../images/point-3.png";
import img4 from "../images/point-4.png";
function WhyDigilanxer() {
  return (
    <div className="HowItWorks">
      <div className="home-howitwork"><Link to='/'>Home </Link>/ <Link to='/freelancers'>Why Digilanxer</Link></div>
      <div className="Apart">
        <h1>What makes us better than the Rest ?</h1>
        <p>
          There are many things that set us apart from the rest.
          Discover the benefits of using our platform to connect with freelancers and employers from across the globe!
        </p>
      </div>

      <div className="Apart-points">
        <div className="Apart-containet">
          <span className="aprt-containet-head">
            Made by Us, for You !
          </span>
          <span className="aprt-containet-value">
            Whether you own your own business, work for an agency, small business, or enterprise company,
            we make it simple for you to lend or seek Digital marketing services online.
          </span>
        </div>
        <div className="apart-img">
          <img src={img1} alt="" />
        </div>
      </div>
      <div className="Apart-points">
        <div className="apart-img">
          <img src={img3} alt="" />
        </div>
        <div className="Apart-containet">
          <span className="aprt-containet-head">
            Best value for your work, Guaranteed!
          </span>
          <span className="aprt-containet-value">
            For the benefit of all of our members and to ensure that you make the most of this platform, we live by our commitment of offering best value at minimal costs. All the tools and features you require to complete tasks successfully are available on
            our cost-effective platform, which also has the lowest fees in the Digital marketing freelancing domain.
          </span>
        </div>
      </div>
      <div className="Apart-points">
        <div className="Apart-containet">
          <span className="aprt-containet-head">
            Be assured about the safety of your funds !
          </span>
          <span className="aprt-containet-value">
            With our specialized payment protection, Employers get comfortable knowing they can review the work before
            paying an invoice, and freelancers are confident they will be paid for their work. Also, to ensure safe transactions, we further offer numerous payment and withdrawal options.
          </span>
        </div>
        <div className="apart-img">
          <img src={img2} alt="" />
        </div>
      </div>
      <div className="Apart-points">
        <div className="apart-img">
          <img src={img4} alt="" />
        </div>
        <div className="Apart-containet">
          <span className="aprt-containet-head">Round the clock Support !</span>
          <span className="aprt-containet-value">
            While you chase your aspirations with full efforts, our customer support team is right there to help you out in case of any issues on the platform. No matter where you are,
            what services you offer, you can rely on them to address your concerns at any given moment of the day !
          </span>
        </div>
      </div>
    </div>
  );
}

export default WhyDigilanxer;
