import React, { useState, useContext, useEffect } from "react";
import remove from '../../images/remove.png';
import add from '../../images/add.png'
import moment from "moment/moment";
import { message } from 'antd';
import { MyContext } from "../../Mycontext";
import axios from 'axios';
import { useNavigate } from "react-router";

function Milestone({ forms, setForms, editable, detailjob, Bid_ID, Bid_Status, disputeStatus }) {
  const navigate = useNavigate();
  const { user, Token, endPointUrl, socket } = useContext(MyContext);
  const [Index, setIndex] = useState();
  const [errorForm, setErrorForm] = useState({ Amount: "", Description: "", M_Due_Date: "", status: "" },);
  const [showTimeSheet, setShowTimeSheet] = useState(false)
  const [showTimeSheetIndex, setShowTimeSheetIndex] = useState();
  const [timeSheetAttachment, setTimeSheetAttachment] = useState('');
  const [milestoneFile, setMileStoneFile] = useState([]);
  const [timeSpend, setTimeSpend] = useState('');
  const [descriptionWork, setDescriptionWork] = useState('');

  const getIndex = () => {
    for (let index = 0; index < forms.length; index++) {
      const item = forms[index];
      if (item.status === "Pending" || item.status === "Rejected") {
        setIndex(index);
        break;
      }
    }
  };


  const handleFormChange = (index, event) => {
    const { name, value } = event.target;
    const newForms = [...forms];
    if (name === "M_Due_Date" && index == 0 && String(new Date(value).getFullYear()).length == 4 && new Date(value).getFullYear() < new Date().getFullYear() && new Date(value) < new Date()) {
      message.warning("Due date should be greater than the Current Date");
      return;
    }
    else if (name === "M_Due_Date" && index > 0 && String(new Date(value).getFullYear()).length == 4) {
      const previousDueDate = newForms[index - 1].M_Due_Date;
      if (new Date(value) <= new Date(previousDueDate)) {
        // Display an error message or handle the validation accordingly
        message.warning("Due date must be greater than the previous one");
        return;
      }
    }

    if (name === "Amount")
      newForms[index][name] = value.slice(0, 6);
    else
      newForms[index][name] = value;

    setForms(newForms);
  };

  const handleAddForm = () => {
    // Validation logic for the last form in the array
    const lastForm = forms[forms.length - 1];
    const newFormErrors = { Amount: "", Description: "", M_Due_Date: "" };
    let showError = false;

    // Check if the last form fields are not empty
    if (lastForm.Amount === 0 || lastForm.Amount === "") {
      showError = true;
      newFormErrors.Amount = 'Please fill out this field!';
    }
    if (lastForm.Description === "") {
      showError = true;
      newFormErrors.Description = 'Please fill out this field!';
    }
    if (lastForm.Description.length <= 50) {
      showError = true;
      newFormErrors.Description = 'Description Must have 50 character long!';
    }
    if (lastForm.M_Due_Date === "") {
      showError = true;
      newFormErrors.M_Due_Date = 'Please fill out this field!';
    }

    // Update the error state
    setErrorForm(newFormErrors);

    // If there are no errors, add a new form
    if (!showError) {
      const newForms = [
        ...forms,
        { Amount: 0, Description: "", M_Due_Date: "" },
      ];
      setForms(newForms);
    }
  };

  //   console.log("ENter ", forms)
  //   const newFormErrors = { Amount: "", Description: "", M_Due_Date: "" };
  //   let showError = false;
  //   if (forms.Amount === "") {
  //     console.log("Error find")
  //     showError = true;
  //     newFormErrors.Amount = 'Please fill out this field!';
  //   } else {
  //     newFormErrors.Amount = '';
  //   }
  //   if (forms.Description === "") {
  //     showError = true;
  //     newFormErrors.Description = 'Please fill out this field!';
  //   }
  //   else {
  //     newFormErrors.Description = "";
  //   }
  //   if (forms.M_Due_Date === "") {
  //     showError = true;
  //     newFormErrors.M_Due_Date = 'Please fill out this field!';
  //   } else {
  //     newFormErrors.M_Due_Date = '';
  //   }

  //   setErrorForm(newFormErrors);
  //   if (!showError) {
  //     const newForms = [
  //       ...forms,
  //       { Amount: 0, Description: "", M_Due_Date: "" },
  //     ];
  //     setForms(newForms);
  //   }
  // };

  const handleRemoveForm = (index) => {
    const newForms = [...forms];
    newForms.splice(index, 1);
    setForms(newForms);
  };

  const handleclick = async (PM_ID) => {
    try {
      await axios.post(endPointUrl + "/project/updatemilestone", { Token, PM_ID: PM_ID, MilstoneStatus: "Up for Review" })
      message.success("Raised milestone up for Review");
      socket.emit("notificationJobAssign", {
        Type: "Raise_Review",
        FromID: user.id,
        ToID: detailjob.Employer_ID,
        Message: `${user.Name + " " + user.Last_Name} raised a review for your project ${detailjob.Title}`,
        IsRead: "false",
        ItemID: detailjob.JobID
      })
    } catch {
      message.error("An error occured while raising review")
    }
    getIndex();
    navigate("/freelancer-job-profile");
  };

  const getMilestoneforBid = async () => {
    try {
      const { data } = await axios.post(
        endPointUrl + "/project/getMilestoneforBid",
        { Bid_ID, Token }
      );

      setMileStoneFile(data.milestones)

    } catch (error) {
      console.log("Error While Get Milestone fro bid", error);
    }
  };

  useEffect(() => {
    getIndex();
    getMilestoneforBid();
  }, [forms])

  const handleAddTimeSheet = async (mid) => {
    if (detailjob?.ProjectType === "Hourly") {
      if (timeSpend.length === 0) {
        message.warning("TimeSpend is Required");
        return;
      }
    }
    if (descriptionWork.length === 0) {
      message.warning("Description is Required");
      return;
    }
    if (descriptionWork.length <= 50) {
      message.warning("Description requied minimum 50 characters")
      return;
    }
    if (!timeSheetAttachment) {
      message.warning("Add File Before Submit");
      return;
    }
    else {
      if (detailjob.ProjectType === "Hourly") {
        try {
          const formData = new FormData()
          formData.append("mid", mid)
          formData.append("Token", Token)
          formData.append("timeSpend", timeSpend);
          formData.append("descriptionWork", descriptionWork);
          formData.append("TimeSheetFile", timeSheetAttachment);

          const { data } = await axios.post(
            endPointUrl + "/project/AddTimeSheetAttachment",
            formData
          );

          if (data.status) {
            message.success("Work Uploaded Successfully")
            getMilestoneforBid()
          }
        } catch (error) {
          message.error("Work Upload Failed!")
          console.log("Error While Add Timesheet")
        }
        setShowTimeSheet(false);
      }
      else {
        try {
          const formData = new FormData()
          formData.append("mid", mid)
          formData.append("Token", Token)
          formData.append("timeSpend", '');
          formData.append("descriptionWork", descriptionWork);
          formData.append("TimeSheetFile", timeSheetAttachment);

          const { data } = await axios.post(
            endPointUrl + "/project/AddTimeSheetAttachment",
            formData
          );

          if (data.status) {
            message.success("Work Uploaded Successfully")
            getMilestoneforBid()
          }
        } catch (error) {
          message.error("Work Upload Failed!")
          console.log("Error While Add Timesheet")
        }
        setShowTimeSheet(false);
      }
    }

  };

  return (
    <>
      {editable ? (
        <div>
          <h4>{detailjob?.ProjectType === "Hourly" ? "" : "Milestone"}</h4>
          {detailjob?.ProjectType === "Hourly" && (Bid_Status == "Active") ? <></> :
            forms.map((form, index) => (
              <div key={index}>
                <div style={{ display: "flex", flexDirection: "column" }} key={index}>
                  <span style={{ marginRight: "5px" }}>{detailjob?.ProjectType === "Hourly" ? "" : `#${index + 1}`}</span>
                  <div className="bid_Form milestone_Form upper">
                    <label style={{ display: "flex" }}>
                      <span id="highlighted">Amount :</span>
                      <span>{form.Amount}</span>
                    </label>
                    <label style={{ display: "flex" }}>
                      <span id="highlighted">Payment :</span>
                      <span>{form.recieveStatus}</span>
                    </label>
                    {detailjob?.ProjectType === "Hourly" ? <></> : <label style={{ display: "flex" }}>
                      <span id="highlighted">Due Date :</span>
                      <span>{moment(form.M_Due_Date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}</span>
                    </label>}
                  </div>
                  <div className="bid_Form upper">
                    <label className="description">
                      <span id="highlighted">Description :</span>
                      <textarea readOnly
                        name="Description"
                        id="Description"
                        cols="40"
                        rows="3"
                        value={form.Description}
                        style={{ overflowX: "hidden" }}
                      ></textarea>
                    </label>
                  </div>
                  {form.paymentStatus === "Released" ? milestoneFile && milestoneFile[index]?.Reason &&
                    <div style={{ fontWeight: 'bold' }}>Reason For Rejection:
                      <div style={{ fontWeight: 'normal' }}> {milestoneFile[index]?.Reason?.split(",")?.map((item) => <div>{item}</div>)}</div>
                    </div> : ""}
                </div>

                {/* <div>
                  {form.paymentStatus === "Released" ? Index === index ?
                    milestoneFile && milestoneFile[index]?.MilstoneStatus === "Rejected" ?
                      <div style={{ fontWeight: 'bold' }}>Reason For Rejection:
                        <div style={{ fontWeight: 'normal' }}> {milestoneFile[index]?.Reason.split(",").map((item) => <div>{item}</div>)}</div>
                      </div>
                      : "" : "" : ""}
                </div> */}

                {form.paymentStatus === "Released" ? Index === index ?
                  <div>
                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'right' }}>
                      {milestoneFile && milestoneFile[index]?.PM_ID === form.mid && milestoneFile[index]?.timeSheetAttachment ?
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <a href={`${endPointUrl}/images/${milestoneFile[index]?.timeSheetAttachment}`} target="_blank">View TimeSheet</a>
                        </div>
                        : milestoneFile && milestoneFile[index]?.MilstoneStatus === "Rejected" ?
                          <div style={{ display: 'flex', alignItems: 'end', fontWeight: 'bolder' }}>{detailjob?.ProjectType === "Hourly" ? "Project Reviewd Rejected By Client" : "MileStone Rejected By Client"}</div>
                          :
                          <></>
                      }

                      {milestoneFile && milestoneFile[index]?.timeSheetAttachment ?
                        disputeStatus === "Pending" || disputeStatus === "InProgress" ? <></> : <>
                          <button className="mil_buton"
                            style={{ marginRight: '5%' }}
                            onClick={() => handleclick(form.mid)}
                          >Raise for Review</button>
                        </> :
                        disputeStatus === "Pending" || disputeStatus === "InProgress" ? <></> : <button className="mil_buton"
                          style={{ marginRight: '5%' }}
                          onClick={() => {
                            setShowTimeSheet(true);
                            setShowTimeSheetIndex(index);
                          }}
                        >
                          Upload Work
                        </button>
                      }

                      {/* <button className="mil_buton"
                      style={{ marginRight: '5%' }}
                      onClick={() => handleclick(form.mid)}
                    >Raise for Review</button> */}
                    </div>

                    {showTimeSheet && index === showTimeSheetIndex ?
                      <div style={{ marginTop: '2%', boxShadow: '0px 0px 10px', marginRight: '5%', padding: '20px', boxSizing: 'border-box', outline: '1px solid grey' }}>
                        <div>
                          {detailjob?.ProjectType === "Hourly" ? <div className="userdeatail_container">
                            <div>
                              <label>Hours Invested:</label>
                              <input type="text" value={timeSpend} onChange={(e) => {
                                if (e.target.value.length < 4) {
                                  if (/^\d+$/.test(e.target.value) && parseInt(e.target.value) >= 0) {
                                    setTimeSpend(e.target.value)
                                  }
                                }
                              }
                              } />
                            </div>
                          </div> : <></>}

                          <div className="userdeatail_container" style={{ gridTemplateColumns: '100%' }}>
                            <label>Description:</label>
                            <input type="text" value={descriptionWork} onChange={(e) => {
                              if (e.target.value.length < 200) {
                                setDescriptionWork(e.target.value)
                              }
                            }} />
                          </div>

                          <div className="userdeatail_container" style={{ gridTemplateColumns: '100%' }}>
                            <div>
                              <label>Upload TimeSheet: </label>
                              <input type="file" onChange={(e) => setTimeSheetAttachment(e.target.files[0])} />
                            </div>
                          </div>

                          <div className="userdeatail_container" style={{ gridTemplateColumns: '100%' }}>
                            <div>
                              <button className="mil_buton" onClick={() => { handleAddTimeSheet(form.mid) }}>Upload Work</button>
                              <button className="mil_buton disabled" onClick={() => setShowTimeSheet(false)} style={{ marginLeft: '5px', cursor: 'pointer' }}>Close</button>
                            </div>
                          </div>

                        </div>
                      </div>
                      : <></>}
                  </div>
                  : form.status === "Up for Review" ?
                    <button className='mil_buton disabled'
                      style={{ marginBlockEnd: "10px", marginInlineStart: "71%", borderRadius: "0" }}
                    >Awaiting Review</button>
                    : form.status === "Approved" && forms.length ?
                      <div>
                        <div style={{ display: 'flex', gap: '10px', justifyContent: 'right' }}>
                          {milestoneFile && milestoneFile[index]?.PM_ID === form.mid && milestoneFile[index]?.timeSheetAttachment ?
                            milestoneFile && milestoneFile[index]?.MilstoneStatus === "Rejected" ? <>Reject Milestone</> :
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <a href={`${endPointUrl}/images/${milestoneFile[index].timeSheetAttachment}`} target="_blank">View TimeSheet</a>
                              </div> : <></>
                          }

                          <button className='mil_buton disabled'
                            style={{ marginRight: '5%' }}
                          >Reviewed
                          </button>
                        </div>

                        {showTimeSheet && index === showTimeSheetIndex ?
                          <div style={{ marginTop: '2%', boxShadow: '0px 0px 10px', marginRight: '5%', padding: '20px', boxSizing: 'border-box', outline: '1px solid grey' }}>
                            <div>
                              <div className="userdeatail_container">
                                <div>
                                  <label>Hours Invested:</label>
                                  <input type="text" value={timeSpend} onChange={(e) => {
                                    if (e.target.value.length < 4) {
                                      if (/^\d+$/.test(e.target.value) && parseInt(e.target.value) >= 0) {
                                        setTimeSpend(e.target.value)
                                      }
                                    }
                                  }
                                  } />
                                </div>

                                <div>
                                  <label>Description:</label>
                                  <input type="text" value={descriptionWork} onChange={(e) => {
                                    if (e.target.value.length < 200) {
                                      setDescriptionWork(e.target.value)
                                    }
                                  }} />
                                </div>
                              </div>

                              <div className="userdeatail_container" style={{ gridTemplateColumns: '100%' }}>
                                <div>
                                  <label>Upload TimeSheet: </label>
                                  <input type="file" onChange={(e) => setTimeSheetAttachment(e.target.files[0])} />
                                </div>
                              </div>

                              <div className="userdeatail_container" style={{ gridTemplateColumns: '100%' }}>
                                <div>
                                  <button className="mil_buton" onClick={() => { handleAddTimeSheet(form.mid) }}>Upload Work</button>
                                  <button className="mil_buton disabled" onClick={() => setShowTimeSheet(false)} style={{ marginLeft: '5px', cursor: 'pointer' }}>Close</button>
                                </div>
                              </div>

                            </div>
                          </div>
                          : <></>}
                      </div>
                      : null : null}
                <hr />
              </div>
            ))
          }
        </div>
      ) : (<div>
        <h4>{detailjob.ProjectType === "Hourly" ? "" : "Milestones"}</h4>
        {detailjob.ProjectType === "Hourly" ? <></> :
          forms.map((form, index) => (
            <div key={index}>
              #{index + 1}
              <div className="bid_Form milestone_Form">
                <label style={{ position: "relative" }}>
                  Amount<span className="asterisk">*</span>
                  <input
                    type="text"
                    name="Amount"
                    step={0.01}
                    value={form.Amount}
                    onChange={(event) => {
                      const { value } = event.target;
                      if (value >= 0 || value === '') {
                        handleFormChange(index, event);
                      }
                    }}
                    disabled={editable}
                  />
                  <small style={{ color: "#ec1c24" }}>{errorForm.Amount}</small>
                </label>

                <label style={{ position: "relative" }}>
                  Due_Date<span className="asterisk">*</span>
                  <input
                    type="date"
                    name="M_Due_Date"
                    value={moment(form.M_Due_Date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")}
                    onChange={(event) => handleFormChange(index, event)}
                    disabled={editable}
                    min={moment().format("YYYY-MM-DD")}
                  />
                  <small style={{ color: "#ec1c24" }}>{errorForm.M_Due_Date}</small>
                </label>

              </div>
              <div className="bid_Form">
                <label className="description" style={{ position: "relative" }}>
                  Description<span className="asterisk">*</span>
                  <textarea
                    name="Description"
                    id="Description"
                    cols="40"
                    rows="5"
                    value={form.Description}
                    onChange={(event) => handleFormChange(index, event)}
                    disabled={editable}
                  ></textarea>
                  <small style={{ color: "#ec1c24" }}>{errorForm.Description}</small>
                </label>

              </div>
              <hr />
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {(index > 0 && !editable) && (
                  <img src={remove} onClick={() => handleRemoveForm(index)} className="remove" />
                )}
                {(index === forms.length - 1 && !editable) && (
                  <img src={add} onClick={handleAddForm} className="add" />
                )}
              </div>
            </div>
          ))
        }
      </div>
      )}
    </>
  );
}

export default Milestone;