import Accordion from 'react-bootstrap/Accordion';
// import 'bootstrap/dist/css/bootstrap.min.css';
function Accordions() {
    return (
        <Accordion defaultActiveKey="0" flush>
            <Accordion.Item eventKey="0">
                <Accordion.Header> Why should I upgrade my payment module in Digilanxer?</Accordion.Header>
                <Accordion.Body>
                    Upgrading your payment module in Digilanxer provides access to advanced features,
                    enhanced security, and improved functionality, ensuring a seamless payment experience for you
                    and your clients.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Can I change my plan for the payment module in Digilanxer?</Accordion.Header>
                <Accordion.Body>
                    Yes, you can change your plan for the payment module in Digilanxer to better suit your business needs.
                    Simply navigate to the settings and select the desired plan that aligns with your requirements.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header> What is the Preferred Freelancer Program in Digilanxer's payment module?</Accordion.Header>
                <Accordion.Body>
                    The Preferred Freelancer Program in Digilanxer's payment module offers exclusive benefits to top-performing
                    freelancers, including priority support, increased visibility, and access to premium projects.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>Can I cancel my membership for the payment module in Digilanxer?</Accordion.Header>
                <Accordion.Body>
                    Yes, you can cancel your membership for the payment module in Digilanxer at any time. Simply follow the
                    cancellation process outlined in your account settings or contact our support team for assistance.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>How do I update my payment information in Digilanxer's payment module?</Accordion.Header>
                <Accordion.Body>
                    You can easily update your payment information in Digilanxer's payment module by
                    accessing your account settings and selecting the option to edit or update your payment details.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>What security measures are in place to protect my payment information in Digilanxer's payment module?</Accordion.Header>
                <Accordion.Body>
                    Digilanxer's payment module employs industry-standard security protocols and encryption techniques to
                    safeguard your payment information, ensuring a secure and reliable transaction process.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>How can I contact customer support for assistance with the payment module in Digilanxer?</Accordion.Header>
                <Accordion.Body>
                    If you require assistance with the payment module in Digilanxer, our professional customer support team is available to address any inquiries or concerns. Simply reach out to us via the
                    designated support channels, and we will be happy to assist you promptly and professionally.
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default Accordions;