import React, { useContext, useEffect, useState, useRef } from "react";
import profile from "../../images/freelancer.png";
import dolar from "../../images/dolar.png";
import { MyContext } from "../../Mycontext";
import moment from "moment/moment";
import dashboardImg from "../../images/img.png";
import axios from "axios";
import Bidding from "../Jobs/Bidding";
import Messages from "../Messages";
import { Collapse } from "antd";
const { Panel } = Collapse;
import { scroller } from "react-scroll";
import ProgressBar from "../ProgressBar";
import Rating from "../Rating";
import { useNavigate, Link, useLocation } from "react-router-dom";
import TotalEarnings from "./freelancer-payment/TotalEarnings";
import { motion } from "framer-motion";
import { Popup } from "semantic-ui-react";
import { BsArrowRight } from "react-icons/bs";
import { BiShowAlt, BiHide } from 'react-icons/bi';
import { IoArrowBackSharp } from 'react-icons/io5';
import { message } from "antd";
import { MdVerified } from 'react-icons/md';
import { IoAddOutline } from 'react-icons/io5';
import { AiFillStar } from 'react-icons/ai';
import img from '../../images/whiiteveryfied.png';
import placeholder from '../../images/layer1.svg';

function FreelancerJobprofile(props) {
  const location = useLocation();
  const { user, endPointUrl, Token, currentPage } = useContext(MyContext);
  const [counter, setCounter] = useState(0);
  const [bgColor, setbgColor] = useState('');
  const [balance, setBalance] = useState(0);
  const [education, setEducation] = useState([]);
  const [exp, setExp] = useState([]);
  const Navigate = useNavigate();
  const [detailview, setdetailview] = useState(false);
  const [detailjob, setdetailjob] = useState({});
  const [bidStatus, setbidStatus] = useState({
    openBid_status: "",
    closedBid_status: ""
  });
  const [ClosedBids, setClosedBids] = useState([]);

  const [OpenBids, setOpenBids] = useState([]);
  const [ongoing, setOngoing] = useState({
    InProgress: [],
    Completed: [],
    Closed: [],
  });
  const [current, setcurrent] = useState([]);
  const [bidData, setBidDate] = useState([]);
  const [Oncurrent, setOncurrent] = useState([]);
  const [analytics, setAnalytics] = useState([]);
  const [amount, setAmount] = useState(null);
  const [activejob, setActivejob] = useState(0);
  const [activejobIn, setActivejobIn] = useState(location?.state?.fromPage === "Invitation" ? 3 : 0);
  const [Onactivejob, setOnActivejob] = useState(0);
  const [MessageActive, setMessageActive] = useState(0);
  const [show, setshow] = useState(false);
  const [PortData, setPortData] = useState([]);
  const [activeBids, setActiveBids] = useState();
  const [archiveBids, setArchiveBids] = useState([]);
  const [showjobs, setShowjobs] = useState({ My_Bids: true });
  const [Applied, setapplied] = useState(true);
  const [messageJobs, setMessageJobs] = useState([]);
  const [invitation, setInvitation] = useState([]);
  const [rejectInputShow, setRejectInputShow] = useState(false);
  const [rejectMsg, setRejectMsg] = useState('');
  const [rejectionError, setRejectionError] = useState('');
  const [rejectIndex, setRejectIndex] = useState();
  const [profileProgress, setProfileProgress] = useState('');
  const [certi, setCerti] = useState('');
  const [port, setPort] = useState('');


  const getEducation = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserEducation", { UserID: user.id, Token })
    setEducation(data.data);
  };


  const getCertificate = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
      Token,
      UserID: user.id
    });

    setCerti(data.data)
  }


  //Set Date
  const handleDate = (item) => {
    let createdAt = new Date(item.Bid_date);
    let day = createdAt.getUTCDate().toString().padStart(2, '0'); // Get day (padded with leading zero if needed)
    let month = (createdAt.getUTCMonth() + 1).toString().padStart(2, '0'); // Get month (January is 0)
    let year = createdAt.getUTCFullYear().toString().slice(-2); // Get last two digits of the year
    let DATE = `${day}/${month}/${year}`;

    //Set Time
    let hours = createdAt.getUTCHours();
    let minutes = createdAt.getUTCMinutes();
    let seconds = createdAt.getUTCSeconds();
    let TIME = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

    //Set TimeZone
    let TimeZone = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).formatToParts(createdAt).find(part => part.type === 'timeZoneName').value
    return <span>{DATE}, {TIME}, {TimeZone}</span>;
  }

  const getExp = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: user.id,
      Token,
    });
    setExp(data.data);
  };

  const getInvitation = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/getInvitations", {
        userID: user.id,
        Token
      })
      setInvitation(data.data);
    }
    catch (e) {
      console.log("error", e);
    }
  }

  const getUserProgress = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/getUpdatedProgess", {
        id: user?.id, Token
      })

      setProfileProgress(data.data)
    } catch (error) {
      console.log("Error While get user Progress", error)
    }
  }

  const getPortfolio = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getportfolioByUserID", {
      Token,
      UserID: user.id
    });
    setPort(data.data);
  }

  useEffect(() => {
    getExp();
    getPortfolio()
    getCertificate();
    getInvitation();
    getEducation();
    getUserProgress();
  }, []);

  useEffect(() => {
    if (location?.state?.fromPage === "Invitation") {
      setActivejobIn(3)
    }
  }, [location.state])

  async function getBidByJobID(JobID) {
    const { data } = await axios.post(endPointUrl + "/job/bidding", {
      JobID,
      Token,
    })
    return data.Bid_date
  }

  async function getEmployerByJobID(JobID) {
    const { data } = await axios.post(endPointUrl + "/user/getemployerByjobID", {
      JobID,
      Token,
    })
    console.log('employer data', data.data)
    return data.data
  }


  const getOngoingjobs = async () => {
    const { data } = await axios.post(endPointUrl + "/getProjectsforfreelancer", {
      Token,
      Awarded_To_ID: user.id,
    });

    // let messageJob = data.data.map(async (item) => {
    //   const Bid_date = await getBidByJobID(item.JobID);
    //   console.log('bid date',Bid_date);
    //   return { ...item.jobs, Bid_date: data.Bid_date }
    // });

    // let messageJob = await Promise.all(data.data.map(async (item) => {
    //   const Bid_date = await getBidByJobID(item.JobID);
    //   const Employer = await getEmployerByJobID(item.JobID);
    //   return { ...item.jobs, Bid_date: Bid_date, ClientDetail: Employer };
    // }));

    let progress = data.data.filter((item) => {
      return item.Project_status === "in_Progress" || item.Project_status === "Semi_Completed"
    });

    let Amount = progress.map((item) => {
      return item.amount;
    })
    let InProgress = progress.map((item) => {
      return item.jobs;
    })

    let closed = data.data.filter((item) => {
      return item.Project_status === "Approved"
    });
    let Closed = closed.map((item) => {
      return item.jobs;
    })

    let completed = data.data.filter((item) => {
      return item.Project_status == "Completed"
    });
    let Completed = completed.map((item) => {
      return item.jobs;
    })

    setAmount(Amount);
    setOngoing({ InProgress: InProgress, Completed: Completed, Closed: Closed });
    setOncurrent(Onactivejob ? Closed : InProgress);
    // setMessageJobs(messageJob)
  };


  const getChatUsers = async (res) => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/chatusers", {
        Token,
        UserID: user.id,
        Type: user?.Type,
      })

      const jobForMsg = [];

      res.map((item) => {
        console.log('item. jobid : ', item.JobID);
        data.data.map((val) => {
          console.log('val.lastChat.JobID', val.lastChat.JobID)
          if (val.lastChat.JobID == item.JobID) {
            jobForMsg.push({ ClientDetail: val.user, ...item });
          }
        })
      });

      setMessageJobs([...messageJobs, ...jobForMsg]);

      console.log('chatdata message : ', jobForMsg);
    } catch (error) {
      console.log("error while get chat users")
    }
  }

  const getjobs = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getAllJobs", {
        Token,
      });

      getChatUsers(data.data);

      let Published = data.data.filter((item) => {
        return item.IsActive === true && item.Status === "Published";
      });
      getAllAppliedJobs(Published);
      getOpenBids();
      getClosedBids();
      getActiveBids()
      getArchiveBids()
      getBidsWithChat()
    } catch {
      getjobs([]);
    }
  };


  const getBidsWithChat = async () => {
    try {
      const { data } = await axios.post(endPointUrl + '/user/getactivemessagesjob', {
        userId: user.id,
        Token,

      })
      let open_bit = data.data.filter((item) => {
        return item;
      });

      // Sort by Bid_date in descending order
      open_bit.sort((a, b) => new Date(b.Bid_date) - new Date(a.Bid_date));

      let Open_bit = open_bit.map((item) => {
        return { ...item.freelancerBidjobs, Bid_Status: item.Bid_Status, Bid_date: item.Bid_date };
      });

      let Bid_Date = open_bit.map((item) => {
        return item.Bid_date;
      });

      let Bid_Status = open_bit.map((item) => item.Bid_Status);
      // setActiveBids(Open_bit);

      setbidStatus({ openBid_status: Bid_Status });
    } catch (error) {
    }
  }

  console.log("active bids", activeBids)

  const handlejobClick = (buttonIndex) => {
    setActivejob(buttonIndex);
  };
  const handlejobClickIn = (buttonIndex) => {
    setActivejobIn(buttonIndex);
  };
  const handleOnjobClick = (buttonIndex) => {
    setOnActivejob(buttonIndex);
  };
  const handleMessageClick = (buttonIndex) => {
    setMessageActive(buttonIndex);
  };


  const scrollToElement = () => {
    scroller.scrollTo("myElement", {
      duration: 300,
      delay: 0,
    });
  };
  const checkeditTable = async (JobID) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
        Token,
        UserID: user.id,
        JobID: JobID,
      });
      if (data.status === true && data.data) {
        setapplied(true);
        setshow(true);
      } else {
        setapplied(false);
        setshow(false);
      }
    } catch {
      setapplied(false);
      setshow(false);
    }
  };
  const BidBalance = async () => {
    const { data } = await axios.post(endPointUrl + "/getuserByBidBalance", {
      Token,
      id: user.id,
    })
    setBalance(data.data.Bid_Balance);
  };
  const getAllAppliedJobs = async (alljob) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/allbidByuser", {
        Token,
        UserID: user.id,
      });

      let arrApplied = [];

      data.data.map((item) => arrApplied.push(item.JobID));

      const appliedbit = alljob.filter((item) =>
        arrApplied.includes(item.JobID)
      );
      const Notappliedbit = alljob.filter(
        (item) => !arrApplied.includes(item.JobID)
      );
      // console.log(appliedbit);
      setSavedJobs(appliedbit);
    } catch {
      console.log("error on getting jobs");
    }
  };

  const getOpenBids = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getPendingbidsJobsofUser", {
      Token,
      UserID: user.id,
    });

    let open_bit = data.data.filter((item) => {
      return item;
    });

    // Sort by Bid_date in descending order
    open_bit.sort((a, b) => new Date(b.Bid_date) - new Date(a.Bid_date));

    let Open_bit = open_bit.map((item) => {
      return { ...item.freelancerBidjobs, Bid_Status: item.Bid_Status, Bid_date: item.Bid_date };
    });

    let Bid_Date = open_bit.map((item) => {
      return item.Bid_date;
    });

    let Bid_Status = open_bit.map((item) => item.Bid_Status);
    setOpenBids(Open_bit);

    setbidStatus({ openBid_status: Bid_Status });
  };

  const getActiveBids = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getActivebidsJobsofUser", {
      Token,
      UserID: user.id,
    });

    let open_bit = data.data.filter((item) => {
      return item;
    });

    // Sort by Bid_date in descending order
    open_bit.sort((a, b) => new Date(b.Bid_date) - new Date(a.Bid_date));

    let Open_bit = open_bit.map((item) => {
      return { ...item.freelancerBidjobs, Bid_Status: item.Bid_Status, Bid_date: item.Bid_date };
    });

    let Bid_Date = open_bit.map((item) => {
      return item.Bid_date;
    });

    let Bid_Status = open_bit.map((item) => item.Bid_Status);
    setActiveBids(Open_bit);

    setbidStatus({ openBid_status: Bid_Status });
  }


  useEffect(() => {
    setcurrent(OpenBids);
  }, [OpenBids])

  const getClosedBids = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getRejectedbidsJobsofUser", {
      Token,
      UserID: user.id
    });
    let closed_bit = data.data.filter((item) => {
      return { ...item.freelancerBidjobs, Bid_date: item.Bid_date, Bid_Status: item.Bid_Status }
    });

    let Closed_bit = closed_bit.map((item) => {
      return { ...item.freelancerBidjobs, Bid_date: item.Bid_date, Bid_Status: item.Bid_Status }
    });



    let Bid_Status = closed_bit.map((item) => item.Bid_Status)
    setClosedBids(Closed_bit);
    setbidStatus({ ...bidStatus, closedBid_status: Bid_Status })
  };


  const getArchiveBids = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getArchivedbidsJobsofUser", {
      Token,
      UserID: user.id
    });
    let closed_bit = data.data.filter((item) => {
      return { ...item.freelancerBidjobs, Bid_date: item.Bid_date, Bid_Status: item.Bid_Status }
    });

    let Closed_bit = closed_bit.map((item) => {
      return { ...item.freelancerBidjobs, Bid_date: item.Bid_date, Bid_Status: item.Bid_Status }
    });



    let Bid_Status = closed_bit.map((item) => item.Bid_Status)
    setArchiveBids(Closed_bit);
    setbidStatus({ ...bidStatus, closedBid_status: Bid_Status })
  };
  const Analytics = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/analyticsforfreelancer", {
        id: user.id,
        Token
      });
      setAnalytics(data.data);
    } catch {
      message.error("Error while fetching analytics")
    }
  };
  const labelStyles = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    color: '#5E5E5E',
    fontSize: '12px',
    margin: '3px'
  }

  // const progress = () => {
  //   (setCounter((counter) => counter = 0));
  //   (user?.Photo) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.About) ? (setCounter((counter) => counter + 10)) : (null),
  //     // (user?.Title) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.Verified_Profile == "Verified") ? (setCounter((counter) => counter + 20)) : (null),

  //     (user?.rate_from) ? (setCounter((counter) => counter + 4)) : (null),
  //     (user?.rate_type) ? (setCounter((counter) => counter + 4)) : (null),

  //     (exp?.length) ? (setCounter((counter) => counter + 5)) : (null),
  //     (education?.length) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.Skills) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.portfolio_status) ? (setCounter((counter) => counter + 4)) : (null),
  //     (user?.certificate_status) ? (setCounter((counter) => counter + 5)) : (null),

  //     (user?.Name) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.Address) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.Gender) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.Mobile) ? (setCounter((counter) => counter + 5)) : (null),
  //     (user?.Language) ? (setCounter((counter) => counter + 5)) : (null),

  //     (user?.Email) ? (setCounter((counter) => counter + 2)) : (null),
  //     (user?.City) ? (setCounter((counter) => counter + 2)) : (null),
  //     (user?.State) ? (setCounter((counter) => counter + 2)) : (null),
  //     (user?.Country) ? (setCounter((counter) => counter + 2)) : (null)
  // }

  // const progress = () => {
  //   let counter = 0;
  //   if (user?.Name) counter += 2;
  //   console.log("counter 1", counter)
  //   if (user?.Email) counter += 2;
  //   console.log("counter 2", counter)
  //   if (user?.Last_Name) counter += 2;
  //   console.log("counter 3", counter)
  //   if (user?.Language) counter += 2;
  //   console.log("counter 4", counter)
  //   if (user?.ScreenName) counter += 2;

  //   if (user?.About) counter += 5;
  //   console.log("counter 5", counter)
  //   if (user?.HourlyRate) counter += 2;
  //   console.log("counter 6", counter)
  //   if (user?.Address) counter += 2;
  //   console.log("counter 7", counter)
  //   if (user?.Gender) counter += 2;
  //   console.log("counter 8", counter)
  //   if (user?.Mobile) counter += 2;
  //   console.log("counter 9", counter)
  //   if (user?.Country) counter += 2;

  //   if (
  //     user?.Bank_Name &&
  //     user?.Account_Name &&
  //     user?.Account_Number &&
  //     user?.IFSC_CODE &&
  //     user?.SWIFT_CODE &&
  //     user?.BRANCH_DETAILS &&
  //     user?.BANK_COUNTRY
  //   ) {
  //     counter += 10;
  //   }
  //   console.log("counter 10", counter)
  //   if (user?.State) counter += 2;
  //   console.log("counter 11", counter)
  //   if (user?.City) counter += 2;
  //   console.log("counter 12", counter)
  //   if (user?.Skills) counter += 6;
  //   console.log("counter 13", counter)
  //   if (user?.Photo) counter += 5;
  //   console.log("counter 14", counter)
  //   if (education?.length) counter += 10;
  //   console.log("counter 15", counter)
  //   if (exp?.length) counter += 10;
  //   console.log("counter 16", counter)
  //   console.log(PortData?.length)
  //   if (PortData?.length > 0) counter += 5;
  //   console.log("counter 17", counter)
  //   if (port?.length > 0) counter += 10;
  //   console.log("counter 18", counter)
  //   if (user?.Verified_Profile === "Verified") counter += 10;
  //   counter;
  //   setCounter(counter)
  // };

  const gradient = () => {
    (counter < 25) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg,rgba(7,178,116,1), #00ffff)')) : (
      (counter < 70) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1), #00ffff, #c4fdff)'))
        : (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1) 25%, #00ffff 63%, rgba(51,154,250,1) 90%)'))
    )
  }

  function removeHtmlTags(input) {
    // Use a regular expression to match and remove HTML tags
    return input.replace(/<[^>]*>/g, '');
  }
  useEffect(() => {
    gradient();
  }, [counter])

  const progress = () => {
    let counter = 0;
    if (user?.Name) counter += 2;
    if (user?.Email) counter += 2;
    if (user?.Last_Name) counter += 2;
    if (user?.Language) counter += 2;
    if (user?.ScreenName) counter += 2;

    if (user?.About) counter += 5;
    if (user?.HourlyRate) counter += 2;
    if (user?.Address) counter += 2;
    if (user?.Gender) counter += 2;
    if (user?.Mobile) counter += 2;
    if (user?.Country) counter += 2;

    if (
      user?.Bank_Name &&
      user?.Account_Name &&
      user?.Account_Number &&
      user?.BANK_COUNTRY
    ) {
      counter += 10;
    }
    if (user?.State) counter += 2;
    if (user?.City) counter += 2;
    if (user?.Skills) counter += 4;
    if (user?.Age != "0") counter += 2;
    if (user?.Photo) counter += 5;
    if (education?.length) counter += 10;
    if (exp?.length) counter += 10;
    if (certi?.length) counter += 10;
    if (port?.length) counter += 10;
    if (user?.Verified_Profile === "Verified") counter += 10;
    // counter;
    setCounter(counter)
  };


  useEffect(() => {
    progress();
  }, [education, certi, port, exp])


  useEffect(() => {
    (setCounter((counter) => counter = 0));
    BidBalance();
    getjobs();
    Analytics();
    getOngoingjobs();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [detailview]);

  function getLanguage() {
    let languages;
    try {
      languages = JSON.parse(user.Language);
    } catch (error) {
      return (<span>{user?.Language}</span>);
    }
    return (
      <span>
        {languages?.Language1}
        {languages?.Language2 ? `, ${languages.Language2}` : ''}
        {languages?.Language3 ? `, ${languages.Language3}` : ''}
        {languages?.Language4 ? `, ${languages.Language4}` : ''}
        {languages?.Language5 ? `, ${languages.Language5}` : ''}
      </span>
    );
  }

  const handleRejectInvitaiton = async (item) => {
    try {
      if (!rejectMsg) {
        setRejectionError("Enter Reason For Reject Invitation")
      }
      else if (rejectMsg.length > 500) {
        setRejectionError("Reason Maxium Length is 500 Characters")
      }
      else {
        const { data } = await axios.post(endPointUrl + "/rejectInvitation", { InviteID: item.InviteID, Invite_By: item.Invite_By, Invite_To: item.Invite_To, JobID: item.JobID, JobTitle: item.Title, Reason: rejectMsg, Token })
        if (data.status) {
          message.success("Invitation Rejected")
          setRejectInputShow(!rejectInputShow);
          setRejectIndex();
          handlejobClickIn(3);
          setcurrent([]);
          getInvitation();
        }
      }
    } catch (error) {
      message.warning("Something Went Wrong")
      console.log("error in rejection of invitation", error)
    }

  }

  return (<>
    <div className="jobProfileContainer">
      <div className="compo">
        {!detailview ? (
          <div className="welcomeConatiner">
            <div>
              <span className="welcome-date">
                {moment().format("dddd") + ", " + moment().format("MMM Do")}
              </span>
              <span className="welcomeName">
                {new Date().getHours() < 12 ? " Good Morning, " : new Date().getHours() >= 12 && new Date().getHours() < 16 ? "Good Afternoon, " : "Good Evening, "}
                <span style={{ color: "#07B274" }}>
                  {user.ScreenName}
                </span>
              </span>
            </div>
            <div>
              <img src={dashboardImg} alt="" />
            </div>
          </div>
        ) : null}
        {detailview ? (
          <>
            <Collapse defaultActiveKey={["1"]}>
              <Panel
                header={
                  <div >
                    <IoArrowBackSharp style={{
                      backgroundColor: "white",
                      borderRadius: "50px",
                      stroke: "#073b",
                      padding: "2px",
                      margin: " -8px 10px -8px 0"
                    }}
                      onClick={() => {
                        setdetailview(!detailview);
                      }}
                    />
                    <span>
                      {detailjob?.Title}
                    </span>
                  </div>
                }
                key="1" showArrow={false} style={{ fontFamily: "poppins" }}>

                <div
                  style={{ color: "#606563" }}
                  dangerouslySetInnerHTML={{ __html: detailjob?.Description }}
                ></div>
              </Panel>
            </Collapse>
            <div id="myElement">
              <Bidding
                Balance={balance}
                detailjob={detailjob}
                show={show}
                Applied={Applied}
              />
            </div>
          </>
        ) : (
          <>

            <div className="jobContainer">
              <div className="job-container-header">
                <span>Dashboard</span>
              </div>
              <div className="jobMatch">
                <span
                  style={
                    activejob === 0
                      ? {

                        color: "#07b273",
                        fontWeight: "800",

                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(0);
                    setShowjobs({ My_Bids: true })
                  }}
                >
                  My Bids
                  {
                    activejob === 0
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
                <span
                  style={
                    activejob === 1
                      ? {

                        color: "#07b273",
                        fontWeight: "800",

                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(1);
                    setShowjobs({ My_Projects: true })

                  }}
                >
                  {" "}
                  My Projects
                  {
                    activejob === 1
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
                <span
                  style={
                    activejob === 2
                      ? {

                        color: "#07b273",
                        fontWeight: "800"
                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(2);
                    setShowjobs({ My_Messages: true })
                  }}
                >
                  My Messages{" "}
                  {
                    activejob === 2
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
                <span
                  style={
                    activejob === 3
                      ? {
                        color: "#07b273",
                        fontWeight: "800"
                      }
                      : null
                  }
                  onClick={() => {
                    handlejobClick(3);
                    setShowjobs({ My_Earnings: true })

                  }}
                >
                  {" "}
                  My Earnings
                  {
                    activejob === 3
                      ? (
                        <motion.div className="underline" layoutId="underline" />
                      )
                      : null
                  }
                </span>
              </div>
              <hr style={{ margin: "-1px -20px" }} />
              {showjobs.My_Bids ?
                <div style={{ display: "grid" }}>
                  <div className="jobMatch-inside">
                    <span
                      style={
                        activejobIn === 0
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(0);
                        setcurrent(OpenBids);
                      }}
                    >
                      Open Bids
                    </span> <p> |</p> <span
                      style={
                        activejobIn === 4
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(4);
                        setcurrent(activeBids);
                      }}
                    >
                      Active Bids
                    </span>
                    <p>|</p>
                    <span
                      style={
                        activejobIn === 1
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(1);
                        const now = moment(); // Get the current time
                        const filteredData = ClosedBids.filter((item) => {
                          const jobCreatedAt = moment(item.createdAt);
                          const diffInMonths = now.diff(jobCreatedAt, 'months');
                          return diffInMonths <= 2; // Filter items within the last 2 months
                        });
                        setcurrent(filteredData);
                      }}
                    >
                      Closed Bids{" "}
                    </span>
                    <p>|</p>
                    <span
                      style={
                        activejobIn === 2
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(2);
                        /*       const now = moment(); 
                              const filteredData = ClosedBids.filter((item) => {
                                const jobCreatedAt = moment(item.createdAt);
                                const diffInMonths = now.diff(jobCreatedAt, 'months');
                                return diffInMonths > 2;
                              });
                              setcurrent(filteredData); */
                        setcurrent(archiveBids)
                      }}
                    >
                      Archived Bids{" "}
                    </span>
                    <p>|</p>
                    <span
                      style={
                        activejobIn === 3
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handlejobClickIn(3);
                        setcurrent([]);
                      }}
                    >
                      Invitation{" "}
                    </span>

                  </div>
                  <div style={{ maxHeight: "1000px", overflowY: "scroll" }}>
                    {current?.length > 0 && activejobIn != 3 ? (
                      current?.map((item, index) => {
                        return (
                          <div key={index}>
                            <div className="current-jobs">
                              <div>
                                <span
                                  className="job-name jobName_Dasbord"
                                  onClick={() => {
                                    window.scrollTo(0, 0);
                                    Navigate(`/detail-view-job/${item?.JobID}`)
                                  }}
                                >
                                  {item?.Title}
                                </span>
                                <p dangerouslySetInnerHTML={{ __html: item?.Description?.slice(0, 300) + '...' }} className="jobName_Dasbord_Paragrap"></p>
                                <div className="bid-status">
                                  <span style={{ background: "none", color: "gray" }} >
                                    {handleDate(item)}
                                  </span>
                                  <span style={{ background: "none", color: "gray" }}>
                                    connects : 1
                                  </span>
                                  <span style={{ background: "none", color: "gray", fontSize: "20px" }}>
                                    {item?.IsActive ? <BiShowAlt /> : <BiHide />}
                                  </span>
                                  <span style={{ background: "none", color: "gray" }}>
                                    Status: {item?.Bid_Status}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <hr />
                          </div>
                        )
                      }
                      )
                    ) :
                      invitation.length > 0 && activejobIn === 3 ?
                        (
                          invitation.map((item, index) => {
                            return (
                              <div key={index}>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ width: '80%' }}>
                                    <div className="current-jobs">
                                      <div
                                        style={{ display: 'block' }}
                                        className="job-name jobName_Dasbord"
                                        onClick={() => {
                                          window.scrollTo(0, 0);
                                          Navigate(`/job-detail-view`, { state: { ...item, page: "Invitation" }, currentPage: currentPage })
                                        }}
                                      >
                                        {item?.Title}
                                      </div>
                                    </div>

                                    <div>
                                      <p dangerouslySetInnerHTML={{ __html: item?.Description?.slice(0, 300) + '...' }} className="jobName_Dasbord_Paragrap"></p>
                                    </div>
                                  </div>

                                  {item.inviteStatus === "Rejected" ?
                                    <div style={{ width: '20%' }}>
                                      <div>
                                        <button style={{ background: "#d70404", border: 'none', color: 'white', padding: '10px', borderRadius: '10px', width: '80%', fontWeight: 'bold', marginTop: '20%' }}>
                                          Rejected
                                        </button>
                                      </div>
                                    </div>
                                    : <div style={{ width: '20%' }}>
                                      <div>
                                        <button style={{ background: "green", border: 'none', color: 'white', padding: '10px', borderRadius: '10px', width: '80%', fontWeight: 'bold', marginTop: '20%', cursor: 'pointer' }}
                                          onClick={() => {
                                            Navigate(`/job-detail-view`, { state: { ...item, navi: true }, currentPage: currentPage })
                                          }}
                                        >
                                          Accept
                                        </button>
                                      </div>

                                      {rejectInputShow && index === rejectIndex ? <></> : <div>
                                        <button style={{ background: "#d70404", border: 'none', color: 'white', padding: '10px', borderRadius: '10px', width: '80%', fontWeight: 'bold', marginTop: '5%', marginBottom: '5%', cursor: 'pointer' }}
                                          onClick={() => {
                                            setRejectInputShow(!rejectInputShow);
                                            setRejectIndex(index)
                                          }}>Reject</button>
                                      </div>}

                                    </div>}
                                </div>
                                {rejectInputShow && index === rejectIndex ?
                                  <div style={{ marginTop: '5%' }}>
                                    <textarea
                                      style={{ boxSizing: 'border-box', width: '98%', padding: "10px" }}
                                      name="reason"
                                      className={rejectionError !== "" ? "errorborder" : ""}
                                      id="reason"
                                      placeholder="Enter Reason For Rejection"
                                      onChange={(e) => {
                                        setRejectMsg(e.target.value)
                                      }}
                                    />
                                    <small style={{ color: "#ec1c24" }}>{rejectionError}</small>

                                    <div style={{ width: '20%' }}>
                                      <button style={{ background: "#d70404", border: 'none', color: 'white', padding: '10px', borderRadius: '10px', width: '80%', fontWeight: 'bold', marginTop: '5%', marginBottom: '5%', cursor: 'pointer' }}
                                        onClick={() => { handleRejectInvitaiton(item) }}>Reject</button>
                                    </div>

                                  </div>
                                  : <></>}
                                <hr />
                              </div>
                            )
                          })
                        )
                        : (
                          <div className="jobs-placeholder">
                            <img src={placeholder} alt="placeholder" />
                            <h3>
                              OOPS its Empty
                            </h3>
                            <p>Looks like you dont have any active project.</p>
                            <button onClick={() => Navigate("/jobs")} className="buton">Find Jobs</button>
                          </div>
                        )}
                  </div>
                </div>
                : null}
              {showjobs.My_Projects ?
                <div style={{ display: "grid" }}>
                  <div className="jobMatch-inside">
                    <span
                      style={
                        Onactivejob === 0
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handleOnjobClick(0);
                        setOncurrent(ongoing.InProgress);
                      }}
                    >
                      Active
                    </span>
                    <p>|</p>
                    <span
                      style={
                        Onactivejob === 1
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handleOnjobClick(1);
                        setOncurrent(ongoing.Completed);
                      }}
                    >
                      Closed{" "}
                    </span>
                    {/*<p>|</p>
                     <span
                      style={
                        Onactivejob === 2
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handleOnjobClick(2);
                        setOncurrent(ongoing.Closed);
                      }}
                    >
                      Closed{" "}
                    </span> */}
                  </div>
                  {Oncurrent.length > 0 ? (
                    Oncurrent.map((item, index) => (
                      <div key={index}>
                        <div className="current-jobs">
                          <div>
                            <span
                              className="job-name"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                Navigate(`/detail-view-job/${item.JobID}`)
                              }}
                            >
                              {item.Title}
                            </span>
                            <div style={{ display: "flex" }}>
                              <p style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{ __html: item?.Description?.slice(0, 300) + '...' }}></p>
                              <hr style={{ margin: "0.5rem" }} />
                              {amount.map((res) =>
                                res?.JobID === item?.JobID ? (
                                  <div className="current-jobs-amount" key={res?.JobID}>
                                    {item?.ProjectType === "Hourly" ? "" : <span>
                                      <p>${res?.total_amount}</p> (Total Amount)
                                    </span>}
                                    <span>
                                      <p>${res?.total_amount_received ? res?.total_amount_received : 0}</p> (Amount Received)
                                    </span>
                                  </div>
                                ) : null
                              )}
                            </div>
                            <div className="current-tags" style={{ marginTop: "0" }}>
                              {item?.SkillsRequired?.split(",").map((skill) => {
                                return skill.length > 0 ? <span> {skill}</span> : null;
                              })}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div className="jobs-placeholder">
                      <img src={placeholder} alt="placeholder" />
                      <h3>
                        OOPS its Empty
                      </h3>
                      <p>Looks like you dont have any active project.</p>
                      <button onClick={() => Navigate("/jobs")} className="buton">Find Jobs</button>

                    </div>
                  )}

                </div>
                : null}
              {showjobs.My_Messages ?
                <div style={{ display: "grid" }}>
                  {/* <div className="jobMatch-inside">
                     <span
                      style={
                        MessageActive === 0
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {

                        handleMessageClick(0);
                      }}
                    >
                      Inbox
                    </span>
                    <p>|</p>
                    <span
                      style={
                        MessageActive === 1
                          ? {
                            color: "#07b273",
                            fontWeight: "600"
                          }
                          : null
                      }
                      onClick={() => {
                        handleMessageClick(1);
                      }}
                    >
                      Sent
                    </span>

                 </div>
                     */}

                  {messageJobs.length > 0 ? (
                    messageJobs.map((item, index) => (
                      <div key={index}>
                        <div className="current-jobs" >
                          <div>
                            <span
                              className="job-name"
                              onClick={() => {
                                window.scrollTo(0, 0);
                                Navigate(`/chat-user`)
                              }}
                            >
                              {item?.ClientDetail?.Name} {item?.ClientDetail?.Last_Name} <div style={{ fontWeight: 400, display: 'inline-block', fontSize: '12px' }}>{`(${item?.Title})`}</div>
                            </span>
                            {/* <span dangerouslySetInnerHTML={{ __html: item?.Description?.slice(0, 100) + '...' }}></span> */}

                            {/* <div className="current-tags" style={{ marginTop: "0" }}>
                              {item?.SkillsRequired?.split(",").map((skill) => {
                                return skill.length > 0 ? <span> {skill}</span> : null;
                              })}
                            </div> */}
                            {/* <div className="bid-status">
                              <span style={{ background: "none", color: "gray" }} >
                                {handleDate(item)}
                              </span>
                              <span style={{ background: "none", color: "gray" }}>
                                Client : {`${item?.ClientDetail?.Name} ${item?.ClientDetail.Last_Name}`}
                              </span>
                              <span style={{ background: "none", color: "gray" }}>
                                Region : {`${item?.ClientDetail?.Country}`}
                              </span>
                              <span style={{ background: "none", color: "gray" }}>
                                TimeZone: {item?.Time_Zone ? item?.Time_Zone : "null"}
                              </span>
                            </div> */}
                          </div>
                        </div>
                        <hr />
                      </div>
                    ))
                  ) : (
                    <div className="jobs-placeholder">
                      <img src={placeholder} alt="placeholder" />
                      <h3>
                        OOPS its Empty
                      </h3>
                      <p>Looks like you dont have any active project.</p>
                      <button onClick={() => Navigate("/jobs")} className="buton">Find Jobs</button>

                    </div>
                  )}

                </div> : null}
              {showjobs.My_Earnings ?
                <TotalEarnings /> : null}
            </div>
          </>
        )}
        {/* <div className="btn-outline">Load More</div> */}
      </div>

      {!detailview ? (
        <div className="compo2">
          <div className="profileConatiner">
            <div className="profile-details" style={{ position: "relative" }} >
              <img src={img} alt="" style={{ filter: user.Verified_Profile == "Verified" ? "none" : "brightness(1)", width: "28px", height: "auto", marginLeft: "auto", position: "absolute", top: "8px", right: "8px" }} />

              <img
                src={
                  user?.Photo === "" || user?.Photo == null
                    ? profile
                    : `${endPointUrl}/images/${user.Photo}`
                }
                alt=""
              />
              <span className="profilename">{user.Name} {user.Last_Name}
              </span>
              {/* <span className="profileDesignation">
                {user?.Title}
              </span> */}
              <span className="rating"
                onClick={() => Navigate(`/user-profile/reviews/${user.Name + "-" + user.id}`)}
              >
                <AiFillStar className="featuredfreelancer-rating-star" />
                {user.avg_Rating ? user.avg_Rating : 0} <p>({user.no_of_reviews ? user.no_of_reviews : 0} Reviews)</p></span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ padding: "4px 15px", color: "#fff", display: "flex", gap: "9px" }}>
                  <span style={{ margin: "0" }}>Bid Balance</span>
                  <hr />
                  <span style={{ margin: "0" }} >{balance ? balance : 0}</span>
                </div>
                <IoAddOutline onClick={() => Navigate("/packages")} className="buy-bid" />
              </div>
            </div>

            <div className="profile-complete" style={{ borderRadius: "0" }}>
              <span style={labelStyles}><span style={{ fontWeight: "600", color: "black", marginRight: "3px" }}>{counter}% </span> Profile complete</span>
              <div className="progress">
                <ProgressBar bgColor={bgColor} completed={counter} />
              </div>

              {user.profile_progress < 80 ?
                <>
                  <h6>Complete your profile</h6>
                  <h6>to increase your chances of getting hired</h6>
                  <div className="profile-btn" onClick={() => {
                    user?.Type == "Employer"
                      ? Navigate("/employer-profile")
                      : Navigate("/login-user-profile");
                  }}>
                    <button style={{ borderRadius: "3px" }}>Complete Profile</button>
                  </div></> : null}
            </div>
            <hr />
            <div className="rate-card" style={{ borderRadius: "0 0 20px 20px " }}>
              <div className="price-range">
                <img style={{ width: "60px" }} src={dolar} alt="" />
                <div className="hour-price">
                  <span className="rate">Rate:</span>
                  <span>${user?.HourlyRate} / hour</span>
                </div>
              </div>
              <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
              <div className="user-details">
                <p>Completed Projects </p>
                <span> {analytics.completedProject} </span>
              </div>
              <div className="user-details">
                <p>Success Rate</p>
                <span>{analytics.successRate}% </span>
              </div>
              <div className="user-details">
                <p>Language</p>
                <span>
                  {getLanguage()}
                </span>
              </div>
              <div className="user-details">
                <p>Total Earnings</p>
                <span>${analytics.totalEarning}</span>
              </div>
              <div className="user-details">
                <p>Gender</p>
                <span>{user.Gender}</span>
              </div>
              <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
              <div className="skills-card" style={{ padding: "10px 0px" }}>
                <h5>Skills</h5>
                <div className="skills-employee " >
                  {user?.Skills?.split(",").map((val) => (
                    <strong style={{ color: "#000000", borderRadius: "15px", border: "1px solid #c6bbbb87", padding: "5px 10px" }} key={val}>{val}</strong>
                  ))}
                </div>
              </div>
            </div>

          </div>
        </div>
      ) : (
        <div className="sideComponent">
          <div className="sideComponent_bid">
            <span>Bid Balance</span>
            <span id="highlight">{balance ? balance : 0}</span>

          </div>
          <div className="see-other-post">
            {!Applied ? (
              <button
                className="btn"
                onClick={() => {
                  scrollToElement();
                  { balance ? setshow(true) : Navigate("/packages") }
                }}
              >
                Apply
              </button>
            ) : null}
          </div>
          <hr style={{ width: "100%" }} />
          <div className="uploadDocs">
            <h4 style={{ margin: "0 0 5px 0" }}>
              {detailjob?.IsActive ? "Documents" : "Upload Documents"}{" "}
            </h4>
            {
              detailjob?.IsActive ? (
                <div className="uploadtags">
                  {(detailjob.Documents
                    ? detailjob.Documents.split(",")
                    : []
                  ).map((tag, index) => (
                    <span key={index}>
                      <a
                        href={`${endPointUrl}/images/${tag}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {tag}
                      </a>
                    </span>
                  ))}
                </div>
              ) : null
              // <DocumentUpload detailjob={detailjob} getjobs={getjobs} />
            }
          </div>
          <hr style={{ width: "100%", background: "" }} />
          <div className="row-view">
            <div>
              <h4 style={{ margin: "5px 0 5px 0" }}>Budget Details</h4>
              <small>{detailjob?.Budget_From}-</small>
              <small>{detailjob?.Budget_To}$ / {detailjob?.Budget_Type}</small>
              {/* <small>{detailjob?.Budget_Type}</small> */}
            </div>
            <div>
              {" "}
              <h4 style={{ margin: "5px 0 5px 0" }}>Job Type</h4>
              <small>{detailjob?.Type}</small>
            </div>
          </div>
          <hr style={{ width: "100%", background: "" }} />
          <div>
            {" "}
            <h4 style={{ margin: "20px 0 5px 0" }}>Skills</h4>
            <div className="skillview">
              {(detailjob?.SkillsRequired).split(",")?.map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Technology</h4>
            <div className="skillview">
              {(detailjob?.Technology).split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>

          <div>
            <h4 style={{ margin: "20px 0 5px 0" }}>Category</h4>
            <div className="skillview">
              {(detailjob?.Category).split(",").map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
        </div>
      )}
    </div >
    <Messages />
  </>
  );
}

export default FreelancerJobprofile;
