

import job from '../images/job.png'
const JobPostingContainer = ({
  jobPostingText,
  jobPostingDescription,
  image
}) => {
  return (
    <div className="rectangle-parent-cards" >
      <div className="icon" style={{ backgroundColor: "transparent" }}>
        <img src={image ? image : job} alt="" />
      </div>
      <div className="job-heading">{jobPostingText}</div>
      <div className="job-description">{jobPostingDescription}</div>
    </div>
  );
};

export default JobPostingContainer;
