import { message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { MyContext } from "../../Mycontext";

function ChangePassword() {
  const [showmodel, setshowModel] = useState(false);
  const [showpass, setshowpass] = useState(false);
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [password, setPassword] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const proceedthree = async () => {
    if (password.newPassword.length < 5) {
      message.warning("Enter stronger password");
    } else if (password.newPassword !== password.confirmPassword) {
      message.warning("Confirm Password does not match");
    } else {
      try {
        const { data } = await axios.post(endPointUrl + "/user/changePassword", {
          id: user.id,
          oldpassword: password.oldPassword,
          newpassword: password.newPassword,
          Token,
        });
        if (!data.status) {
          return message.warning(data.message);
        }
        message.success("Password has been reset succefully");
        CloseModel();
      } catch (err) {
        console.log("Somting Wrong", err)
      }

    }
  };

  const handleExpModel = () => {

    setshowModel(true);

  };
  const CloseModel = () => {
    setPassword({
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    });
    setshowModel(false);
  };

  return (
    <>
      {" "}
      <p style={{ margin: "0" }}
        // style={{marginInlineStart:"66%",cursor:"pointer"}}
        onClick={() => handleExpModel()}

      >
        Change Password
      </p>
      <div id="myModal" className={showmodel ? "model-open" : "model"}>
        <div className="modal-content" style={{ width: "28%" }}>
          <span className="close" onClick={CloseModel}>
            &times;
          </span>

          <div className="forgot-modal"
            style={{ paddingInline: "2%", alignItems: "flex-start" }}>
            <h1 style={{ fontSize: "1.3rem", marginInline: "auto" }}>Reset Password</h1>

            <span>Enter old password</span>
            <div className="loginemail password">
              <input
                type="text"
                value={password.oldPassword}
                name="oldPassword"
                placeholder="old password"
                onChange={(e) => setPassword({ ...password, oldPassword: e.target.value.trim() })}
              />

            </div>

            <span>Enter new password</span>
            <div className="loginemail password">
              <input
                type={`${showpass ? "text" : "password"}`}
                name="newPassword"
                value={password.newPassword}
                placeholder="new password"
                onChange={(e) => setPassword({ ...password, newPassword: e.target.value.trim() })}
              />
              <span onClick={() => setshowpass(!showpass)}>
                {showpass ? "hide" : "show"}
              </span>
            </div>

            {/* <span>Confirm new password</span> */}
            <div className="loginemail password">
              <input
                type={`${showpass ? "text" : "password"}`}
                name="confirmPassword"
                value={password.confirmPassword}
                placeholder="confirm new password"
                onChange={(e) => setPassword({ ...password, confirmPassword: e.target.value.trim() })}
              />
            </div>
            <button className="btn-outline" onClick={proceedthree}>Proceed</button>

          </div>


        </div>
      </div>
    </>
  );
}


export default ChangePassword