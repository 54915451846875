import React from 'react'
import signup from '../images/sign-up.png'
import arrow from '../images/right-arrows.png'
import { useNavigate } from "react-router-dom";
function SignUpFreelancer() {
  const Navigate=useNavigate();
  return (
    <div className="SignUpEmployee">
    <h1>Sign Up</h1>
    <p className="sign-up-content">
        Creating your profile on Digilanxer is easy! 
    </p>
    <div className="sign-up-content-img">
<div className="sign-up-points">
        <div className="sign-up-points-numbers">
          <div className="number">1</div>
            <div className="content"><h3>Get on board with Digilanxer - it's free!</h3>
              <p>Simply sign up on the platform, verify and get ready to connect with clients from across the globe ! </p>
          {/* <p className="points-links" onClick={()=>Navigate('../login')}>Get Started <img src={arrow} alt="" /></p> */}
          </div>
        </div>
        <div className="sign-up-points-numbers">
          <div className="number">2</div>
            <div className="content"><h3>Create your profile</h3>
              <p>Include details that will set your profile apart from the competition, such as a description of the services you provide,
                examples of your work, and other specifics.</p>
              {/* <p className="points-links">Post a Job<img src={arrow} alt="" /></p> */}
            </div>
        </div>
        
        
       
       
        
        
      </div>
    <div className="sign-up-img"><img src={signup} alt="" /></div>
    </div>
  </div>
  )
}

export default SignUpFreelancer