import React from 'react'
import { Link } from 'react-router-dom'

export default function Sitemap() {
  return (
      <div className='Sitemap-container'>
          <h2>Sitemap</h2>
          <div className='sitemap-lower'>
              <div className='sitemap-link-head'>
                  <span>Digilanxer</span>
              </div>
              <div className='sitemap-link'>
                  <Link to="/jobs" >TopJobs</Link>
                  <Link to="/freelancers" >Top Freelancers</Link>
                  <Link to="/jobs" >Membership</Link>
                  <Link to="/reviews" >Reviews</Link>
                  <Link to="/contact-us" >Contact Page</Link>
              </div>
          </div>
          <div className='sitemap-lower'>
              <div className='sitemap-link-head'>
                  <span>Browse</span>
              </div>
              <div className='sitemap-link'>
                  <Link to="/why-digilanxer" >About Digilanxer</Link>
                  <Link to="/job-creation" >Post a job</Link>
                  <Link to="/freelancers" >Hire freelancer</Link>
                  <Link to="/how-it-works" >How It Works</Link>
                  <Link to="/escrow-payment-system" >Payment Method</Link>
                  <Link to="/sitemap" >Site Map</Link>
              </div>
          </div>
          <div className='sitemap-lower'>
              <div className='sitemap-link-head'>
                  <span>Terms</span>
              </div>
              <div className='sitemap-link'>
                  <Link to="/privacy-policy" >Privacy Policy</Link>
                  <Link to="/terms-condition" >Terms and Conditions</Link>
                  <Link to="/EscrowPaymentSustem" >Escrow Payment System</Link>
                  <Link to="/copy-right-policy" >Copyright Policy</Link>
              </div>
          </div>
    </div>
  )
}
