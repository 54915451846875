import React, { useContext, useEffect, useState, useRef } from 'react'
import { MyContext } from '../Mycontext';
import Profile from "../images/emplyeer.png"
import placeholderImg from '../images/layer1.svg'
import axios from 'axios';
import '../ChatPage.scss';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { IoMdLink } from "react-icons/io";
import { CiSearch } from "react-icons/ci";
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

export default function ChatPage() {
    const { id } = useParams();
    const navigate = useNavigate();
    const scrollRef = useRef(null);
    const { user, endPointUrl, Token, chatUsers, onlineUser, UnreadUsers, setUnreadUsers, setChatUsers, socket } = useContext(MyContext);
    const [chatUser, setChatUser] = useState();
    const [selectedIndex, setSelectedIndex] = useState(false)
    const [sender, setSender] = useState();
    const [receiver, setReceiver] = useState();
    const [message, setMessage] = useState("");
    const [chatHistory, setChatHistory] = useState([]);
    const [currentRoom, setCurrentRoom] = useState(null);
    const [chatboxshow, setChatBoxShow] = useState(false);
    const [detailjob, setdetailjob] = useState()
    const [imageURL, setImageURL] = useState();
    const [imageByte, setImageByte] = useState();

    const localUser = user;
    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    const getPreviousMessages = async (ReceiverID) => {
        try {
            const response = await axios.post(endPointUrl + "/getMessages", {
                sender: user.id,
                receiver: ReceiverID,
                JobID: detailjob?.JobID
            });
            setChatHistory(response.data.data);
        } catch (error) {
            console.error("Error retrieving chat messages:", error);
        }
    };

    const formatTimestamp = (timestamp) => {
        const date = moment(timestamp);
        const now = moment();
        const timeDifference = now.diff(date, 'seconds');

        if (timeDifference < 60) {
            return (<span>{timeDifference} seconds ago</span>);
        } else if (timeDifference < 3600) {
            const minutes = moment.duration(timeDifference, 'seconds').minutes();
            return (<span>{minutes} minutes ago</span>);
        } else if (timeDifference < 86400) {
            const hours = moment.duration(timeDifference, 'seconds').hours();
            return (<span>{hours} hours ago</span>);
        } else {
            return (<span>{date.format('MMMM Do YYYY')}</span>); // Display the full date for older messages
        }
    };

    const renderTimestamp = (currentTimestamp, prevTimestamp) => {
        const currentMoment = moment(currentTimestamp);
        const prevMoment = moment(prevTimestamp);

        const timeDifference = currentMoment.diff(prevMoment, 'hours');

        if (timeDifference >= 2) {
            return formatTimestamp(currentTimestamp);
        }

        return null; // Return null if the difference is less than 2 hours
    };

    // function sendNotificaition(){
    //     console.log('show notification', onlineUser)
    //     console.log('fist message', chatHistory)
    //     // if(onlineUser){

    //     // }
    // }

    useEffect(() => {
        // Scroll to the bottom of the chat window when the chatHistory changes
        const chatWindow = document.getElementById("chat-window");
        if (chatWindow) {
            chatWindow.scrollTop = chatWindow.scrollHeight;
        }
        // sendNotificaition()
    }, [chatHistory]);

    const handleLogin = (ReceiverID) => {
        setSender(user.id);
        setReceiver(ReceiverID);
        setChatBoxShow(true)
        scrollToBottom();
        setChatUser(chatUsers.filter((user => user?.user?.id == ReceiverID)))
        const sortedNames = [user?.id, ReceiverID].sort();
        const room = `${sortedNames[0]}-${sortedNames[1]}`;

        setCurrentRoom(room);
        const data = {
            sender: user.id,
            receiver: ReceiverID,
            message: "",
            room: room,
        };
        socket.emit("login", data);

        getPreviousMessages(ReceiverID);
    }

    useEffect(() => {
        socket?.on("receive-message", (data) => {
            console.log('data', data)
            setChatUsers(prevUsers => {
                const updatedUsers = prevUsers.map(user => {
                    console.log('prevuser', user)
                    if ((user?.user?.id == data?.receiver) && (data?.JobID == user?.lastChat?.JobID)) {
                        return {
                            ...user,
                            lastChat: {
                                ...user.lastChat,
                                message: data.message, // Update lastChat.message with the new message
                                IsRead: true
                            }
                        };
                    }
                    return user;
                });
                const senderIndex = updatedUsers.findIndex(user => user?.user?.id == data?.receiver);

                if (senderIndex !== -1) {
                    const senderUser = updatedUsers.splice(senderIndex, 1)[0];
                    updatedUsers.unshift(senderUser);
                }
                return updatedUsers;
            });
            setChatHistory((prevChatHistory) => [...prevChatHistory, data]);
        });
        return () => {
            socket?.off("receive-message");
        };
    }, [socket])


    const markasRead = async (senderID) => {
        setChatUsers(prevUsers => {
            const updatedUsers = prevUsers.map(user => {
                if (user.user.id == senderID) {
                    return {
                        ...user,
                        lastChat: {
                            ...user.lastChat,
                            IsRead: true
                        }
                    };
                }
                return user;
            });
            return updatedUsers;
        });
        try {
            await axios.post(endPointUrl + '/admin/UserChatUpdate', {
                Token,
                SenderID: senderID,
                RecieverID: user.id
            })
            // getChatUsers();
        } catch {
            console.log('Error updating Chat');
        }
    }
    const getChatUsers = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/admin/chatusers", {
                Token,
                UserID: user?.id,
                Type: user?.Type,
            })

            // Extract chat data
            const chatData = data.data;
            // Set the count in the parent component (you may need to pass a prop to the parent component)
            const unreadCount = chatData?.filter((chat) => !chat.lastChat.IsRead && chat.lastChat.sender != user.id);
            const unreadusersID = await unreadCount?.map((chat) => chat.user.id);
            setUnreadUsers(new Set(unreadusersID));
            setChatUsers(data.data);
        } catch (error) {
            console.log("Error While Get Chat USers");
        }
    }

    useEffect(() => {
        if (user) {
            getChatUsers();
            navigate('/chat-user')
        }

    }, [user]);

    // function for cheking User Chat Already is active or not
    useEffect(() => {
        const idNumber = parseInt(id, 10);
        if (!isNaN(idNumber) && UnreadUsers instanceof Set && UnreadUsers.has(idNumber)) {
            const UpdatedData = UnreadUsers?.delete(idNumber);
            setUnreadUsers(UpdatedData);
            markasRead(idNumber)
        }
    }, [UnreadUsers]);

    useEffect(() => {
        // Check if both 'id' and 'chatUsers' are available and 'chatUsers' has been updated
        if (id && chatUsers?.length > 0) {
            handleLogin(id);
        }
    }, [id, chatUsers]);

    const handleSendMessage = async () => {
        if (message.trim() !== "") {
            scrollToBottom();
            const data = {
                sender: sender,
                receiver: receiver,
                message: message,
                room: currentRoom,
                JobID: detailjob.JobID,
            };
            socket.emit("send-message", data);
            setMessage("");
        }

        if (imageURL) {
            const formData = new FormData()
            formData.append('image', imageByte)
            formData.append('UserID', user.id)
            formData.append('sender', sender)
            formData.append('receiver', receiver)
            formData.append('JobID', detailjob.JobID)
            formData.append('Token', Token)
            formData.append('room', currentRoom)

            try {
                setImageByte("")
                setImageURL("")
                await axios.post(endPointUrl + "/MsgAttachment", formData)
            }
            catch (e) {
                console.log('Error while sending attachment')
            }
        };


        if (!onlineUser.find((onlineU => onlineU?.id == receiver))) {
            socket.emit("Job_Expiry", {
                Type: "Message",
                FromID: sender,
                ToID: receiver,
                Message: `You have a new Message from ${user.Name} ${user.Last_Name} `,
                IsRead: "false",
                ItemID: sender,
            })
        }

    };

    const handleFileUpload = async (event) => {
        try {
            const file = event.target.files[0];
            setImageURL(URL.createObjectURL(event.target.files[0]))
            setImageByte(event.target.files[0])

        } catch (error) {
            console.error("Error uploading file:", error);
        }
    };


    const FileAttachment = ({ filename, fileURLs }) => {
        return (
            <div className="file-attachment">
                {fileURLs?.substring(fileURLs.lastIndexOf(".") + 1) === "pdf" || fileURLs?.substring(fileURLs.lastIndexOf(".") + 1) === "docx" ?
                    <a target='_blank' href={`${endPointUrl}/ChatAttachment/${fileURLs}`}>
                        <div>
                            Click to View Document
                        </div>
                    </a>
                    :
                    <a target='_blank' href={`${endPointUrl}/ChatAttachment/${fileURLs}`}>
                        <img
                            className="image-thumbnail"
                            src={`${endPointUrl}/ChatAttachment/${fileURLs}`}
                            alt="pic"
                        // onClick={() => handleImageDownload(`${endPointUrl}${fileURLs}`, filename)}
                        // download={true}
                        />
                    </a>}
            </div>
        );
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    return (
        <div className='chatbox_wrapper'>
            <div className='chatbox_Container'>
                <div className='chatbox_userbox'>
                    <span style={{ position: "relative" }}>
                        <input type="search" placeholder='Search User' className='chatbox_user_search' />
                        <CiSearch className='chatbox_search_button' />
                    </span>

                    {
                        chatUsers?.length > 0 ?

                            chatUsers?.map((user, index) => {
                                /*       const isChatUserActive = chatUser && chatUser[0]?.user?.id === user?.user?.id; */
                                const isChatUserActive = selectedIndex == index
                                const userPhoto = user?.user?.Photo || Profile;
                                const truncatedMessage = user?.lastChat?.message ? user?.lastChat?.message.substring(0, 35) : 'picture';

                                const handleClick = async (jobID, index) => {
                                    // getjobs(jobID)
                                    setSelectedIndex(index)
                                    try {
                                        const { data } = await axios.post(endPointUrl + "/user/getJobsByJobId", {
                                            JobID: jobID,
                                            Token,
                                        });
                                        setdetailjob(data.data);
                                    } catch (err) {
                                        console.log(err);
                                    }
                                    // Remove user from UnreadUsers if it's a Set
                                    if (UnreadUsers instanceof Set) {
                                        const updatedUnreadUsers = new Set(UnreadUsers);
                                        updatedUnreadUsers.delete(user?.user?.id);
                                        setUnreadUsers(updatedUnreadUsers);
                                    }

                                    // Mark the message as read and navigate to chat user
                                    const updatedUsers = chatUsers.map(chatUser => {
                                        if (chatUser.user.id === user?.user?.id) {
                                            return {
                                                ...chatUser,
                                                lastChat: {
                                                    ...chatUser.lastChat,
                                                    IsRead: true
                                                }
                                            };
                                        }
                                        return chatUser;
                                    });
                                    setChatUsers(updatedUsers);
                                    markasRead(user?.user?.id);
                                    navigate(`/chat-user/${user?.user?.id}`);
                                };

                                return (
                                    <div
                                        className={`chatbox_Detailbox ${isChatUserActive ? "active" : ""}`}
                                        // className='chatbox_Detailbox'
                                        key={index}
                                        style={{ backgroundColor: user?.lastChat?.IsRead === false && user?.lastChat?.sender != localUser?.id ? "#03b4743d" : "" }}
                                        // style={{ backgroundColor: user?.lastChat?.IsRead ? "" : "#03b4743d", paddingBottom: "8px" }}
                                        onClick={() => handleClick(user?.lastChat?.JobID, index)}
                                    >
                                        <img src={`${endPointUrl}/images/${userPhoto}`} alt="" />
                                        <span className='chatbox_userDetail'>
                                            <span>
                                                <h4 style={{ display: 'inline-block' }}>{user?.user?.jobName}</h4>
                                                <div style={{ fontWeight: 400, display: 'inline-block', fontSize: '12px', marginLeft: '5px' }}>{`(${user?.user?.Name})`}</div>
                                                <p>{truncatedMessage}</p>
                                            </span>
                                            {onlineUser.find((onlineU => onlineU?.id == user?.user?.id)) ? <p className='Online_dot' ></p> : null}
                                        </span>
                                    </div>
                                );
                            })
                            :
                            <div style={{ color: "#07Af72", textAlign: 'center' }}>No chat users...</div>
                    }


                </div>

                <div className='chatbox_chatbox'>

                    {chatboxshow ?
                        <>
                            <div className='chatbox_userDetail'>
                                <img
                                    src={
                                        chatUser && chatUser[0]?.user?.Photo === "" || chatUser && chatUser[0]?.user?.Photo == null
                                            ? Profile
                                            : `${endPointUrl}/images/${chatUser && chatUser[0]?.user?.Photo}`
                                    }
                                    alt="profile" />
                                <h4>{chatUser && chatUser[0]?.user?.Name}</h4>
                                <div style={{ fontWeight: 400, display: 'inline-block', fontSize: '14px' }}>{`(${detailjob?.Title})`}</div>
                            </div>
                            <div className='chatbox_box' id="chat-window" ref={scrollRef}>
                                <div className='chatbox_UserDetail'>
                                    <img
                                        src={
                                            chatUser && chatUser[0]?.user?.Photo === "" || chatUser && chatUser[0]?.user?.Photo == null
                                                ? Profile
                                                : `${endPointUrl}/images/${chatUser && chatUser[0]?.user?.Photo}`
                                        }
                                        alt="profile" />
                                    <h4 >{chatUser && chatUser[0]?.user?.Name}</h4>
                                    <p>Digilancer</p>
                                </div>
                                {chatHistory.map((chat, index) => (
                                    <>
                                        {renderTimestamp(chat.createdAt, chatHistory[index - 1]?.createdAt)}
                                        <div
                                            key={index}
                                            className={
                                                chat.sender.toString() !== sender.toString()
                                                    ? "chat-bubble left chatbox_message_left"
                                                    : "chat-bubble right chatbox_message_right"
                                            }
                                        >
                                            {chat.file_url ?
                                                <>
                                                    <img
                                                        style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                                        src={chat.sender.toString() !== sender.toString() ?
                                                            chatUser && chatUser[0]?.user?.Photo === "" || chatUser && chatUser[0]?.user?.Photo == null
                                                                ? Profile
                                                                : `${endPointUrl}/images/${chatUser && chatUser[0]?.user?.Photo}`
                                                            :
                                                            user?.Photo === "" || user?.Photo == null
                                                                ? Profile
                                                                : `${endPointUrl}/images/${user.Photo}`
                                                        }
                                                        alt=""
                                                    // onClick={(e) => { handleLogin(e, user.user.id) }}
                                                    />
                                                    <FileAttachment
                                                        filename={chat.message}
                                                        fileURLs={chat.file_url}
                                                    />
                                                </>
                                                : <>
                                                    <img
                                                        style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                                                        src={chat.sender.toString() !== sender.toString() ?
                                                            chatUser && chatUser[0]?.user?.Photo === "" || chatUser && chatUser[0]?.user?.Photo == null
                                                                ? Profile
                                                                : `${endPointUrl}/images/${chatUser && chatUser[0]?.user?.Photo}`
                                                            :
                                                            user?.Photo === "" || user?.Photo == null
                                                                ? Profile
                                                                : `${endPointUrl}/images/${user?.Photo}`
                                                        }

                                                        alt=""
                                                        onClick={(e) => { handleLogin(user?.id) }}
                                                    />
                                                    <p>{chat.message}</p>
                                                </>}
                                        </div>
                                    </>
                                ))}
                            </div>
                            <div className="chat-input">
                                <div className="input-container chatbox_messageInputbox">
                                    <input
                                        type="text"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Type your message..."
                                        onKeyPress={handleKeyPress}
                                    />
                                </div>
                                <div className="file-upload-container ">
                                    <div>
                                        <label htmlFor="file">
                                            <IoMdLink />
                                        </label>
                                        <input type="file" id="file" onChange={handleFileUpload} />
                                    </div>
                                    <div>
                                        <img src={imageURL} width="100%"></img>
                                    </div>
                                    <button onClick={handleSendMessage}>Send</button>
                                </div>
                            </div>
                        </>

                        : <div className='chatbox_Empty_Image'>
                            {/* <img src={placeholderImg} alt="placeholderImg" /> */}
                        </div>
                    }
                </div >
            </div >
        </div>

    )
}
