import React from "react";
import Profile from "../images/freelancer.png";
import dolar from "../images/dolar.png";
import editbutton from "../images/edit.png";
import deletebutton from "../images/delet2.png";
import Frame from "../images/Frame.svg"
import { Link, Navigate, useNavigate } from "react-router-dom";
import { MyContext } from "../Mycontext";
import { useContext, useEffect, useState } from "react";
import { Typography, message } from "antd";
import axios from "axios";
import WorkExp from "./user-profile/WorkExp";
import Userprofileupdate from "./user-profile/userprofileupdate";
import Uploadimg from "./user-profile/Uploadimg";
import { MdOutlineFileUpload } from "react-icons/md";
import add from '../images/add.png';
import AddPortfolio from "./user-profile/Portfolio/AddPortfolio";
import ProgressBar from "./ProgressBar";
import Certificate from "./user-profile/Certificate/AddCertificate";
import { AiFillStar } from 'react-icons/ai';
import img from '../images/veryfied.png';
import Selectant from 'react-select';
import { MdPictureAsPdf } from "react-icons/md";
import { Box, Card, CardContent, Modal } from "@mui/material"
import ModalViewDocument from "./ModalViewDocument";
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { PiCertificateFill } from "react-icons/pi";
import certified from "../images/certified.svg"
import { MdCastForEducation } from "react-icons/md";

function Logeduser() {
  const Navigate = useNavigate()
  const { user, setuser, endPointUrl, Token } = useContext(MyContext);
  const [counter, setCounter] = useState(0);
  const [bgColor, setbgColor] = useState('');
  const [education, setEducation] = useState([]);
  const [formList, setFormList] = useState([]);
  const [Editeducation, setEditeducation] = useState([]);
  const [exp, setExp] = useState([]);
  const [newEducation, setnewEducation] = useState({ DegreeName: "", Univercity: "", Year: "" });
  const [errorForm, setErrorForm] = useState({ DegreeName: "", Univercity: "", Year: "", file: "", });
  const [preform, setPreform] = useState(null);
  const [filedata, setFileData] = useState([]);
  const [showmodel, setshowModel] = useState(false);
  const [showEdit, setshowEdit] = useState(false);
  const [uploadImg, setUploadImg] = useState(false);
  const [imgURL, setImgURL] = useState('')
  const [certi, setCerti] = useState('');
  const [port, setPort] = useState('');
  const [DegreeMaster, setDegreeMaster] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [takenTest, setTakenTest] = useState([]);

  const handleOpen = (e, item) => {
    e.preventDefault();
    setOpen(true);
    setSelectedItem(item.Education_File);
  }

  let skills = user?.Skills ? user.Skills.split(",") : [];

  // const refreshUser = () => {
  //   try {
  //     const { data } = axios.post("/getUserById", {Token, id:user.id})
  //   } catch (error) {
  //     console.log("Error while Refresh User")
  //   }
  // }

  const handleuploadImg = () => {
    setUploadImg(!uploadImg);
  }

  const getCertificate = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
      Token,
      UserID: user.id
    });

    setCerti(data.data)
  }


  const getPortfolio = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getportfolioByUserID", {
      Token,
      UserID: user.id
    });
    setPort(data.data);
  }

  const getEducation = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserEducation", { UserID: user.id, Token })
    setEducation(data.data);
  };

  const getExp = async () => {
    const { data } = await axios.post(endPointUrl + "/user/getuserExp", {
      UserID: user.id,
      Token,
    });
    setExp(data.data);
  };

  const DegreeMAster = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/Admin/getallDegree", {
        Token,
      });
      if (data && data.data && Array.isArray(data.data)) {
        const languageNames = data.data.map((item) => ({
          value: item.Name,
          label: item.Name,
        }));
        setDegreeMaster(languageNames);
      } else {
        console.log('Failed to fetch Degree. Data may be empty or not in the expected format.');
      }
    } catch (error) {
      console.log('Failed to fetch Degree:', error);
    }
  };

  useEffect(() => {
    getExp();
    getEducation();
    getCertificate();
    getPortfolio();
    DegreeMAster();
  }, []);

  // const addEducation = async (education) => {
  //   if (formList.length === 0 && education) {
  //     await axios.post(endPointUrl +
  //       "/user/userEducation",
  //       {
  //         formList: [education],
  //         UserID: user.id, Token
  //       }
  //     );
  //   } else {
  //     await axios.post(endPointUrl +
  //       "/user/userEducation",
  //       { formList, UserID: user.id, Token }
  //     );
  //   }

  //   setnewEducation({ DegreeName: "", Univercity: "", Year: "" });
  //   getEducation();
  // };


  const addEducation = async (education, type) => {
    try {
      var res = ""
      if (formList.length === 0 && education) {
        res = await axios.post(endPointUrl + "/user/userEducation", {
          formList: [education],
          UserID: user.id,
          Token
        });
      }
      else if (type === 'submit') {
        res = await axios.post(endPointUrl + "/user/userEducation", {
          formList,
          UserID: user.id,
          Token
        });
      }
      else {
        res = await axios.post(endPointUrl + "/user/userEducation", {
          formList: [...formList, newEducation],
          UserID: user.id,
          Token
        });
      }
      const EducationID = res.data.data;
      const formdata = new FormData();
      var imagaeData = "";

      if (filedata.length === 0) {
        imagaeData = [preform]
      } else {
        imagaeData = preform ? [...filedata, preform] : [...filedata];
      }
      imagaeData.forEach(item => formdata.append('File_Upload', item));

      try {
        const response = await axios.post(
          endPointUrl + "/user/educationdocupload",
          formdata,
          {
            params: {
              Token,
              eduID: EducationID
            }
          }
        );
        console.log("Document upload response:", response.data);
      } catch (error) {
        console.error("Error uploading documents:", error);
      }


      message.success("Education Added")
      getEducation();
      setnewEducation({ DegreeName: "", Univercity: "", Year: "" });
      setFormList([]);
      setErrorForm({ DegreeName: "", Univercity: "", Year: "", file: "" })
      setPreform(null)
      setnewEducation({ DegreeName: "", Univercity: "", Year: "" })
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };


  const handleEducationModel = (e) => {
    setErrorForm(
      { DegreeName: "", Univercity: "", Year: "", file: "" }
    );
    setnewEducation({ DegreeName: "", Univercity: "", Year: "" });
    setFormList([]);
    setshowModel(true);
  }

  const handleEditEducationModel = (Item) => {
    let tem = education.filter((item) => {
      return item.U_E_ID === Item.U_E_ID
    });
    setEditeducation(tem);
    setnewEducation(tem[0])
    setshowEdit(true);
  };

  const CloseModel = () => {
    setshowModel(false);
    setshowEdit(false);
    setErrorForm(
      { DegreeName: "", Univercity: "", Year: "", file: "" }
    );
    setnewEducation({ DegreeName: "", Univercity: "", Year: "" });
    setFormList([]);
  };

  const handlesubmitEducationModel = (e, type) => {
    e.preventDefault();
    const newFormErrors = {
      DegreeName: "",
      Univercity: "",
      Year: "",
      file: "",
    };

    let showError = false;

    if (!newEducation.DegreeName && !newEducation.Univercity && !newEducation.Year && !preform && type === "Submit" && formList.length >= 1) {
      setshowModel(false);
      addEducation(newEducation, 'submit');
    }

    else {
      if (newEducation.DegreeName === null || newEducation.DegreeName === "") {
        showError = true;
        newFormErrors.DegreeName = 'Please fill out this field!';
      } else {
        newFormErrors.DegreeName = '';
      }
      if (newEducation.Univercity === null || newEducation.Univercity === "") {
        showError = true;
        newFormErrors.Univercity = 'Please fill out this field!';
      }
      else {
        newFormErrors.Univercity = "";
      }
      if (newEducation.Year === null || newEducation.Year === "") {
        showError = true;
        newFormErrors.Year = 'Please fill out this field!';
      } else {
        newFormErrors.Year = '';
      }
      if (newEducation?.Year?.split("-")[0] < 1960 || newEducation?.Year?.split("-")[0] > 2024) {
        showError = true;
        newFormErrors.Year = 'Enter Year Between 1960 to 2024';
      } else {
        newFormErrors.Year = '';
      }
      if (preform === null || !preform) {
        showError = true;
        newFormErrors.file = 'Please upload certificate!';
      } else {
        newFormErrors.file = '';
      }
      setErrorForm(newFormErrors);
      if (!showError) {
        setFileData([...filedata, preform]);
        setFormList([...formList, newEducation]);

        if (type != "submit") {
          setnewEducation({
            DegreeName: "",
            Univercity: "",
            Year: ""
          })
          setPreform(null)
          document.getElementById("certificatefile").value = '';
        }

        if (type === "Submit") {
          setshowModel(false);
          addEducation(newEducation);
        }
      }
    }
  };



  const handleEditsubEducationModel = (e) => {
    e.preventDefault();
    const newFormErrors = {
      DegreeName: "",
      Univercity: "",
      Year: "",
    };
    let showError = false;
    if (newEducation.DegreeName === null || newEducation.DegreeName === "") {
      showError = true;
      newFormErrors.DegreeName = 'Please fill out this field!';
    } else {
      newFormErrors.DegreeName = '';
    }
    if (newEducation.Univercity === null || newEducation.Univercity === "") {
      showError = true;
      newFormErrors.Univercity = 'Please fill out this field!';
    }
    else {
      newFormErrors.Univercity = "";
    }
    if (newEducation?.Year?.split("-")[0] < 1960 || newEducation?.Year?.split("-")[0] > 2024) {
      showError = true;
      newFormErrors.Year = 'Enter Year Between 1960 to 2024';
    } else {
      newFormErrors.Year = '';
    }
    setErrorForm(newFormErrors);
    if (!showError) {
      updateEducation();
      setshowEdit(false);
    }
  };


  const deleteEducation = async (U_E_ID) => {
    try {
      await axios.post(endPointUrl + "/user/deleteuserEducation", { U_E_ID, Token });
      message.success('Delete Successful');
      getEducation();
    } catch {
      message.error('something Went wrong');
    }
  };
  const updateEducation = async () => {
    try {
      let res = "";
      if (preform) {
        const formData = new FormData()
        formData.append("Education_File", preform)

        res = await axios.post(
          endPointUrl + "/user/educationupload", formData
        );
        setFormList([])
        setPreform(null)
        setnewEducation({ DegreeName: "", Univercity: "", Year: "" })


        await axios.post(endPointUrl + "/user/updateUserEducation ", {
          ...newEducation,
          U_E_ID: Editeducation[0].U_E_ID,
          Education_File: res?.data?.fileName,
          Token,
        });
        getEducation();
        message.success("Update Successful");
      }
      else {
        await axios.post(endPointUrl + "/user/updateOnlyUserEducation ", {
          ...newEducation,
          U_E_ID: Editeducation[0].U_E_ID,
          Token,
        });
        getEducation();
        message.success("Update Successful");
      }
    } catch {
      message.error("something went Wrong ");
    }
  };
  const labelStyles = {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    color: '#5E5E5E',
    fontSize: '12px'
  }


  const handleDegreeSelect = (selectedOption) => {
    setnewEducation({ ...newEducation, DegreeName: selectedOption.value });
  };

  const progress = () => {
    let counter = 0;
    if (user?.Name) counter += 2;
    if (user?.Email) counter += 2;
    if (user?.Last_Name) counter += 2;
    if (user?.Language) counter += 2;
    if (user?.ScreenName) counter += 2;

    if (user?.About) counter += 5;
    if (user?.HourlyRate) counter += 2;
    if (user?.Address) counter += 2;
    if (user?.Gender) counter += 2;
    if (user?.Mobile) counter += 2;
    if (user?.Country) counter += 2;

    if (
      user?.Bank_Name &&
      user?.Account_Name &&
      user?.Account_Number &&
      user?.BANK_COUNTRY
    ) {
      counter += 10;
    }
    if (user?.State) counter += 2;

    if (user?.City) counter += 2;

    if (user?.Skills) counter += 4;

    if (user?.Age != "0") counter += 2;
    if (user?.Photo) counter += 5;
    if (education?.length) counter += 10;
    if (exp?.length) counter += 10;
    if (certi?.length) counter += 10;
    if (port?.length) counter += 10;
    if (user?.Verified_Profile === "Verified") counter += 10;
    // counter;
    setCounter(counter)
  };


  const gradient = () => {
    (counter < 25) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg,rgba(7,178,116,1), #00ffff)')) : (
      (counter < 70) ? (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1), #00ffff, #c4fdff)'))
        : (setbgColor((bgColor) => bgColor = 'linear-gradient(90deg, rgba(7,178,116,1) 25%, #00ffff 63%, rgba(51,154,250,1) 90%)'))
    )
  }
  const profileProgress = async () => {
    try {
      await axios.post(endPointUrl + "/updateUserProfileProgress", {
        profile_progress: counter,
        id: user?.id, Token
      });
      setuser({ ...user, profile_progress: counter })
    } catch { }
  };


  const getTests = async () => {
    const { data } = await axios.post(endPointUrl + '/getSkilltestforfreelancer', {
      Token,
      id: user.id
    })
    setTakenTest(data?.assesment);
  };


  useEffect(() => {
    if (counter > 0) {
      profileProgress();
    }
    gradient();
  }, [counter])

  useEffect(() => {
    progress();
  }, [user, education, exp, certi, port]);

  useEffect(() => {
    (setCounter((counter) => counter = 0));
    getTests()
  }, []);

  function getLanguage() {

    let languages;
    try {
      languages = JSON.parse(user.Language);
    } catch (error) {
      return (<span>{user?.Language}</span>);
    }
    return (
      <span>
        {languages?.Language1}
        {languages?.Language2 ? `, ${languages.Language2}` : ''}
        {languages?.Language3 ? `, ${languages.Language3}` : ''}
        {languages?.Language4 ? `, ${languages.Language4}` : ''}
        {languages?.Language5 ? `, ${languages.Language5}` : ''}
      </span>
    );
  }


  return (
    <div className="HowItWorks" >

      <div className="home-howitwork">
        {/* <Link to={`/${user?.Type == 'Employer' ? 'employer-job-profile' : 'freelancer-job-profile'}`}>Home</Link> {" "}/{" "} */}
        <Link to={"/"}>Home</Link> {" "}/{" "}
        <Link to="/login-user-profile">{user?.Name}</Link>
      </div>

      <div className="profile-Apart">
        <div className="profile-Apart-inner" style={{ marginTop: "40px" }}>
          {!uploadImg ? <img src={user?.Photo === "" || user?.Photo == null ? Profile : `${endPointUrl}/images/${user?.Photo}`} alt="profile" title="Click here to edit" onClick={() => handleuploadImg()} /> : <Uploadimg handleuploadImg={handleuploadImg} userid={user.id} />}
        </div>
        <div className="user-name">
          <img src={img} alt="" style={{ filter: user.Verified_Profile == "Verified" ? "none" : "grayscale(1)", width: "18px", height: "auto", marginLeft: "auto", top: "15px", right: "0px" }} />
          <h2>{user.ScreenName}</h2>
          <p style={{ margin: 0 }}>{user?.Name} {user.Last_Name}</p>
          <p onClick={() => handleuploadImg()} style={{ padding: 0, margin: 0, fontSize: "20px", cursor: "pointer" }}>{!uploadImg ? <MdOutlineFileUpload /> : null}</p>
        </div>
        <div className="user-reviews-address">
          <span className="rating"
            onClick={() => Navigate(`/user-profile/reviews/${user.Name + "-" + user.id}`)}
            style={{ paddingLeft: "8px", fontSize: "16px" }}
          >
            <AiFillStar className="featuredfreelancer-rating-star" />
            {user.avg_Rating ? user.avg_Rating : 0} <p>({user.no_of_reviews ? user.no_of_reviews : 0} Reviews)</p></span>
          <span>|  <img src={Frame} alt="location" style={{ paddingRight: "3px", paddingLeft: "18px", width: "18px" }} /> {user?.City} {user?.City ? "," : null} {user?.Country}</span>
        </div>
      </div>

      <section className="logedUser-section">
        <div style={{ width: "69%" }}>
          <div className="about-rate">
            <div className="about">
              <b>About</b>
              <p>{user?.About}</p>
            </div>
          </div>

          <div className="education">
            <strong style={{ display: 'flex', alignItems: 'center' }}>Education <img src={add} style={{ width: "20px" }} alt="" onClick={handleEducationModel} /></strong>
            <div className="all-education">
              <div className="education-conainer" style={{ width: '100%' }}>
                {education.map((item) => (
                  <div style={{ marginTop: '3%' }}>
                    <div style={{ display: 'flex', width: "100%" }}>
                      <div style={{ width: "17%", background: '' }}>
                        <a
                          href={`${endPointUrl}/education/${item.Education_File}`}
                          target="modalOpen"
                          rel="noopener noreferrer"
                          onClick={(e) => handleOpen(e, item)}
                        >
                          {item.Education_File?.substring(item.Education_File?.lastIndexOf(".") + 1) === "pdf"
                            ?
                            <div><MdPictureAsPdf style={{ width: '70px', height: "70px", cursor: 'pointer' }} /></div>
                            : <MdCastForEducation
                              style={{ width: '70px', height: "70px", cursor: 'pointer' }}
                              className="potfoliothumbail"
                            />}
                        </a>
                      </div>

                      <div style={{ width: '80%' }}>
                        <div style={{ fontWeight: "bold" }}>{item.DegreeName} - {item.Year}</div>
                        <div style={{ fontSize: '14px', color: "#606563" }}>{item.Univercity}</div>
                      </div>

                    </div>

                    <div className="edit-icons">
                      <img id="edit-btn" src={editbutton} alt="" onClick={() => handleEditEducationModel(item)} />
                      <img id="del-btn" src={deletebutton} alt="" onClick={() => deleteEducation(item.U_E_ID)} />
                    </div>
                    <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>

                    {selectedItem && <ModalViewDocument type="education" open={open} setOpen={setOpen} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                    {/* <div className="edit-icons">
                      <img id="edit-btn" src={editbutton} alt="" onClick={() => handleEditEducationModel(item)} />
                      <img id="del-btn" src={deletebutton} alt="" onClick={() => deleteEducation(item.U_E_ID)} />
                    </div> */}
                    {/* <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr> */}
                  </div>
                ))}
              </div>
            </div>
            {/* this model is used for add education*/}
            <div id="myModal" className={showmodel ? 'model-open' : 'model'}>
              <div className="modal-content" style={{ width: "60%", padding: 0 }} >
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                  <span style={{ color: "#46c294", fontWeight: "600" }}>Add Education</span>
                  <span className="close" onClick={CloseModel}>&times;</span>
                </div>
                <form style={{ padding: "0 40px", marginTop: "30px" }} className="model-field" >
                  <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                    <div style={{ width: "100%" }}>
                      <label htmlFor="DegreeName">Degree<span className="asterisk">*</span></label>
                      <small style={{ color: "#ec1c24" }}>{errorForm.DegreeName}</small>
                      <Selectant
                        className={errorForm.DegreeName != "" ? "errorborder" : ""}
                        name="DegreeName"
                        options={DegreeMaster}
                        styles={{
                          container: (provided) => ({
                            ...provided,
                            width: '100%',
                          }),
                        }}
                        onChange={handleDegreeSelect}
                        value={{ value: newEducation.DegreeName, label: newEducation.DegreeName }}
                      />
                    </div>

                    <div>
                      <label htmlFor="Univercity">University<span className="asterisk">*</span></label>
                      <small style={{ color: "#ec1c24" }}>{errorForm.Univercity}</small>
                      <input className={errorForm.Univercity != "" ? "errorborder" : ""} type="text" name="Univercity" style={{ marginInlineEnd: "12%" }}
                        placeholder="Enter university/college name" value={newEducation.Univercity}
                        onChange={(e) => {
                          const inputValue = e.target.value.slice(0, 45);
                          if (!inputValue.trim()) {
                            return setnewEducation({ ...newEducation, Univercity: "" });
                          }
                          setnewEducation({ ...newEducation, Univercity: inputValue });
                        }} />
                    </div>
                  </div>


                  <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>

                    <div>
                      <label htmlFor="Year">Completion Years<span className="asterisk">*</span></label>
                      <small style={{ color: "#ec1c24" }}>{errorForm.Year}</small>
                      <input className={errorForm.Year != "" ? "errorborder" : ""}
                        type="month"
                        name="completionYear"
                        min="1960-01"
                        max="2024-12"
                        value={newEducation.Year}
                        onChange={(e) => {
                          setnewEducation({ ...newEducation, Year: e.target.value });
                        }}
                      />
                    </div>

                    <div >
                      <label htmlFor="file" style={{ display: "block", marginBottom: "0" }}>Upload certificate<span className="asterisk">*</span></label>
                      <span style={{ fontSize: "12px", color: "#9b9b9b" }}>maximum size 500 KB*</span>
                      <small style={{ color: "#ec1c24" }}>{errorForm.file}</small>
                      <input
                        type="file"
                        name="File_Upload"
                        id="certificatefile"
                        className={errorForm.file !== "" ? "errorborder" : ""}
                        onChange={(e) => {
                          const selectedFile = e.target.files[0];
                          const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
                          if (selectedFile && selectedFile.size / 1024 <= 500) {
                            if (allowedTypes.includes(selectedFile.type)) {
                              setPreform(selectedFile);
                            } else {
                              if (selectedFile.size / 1024 > 500) {
                                message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                              }
                              e.target.value = '';
                              setPreform(null)
                              return message.warning('Please select a valid image (jpg, jpeg, png) or PDF file.');
                            }
                          }
                          else {
                            e.target.value = '';
                            setPreform(null);
                            message.warning('File size exceeds 500 KB. Please upload a smaller file.');
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                    <input type="reset" className="btn" value={'Add Education'} style={{ padding: "10px 5px", backgroundColor: "#e3e3e3", color: "#000", width: "20%" }} onClick={(e) => handlesubmitEducationModel(e, "Add")} />
                    <input type="submit" className="btn" value={'Submit'} style={{ width: "20%" }} onClick={(e) => handlesubmitEducationModel(e, "Submit")} />
                  </div>
                </form>
                <div style={{ maxHeight: '200px', overflowY: "scroll" }}>
                  {formList?.map((value, index) => (
                    <div key={index} style={{ padding: "0 40px", marginTop: "30px", marginBottom: "30px", display: 'flex', gap: '10px' }}>
                      <span>
                        <strong>DegreeName</strong>:
                        {value.DegreeName}
                      </span>
                      {/* <br /> */}
                      <span>
                        <strong>Univercity</strong>:
                        {value.Univercity}
                      </span>
                      {/* <br /> */}
                      <span>
                        <strong>Year</strong>:
                        {value.Year}
                      </span>
                      {/* <br /> */}
                      <span>
                        <strong>File</strong>:
                        {filedata[index].name}
                      </span>
                      {/* <div style={{ display: 'inline-block', width: '100%' }}>
                        <hr />
                      </div> */}
                    </div>


                  ))}
                </div>

              </div>

            </div>

            {/* this model is for update education */}

            {showEdit ?
              <div id="myModal" className='model-open'>
                <div className="modal-content" style={{ width: "60%", padding: 0 }} >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                    <span style={{ color: "#46c294", fontWeight: "600" }}>Update Education</span>
                    <span className="close" onClick={CloseModel}>&times;</span>
                  </div>
                  <form style={{ padding: "0 40px", marginTop: "30px" }} className="model-field" onSubmit={handleEditsubEducationModel}>
                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                      <div>
                        {/* <label htmlFor="DegreeName">Degree<span className="asterisk">*</span></label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.DegreeName}</small>
                        <input type="text" name="DegreeName"
                          placeholder="Enter your Degree Name"
                          value={newEducation.DegreeName}
                          onChange={(e) => {
                            const inputValue = e.target.value.slice(0, 45);
                            if (!inputValue.trim()) {
                              return setnewEducation({ ...newEducation, DegreeName: "" });
                            }
                            setnewEducation({ ...newEducation, DegreeName: inputValue });
                          }} />
                           
                           */}

                        <label htmlFor="DegreeName">Degree<span className="asterisk">*</span></label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.DegreeName}</small>
                        <Selectant
                          className={errorForm.DegreeName != "" ? "errorborder" : ""}
                          name="DegreeName"
                          options={DegreeMaster}
                          onChange={handleDegreeSelect}
                          value={{ value: newEducation.DegreeName, label: newEducation.DegreeName }}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              width: '100%',
                            }),
                          }}
                        />
                      </div>
                      <div>
                        <label htmlFor="Univercity">University<span className="asterisk">*</span></label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Univercity}</small>
                        <input type="text" name="Univercity" style={{ marginInlineEnd: "12%" }}
                          value={newEducation.Univercity}
                          placeholder="Enter university/college name"
                          onChange={(e) => {
                            const inputValue = e.target.value.slice(0, 45);
                            if (!inputValue.trim()) {
                              return setnewEducation({ ...newEducation, Univercity: "" });
                            }
                            setnewEducation({ ...newEducation, Univercity: inputValue });
                          }} />
                      </div>

                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>

                      <div>
                        <label htmlFor="Year">Completion Year<span className="asterisk">*</span></label>
                        <small style={{ color: "#ec1c24" }}>{errorForm.Year}</small>
                        <input className={errorForm.Year != "" ? "errorborder" : ""} type="month"
                          name="Year"
                          min="1960"
                          max="2024"
                          placeholder="Enter Education Completion Year" value={newEducation.Year}
                          onChange={(e) => {
                            setnewEducation({ ...newEducation, Year: e.target.value });
                          }} />

                      </div>

                      <div>
                        <label htmlFor="file" style={{ display: "block", marginBottom: "0" }}>Upload New certificate<span className="asterisk">*</span></label>
                        <span style={{ fontSize: "12px", color: "#9b9b9b" }}>maximum size 500 KB*</span>
                        <small style={{ color: "#ec1c24" }}>{errorForm.file}</small>
                        <input
                          type="file"
                          name="File_Upload"
                          id="certificatefile"
                          className={errorForm.file !== "" ? "errorborder" : ""}
                          onChange={(e) => {
                            const selectedFile = e.target.files[0];
                            const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];
                            if (selectedFile && selectedFile.size / 1024 <= 500) {
                              if (allowedTypes.includes(selectedFile.type)) {
                                setPreform(selectedFile);
                                setImgURL(URL.createObjectURL(selectedFile))
                              } else {
                                if (selectedFile.size / 1024 > 500) {
                                  message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                }
                                e.target.value = ''; // Reset the input value
                                setPreform(null)
                                return message.warning('Please select a valid image (jpg, jpeg, png) or PDF file.');
                              }
                            }
                            else {
                              e.target.value = '';
                              setPreform(null)
                              message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                            }
                          }}
                        />
                      </div>
                    </div>


                    <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                      {imgURL ?
                        preform?.type == "application/pdf" ?
                          <div>
                            <embed src={imgURL} width="50%" height="200px" type="application/pdf" />
                          </div>
                          :
                          <img src={imgURL} width="50%" style={{ borderRadius: '10px' }} />
                        :
                        newEducation.Education_File?.substring(newEducation.Education_File?.lastIndexOf(".") + 1) === "pdf" ?
                          <embed src={`${endPointUrl}/education/${newEducation.Education_File}`} width="50%" height="200px" type="application/pdf" />
                          :
                          <img src={`${endPointUrl}/education/${newEducation.Education_File}`} width="50%" style={{ borderRadius: '10px' }} />
                      }
                    </div>

                    <div style={{ display: "flex", alignItems: "center", gap: "15px", marginTop: '2%' }}>
                      {/* <input type="submit" className="btn" value={'Add Education'} style={{ padding: "10px 5px", backgroundColor: "#e3e3e3", color: "#000", width: "20%" }} /> */}
                      <input type="submit" className="btn" value={'Update'} style={{ width: "20%" }} />
                    </div>
                  </form>
                </div>
              </div>
              : null}
          </div>
          <WorkExp UserID={user.id} getExpProfile={getExp} />
          <Certificate getCertificateProfile={getCertificate} />
          <AddPortfolio getPortfolioProfile={getPortfolio} />
        </div>


        <div className="skills-tech-container">
          <div className="rate-card">
            <Userprofileupdate userDetail={user} />
            <div className="profile-complete" style={{ borderRadius: "10px", marginBottom: "20px", background: "#0cfca415" }}>
              <span style={labelStyles}><span style={{ fontWeight: "600", color: "black", marginRight: "3px" }}>{counter}% </span> Profile complete</span>
              <div className="progress">
                <ProgressBar bgColor={bgColor} completed={counter} />
              </div>

            </div>
            <div className="price-range">
              <img src={dolar} alt="" />
              <div className="hour-price">
                <span className="rate">Rate:</span>
                <span>${user?.HourlyRate} / hour</span>
                {/* <span>
                  <div style={{ fontSize: '14px', lineHeight: '20px', color: 'gray' }}>${user?.HourlyRate}/hour</div>
                  <div style={{ fontSize: '14px', lineHeight: '20px', color: 'gray' }}>${user?.WeeklyRate}/Week</div>
                  <div style={{ fontSize: '14px', lineHeight: '20px', color: 'gray' }}>${user?.MonthlyRate}/Month</div>
                </span> */}
              </div>

            </div>
            <div className="user-details">
              <p>Location</p>
              <span>{user?.City} {user?.City ? "," : null} {user?.Country}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Verification Status</p>
              <span>{(user?.Verified_Profile == "Verified") ? "Verified" : "Pending"}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Language</p>
              {getLanguage()}
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Gender</p>
              <span>{user?.Gender}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Email</p>
              <span>{user?.Email}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Age</p>
              <span>{user?.Age}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Phone Number</p>
              <span>{user?.Mobile}</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Weekly Rate</p>
              <span>${user?.WeeklyRate}/Week</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="user-details">
              <p>Monthly Rate</p>
              <span>${user?.MonthlyRate}/Month</span>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />

            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
            <div className="skills-card" style={{ margin: "10px 0px" }}>
              <h5>Time Zone</h5>
              <div className="user-details">
                <p>Zone</p>
                <span>{user?.Time_Zone}</span>
              </div>
            </div>

            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
            <div className="skills-card" style={{ margin: "10px 0px" }}>
              <h5>Availability</h5>
              <div className="user-details">
                <p>Weekly Hours</p>
                <span>{user?.Weekly_Availability}</span>
              </div>
              <div className="user-details">
                <p>Monthly Hours </p>
                <span>{user?.Monthly_Availability}</span>
              </div>
              <div className="user-details">
                <p>Contractual Position / Projects</p>
                <span>{user?.Contractual === "true" ? "YES" : "NO"}</span>
              </div>
            </div>

            <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
            <div className="skills-card" style={{ margin: "10px 0px" }}>
              <h5>Bank Details</h5>
              <div className="user-details">
                <p>Bank_Name</p>
                <span>{user?.Bank_Name}</span>
              </div>
              <div className="user-details">
                <p>Account_Number</p>
                <span>{user?.Account_Number}</span>
              </div>
              <div className="user-details">
                <p>Account_Name</p>
                <span>{user?.Account_Name}</span>
              </div>
              <div className="user-details">
                <p>IFSC_CODE</p>
                <span>{user?.IFSC_CODE}</span>
              </div>
              <div className="user-details">
                <p>SWIFT_CODE</p>
                <span>{user?.SWIFT_CODE}</span>
              </div>
              <div className="user-details">
                <p>BRANCH_DETAILS</p>
                <span>{user?.BRANCH_DETAILS}</span>
              </div>
              <div className="user-details">
                <p>BANK_COUNTRY</p>
                <span>{user?.BANK_COUNTRY}</span>
              </div>
            </div>
            <hr style={{ borderTop: '1px solid #E4E4E4' }} />
            <div className="skills-card" style={{ margin: "10px 0px" }}>
              <h5>Skills</h5>
              <div className="skills-employee " >
                {skills.map((val) => (
                  <strong style={{ color: "#000000", borderRadius: "15px", border: "1px solid #c6bbbb87", padding: "5px 10px" }} key={val}>{val}</strong>
                ))}
              </div>
            </div>
          </div>


          <div className="rate-card">
            {takenTest.length > 0 &&
              <div style={{ fontSize: '20px' }}>
                <div><strong style={{ padding: 0 }}>Digilanxer Certified</strong></div>
                <hr style={{ borderTop: '1px solid #f1eded' }}></hr>
              </div>
            }

            {takenTest.length > 0 && takenTest?.map((item) => {
              {
                return (
                  item.Result == "Pass" ?

                    <div className="skills-card" style={{ margin: "10px 0px", display: 'flex' }}>
                      <h5><img src={certified} style={{ color: "#07b273", width: "50px", }} />
                      </h5>
                      <div className="skills-employee " style={{ fontWeight: 'bold', marginLeft: "5px" }}>
                        Digilanxer Certified {item.userexam.SKT_Title} Expert
                      </div>
                    </div> : null)
              }
            })}
          </div>
        </div>
      </section >
    </div >
  );
}

export default Logeduser;
