import React, { useContext, useState } from "react";
import { MyContext } from "../Mycontext";
import Profile from "../images/profile.jpg";
import Arrow from "../images/Arrow.png";
import { useNavigate } from "react-router-dom";
import unlike from "../images/unlike.png";
import like from "../images/like.png";
import { MdVerified } from 'react-icons/md';
import { Popup } from "semantic-ui-react";
import { AiFillStar } from 'react-icons/ai';
import img from '../images/veryfied.png'
import { Avatar } from "@mui/material";

function ProfilesCard({ item }) {
  const [Like, setlike] = useState(false);
  const { endPointUrl } = useContext(MyContext);
  let Skills = item?.Skills ? item.Skills.split(",") : [];
  const name = item.Name.split(" ")[0];
  const id = item.id;
  const Navigate = useNavigate();
  return (
    <div className="freelancers-card">
      <div className="imglike" style={{ width: "94%", display: "flex" }}>
        <img src={img} alt="" style={{ filter: item.Verified_Profile == "Verified" ? "none" : "grayscale(1)" }} />
      </div>
      {item.Photo ? <img
        src={`${endPointUrl + "/images/" + item.Photo}`}
        alt="profile"
      /> :
        <Avatar sx={{width:'90px',height:'90px'}}></Avatar>}
      <span className="name">{item.ScreenName || item.Name}</span>
      <span className="rating"
        onClick={() => Navigate(`/user-profile/reviews/${item.Name + "-" + item.id}`)}
      >
        <AiFillStar className="featuredfreelancer-rating-star" />
        {item.avg_Rating ? item.avg_Rating : 0} <p>({item.no_of_reviews ? item.no_of_reviews : 0} Reviews)</p></span>
      <div className="rate">
        <span>Rate:</span>
        ${item?.HourlyRate} / hour
      </div>

      <hr className="line" />
      <div className="location-content btn-popular skills">
        {/* <div className="btn-popular skills"> */}
        {Skills.length < 1 ? <button className="skill">.... ....</button> : null}
        {Skills.map((item, index) => {
          if (index < 3) {
            return (
              <button key={index} className="skill">
                {item}
              </button>
            );
          }
        })}
        {Skills.length > 3 ? "+" : ""}
        {Skills.length > 3 ? Skills.length - 3 : ""}

      </div>
      <hr className="line" />
      <div className="view-profile" onClick={() => Navigate(`/user-profile/${name + "-" + id}`)}>
        View Profile
        <img
          src={Arrow}
          alt=""
          onClick={() => Navigate(`/user-profile/${name + "-" + id}`)}
        />
      </div>
    </div>
  );
}

export default ProfilesCard;
