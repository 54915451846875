import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../../Mycontext";
import axios from "axios";
import Invoices from "../invoince/Invoices";
import { useNavigate } from "react-router-dom";
import EarningDetail from "../../EarningDetail";

function TotalEarnings() {
  const { endPointUrl, user, Token, earningType, setEarningType } = useContext(MyContext);
  const [Sum, setSum] = useState({
    fundsInEscrowSum: 0,
    totalEarningsSum: 0
  });
  const [invoiceShow, setInvoiceShow] = useState(false)
  const [Project, setProject] = useState({
    FundsInEscrow: [],
    TotalEarning: []
  });
  const [Projecter, setProjecter] = useState([]);
  const [PaymentActive, setPaymentActive] = useState(0);
  const Navigate = useNavigate()

  const getemployerfinance = async () => {
    const { data } = await axios.post(endPointUrl + '/admin/getfreelancerfinance', {
      Token,
      Awarded_To_ID: user.id
    })
    let escrow = data.data[0].freelancers.filter((item) => {
      return item.FundsInEscrow > 0;
    })
    let total = data.data[0].freelancers.filter((item) => {
      return item.TotalEarning > 0;
    })
    setProjecter(total);
    setProject({ FundsInEscrow: escrow, TotalEarning: total })
    setSum({ fundsInEscrowSum: data.data[0].fundsInEscrowSum, totalEarningsSum: data.data[0].totalEarningsSum });
  };
  const handlePaymentClick = (buttonIndex) => {
    setPaymentActive(buttonIndex);
  };
  useEffect(() => {
    getemployerfinance();
    setEarningType("Credited")
  }, [])
  return (
    <div style={{ display: "grid" }}>
      <div className="jobMatch-inside">

        <span
          style={
            PaymentActive === 0
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setProjecter(Project.TotalEarning)
            handlePaymentClick(0);
            setEarningType('Credited')
          }}
        >
          Credited
        </span>
        <p>|</p>
        <span
          style={
            PaymentActive === 1
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(false)
            setProjecter(Project.FundsInEscrow)
            handlePaymentClick(1);
            setEarningType("InProcess")
          }}
        >
          In-Progress
        </span>
        <p>|</p>
        <span
          style={
            PaymentActive === 2
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(false)
            setProjecter(Project.FundsInEscrow)
            handlePaymentClick(2);
            setEarningType("InReview")
          }}
        >
          In-Review
        </span>
        <p>|</p>
        <span
          style={
            PaymentActive === 3
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(false)
            setProjecter(Project.FundsInEscrow)
            handlePaymentClick(3);
            setEarningType("Pending")
          }}
        >
          Pending Payment
        </span>
        <p>|</p>
        <span
          style={
            PaymentActive === 4
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(true)
            handlePaymentClick(4);
            setEarningType("Invoice")
          }}
        >
          Invoices
        </span>

      </div>

      <div>
        {invoiceShow ?
          <div>
            <Invoices />
          </div>
          : <div>
            {Projecter.map((item, index) => <>
              <div className="current-jobs payment-container" key={index}>
                <div >
                  <span
                    className="job-name"
                    onClick={() => {
                      earningType === "Pending" ? '' : Navigate(`/earning-detail/${item.jobs.JobID}`)
                    }}
                  >
                    {item.jobs.Title}
                  </span>
                  <p dangerouslySetInnerHTML={{ __html: item?.jobs?.Description?.slice(0, 300) + '...' }}></p>
                  {/* <p>{item.jobs.ShortDesc}</p> */}
                  <div className="current-tags">
                    {item?.jobs?.SkillsRequired?.split(",").map((val) =>
                      val.length > 0 ? <span>{val}</span> : null
                    )}
                  </div>

                </div>
                {/* <hr /> */}
                <div className="payment-jobs-amount">
                  {PaymentActive === 0 ? <span ><p>${(item.TotalEarning).toFixed(2)}</p>  ( Earnings ) </span>
                    : <span ><p>${item.FundsInEscrow}</p>  ( Funds in Escrow ) </span>}

                </div>
              </div>

              <div>
                {earningType === "Pending" ? <EarningDetail JobID={item.jobs.JobID} /> : <></>}
              </div>

              <hr /> </>)}
          </div>}
      </div>
    </div>

  )
}

export default TotalEarnings