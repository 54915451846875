
import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { MyContext } from '../Mycontext';
import img1 from '../images/business-people-1166576_1280.jpg'
import img2 from '../images/social-3408791_1280.jpg'
import img3 from '../images/smileys-5776137_1280.jpg'
import Arrow from '../images/right-arrows.png'
import { useNavigate } from 'react-router-dom'
const TShirtDesignCard = () => {
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [Guide, setGuide] = useState([]);
  const getGuides = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/guidedatafind", {
        Token,
      });

      // Randomly select 3 items from data.data
      const shuffledGuides = data.data.sort(() => 0.5 - Math.random());
      const selectedGuides = shuffledGuides.slice(0, 3);

      setGuide(selectedGuides);
      // console.log(selectedGuides);
    } catch (error) {
      console.error("Error fetching guide data:", error);
    }
  };
  useEffect(() => {
    getGuides();
  }, [])
  const Navigate = useNavigate();
  return (<>

    {Guide.map((item, index) =>
      <div key={index} className="card1">
        <img
          style={{ cursor: "pointer" }}
          src={`${endPointUrl}/images/${item.Guide_Image}`}
          alt="Digi_pic"
          onClick={() => Navigate(`/digi-guide/${item.GID}`)}
        />
        <div className="card-content">
          <div className="tags-and-time">
            <div className="design">DESIGN</div>
            <div className="time">July 15, 2023</div>
          </div>
          <div className="blog-head" style={{ height: "80px" }}>{item?.Guide_Title}</div>
          <div className="blog-content">
            {item?.Guide_ShortDec.length <= 500 ? item?.Guide_ShortDec : `${item?.Guide_ShortDec.slice(0, 500)}...`}
          </div>
        </div>
        <div className="right-arrow">
          <a style={{ marginBlock: "20px" }}>
            <img
              src={Arrow}
              style={{ cursor: "pointer" }}
              alt="Digi_arrow"
              onClick={() => Navigate(`/digi-guide/${item.GID}`)}
            />
          </a>
        </div>
      </div>
    )}
  </>
  );
};

export default TShirtDesignCard;
