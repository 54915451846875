import React from "react";
import tel from '../images/tel.png'
import { BsTelephoneFill } from "react-icons/bs";
import img1 from '../images/Icons/active-freelancer.png'
import img2 from '../images/Icons/employer.png'
import img3 from '../images/Icons/job-posted.png'
import img4 from '../images/Icons/hired.png'
function ShowNumbers() {
  return (
    <div className="show-number">

      <div className="box">
        <div>
          <img src={img1} alt="" />
        </div>
        <div className="count-content">
          <span className="number">20,000</span>
          <span className="number-head">Active Freelancers</span>
        </div>
      </div>
      <hr />
      <div className="box">
        <div>
          <img src={img2} alt="" />
        </div>
        <div className="count-content">
          <span className="number">1,000</span>
          <span className="number-head">Employers Registered</span>
        </div>
      </div>
      <hr />
      <div className="box">
        <div>
          <img src={img3} alt="" style={{marginTop:"10px"}}/>
        </div>
        <div className="count-content">
          <span className="number">15,000</span>
          <span className="number-head">Jobs Posted Everyday</span>
        </div>
      </div>
      <hr />
      <div className="box">
        <div>
          <img src={img4} alt="" />
        </div>
        <div className="count-content">
          <span className="number">5,000</span>
          <span className="number-head">Hires Happen Each Day</span>
        </div>
      </div>

      {/* <div className="box">
        <div className="count-content">
          <span className="number">10 Million</span>
          <span className="number-head">Worth of Jobs Closed Till Now</span>
        </div>
      </div> */}

    </div>
  );
}

export default ShowNumbers;
