import React from 'react'
import { Link } from 'react-router-dom'

export default function CopyrightPolicy() {
  return (
      <div className='HowItWorks privacy-footer'>
          <div className="home-howitwork">
              <Link to="/">Home </Link>/{" "}
              <Link to="/copy-right-policy">Copyright Policy</Link>
          </div>
          <span className="jobpostheading" style={{ backgroundColor: "#ceefe4", paddingRight: "0 40px 0 40px" }}>
              <div>
                  Copyright Policy
                  {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur, perspiciatis.</p> */}
              </div>
          </span>
          <div className='footer-terms'>
              <div class="footer-terms-heading">
                  Copyright law is more important than ever for creators and content consumers alike. Whether you're a photographer, writer, musician, or simply someone who enjoys consuming media, knowing your rights and responsibilities when it comes to copyright is crucial. In this post, we'll cover the basics of copyright policy, including what copyright is, how it works, and why it matters. We'll also delve into the practical implications of copyright law for different types of content creators, as well as explore the impact of technological advancements on copyright policy. 
              </div>
              <section>
                  <div className='footer-terms-main'>
                      <h3>
                          Infringement Policy at Digilanxer
                      </h3>
                      <span>
                          At Digilanxer, we take copyright infringement very seriously and have implemented a comprehensive Infringement Policy to protect the rights of content creators. Our policy strictly prohibits the unauthorized use, reproduction, or distribution of copyrighted material, including text, images, audio, and video content. We are committed to upholding the rights of copyright owners and have put in place efficient measures to promptly address any reported cases of infringement.
                      </span>
                  </div>
                  <div className='footer-terms-main'>
                      <h3>
                          Enforcement of Copyright Policy
                      </h3>
                      <span>
                          To ensure compliance with our Copyright Policy, Digilanxer has established a dedicated team responsible for monitoring and addressing copyright infringement issues. Upon receiving a valid infringement claim, we conduct a thorough 
                          investigation to verify the alleged infringement and take appropriate action, which may include the removal of the infringing content and, if necessary, the suspension or termination of the user account. Our enforcement process is designed to be fair and efficient, with the goal of safeguarding the integrity of copyrighted works while respecting the rights of all parties involved.
                      </span>
                  </div>
                  
              </section>
          </div>
      </div>
  )
}
