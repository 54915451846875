import React, { useState, useContext } from 'react'
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { MyContext } from "../Mycontext";
import axios from "axios";
import { message } from "antd";
import { Collapse } from "antd";
const { Panel } = Collapse;
import { IoArrowBackSharp } from 'react-icons/io5';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { CLIENT_ID } from '../Config/Config';


function EarningDetail({ JobID }) {
    const { id } = useParams();
    const [detailjob, setdetailjob] = useState();
    const { user, endPointUrl, Token, earningType } = useContext(MyContext);
    const [milestones, setMilestones] = useState([])
    const [BidID, setBidID] = useState()
    const [payment, setPayment] = useState([])
    const [projectStatus, setProjectStatus] = useState('')
    const [paymentDate, setPaymentDate] = useState('')
    const [dateIndex, setDateIndex] = useState('')
    const [pendingMilestones, setPendingMilestones] = useState('')
    const [showPayPal, setShowPayPal] = useState(false)
    const [bidData, setBidData] = useState({});

    const getPaymentByBidID = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
                Token,
                UserID: user.id,
                JobID: id ? id : JobID,
            });
            setBidID(data.data.Bid_ID)
            setBidData(data.data);

            const result = await axios.post(endPointUrl + "/user/getPaymentsByBidID", {
                Token,
                BidID: data.data.Bid_ID,
            });
            setPayment(result.data.data)

            const response = await axios.post(endPointUrl + "/getProjectsByJobID", {
                Token,
                JobID: id ? id : JobID,
            });

            setProjectStatus(response.data.data.Project_status)

        } catch (error) {
            console.log("Error While Get Payments By Bid ID", error)
        }
    }
    const getMileStoneForBid = async (JobID) => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/job/bidding", {
                Token,
                UserID: user.id,
                JobID: JobID,
            });
            if (data.status === true && data.data != null) {
                const { Bid_ID } = data.data;

                const result = await axios.post(
                    endPointUrl + "/project/getMilestoneforBid",
                    { Bid_ID, Token }
                );

                setMilestones(result.data.milestones)
            }
        } catch (error) {
            console.log("error while get milestones for timesheet", error)
        }
    }

    useEffect(() => {
        const getjobs = async () => {
            try {
                const { data } = await axios.post(endPointUrl + "/user/getJobsByJobId", {
                    JobID: id ? id : JobID,
                    Token,
                });
                setdetailjob(data.data);
            } catch (err) {
                console.log(err);
            }
        };
        window.scrollTo(0, 0)
        getjobs()
        getPaymentByBidID()
        getMileStoneForBid(id ? id : JobID)
    }, [])


    const handleDate = (item) => {
        let createdAt = new Date(item.Payment_date);
        let day = createdAt.getUTCDate().toString().padStart(2, '0'); // Get day (padded with leading zero if needed)
        let month = (createdAt.getUTCMonth() + 1).toString().padStart(2, '0'); // Get month (January is 0)
        let year = createdAt.getUTCFullYear().toString().slice(-2); // Get last two digits of the year
        let DATE = `${day}-${month}-${year}`;

        return (
            <div>{DATE}</div>
        )
    }

    const handleTime = (item) => {
        let createdAt = new Date(item.Payment_date);
        let hours = createdAt.getHours();
        let minutes = createdAt.getMinutes();
        let seconds = createdAt.getSeconds();
        let TIME = hours.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0') + ":" + seconds.toString().padStart(2, '0');

        return (
            <div>{TIME}</div>
        )
    }

    const getDate = () => {
        if (!paymentDate) {
            return (
                <></>
            )
        }
        else {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;

            if (paymentDate === "Month") {
                return (`${new Date(year, month, 0).getDate()}/${month}/${year}`);
            }
            else if (paymentDate === "Week") {
                const dayOfWeek = date.getDay();
                const diffToLastDayOfWeek = 6 - dayOfWeek;
                const lastDateOfWeek = new Date(date);
                lastDateOfWeek.setDate(date.getDate() + diffToLastDayOfWeek);
                return (`${lastDateOfWeek.getDate()}/${month}/${year}`);
            }
            else {
                const day = date.getDate();
                const currentDate = `${day}/${month}/${year}`;
                return currentDate
            }
        }
    }

    function showMilestones() {
        return payment.map((item, i) => {
            return (
                <>
                    {earningType === "InReview" ?
                        milestones[i]?.MilstoneStatus === "Up for Review" ?
                            <div>
                                <div className="bid_Form milestone_Form upper">
                                    <label>
                                        {`Milestones #${i + 1}`}
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">Amount Requested:</span>
                                        <span>${item.Amount}</span>
                                    </label>
                                </div>
                                <hr />
                            </div> : <></>
                        :
                        <div>
                            {bidData.Duration != 0 ?
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <label>
                                        {`Milestones #${i + 1}`}
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">Total Amount :</span>
                                        <span>${item.Amount}</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">Platform Charges :</span>
                                        <span>10%</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">Amount Paid:</span>
                                        <span>{item.Amount - (item.Amount * 10) / 100}</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">{`${earningType === "InProcess" ? "Date of Escrow" : "Date of Credit :"}`}</span>
                                        <span>{handleDate(item)}</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">{`${earningType === "InProcess" ? "Time of Escrow" : "Time of Credit :"}`}</span>
                                        <span>{handleTime(item)}</span>
                                    </label>
                                </div>
                                :
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <label>
                                        {`Payment`}
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">Total Amount :</span>
                                        <span>${item.Amount}</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">Platform Charges :</span>
                                        <span>10%</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">Amount Paid:</span>
                                        <span>{item.Amount - (item.Amount * 10) / 100}</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">{`${earningType === "InProcess" ? "Date of Escrow" : "Date of Credit :"}`}</span>
                                        <span>{handleDate(item)}</span>
                                    </label>
                                    <label style={{ display: "flex" }}>
                                        <span id="highlighted">{`${earningType === "InProcess" ? "Time of Escrow" : "Time of Credit :"}`}</span>
                                        <span>{handleTime(item)}</span>
                                    </label>
                                </div>}
                            <hr />
                        </div>}
                </>
            )
        })
    }


    function showPendingMilestone() {
        return milestones.map((item, i) => {
            return (
                <div style={{ padding: '10px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ width: '33%' }}>
                            {`Milestones #${i + 1}`}
                        </div>

                        <div style={{ width: '33%', textAlign: 'center' }}>
                            <span id="highlighted">Payment Status :</span>
                            <span>{item.MilstoneStatus === "Up for Review" ? "Release" : item.MilstoneStatus === "Approved" ? "Credit" : "Pending"}</span>
                        </div>

                        <div style={{ width: '33%', textAlign: 'center' }}>
                            <span id="highlighted">Amount :</span>
                            <span>${item.Amount}</span>
                        </div>
                    </div>

                    <div style={{ display: 'flex', marginTop: '2%' }}>
                        <div style={{ width: '33%' }} className='paymentMode'>
                            <select
                                onChange={(e) => {
                                    setPaymentDate(e.target.value);
                                    setDateIndex(i);
                                }}
                            >
                                <option value="">Select Date of Payment</option>
                                <option value="Month">Monthly</option>
                                <option value="Week">Weekly</option>
                                <option value="credit">Credit Now</option>
                            </select>
                        </div>

                        <div style={{ width: '33%', textAlign: 'center' }}>
                            {paymentDate && i === dateIndex ? <span> Date :{getDate()}</span> : <></>}
                        </div>

                        <div style={{ width: '33%', textAlign: 'center' }}>
                            {paymentDate === "credit" && i === dateIndex && !showPayPal ? <button className='search' style={{ fontWeight: 'bold', padding: '10px' }} onClick={() => { setShowPayPal(!showPayPal) }}>Transer Now</button> : <></>}

                            {paymentDate === "credit" && i === dateIndex && showPayPal ? <PayPalScriptProvider options={{ "client-id": CLIENT_ID }}>
                                <PayPalButtons />
                            </PayPalScriptProvider>
                                : <></>}
                        </div>
                    </div>
                    {milestones.length === i + 1 ? <></> : <hr />}
                </div>
            )
        })

    }

    return (
        <div className="jobProfileContainer" style={{ display: 'block', padding: earningType === "Pending" ? '0px' : "50px" }}>
            {id ? <div>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header={
                        <div >
                            <IoArrowBackSharp style={{
                                backgroundColor: "white",
                                borderRadius: "50px",
                                stroke: "#073b",
                                padding: "2px",
                                margin: " -8px 10px -8px 0"
                            }}
                                onClick={() => {
                                    window.history.back({ activej: 1, onactivej: 0 });

                                }}
                            />
                            <span>
                                {detailjob?.Title}
                            </span>
                        </div>
                    }
                        key="1" showArrow={false} style={{ fontFamily: "poppins" }}>
                        <div
                            style={{ color: "#606563", wordBreak: 'break-word' }}
                            dangerouslySetInnerHTML={{ __html: detailjob?.Description }}
                        ></div>
                    </Panel>
                </Collapse >
            </div> : <></>}

            {id ? <div style={{ fontSize: '18px', marginTop: '2%' }}>
                Project Status: {`${projectStatus}`}
            </div> : <></>}

            {id ? <div style={{ width: '100%', marginTop: '2%' }}>
                <div>{showMilestones()}</div>
            </div> : <></>}

            {id ?
                <></>
                :
                <div>
                    {showPendingMilestone()}
                </div>
            }
        </div>
    )
}

export default EarningDetail;