import React, { useContext, useEffect } from 'react';
import { MyContext } from "../../Mycontext";
import axios from "axios";

const LinkedInCallback = () => {
    const { endPointUrl, Token } = useContext(MyContext);
    useEffect(async () => {
        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');

        if (code) {
            const { data } = await axios.post(`${endPointUrl}/auth/linkedin`, {
                code: code
            })

            if (data.status) {
                console.log('data.data linkedin', data)
            } else {
                console.log('error linkedin')
            }
        }
    }, []);

    return (
        <div style={{ width: "90vw", height: "80vh", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            Processing LinkedIn login...
        </div>);
};

export default LinkedInCallback;