import React, { useContext, useState } from 'react'
import group from '../images/Logo.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../Mycontext';
import { message } from 'antd';
import { FaFacebookSquare, FaTwitterSquare, FaLinkedin, FaInstagramSquare, FaYoutubeSquare } from 'react-icons/fa'
const CategoryFooter = () => {
  const { user, endPointUrl, Token } = useContext(MyContext);
  const [email, setEmail] = useState("");

  const validateEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  const handleClick = async () => {
    if (validateEmail(email)) {
      try {
        await axios.post(endPointUrl + '/admin/newsletterdataCreate', {
          Token,
          N_Email: email,
        });
        message.success('Subscribed');
        setEmail('');
      } catch {
        message.error('Failed to subscribe');
      }
    } else {
      message.warning("Input valid Email");
    }
  };
  const handleFunction = (value) => {
    if (value == "post a job") {
      user?.type == "Employer" ? navigate("/job-creation")
        : message.info("Login as an Employer to post a job");
    } else {
      document.documentElement.scrollTop = 0;
      navigate(value)
    }

  };
  const navigate = useNavigate();
  return (
    <div className="footer">
      <div className="all-footer">
        <div className="footer-links">
          <div className="links-1">
            <li>Company Info</li>
            <ul>
              <li onClick={() => handleFunction("/why-digilanxer")}>About Us</li>
              <li onClick={() => handleFunction("/how-it-works")}>How It Works</li>
              <li onClick={() => handleFunction("/how-it-works")}>Careers</li>
              <li onClick={() => handleFunction("/contact-us")}>Contact Us</li>
              <li onClick={() => handleFunction("/reviews")}>Testimonials</li>

            </ul>
          </div>
          <div className="links-2">
            <li>Navigate</li>
            <ul>
              <li onClick={() => handleFunction("/")}>Home</li>
              <li onClick={() => handleFunction("post a job")}>Post a Job</li>
              <li onClick={() => handleFunction("/freelancers")}>Find a Freelancer</li>
              <li onClick={() => handleFunction("/jobs")}>Find a Job</li>
              <li><a style={{ color: "white" }} href="https://digilanxer.com/blog" target='_blank' rel="noopener noreferrer">Blog</a></li>
            </ul>
          </div>
          <div className="links-3">
            <li>Terms</li>
            <ul>
              <li onClick={() => handleFunction("/privacy-policy")}>Privacy Policy</li>
              <li onClick={() => handleFunction("/terms-condition")}>Terms and Conditions</li>
              <li onClick={() => handleFunction("/copy-right-policy")}>Copyright Policy</li>
              <li onClick={() => handleFunction("/escrow-payment-system")}>Fees and Charges</li>
            </ul>
          </div>
        </div>
        <div className="email">
          <div className="email-head">
            <div className="email-heading"><h5>Email Sign Up</h5>
              <p>Subscribe to our newsletter and get exclusive deals you won't find anywhere else straight to your inbox!</p></div>
            <div className="email-input">
              <input
                type="email"
                placeholder="Enter Email Address"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <button style={{ cursor: "pointer" }} onClick={() => handleClick()}>SUBSCRIBE</button>
            </div>
            <div className='follow'>
              <h5>Follow Us</h5>
              <div className='follow-icon' >
                <FaFacebookSquare style={{cursor:'pointer'}} onClick={() => window.open('https://www.facebook.com/digilanxer', '_blank', 'noopener,noreferrer')} />
                <FaTwitterSquare  style={{cursor:'pointer'}}/>
                <FaLinkedin  style={{cursor:'pointer'}} onClick={() => window.open('https://www.linkedin.com/company/digilanxer/', '_blank', 'noopener,noreferrer')}/>
                <FaInstagramSquare  style={{cursor:'pointer'}}/>
                <FaYoutubeSquare  style={{cursor:'pointer'}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div className="copywrite-social-media">
        <img src={group} alt="" />
        <div className="social-media">
          <div className="copy">© Copyrights DigiLanxer 2023 | All Rights Reserved.</div>

        </div>
      </div>
    </div>
  )
};

export default CategoryFooter;
