import React, { useContext, useEffect, useState } from 'react'
import Profile from "../images/emplyeer.png"
import Chat from './Chat'
import { MyContext } from '../Mycontext'
import axios from 'axios'
import { FaArrowLeft } from "react-icons/fa";
import placeholderImg from '../images/layer1.svg'

function Messages({ detailJob, Receiver }) {
  const { user, endPointUrl, Token, onlineUser, Ruser, setRUser, chatUsers, setChatUsers, UnreadUsers, setUnreadUsers, showMessg, setshowMessg, showchat, setShowchat, setJobsId } = useContext(MyContext);
  console.log("showchat",showchat)
  const [chatUser, setChatUser] = useState();

  const [ReceiverID, setReceiverID] = useState(null);

  const [detailjob, setdetailjob] = useState({})

  useEffect(() => {
    getChatUsers();
  }, [Receiver]);

  const getChatUsers = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/chatusers", {
        Token,
        UserID: user.id,
        JobID: detailJob ? detailJob.JobID : null,
        Type: user?.Type,
      })
      // Extract chat data
      const chatData = data.data;
      // Set the count in the parent component (you may need to pass a prop to the parent component)
      const unreadCount = chatData.filter((chat) => !chat?.lastChat?.IsRead && chat?.lastChat?.sender != user.id);
      const unreadusersID = await unreadCount.map((chat) => chat.user.id);
      setUnreadUsers(new Set(unreadusersID));
      setChatUsers(data.data);
    } catch (error) {
      console.log("error of chatusers", error)
    }
  }

  const markasRead = async (senderID) => {
    try {
      await axios.post(endPointUrl + '/admin/UserChatUpdate', {
        Token,
        SenderID: senderID,
        RecieverID: user.id
      })
      // getChatUsers();
    } catch {
      console.log('Error updating Chat');
    }
  }
  const handleclick = () => {
    setshowMessg(!showMessg);
    // setRUser(Receiver)
  };
  const handleChat = async (ReceiverUser, jobId) => {
    try {
      const { data } = await axios.post(endPointUrl + "/user/getJobsByJobId", {
        JobID: jobId,
        Token,
      });
      setdetailjob(data.data);
    } catch (err) {
      console.log(err);
    }
    setChatUsers(prevUsers => {
      const updatedUsers = prevUsers.map(user => {
        if (user.user.id === ReceiverUser?.id) {
          return {
            ...user,
            lastChat: {
              ...user.lastChat,
              IsRead: true
            }
          };
        }
        return user;
      });
      return updatedUsers;
    });
    markasRead(ReceiverUser?.id);
    setReceiverID(ReceiverUser?.id);
    setShowchat(!showchat);
    setRUser(ReceiverUser);
  };

  // function for cheking User Chat Already is active or not
  useEffect(() => {
    // const idNumber = parseInt(ReceiverID, 10);
    if (!isNaN(ReceiverID) && UnreadUsers instanceof Set && UnreadUsers.has(ReceiverID)) {
      const UpdatedData = UnreadUsers?.delete(ReceiverID);
      setUnreadUsers(UpdatedData);
      markasRead(ReceiverID)
    }
  }, [UnreadUsers]);

  useEffect(() => {
    if (Receiver) {
      setReceiverID(Receiver);
      // setShowchat(true);
      // setshowMessg(true);
      // getChatUsers();
    }
  }, [Receiver])


  return (
    <div className='chat-overlay'>
      <div className='msg-overlay-container'>
        <div className={showMessg ? "msg-overlay" : "msg-overlay minimised"} >
          <header className='msg-overlay-header'>
            {
              Ruser == null ? ( // If Ruser is null
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }} onClick={handleclick}>
                  <span>Messages</span>
                  {UnreadUsers?.size > 0 && ( // If UnreadUsers has a size greater than 0
                    <div className="unread-messages-indicator" style={{ width: "5px", height: "5px", padding: 0 }}>
                      {/* This div probably indicates unread messages */}
                    </div>
                  )}
                </div>
              ) : ( // If Ruser is not null
                <span
                  style={{
                    cursor: "pointer",
                    margin: "6px 4px 0 0",
                    display: "flex",
                    alignItems: "center"
                  }}

                >
                  <FaArrowLeft
                    style={{
                      width: "25px",
                      height: "18px",
                      color: "#07b273",
                      padding: "0 5px"
                    }}
                    onClick={() => {
                      setReceiverID(null);
                      setShowchat(false);
                      getChatUsers();
                      setRUser(null)
                      setJobsId('')
                      setdetailjob({})
                    }}
                  />
                  <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <span style={{ display: "flex", alignItems: "center", gap: "5px", position: "relative" }}>
                      <img src={`${endPointUrl}/images/${Ruser.Photo}`} style={{ width: "30px", height: "30px", borderRadius: "50%", marginRight: "5px" }} alt="" />
                      <p style={{ padding: 0, margin: 0 }}>
                        {detailJob ? detailJob?.Title : detailjob?.Title} {"(" + Ruser.Name + " " + Ruser.Last_Name + ")"}
                      </p>
                      {onlineUser.find((onlineU => onlineU?.id == Ruser.id)) ? <p className='Online_dot' style={{ position: "absolute", top: "-10px", right: "-10px" }} ></p> : null}
                    </span>
                  </span>
                </span>
              )
            }
          </header>
          {!showchat ?
            <div className='msg-overlay-profiles'>
              {chatUsers.length > 0 ? (
                chatUsers?.map((item, index) =>
                (<>
                  <div key={index} className='overlay-profiles'
                    style={{ backgroundColor: item?.lastChat?.IsRead === false && item?.lastChat?.sender != user?.id ? "#03b4743d" : "" }}
                    onClick={() => {
                      if (UnreadUsers instanceof Set) {
                        const updatedUnreadUsers = new Set(UnreadUsers); // Create a new Set to modify
                        updatedUnreadUsers.delete(user?.user?.id);
                        setUnreadUsers(updatedUnreadUsers);
                      }
                      // setNewMessageCount(prevCount => prevCount - 1);
                      handleChat(item?.user, item?.lastChat?.JobID)
                    }}>
                    <img
                      src={
                        item?.user?.Photo === "" || item?.user?.Photo == null
                          ? Profile
                          : `${endPointUrl}/images/${item?.user?.Photo}`
                      }
                      alt=""
                    />
                    <div className='overlay-profiles-details'>
                      <span style={{ lineHeight: '1.2' }}>
                        <h4>{item?.user?.Name + " " + item?.user?.Last_Name}</h4>
                        <span style={{ fontSize: '12px' }}>{item?.user?.jobName}</span>
                        {/* <p>{item?.lastChat?.message ? item?.lastChat?.message?.substring(0, 35) : 'picture'}</p> */}
                      </span>

                      {onlineUser.find((onlineU => onlineU?.id == item?.user?.id)) ? <p className='Online_dot' ></p> : null}
                    </div>
                  </div >
                  <hr style={{ marginLeft: "0%", marginBlock: "0em" }} />
                </>)
                )) : (
                <div className="no-chat-users-placeholder">
                  <img src={placeholderImg} alt="Placeholder" />
                  <p>No chat users available</p>
                </div>
              )}
            </div>
            : <div>
              <Chat detailJob={detailjob} Receiver={Ruser} />
            </div>}
        </div>
      </div>
    </div >
  )
}

export default Messages