import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import img1 from "../images/point-1.png";
import img2 from "../images/point-2.png";
import img3 from "../images/point-3.png";
import img4 from "../images/point-4.png";
import { Button } from "@mui/material";

const Certificate = () => {
    const navigate = useNavigate()
    return (
        <div className="HowItWorks">
            <div className="home-howitwork"><Link to='/'>Home </Link>/ <Link to='/freelancers'>Certification</Link></div>
            <div className="Apart" style={{ padding: "0px", margin: '0px' }}>
                <div className="Apart-points" style={{ background: '#07b273', padding: "50px", margin: '0px' }}>
                    <div className="apart-img">
                        <img src={img1} alt="" />
                    </div>

                    <div className="Apart-containet" >
                        <span className="aprt-containet-head" style={{ color: "#fff" }}>
                            Why should I take the certification quiz?
                        </span>
                        <span className="aprt-containet-value" style={{ color: "#fff" }}>
                            Holding a certification can set you apart from others in a competitive job market.
                            Recognizing the Hard Work and Expertise of Our Certified Professionals and Their Achievements.
                        </span>
                        <span className="aprt-containet-value" style={{ color: "#fff" }}>
                            <button
                                className="invite"
                                style={{ border: "1px solid white", marginTop: "3%", fontWeight: 'bold', padding: "12px 30px", width: "auto", background: "white", color: "#07b273" }}
                                onClick={() => navigate("/skill-test")}
                            >
                                Explore
                            </button>
                        </span>
                    </div>
                </div>

                {/* 
                <h2>Certifications validate expertise and proficiency in a particular field</h2>
                <p>
                    Holding a certification can set you apart from others in a competitive job market. Recognizing the Hard Work and Expertise of Our Certified Professionals and Their Achievements
                </p> */}
            </div>

            <div className="Apart-points">
                <div className="Apart-containet">
                    <span className="aprt-containet-head">
                        Our Certified Experts
                    </span>
                    <span className="aprt-containet-value">
                        Celebrating Our Team's Professional Certifications and Industry-Recognized Achievements,
                        Highlighting the Industry Certifications and Accreditations Our Team Has Earned
                    </span>
                </div>
                <div className="apart-img">
                    <img src={img1} alt="" />
                </div>
            </div>
            <div className="Apart-points">
                <div className="apart-img">
                    <img src={img3} alt="" />
                </div>
                <div className="Apart-containet">
                    <span className="aprt-containet-head">
                        Credentials of Distinction
                    </span>
                    <span className="aprt-containet-value">
                        Certifications provide a standardized measure of knowledge and skills, increasing credibility and trust with employers, clients, and peers.                    </span>
                </div>
            </div>
            <div className="Apart-points">
                <div className="Apart-containet">
                    <span className="aprt-containet-head">
                        Recognized Certifications !
                    </span>
                    <span className="aprt-containet-value">
                        Our Commitment to Excellence Through Comprehensive Certification and Accreditation Programs.
                        Recognizing the Hard Work and Expertise of Our Certified Professionals and Their Achievements
                    </span>
                </div>
                <div className="apart-img">
                    <img src={img2} alt="" />
                </div>
            </div>
            <div className="Apart-points">
                <div className="apart-img">
                    <img src={img4} alt="" />
                </div>
                <div className="Apart-containet">
                    <span className="aprt-containet-head">Career Advancement</span>
                    <span className="aprt-containet-value">
                        Certified individuals often have better job prospects, higher earning potential, and more opportunities for career advancement.
                    </span>
                </div>
            </div>
        </div>
    );
}


export default Certificate