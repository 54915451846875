import React, { useContext, useState } from 'react';
import { Modal, Button } from 'antd';
import { MyContext } from '../../Mycontext';
import { Link, useNavigate } from "react-router-dom";
import ChangePassword from './ChangePassword';
import dashboard from '../../images/Menu Icon/dashboard.png';
import profile from '../../images/Menu Icon/profile-icon.png';
import password from '../../images/Menu Icon/passwordicon.png';
import logout from '../../images/Menu Icon/logout.png';
const Sidebar = ({ sidebar, handleSidebar }) => {
    const { user, setuser } = useContext(MyContext)
    const [popUp, setPopUp] = useState(false);
    const Navigate = useNavigate();
    const handleLogout = () => {
        sessionStorage.clear();
        setuser(null);
        handleSidebar(sidebar);
        Navigate('/');
    }
    return (
        <>
            {popUp ?
                <div className='confirmation-popup-box'>
                    <div className="confirmation-popup">
                        <p>Do you really want to logout?</p>
                        <div className='confirmation-popup-button'>
                            <button className='btn' style={{ padding: "6px 15px",width:'30%' }} onClick={handleLogout}>Ok</button>
                            <button className='btn' style={{ padding: "6px 15px",width:'30%' }} onClick={() => setPopUp(false)}>Cancel</button>
                        </div>
                    </div>
                </div>

                :
                <div className='navbar_sidebar'>
                    <ul>
                        <span>
                            <img src={dashboard} alt="" />
                            <li onClick={() => {
                                Navigate(`/${user.Type == 'Employer' ? 'employer-job-profile' : 'freelancer-job-profile'}`)
                                handleSidebar(sidebar);
                            }}>Dashboard</li>
                        </span>

                        <span>
                            <img src={profile} alt="" />
                            <li onClick={() => {
                                user.Type == "Employer"
                                    ? Navigate("/employer-profile")
                                    : Navigate("/login-user-profile");
                                handleSidebar(sidebar);
                            }}>Profile</li>
                        </span>

                        <span>
                            <img src={password} alt="" />
                            <li><ChangePassword /></li>
                        </span>
                        <span>
                            <img src={logout} alt="" />
                            <li style={{ borderBottom: "none" }} onClick={() => setPopUp(true)}>Logout</li>
                        </span>
                        {/* {user.Type == "Freelancer" ? <li onClick={() => {Navigate("/SkillTest"), handleSidebar(sidebar)}}>Skill Test</li> :null} */}
                    </ul>
                </div>
            }
        </>

    );
}

export default Sidebar;
