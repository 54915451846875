import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { MyContext } from '../Mycontext'
import { message } from 'antd'
import { Link } from "react-router-dom";
import Profile from "../images/profile.jpg";
import { AiOutlineFilePdf, AiOutlineFileText, AiFillStar } from 'react-icons/ai';
import { useParams } from "react-router-dom";

export default function UserReviews() {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [userReviews, setuserReviews] = useState([]);
    const [item, setitem] = useState({});
    const { nameid } = useParams();

    const getuser = async () => {
        let id = nameid.split("-")[1];
        try {
            const { data } = await axios.post(endPointUrl + "/getUserById", {
                Token,
                id,
            });
            setitem(data.data);

        } catch {
            console.log("error for geting the userProfile");
        }
    };

    const getreviews = async () => {
        let id = nameid.split("-")[1];
        try {
            const { data } = await axios.post(endPointUrl + "/getReviewById", {
                Token,
                To_ID: id,
            });
            setuserReviews(data.data);
        } catch {
            console.log("error for geting the userProfile");
        }
    };

    function formatDate(dateString) {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleString('en-US', options);
        return formattedDate;
    };

    useEffect(() => {
        getuser();
        getreviews();
    }, [])

    console.log('usr review', userReviews)

    return (
        <div className="HowItWorks">
            <div className="home-howitwork">

                {user?.Type !== "Employer" ? (
                    <>
                        <Link to="/freelancers">Freelancers </Link>/{" "}
                        <Link to={`/user-profile/${item.Name}-${item.id}`}>
                            {`${item.Name} ${item.Last_Name} `}
                        </Link>
                        /{" "}
                    </>
                ) : null}
                <Link to={`/user-profile/reviews/${item.Name}-${item.id}`}>Reviews</Link>

            </div>
            <div className="profile-Apart">
                <img
                    src={
                        item.Photo === "" || item.Photo == null
                            ? Profile
                            : `${endPointUrl}/images/${item.Photo}`
                    }
                    alt=""
                />
                <div className="user-name">
                    <h2>{item.Name} {item.Last_Name}</h2>
                </div>
                <div className="user-reviews-address">
                    <span className="rating" style={{ paddingLeft: "8px", fontSize: "16px", paddingBottom: "9px" }}>

                        <AiFillStar className="featuredfreelancer-rating-star" />
                        {item.avg_Rating ? item.avg_Rating : 0} <p>({item.no_of_reviews ? item.no_of_reviews : 0} Reviews)</p></span>
                </div>
            </div>
            <section className='user-review-container'>
                {userReviews?.map((item, index) =>
                    <div className='user-review-box' key={index}>
                        <h2 className="star star-2">
                            <b style={{fontSize:'26px'}}>{item?.P_Name}</b>
                        </h2>
                        <h2 className="star star-2">
                            <i data-star={item?.Stars}></i>
                            <p>{item?.Stars} out of 5 stars</p>
                        </h2>
                        <div>
                            <span>
                                {item?.From_Name} - {formatDate(item?.Review_Date)}
                            </span>
                            <p>
                                {item?.Review}
                            </p>
                        </div>
                    </div>
                )}
            </section>
        </div>
    )
}
