import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../Mycontext";
import { message } from "antd";
import { useNavigate } from "react-router";
import axios from "axios";

function PublishedJob({ job, LocationSelected, languageSelected, skillSelected, technologySelected, categorySelected, setshowPublished, showpublished, jobCategoryOther, technologyOther, Description, Document, LocalDocument }) {
  const { endPointUrl, Token, user, socket } = useContext(MyContext);
  const Navigate = useNavigate();
  const [showmodel, setshowModel] = useState(true);
  const CloseModel = () => {
    setshowModel(false);
    setshowPublished(!showpublished)
  };

  const handleSubmit = async (IsActive) => {
    // seterror(true);
    let Status;
    if (IsActive) {
      Status = "Published";
    } else {
      Status = "Draft";
    }

    if (Document) {
      try {
        const formData = new FormData();
        formData.append("Budget_From", job.Budget_From);
        formData.append("Budget_To", job.Budget_To);
        formData.append("Budget_Type", job.Budget_Type);
        formData.append("JobCategory", job.JobCategory);
        formData.append("Title", job.Title);
        formData.append("Type", job.Type);
        formData.append("expertise", job.expertise);
        formData.append("ProjectType", job.ProjectType);
        formData.append("projectTime", job.projectTime);
        formData.append("projectScope", job.projectScope);
        formData.append("shortDescription", job.shortDescription);
        formData.append("Location", LocationSelected.join(","));
        formData.append("SkillsRequired", skillSelected.join(","));
        formData.append("Technology", technologySelected.join(","));
        formData.append("Category", categorySelected.join(","));
        formData.append("Language", languageSelected.join(","));
        formData.append("Description", Description);
        formData.append("Token", Token);
        formData.append("IsActive", IsActive);
        formData.append("Status", Status);
        formData.append("From_Name", `${user.Name} ${user.Last_Name}`);
        formData.append("Document", Document);
        formData.append("Employer_ID", user.id);
        formData.append("TechnologyOther", technologyOther);
        formData.append("JobCategoryOther", jobCategoryOther);

        const { data } = await axios.post(endPointUrl + "/user/userAddJobsWithDocument", formData);
        if (IsActive) {
          socket.emit('job_posting_alert', {
            FromID: user.id,
            Title: job.Title,
            Type: "Project",
            SkillsRequired: skillSelected,
            // LanguageRequired: languageSelected,
            // LocationRequired: LocationSelected,
            Message: `${user.Name + " " + user.Last_Name} posted recently one job for ${job.Title}`,
            IsRead: false,
            ItemID: data.data.JobID,
          })
          message.success("Job Published Successfully");
        } else {
          message.success("Job saved in Draft");
        }
        Navigate("../employer-job-profile");
        setshowModel(false);
      } catch (error) {
        console.log("error", error);
        window.scroll(0, 0);
        message.warning("Something Went Wrong");
      }
    } else {
      try {
        const formData = {
          ...job,
          Location: LocationSelected.join(","),
          SkillsRequired: skillSelected.join(","),
          Technology: technologySelected.join(","),
          Category: categorySelected.join(","),
          Language: languageSelected.join(","),
          Description: Description,
          Token,
          TechnologyOther:technologyOther,
          JobCategoryOther:jobCategoryOther,
          Employer_ID: user.id,
          IsActive,
          Status,
          From_Name: `${user.Name} ${user.Last_Name}`
        }

        const { data } = await axios.post(endPointUrl + "/user/userAddJobs", formData);
        if (IsActive) {
          socket.emit('job_posting_alert', {
            FromID: user.id,
            Title: job.Title,
            Type: "Project",
            SkillsRequired: skillSelected,
            // LanguageRequired: languageSelected,
            // LocationRequired: LocationSelected,
            Message: `${user.Name + " " + user.Last_Name} posted recently one job for ${job.Title}`,
            IsRead: false,
            ItemID: data.data.JobID,
          })
          message.success("Job Published Successfully");
        } else {
          message.success("Job saved in Draft");
        }
        Navigate("../employer-job-profile");
        setshowModel(false);
      } catch (error) {
        console.log("error", error);
        window.scroll(0, 0);
        message.warning("Something Went Wrong");
      }
    }
  };
  return (
    <div id="myModal" className={showmodel ? "model-open" : "model"} style={{ overflow: 'scroll' }}>
      <div className="modal-content publish">
        <span className="close" onClick={CloseModel}>
          &times;
        </span>
        <div className="publishButton">
          <div className="btn" onClick={() => handleSubmit(true)}>
            Publish
          </div>
          <div className="btn" style={{ background: "#b69d48" }} onClick={() => handleSubmit(false)}>
            Save as Draft
          </div>
        </div>
        <hr />
        <div>
          <h2 style={{ fontWeight: "bold" }}>{job?.Title}</h2>
        </div>
        {/* <div>
          <h3>ShortDesc</h3>
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            {job?.ShortDesc}
          </span>
        </div> */}
        <div>
          <h3>Project Type</h3>
          <span style={{ fontSize: "14px", fontWeight: "400" }}>
            {job?.ProjectType}
          </span>
        </div>
        <div>
          <h3 style={{ fontWeight: "600", display: "block" }}>Description</h3>
          <span style={{ wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: Description }}></span>
        </div>

        <h3>Budget Details</h3>
        <span>{job?.Budget_From}-</span>
        <span>{job?.Budget_To} $</span>
        <h3>Payment Type</h3>
        <span>{job?.Budget_Type}</span>

        <div>
          <h3>Job Type</h3>
          <span>{job?.Type}</span>

          <div className="viewSkills">
            <h3>Skills</h3>
            <div>
              {skillSelected.map((item) => (
                <span>{item}</span>
              ))}
            </div>
          </div>
          <div className="viewSkills">
            <h3>Technology</h3>
            <div>
              {technologySelected.map((item) => <span>{item === "Other" ? `${technologyOther}` : item}</span>)}</div>
          </div>
          <div className="viewSkills">
            <h3>Category</h3>
            <div>{categorySelected.map((item) => <span>{item === "Other" ? `${jobCategoryOther}` : item}</span>)}</div>
          </div>
          {/* <div className="viewSkills">
            <h3>Keywords</h3>
            <div>{tags.map((item) => <span>{item}</span>)}</div>
          </div> */}
          <div className="viewSkills">
            <h3>Language</h3>
            <div>
              {languageSelected.map((item) => <span>{item}</span>)}</div>
          </div>
          <div className="viewSkills">
            <h3>Location</h3>
            <div>
              {LocationSelected.map((item) => <span>{item}</span>)}</div>
          </div>
          <div>
            <h3>Expertise</h3>
            <div>
              {job?.expertise}
            </div>
          </div>
          <div>
            <h3>Project Scope</h3>
            <div>
              {job?.projectScope}
            </div>
          </div>
          <div>
            <h3>Short Description</h3>
            <div>
              {job?.shortDescription}
            </div>
          </div>
          <div>
            <h3>Document</h3>
            <div>
              <img src={LocalDocument} style={{ maxWidth: '200px', maxHeight: '100px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PublishedJob;
