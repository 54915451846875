import React from 'react'
import img from '../../images/active-freelancer.png'
import { Link } from 'react-router-dom'
export default function PrivacyPolicy() {
  return (
    <div className='HowItWorks privacy-footer'>
      <div className="home-howitwork">
        <Link to="/">Home </Link>/{" "}
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <span className="jobpostheading" style={{ backgroundColor: "#ceefe4", paddingRight: "0 40px 0 40px" }}>
        <div>
          Privacy Policy
          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur, perspiciatis.</p> */}
        </div>
      </span>
      <div className='footer-terms'>
        <div class="footer-terms-heading">
          As a networking and digital marketing company, Digilanxer is committed to respecting and protecting your privacy. We take the privacy of our users seriously and want to provide a safe and secure online experience. Our Privacy Policy outlines our commitment to safeguarding
          your personal information and explains how we collect, use, and protect the data you provide to us.
        </div>
        <section>
          <div className='footer-terms-main'>
            <h3>
              Information Collection
            </h3>
            <span>
              Digilanxer does not rent or sell your personally identifiable information to third parties. We may collect personal information such as your name, email address, and phone number when you sign up for our services. We use this information to personalize your experience, provide customer support, and communicate with you about
              our products and services. We do not share your contact information with any third parties without your consent.
            </span>
          </div>
          <div className='footer-terms-main'>
            <h3>
              Google Analytics & AdWords
            </h3>
            <span>
              We use Google Analytics to track and analyze website traffic. This helps us understand how users interact with our site and allows us to improve our services. We may also use Google AdWords to advertise our
              services to potential clients. These tools may collect information about your browsing behavior, such as your IP
              address and the pages you visit on our website. We use this data to optimize our advertising efforts and enhance
              your experience on our site.
            </span>
          </div>
          <div className='footer-terms-main'>
            <h3>
              Data Security
            </h3>
            <span>
              We are committed to protecting your personal information from unauthorized access, use, or disclosure.
              We use industry-standard security measures to safeguard your data, including encryption and secure servers. We also regularly review and update our security practices to ensure that your information is protected from potential threats.
            </span>
          </div>
          <div className='footer-terms-main'>
            <h3>
              Updates to Privacy Policy
            </h3>
            <span>
              We may update our Privacy Policy from time to time to reflect changes in our practices or regulatory requirements.
              We encourage you to review this policy periodically to stay informed about how we collect, use, and protect your
              personal information.
              By continuing to use our services, you acknowledge and agree to the terms of our Privacy Policy.
            </span>
          </div>
        </section>
      </div>
    </div>
  )
}
