import { message } from "antd";
import axios from "axios";
import React, { useContext, useState } from "react";
import { MyContext } from "../../Mycontext";

function Uploadimg({ handleuploadImg, userid }) {
  const [image, setImage] = useState(null);
  const { user, setuser, endPointUrl, Token } = useContext(MyContext);
  const handleSubmit = async () => {
    handleuploadImg();
    const formdata = new FormData();
    formdata.append("photo", image);
    try {
      const { data } = await axios.post(
        endPointUrl + "/user/imageUpload",
        formdata
      );
      console.log({ 'profile data': formdata })
      try {
        await axios.post(endPointUrl + "/updateUserProfile", {
          Photo: data.fileName,
          id: userid, Token
        });
        message.success("Image Uploaded");
        setuser({ ...user, Photo: data.fileName });
      } catch {
        message.error("Image Not Uploaded");
      }
    } catch {
      message.error("Image Not Uploaded");
    }
  };
  return (
    <div id="upload-modal" className="modal">
      <div className="modal-content">
        <form
          encType="multipart/form-data"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <label htmlFor="image-upload">Select an image to upload:</label>
          {/* <input
            type="file"
            id="image-upload"
            name="photo"
            onChange={(e) => setImage(e.target.files[0])}
          /> */}
          <input
            type="file"
            id="image-upload"
            name="photo"
            accept="image/*" 
            onChange={(e) => {
            const selectedFile = e.target.files[0];
            if (selectedFile && selectedFile.type.startsWith('image/')) {
                setImage(selectedFile);
            }
            }}/>

          <button type="submit">Upload</button>
          <button type="reset" onClick={handleuploadImg}>
            Close
          </button>
        </form>
      </div>
    </div>
  );
}
export default Uploadimg;
