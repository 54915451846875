import React from 'react'
import manage from '../images/manage.png'
function ManageEmployee() {
  return (
    <div className="SignUpEmployee">
      <h1>Manage</h1>
      <p className="sign-up-content">
        Make best use of dedicated workrooms to manage Freelancers and monitor progress !
      </p>
      <div className="sign-up-content-img">
      <div className="sign-up-points">
        <div className="sign-up-points-numbers">
          <div className="number">1</div>
          <div className="content"><h3>Build your team</h3>
              <p>To keep it going in a smooth and streamlined manner, hire multiple Freelancers for a given task and allocate work accordingly </p>
          </div>
        </div>
        <div className="sign-up-points-numbers">
          <div className="number">2</div>
            <div className="content"><h3>Increase productivity</h3>
              <p>Increase your productivity by managing your team, files, , communication, and payments right at one place.</p></div>
        </div>
        <div className="sign-up-points-numbers">
          <div className="number">3</div>
            <div className="content"><h3>Effective monitoring </h3>
              <p>Keep a tab on the assigned work with our efficient tools !</p>
</div>
        </div>
        
       
       
        
        
      </div>
      <div className="sign-up-img"><img src={manage} alt="" /></div>
      </div>
      
    </div>
  )
}

export default ManageEmployee