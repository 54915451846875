import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Profile from "../images/emplyeer.png"
import { MyContext } from "../Mycontext";
import { IoMdLink } from "react-icons/io";
import moment from 'moment/moment';

const Chat = ({ detailJob, Receiver }) => {
  const [sender, setSender] = useState();
  const [receiver, setReceiver] = useState();
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [currentRoom, setCurrentRoom] = useState(null);
  const { user, endPointUrl, Token, socket, JobsId } = useContext(MyContext);
  const [imageURL, setImageURL] = useState();
  const [imageByte, setImageByte] = useState();

  const getPreviousMessages = async () => {
    try {
      const response = await axios.post(endPointUrl + "/getMessages", {
        sender: user.id,
        receiver: Receiver?.id,
        JobID: detailJob.JobID ? detailJob?.JobID : JobsId,
      });

      setChatHistory(response.data.data);
    } catch (error) {
      console.error("Error retrieving chat messages:", error);
    }
  };

  useEffect(() => {
    if (currentRoom) {
      socket.on("receive-message", (data) => {
        if (data.room === currentRoom) {
          setChatHistory((prevChatHistory) => [...prevChatHistory, data]);
        }
      });
      socket.on("receive-files", (data) => {
        if (data.room === currentRoom) {
          setChatHistory((prevChatHistory) => [...prevChatHistory, data]);
        }
      });
    }

    return () => {
      socket.off("receive-message");
    };
  }, [currentRoom]);

  const handleLogin = () => {
    if (Receiver?.id) {
      setSender(user.id);
      setReceiver(Receiver?.id);
      const sortedNames = [user.id, Receiver?.id].sort();
      const room = `${sortedNames[0]}-${sortedNames[1]}`;

      setCurrentRoom(room);

      const data = {
        sender: user.id,
        receiver: Receiver?.id,
        message: "",
        room: room,
      };
      socket.emit("login", data);

      socket.on("receive-message", (data) => {
        setChatHistory((prevChatHistory) => [...prevChatHistory, data]);
      });

      getPreviousMessages();
    }
  };

  useEffect(() => {
    handleLogin();
  }, [Receiver?.id])


  useEffect(() => {
    // Scroll to the bottom of the chat window when the chatHistory changes
    const chatWindow = document.getElementById("chat-window");
    if (chatWindow) {
      chatWindow.scrollTop = chatWindow.scrollHeight;
    }
  }, [chatHistory]);

  const handleSendMessage = async () => {
    if (message.trim() !== "") {
      const data = {
        sender: sender,
        receiver: receiver,
        message: message,
        room: currentRoom,
        JobID: detailJob.JobID ? detailJob?.JobID : JobsId,
      };
      socket.emit("send-message", data);
      setMessage("");
    }

    if (imageURL) {
      const formData = new FormData()
      formData.append('image', imageByte)
      formData.append('UserID', user.id)
      formData.append('sender', sender)
      formData.append('receiver', receiver)
      formData.append('JobID', JobsId ? JobsId : detailJob?.JobID)
      formData.append('Token', Token)
      formData.append('room', currentRoom)

      try {
        setImageByte("")
        setImageURL("")
        await axios.post(endPointUrl + "/MsgAttachment", formData)
      }
      catch (e) {
        console.log('Error while sending attachment')
      }
    };
  };


  const formatTimestamp = (timestamp) => {
    const date = moment(timestamp);
    const now = moment();

    const timeDifference = now.diff(date, 'seconds');

    if (timeDifference < 60) {
      return (<span>{timeDifference} seconds ago</span>);
    } else if (timeDifference < 3600) {
      const minutes = moment.duration(timeDifference, 'seconds').minutes();
      return (<span>{minutes} minutes ago</span>);
    } else if (timeDifference < 86400) {
      const hours = moment.duration(timeDifference, 'seconds').hours();
      return (<span>{hours} hours ago</span>);
    } else {
      return (<span>{date.format('MMMM Do YYYY')}</span>); // Display the full date for older messages
    }
  };

  const renderTimestamp = (currentTimestamp, prevTimestamp) => {
    const currentMoment = moment(currentTimestamp);
    const prevMoment = moment(prevTimestamp);

    const timeDifference = currentMoment.diff(prevMoment, 'hours');

    if (timeDifference >= 2) {
      return formatTimestamp(currentTimestamp);
    }

    return null; // Return null if the difference is less than 2 hours
  };

  console.log('imgaguploaded', imageByte?.name?.substring(imageByte?.name.lastIndexOf(".") + 1));
  // console.log('fileuploaded',imageByte?.name?.slice(imageByte?.name.lastIndexOf(".")))


  const handleFileUpload = async (event) => {
    try {
      const file = event.target.files[0];
      setImageURL(URL.createObjectURL(event.target.files[0]))
      setImageByte(event.target.files[0])

    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  const FileAttachment = ({ filename, fileURLs }) => {
    return (
      <div className="file-attachment" style={{ maxWidth: '150px',marginRight:'10px' }}>
        {
          fileURLs?.substring(fileURLs.lastIndexOf(".") + 1) === "pdf" || fileURLs?.substring(fileURLs.lastIndexOf(".") + 1) === "docx" ?
            <a target='_blank' href={`${endPointUrl}/ChatAttachment/${fileURLs}`}>
              <div>
                Click to View Document
              </div>
            </a>
            : <a target='_blank' href={`${endPointUrl}/ChatAttachment/${fileURLs}`}>
              <img
                className="image-thumbnail"
                style={{ width: "100%" }}
                src={`${endPointUrl}/ChatAttachment/${fileURLs}`}
                alt="pic"
              // onClick={() => handleImageDownload(`${endPointUrl}${fileURLs}`, filename)}
              // download={true}
              />
            </a>}
      </div>
    );
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        handleSendMessage();
    }
};

  const handleImageDownload = (fileURL, filename) => {
    // Create a temporary link element
    const link = document.createElement("a");
    link.href = fileURL;
    link.target = "_blank"; // Open the file in a new tab if the download fails
    link.style.display = "none";
    link.download = filename;
    // Trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  };


  return (
    <div className="chat-container">
      <div className="chat-window" id="chat-window">
        <div className="chat-messages">
          {chatHistory.map((chat, index) => (
            <>
              {renderTimestamp(chat.createdAt, chatHistory[index - 1]?.createdAt)}
              <div
                key={index}
                className={
                  chat.sender.toString() !== sender.toString()
                    ? "chat-bubble left chat-message-leftExtra "
                    : "chat-bubble right chat-message-RightExtra"
                }
              >
                {chat.file_url ?
                  <>
                    <span>
                      <img
                        style={{ width: "30px", height: "30px", borderRadius: "50%", display: 'flex' }}
                        src={chat.sender.toString() !== sender.toString() ?
                          Receiver?.Photo === "" || Receiver?.Photo == null
                            ? Profile
                            : `${endPointUrl}/images/${Receiver?.Photo}`
                          :
                          user?.Photo === "" || user?.Photo == null
                            ? Profile
                            : `${endPointUrl}/images/${user.Photo}`
                        }
                        alt=""
                      // onClick={(e) => { handleLogin(e, user.user.id) }}
                      />

                      <FileAttachment
                        filename={chat.message}
                        fileURLs={chat.file_url}
                      />
                    </span>

                  </> :
                  <span style={{ backgroundColor: "transparent" }}>
                    <img
                      style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                      src={chat.sender.toString() !== sender.toString() ?
                        Receiver?.Photo === "" || Receiver?.Photo == null
                          ? Profile
                          : `${endPointUrl}/images/${Receiver?.Photo}`
                        :
                        user?.Photo === "" || user?.Photo == null
                          ? Profile
                          : `${endPointUrl}/images/${user?.Photo}`
                      }

                      alt=""
                      onClick={(e) => { handleLogin(user?.id) }}
                    />
                    <p style={{ maxWidth: '200px' }}>{chat.message}</p>
                  </span>

                  // : chat.message
                }
              </div>
            </>
          ))}
        </div >
        <div className="chat-input">
          <div className="input-container">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message..."
              onKeyPress={handleKeyPress}
              />

          </div>
          <div className="file-upload-container">
            <div><label htmlFor="file">
              <IoMdLink />
            </label>
              <input type="file" id="file" onChange={handleFileUpload} />
            </div>
            <div>
              {imageByte?.name?.substring(imageByte?.name.lastIndexOf(".") + 1) === "pdf" || imageByte?.name?.substring(imageByte?.name.lastIndexOf(".") + 1) === "docx" ?
                <div>{imageByte?.name}</div>
                : <img src={imageURL} width="100%"></img>}
            </div>
            <button onClick={handleSendMessage}>Send</button>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Chat;