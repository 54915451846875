import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import add from "../../../images/add.png";
import { message } from 'antd';
import EditCertificate from "./EditCertificate";
import DeleteCertificate from "./DeleteCertificate";
import ModalViewDocument from "../../ModalViewDocument";
import { MdPictureAsPdf } from "react-icons/md";

function AddCertificate({ getCertificateProfile }) {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [showmodel, setshowModel] = useState(false);
    const [expireDatetoggle, setExporeDatetoggle] = useState(false)
    const [preform, setPreform] = useState(null);
    const [PortData, setPortData] = useState([]);
    const [filedata, setFileData] = useState([]);
    const [formList, setFormList] = useState([]);
    const [fileName, setFileName] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [form, setForm] = useState({
        Title: "",
        Type: "",
        Authorized_Certification: "",
        Certification_UID: "",
        Completion_Date: null,
        Completion_Expire_Date: null,
    });
    const [errorForm, setErrorForm] = useState({
        Title: "",
        Type: "",
        Authorized_Certification: "",
        Certification_UID: "",
        Completion_Date: "",
        Completion_Expire_Date: "",
        file: "",
    });

    const handlemodle = () => {
        setForm({
            Title: "",
            Type: "",
            Authorized_Certification: "",
            Certification_UID: "",
            Completion_Date: null,
            Completion_Expire_Date: null
        })
        setErrorForm({
            Title: "",
            Type: "",
            Authorized_Certification: "",
            Certification_UID: "",
            Completion_Date: "",
            Completion_Expire_Date: "",
            file: "",
        })
        setshowModel(true)
    }


    const handleCertificate = (value) => {
        const newFormErrors = {
            Title: "",
            Type: "",
            Authorized_Certification: "",
            Certification_UID: "",
            Completion_Date: null,
            Completion_Expire_Date: null,
            file: "",
        };
        let showError = false;
        if (!form.Title && !form.Type && !form.Authorized_Certification && !form.Certification_UID && (!expireDatetoggle && !form.Completion_Expire_Date) && !preform && formList.length >= 1 && filedata.length >= 1) {
            AddCertificate(value, "Submit")
            setshowModel(false)
        }
        else {
            if (form.Title === null || form.Title === "") {
                showError = true;
                newFormErrors.Title = 'Please fill out this field!';
            } else {
                newFormErrors.Title = '';
            }
            if (form.Type === null || form.Type === "") {
                showError = true;
                newFormErrors.Type = 'Please fill out this field!';
            } else {
                newFormErrors.Type = '';
            }
            // if (form.Authorized_Certification === null || form.Authorized_Certification === "") {
            //     showError = true;
            //     newFormErrors.Authorized_Certification = 'Please fill out this field!';
            // } else {
            //     newFormErrors.Authorized_Certification = '';
            // }
            if (form.Certification_UID === null || form.Certification_UID === "") {
                showError = true;
                newFormErrors.Certification_UID = 'Please fill out this field!';
            } else {
                newFormErrors.Certification_UID = '';
            }
            if (!expireDatetoggle && form.Completion_Expire_Date === null) {
                showError = true;
                newFormErrors.Completion_Expire_Date = 'Please fill out this field!';
            }
            else {
                newFormErrors.Completion_Expire_Date = "";
            }
            if (form.Completion_Date === null) {
                showError = true;
                newFormErrors.Completion_Date = 'Please fill out this field!';
            } else if (new Date(form.Completion_Date) > new Date()) {
                showError = true;
                newFormErrors.Completion_Date = 'Invalid Date';
            } else {
                newFormErrors.Completion_Date = '';
            }

            if (!expireDatetoggle && form.Completion_Expire_Date <= form.Completion_Date) {
                showError = true;
                newFormErrors.Completion_Expire_Date = 'Expire_Date should be greater than Completion_Date';
            }
            else {
                newFormErrors.Completion_Expire_Date = "";
            }
            if (preform === null || !preform) {
                showError = true;
                newFormErrors.file = 'Please fill out this field!';
            } else {
                newFormErrors.file = '';
            }
            setErrorForm(newFormErrors);
            if (!showError) {
                setFileData([...filedata, preform]);
                setFormList([...formList, form]);

                if (!value) {
                    setForm({
                        Title: "",
                        Type: "",
                        Authorized_Certification: "",
                        Certification_UID: "",
                        Completion_Date: null,
                        Completion_Expire_Date: null,
                    })
                    setExporeDatetoggle(false)
                    setFileName(null)
                    setPreform(null)
                }

                if (value) {
                    AddCertificate(value)
                    setshowModel(false)
                }
            }
        }
    }

    const getCertificate = async () => {
        const { data } = await axios.post(endPointUrl + "/user/getCertiByUserID", {
            Token,
            UserID: user.id
        });
        if (data.data != null) {
            setPortData(data.data);
        }

    }
    const AddCertificate = async (value, Type) => {
        try {
            try {
                var res = "";
                if (formList.length === 0 && form) {
                    res = await axios.post(endPointUrl + "/user/addcertificate", {
                        formList: [form],
                        UserID: user.id,
                        Token
                    });
                }
                else if (Type === "Submit") {
                    res = await axios.post(endPointUrl + "/user/addcertificate", {
                        formList,
                        UserID: user.id,
                        Token
                    });
                } else {
                    res = await axios.post(endPointUrl + "/user/addcertificate", {
                        formList: [...formList, form],
                        UserID: user.id,
                        Token
                    });
                }


                const CertificateID = res.data.data;
                const formdata = new FormData();
                var imagaeData = "";
                if (filedata.length === 0) {
                    imagaeData = [preform]
                } else {
                    imagaeData = [...filedata, preform];
                }
                imagaeData.forEach(item => formdata.append('File_Upload', item));

                try {
                    const response = await axios.post(
                        endPointUrl + "/user/certificatedocupload",
                        formdata,
                        {
                            params: {
                                Token,
                                cerID: CertificateID
                            }
                        }
                    );
                    console.log("Document upload response:", response.data);
                } catch (error) {
                    console.error("Error uploading documents:", error);
                }


                message.success("Certificate Added")
                getCertificate()
                getCertificateProfile();
                setForm({
                    Title: "",
                    Completion_Date: "",
                    Authorized_Certification: "",
                    Certification_UID: "",
                    Completion_Expire_Date: ""
                })
                setFileName(null)
                setPreform(null)
                setFileData([]);
                setFormList([]);

            } catch {
                console.log("upload url produce error");
            }
        } catch {
            console.log("error occur during upload docs");
        }
    }
    useEffect(() => {
        getCertificate();
    }, [])

    return (
        <div className="education" style={{width:"70%"}}>
            <strong style={{ display: "flex", alignItems: "center" }}>
                Cetificate <img src={add} alt="" style={{ width: "20px" }} onClick={handlemodle} />
            </strong>

            <div>
                <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: "10%" }}>
                    {PortData.map((item, index) =>
                        item?.UserID ?
                            <div key={index} style={{ width: "45%", marginTop: '3%' }}>
                                <div style={{ display: 'flex', width: "100%" }}>
                                    <div style={{ width: "35%" }}>
                                        <a
                                            href={`${endPointUrl}/certificates/${item.Certificate_Image}`}
                                            rel="noopener noreferrer"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setOpen(true);
                                                setSelectedItem(item.Certificate_Image);
                                            }}
                                        >
                                            {item.Certificate_Image.substring(item.Certificate_Image.lastIndexOf(".") + 1) === "pdf"
                                                ?
                                                <div><MdPictureAsPdf style={{ width: "100%", height: "100px", cursor: 'pointer' }} /></div>
                                                : < img
                                                    src={`${endPointUrl}/certificates/${item.Certificate_Image}`}
                                                    style={{ width: "100%", height: "100px", borderRadius: "10%" }}
                                                    alt="cert"
                                                    className="potfoliothumbail"
                                                />
                                            }

                                        </a>
                                        {selectedItem && <ModalViewDocument open={open} setOpen={setOpen} type="certificates" selectedItem={selectedItem} setSelectedItem={setSelectedItem} />}
                                    </div>

                                    <div style={{ marginLeft: "3%", width: "65%", color: "#606563" }}>
                                        <div><span style={{ color: "black", fontWeight: "bold" }}>{item?.Title}</span></div>
                                        <div style={{ fontSize: '12px' }}>{item?.Type}</div>
                                        <div style={{ fontSize: '12px' }}>
                                            {item?.Completion_Date?.slice(5, 7)}, {" "}
                                            {item?.Completion_Date?.slice(0, 4)}
                                            {item?.Completion_Expire_Date ? "- " : ""}
                                            {item?.Completion_Expire_Date?.slice(5, 7)}
                                            {item?.Completion_Expire_Date ? ", " : ""}
                                            {item?.Completion_Expire_Date?.slice(0, 4)}
                                        </div>
                                        {item?.Authorized_Certification && <div style={{ fontSize: '12px' }}>{item?.Authorized_Certification}</div>}
                                        <div style={{ fontSize: '12px' }}>UID:<span>{item?.Certification_UID}</span></div>
                                    </div>
                                </div>

                                <div style={{ display: 'flex' }}>
                                    <span><EditCertificate Certificate={item} getCertificate={getCertificate} /></span>
                                    <span><DeleteCertificate Certificate={item} getCertificate={getCertificate} getCertificateProfile={getCertificateProfile} /></span>
                                </div>
                                {/* <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr> */}

                            </div> : null
                    )}
                </div>
            </div>

            <hr style={{ borderTop: '1px solid rgb(228, 228, 228)' }}></hr>

            <div id="myModal" className={showmodel ? "model-open" : "model"}>

                <div className="modal-content" style={{ width: "60%", padding: 0 }} >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", background: "#ebebeb", padding: "0 40px", borderRadius: "20px 20px 0 0" }}>
                        <span style={{ color: "#46c294", fontWeight: "600" }}>Add Certificate</span>
                        <span className="close" onClick={(() => setshowModel(false))}>
                            &times;
                        </span>
                    </div>

                    <form style={{ padding: "0 40px", margin: "30px 0" }} className="model-field exp-form"
                        encType="multipart/form-data"
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}>
                        <div style={{ paddingInlineEnd: "20px" }}>
                            <div>
                                {" "}
                                <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                    <div>
                                        <label htmlFor="title">Certificate Name<span className="asterisk">*</span></label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Title}</small>
                                        <input
                                            style={{ marginInlineEnd: "12%" }}
                                            value={form.Title}
                                            type="text"
                                            name="title"
                                            className={errorForm.Title != "" ? "errorborder" : ""}
                                            placeholder='Enter title'
                                            onChange={(e) => {
                                                const inputValue = e.target.value.slice(0, 45);
                                                if (!inputValue.trim()) {
                                                    return setForm({ ...form, Title: "" });
                                                }
                                                setForm({ ...form, Title: inputValue });
                                            }}

                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="certificate_type">Certificate Type<span className="asterisk">*</span></label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Type}</small>
                                        <select
                                            style={{ marginInlineEnd: "12%", marginBottom: "0", width: "100%" }}
                                            name="certificate_type"
                                            value={form.Type}
                                            className={errorForm.Type != "" ? "errorborder" : ""}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!inputValue.trim()) {
                                                    return setForm({ ...form, Type: "" });
                                                }
                                                setForm({ ...form, Type: inputValue });
                                            }}
                                        >
                                            <option value="">Certificate type</option>
                                            <option value="SEO">SEO</option>
                                            <option value="Development">Development</option>
                                            <option value="Management">Management</option>
                                            <option value="Digital Marketing">Digital Marketing</option>
                                            <option value="YouTube Marketing">YouTube Marketing</option>
                                            <option value="Data Analytics">Data Analytics</option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>
                                </div>

                                <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                    <div>
                                        <label htmlFor="Authorized_Certification">Authorized Certification Body</label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Authorized_Certification}</small>
                                        <input
                                            style={{ marginInlineEnd: "12%" }}
                                            value={form.Authorized_Certification}
                                            type="text"
                                            name="Authorized_Certification"
                                            className={errorForm.Authorized_Certification != "" ? "errorborder" : ""}
                                            placeholder='Enter authorized certification body'
                                            onChange={(e) => {
                                                const inputValue = e.target.value.slice(0, 55);
                                                if (!inputValue.trim()) {
                                                    return setForm({ ...form, Authorized_Certification: "" });
                                                }
                                                setForm({ ...form, Authorized_Certification: inputValue });
                                            }}

                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="Certification_UID">Certification UID<span className="asterisk">*</span></label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Certification_UID}</small>
                                        <input
                                            style={{ marginInlineEnd: "12%" }}
                                            value={form.Certification_UID}
                                            type="text"
                                            name="Certification_UID"
                                            className={errorForm.Certification_UID != "" ? "errorborder" : ""}
                                            placeholder='Enter certification UID'
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                if (!inputValue.trim()) {
                                                    return setForm({ ...form, Certification_UID: "" });
                                                }
                                                if (inputValue.length <= 20) {
                                                    setForm({ ...form, Certification_UID: inputValue });
                                                }
                                                else {
                                                    message.warning("Certification UID contain only 20 Characters")
                                                }
                                            }}

                                        />
                                    </div>
                                </div>


                                <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                    <div>
                                        <label htmlFor="Completion_Date">Certificate Completion Date<span className="asterisk">*</span></label>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Completion_Date}</small>
                                        <input
                                            type="date"
                                            value={form.Completion_Date}
                                            className={errorForm.Completion_Date != "" ? "errorborder" : ""}
                                            name="Completion_Date"
                                            placeholder="Enter Certificate Completion Date"
                                            min="1900"
                                            max="2099"
                                            onChange={(e) => {
                                                const enteredDate = e.target.value;
                                                const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                setForm({ ...form, Completion_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="Completion_Expire_Date">Completion Expire Date<span className="asterisk">*</span></label>
                                        <span style={{ display: 'flex', alignItems: 'center', gap: "6px", fontSize: '12px', marginTop: "6px" }}><input type="checkbox" onChange={() => {
                                            setExporeDatetoggle(!expireDatetoggle)
                                            setErrorForm({ ...errorForm, Completion_Expire_Date: "" })
                                            setForm({ ...form, Completion_Expire_Date: null });
                                        }
                                        } style={{ width: "auto", margin: 0, padding: 0 }} />Certificate not expire</span>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.Completion_Expire_Date}</small>
                                        <input
                                            readOnly={expireDatetoggle}
                                            style={{ backgroundColor: expireDatetoggle ? "#eeeeee" : "" }}
                                            type="date"
                                            value={!expireDatetoggle ? form.Completion_Expire_Date : ""}
                                            className={errorForm.Completion_Expire_Date != "" ? "errorborder" : ""}
                                            name="Completion_Expire_Date"
                                            placeholder="Enter Completion Expire Date"
                                            min="1900"
                                            max="2099"
                                            onChange={(e) => {
                                                const enteredDate = e.target.value;
                                                const extractedYear = enteredDate.split("-")[0].slice(0, 4);
                                                setForm({ ...form, Completion_Expire_Date: `${extractedYear}-${enteredDate.split("-")[1]}-${enteredDate.split("-")[2]}` });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "grid", gridTemplateColumns: "45% 45%", justifyContent: "space-between" }}>
                                <div>
                                    <div >
                                        <label htmlFor="file" style={{ display: "block", marginBottom: "0" }}>Upload Certificate<span className="asterisk">*</span></label>
                                        <span style={{ fontSize: "12px", color: "#9b9b9b" }}>maximum size 500kb*</span>
                                        <small style={{ color: "#ec1c24" }}>{errorForm.file}</small>
                                        <input
                                            type="file"
                                            name="File_Upload"
                                            id="certificatefile"
                                            className={errorForm.file !== "" ? "errorborder" : ""}
                                            onChange={(e) => {
                                                const selectedFile = e.target.files[0];
                                                const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];

                                                if (selectedFile && selectedFile.size / 1024 <= 500) {
                                                    if (allowedTypes.includes(selectedFile.type)) {
                                                        setPreform(selectedFile);
                                                        setFileName(URL.createObjectURL(selectedFile))
                                                    } else {
                                                        if (selectedFile.size / 1024 > 500) {
                                                            message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                        }
                                                        e.target.value = ''; // Reset the input value
                                                        setPreform(null)
                                                        return message.warning('Please select a valid image (jpg, jpeg, png) or PDF file.');
                                                    }
                                                }
                                                else {
                                                    e.target.value = ''; // Reset the input value
                                                    setPreform(null)
                                                    e.target.value = ''; // Reset the input value
                                                    message.warning('File size exceeds 500 KB. Please upload a smaller file.')
                                                }
                                            }}
                                        />
                                        {fileName ? <div>
                                            <img src={fileName} width="50%"></img>
                                        </div> : <></>}
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
                                <input type="reset" className="btn" value={'Add Certificate'} onClick={() => handleCertificate(false)} style={{ width: "20%", backgroundColor: "#e3e3e3", color: "#000" }} />
                                <input type="submit" className="btn" onClick={() => handleCertificate(true)} value={"Submit"} />
                            </div>
                        </div>
                    </form>
                    <div style={{ maxHeight: '200px', overflowY: "scroll" }}>
                        {formList?.map((value, index) => (
                            <div key={index} style={{ padding: "0 40px", marginTop: "30px", marginBottom: "30px" }}>
                                <span>
                                    <strong>Certificate Name</strong>:
                                    {value.Title}
                                </span>
                                {/* <br /> */}
                                <span>
                                    <strong>Type</strong>:
                                    {value.Type}
                                </span>
                                {/* <br /> */}
                                <span>
                                    <strong>Authorized Certification</strong>:
                                    {value.Authorized_Certification}
                                </span>
                                {/* <br /> */}
                                <span>
                                    <strong>Certification UID</strong>:
                                    {value.Certification_UID}
                                </span>
                                {/* <br /> */}
                                <span>
                                    <strong>Completion_Date</strong>:
                                    {value.Completion_Date}
                                </span>
                                {/* <br /> */}
                                <span>
                                    <strong>Completion_Expire_Date</strong>:
                                    {value.Completion_Expire_Date}
                                </span>
                                {/* <br /> */}
                                <span>
                                    <strong>File</strong>:
                                    {filedata[index].name}
                                </span>
                                {/* <hr /> */}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AddCertificate;