import axios from "axios";
import { useState, useContext } from "react"
import { MyContext } from '../../Mycontext';
import img from '../../images/veryfied.png'
import { useNavigate } from "react-router-dom";
import { AiFillStar } from 'react-icons/ai';
import { message } from "antd";
import Profile from "../../images/profile.jpg";
import { AiOutlineMessage } from "react-icons/ai";

function Invite({ detailjob, JobID, JobTitle }) {
    const Navigate = useNavigate();
    const { Token, endPointUrl, showMessg, setshowMessg, showchat, JobId, setJobId, setShowchat, Ruser, setRUser, socket, user } = useContext(MyContext);
    const [click, setClick] = useState(false)
    const [inviteFreelancer, setInviteFreelancer] = useState();
    const [name, setName] = useState('')
    const [screenName, setScreenName] = useState('')
    const [emailID, setEmailID] = useState('')
    const [freelancerID, setFreelancerID] = useState();
    const [freelancerByID, setFreelancerByID] = useState();
    const [freelancerByName, setFreelancerByName] = useState([]);
    const [invitedFreelancer, setInvitedFreelancer] = useState([]);

    const handleInviteClick = async () => {
        setClick(!click)
        try {
            const { data } = await axios.post(endPointUrl + "/getInvitedFreelancer", { Invite_By: user.id, JobID, Token })
            setInvitedFreelancer(data.data);
        } catch (error) {
            message.warning(`Invited Freelancer Failed!`);
            console.log("Error While Get Invited Freelancer", error)
        }
    }

    const handleClose = () => {
        setClick(false)
    }

    const handleFreelancer = (e) => {
        setInviteFreelancer(e.target.value)
        setFreelancerByID('')
        setFreelancerByName([])
    }

    const handleInvite = async (item) => {
        try {
            const { data } = await axios.post(endPointUrl + "/sendInvitation", { Invite_To: item.id, Invite_By: user.id, JobID, JobTitle, Token, Status: "Pending" })
            if (data.status === true) {
                message.success(`Invitation Sent Succesfully`);
                setClick(!click)
            }
            if (data.status === "Exist") {
                message.success(data.data);
            }
        } catch (error) {
            message.warning(`Invitation failed`);
            setClick(!click)
            console.log("Error While Invite Freelancer", error)
        }
    }

    const handleSearchByID = async () => {
        if (!freelancerID) {
            alert("Please Enter Freelancer ID")
        }
        else {
            try {
                const { data } = await axios.post(endPointUrl + "/getUserById", { id: freelancerID, Token })
                setFreelancerByID(data.data)
            } catch (error) {
                console.log("error while search freelancer by id", error)
            }
        }
    }

    const handleSearchByName = async () => {
        if (!name && !screenName && !emailID) {
            alert("Please Enter One of the Field")
        }
        else {
            try {
                const { data } = await axios.post(endPointUrl + "/getUserForInvite", { name, screenName, emailID, Token })
                if (data.data.length > 0)
                    setFreelancerByName(data.data)
                else
                    setFreelancerByName([null])
            } catch (error) {
                console.log("error while search freelancer by id", error);
            }
        }
    }

    const showFreelancerForInvite = () => {
        return (
            <div style={{ outline: '1px solid gray', width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="imglike" style={{ width: "94%", display: "flex", margin: '0 auto' }}>
                    <img src={img} alt="" style={{ filter: freelancerByID.Verified_Profile == "Verified" ? "none" : "grayscale(1)" }} />
                </div>
                <div style={{ width: '35%', marginTop: '3%' }}>
                    <img
                        style={{ width: '100%', height: '90px', borderRadius: '50%' }}
                        src={freelancerByID.Photo ? `${endPointUrl + "/images/" + freelancerByID.Photo}` : Profile}
                        alt="profile"
                    />
                </div>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{freelancerByID.ScreenName}</div>

                <span className="rating"
                    onClick={() => Navigate(`/user-profile/reviews/${freelancerByID.Name + "-" + freelancerByID.id}`)}
                >
                    <AiFillStar className="featuredfreelancer-rating-star" />
                    {freelancerByID.avg_Rating ? freelancerByID.avg_Rating : 0} <p>({freelancerByID.no_of_reviews ? freelancerByID.no_of_reviews : 0} Reviews)</p>
                </span>

                <span style={{ color: '#fff', marginBottom: "10px", cursor: 'pointer', backgroundColor: 'rgba(7, 178, 115, 0.725)', padding: '5px 25px', border: 'none', borderRadius: '5px' }} onClick={() => { handleInvite(freelancerByID) }}>
                    Invite
                </span>
            </div>
        )
    }

    const showFreelancerForInviteByName = () => {
        if (!freelancerByName[0]) {
            return (<div>No Freelancer Found</div>)
        }
        return freelancerByName.map((item) => {
            return (<div style={{ outline: '1px solid gray', width: '40%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="imglike" style={{ width: "94%", display: "flex", margin: '0 auto' }}>
                    <img src={img} alt="" style={{ filter: item.Verified_Profile == "Verified" ? "none" : "grayscale(1)" }} />
                </div>
                <div style={{ width: '35%', marginTop: '3%' }}>
                    <img
                        style={{ width: '100%', height: '90px', borderRadius: '50%' }}
                        src={item.Photo ? `${endPointUrl + "/images/" + item.Photo}` : Profile}
                        alt="profile"
                    />
                </div>
                <div style={{ fontSize: '20px', fontWeight: 'bold' }}>{item.ScreenName ? item.ScreenName : item.Name}</div>

                <span className="rating"
                    onClick={() => Navigate(`/user-profile/reviews/${item.Name + "-" + item.id}`)}
                >
                    <AiFillStar className="featuredfreelancer-rating-star"
                    />
                    {item.avg_Rating ? item.avg_Rating : 0} <p>({item.no_of_reviews ? item.no_of_reviews : 0} Reviews)</p>
                </span>

                <span style={{ color: '#fff', marginBottom: "10px", cursor: 'pointer', backgroundColor: 'rgba(7, 178, 115, 0.725)', padding: '5px 25px', border: 'none', borderRadius: '5px' }} onClick={() => { handleInvite(item) }}>
                    Invite
                </span>
            </div>)
        })
    }

    function showInvitedFreelancer() {
        if (invitedFreelancer.length > 0) {
            return invitedFreelancer.map((item, index) => {
                return (
                    <div style={{ fontSize: "18px", background: index % 2 != 0 ? "#07B273" : "white", color: "#000", textAlign: 'center', boxShadow: invitedFreelancer?.length === 0 ? '' : '0px 0px 1px 0.2px #606563', marginTop: '3%', borderRadius: '10px' }}>
                        {item.Status === "Rejected" ?
                            `${item.User.ScreenName || item.User.Name} rejected invitation` : `You Invited ${item.User.ScreenName || item.User.Name}`}
                        <div>{item.Status === "Rejected" ? <div>Reason: <span style={{ fontSize: '14px', color: index % 2 != 0 ? "#fff" : "#000" }}>{item.Reason}</span></div> : <></>}</div>
                    </div>
                )
            })
        }
    }

    return (
        <div>
            <button className="invite" onClick={handleInviteClick} style={{fontSize:'14bipx',marginRight:'10px'}}>Invite Freelancer</button>
            <div className="invite_model" style={{ display: click ? 'block ' : 'none' }}>
                <div className="invite_model_content">
                    <div className="invite_close" onClick={handleClose}>&times;</div>
                    <h2 style={{ textAlign: 'center' }}>Invite Freelancer</h2>


                    <div style={{ fontSize: '14px', display: 'flex', gap: '10px', justifyContent: 'center' }}>
                        <div><input type="radio" name="search" value="byID" onChange={handleFreelancer} />By Freelancer ID</div>
                        <div><input type="radio" name="search" value="byName" onChange={handleFreelancer} />By Freelancer Name</div>
                    </div>

                    {inviteFreelancer === "byID" ?
                        <div style={{ textAlign: 'center', fontSize: '14px' }}>
                            <div style={{ marginTop: '2%' }}><input style={{ padding: '10px', width: '30%', borderRadius: '5px' }} type="text" placeholder="Enter Freelancer ID" onChange={(e) => setFreelancerID(e.target.value)} /></div>
                            <div style={{ marginTop: '2%' }}><button style={{ backgroundColor: 'rgba(7, 178, 115, 0.725)', color: '#fff', padding: '8px 15px', border: 'none', borderRadius: '10px' }} onClick={handleSearchByID}>Search</button></div>
                        </div> :
                        inviteFreelancer === "byName" ?
                            <div>
                                <div style={{ marginTop: '2%', textAlign: 'center' }}>
                                    <div style={{ marginTop: '2%' }}><input style={{ padding: '10px', width: '40%', borderRadius: '5px' }} type="text" placeholder="Enter Freelancer Name" onChange={(e) => setName(e.target.value)} /></div>
                                    <div style={{ marginTop: '2%' }}><input style={{ padding: '10px', width: '40%', borderRadius: '5px' }} type="text" placeholder="Enter Screen Name" onChange={(e) => setScreenName(e.target.value)} /></div>
                                    <div style={{ marginTop: '2%' }}><input style={{ padding: '10px', width: '40%', borderRadius: '5px' }} type="text" placeholder="Enter Email ID" onChange={(e) => setEmailID(e.target.value)} /></div>
                                    {/* <div style={{ marginTop: '2%' }}><input style={{ padding: '10px', width: '40%', borderRadius: '5px' }} type="text" placeholder="Enter Skills" onChange={(e) => setSkills(e.target.value)} /></div> */}
                                    <div style={{ marginTop: '2%' }}><button style={{ width: '44%', backgroundColor: 'rgba(7, 178, 115, 0.725)', fontWeight: 'bold', color: '#fff', padding: '8px 15px', border: 'none', borderRadius: '10px' }} onClick={handleSearchByName}>Search</button></div>
                                </div>
                            </div> :
                            <></>
                    }

                    {freelancerByID ?
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3%' }}>{showFreelancerForInvite()}</div>
                        : freelancerByID === null ?
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3%' }}>No Freelancer Found</div>
                            : <></>
                    }

                    {freelancerByName.length > 0 ?
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '3%', gap: '5px' }}>{showFreelancerForInviteByName()}</div>
                        :
                        <></>
                    }

                    <div>{showInvitedFreelancer()}</div>

                </div>
            </div>
        </div>
    )
}


export default Invite;