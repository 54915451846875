import React, { useState, useContext, useEffect } from "react";
import { MyContext } from "../../Mycontext";
import axios from "axios";

function TotalAndAvgBidding({ JobID }) {
  const { Token, endPointUrl } = useContext(MyContext);
  const [totalbits, settotalbits] = useState("0");
  const [avgPrice, setavgPrice] = useState("0");
  const gettotalbits = async (JobID) => {
    const { data } = await axios.post(endPointUrl + "/user/job/totalBid", {
      Token,
      JobID,
    });
    const notDraft = data.data.filter((val) => val.Bid_Status !== "Draft")
    settotalbits(notDraft.length);
    calculateMedian(notDraft);
  };

  const calculateMedian = (numbers) => {
    if (numbers.length === 0) {
      setavgPrice(null);
      return;
    }
    const num = [];
    numbers.map((item) => {
      num.push(item.BidAmount);
    });

    const sortedNumbers = [...num].sort((a, b) => a - b);
    const length = sortedNumbers.length;
    const middleIndex = Math.floor(length / 2);

    if (length % 2 === 0) {
      const medianValue =
        (sortedNumbers[middleIndex - 1] + sortedNumbers[middleIndex]) / 2;
      setavgPrice(medianValue);
    } else {
      setavgPrice(sortedNumbers[middleIndex]);
    }
  };

  useEffect(() => {
    if (JobID) {
      gettotalbits(JobID);
    }

  }, [JobID]);
  return (
    <>
      <div className="avg-bite">
        <strong>$   {avgPrice} </strong>
        <small>(Avg Bid)</small>
      </div>
      <span className="total-bits">{totalbits} bids</span>
    </>
  );
}

export default TotalAndAvgBidding;
