import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
// import Selectant from "../Jobs/Select";
import {MyContext} from "../../Mycontext";
import { Select, Button } from "antd";
function Userskills() {
  const { user,endPointUrl, Token, setuser} = useContext(MyContext);
  const [show, setshow] = useState(false);
  
  // const [userskills, setuserskills] = useState([]);
  const [skillmaster, setskillmaster] = useState([]);
  const [selected, setselected] = useState(user.Skills ? user.Skills.split(",") : []);

  const filteredOptions = skillmaster.filter((o) => !selected.includes(o));
  const handleskillsmaster = async () => {
    const { data } = await axios.post(endPointUrl + "/Admin/getallskills", {
      Token,
    });
    let a = [];
    data.data.map((item) => a.push(item.Name));
    setskillmaster(a);
  };
  const updateskill = async (selected) => {
    await axios.post(endPointUrl + "/updateuserProfile", {
      Skills: selected.join(","),
      id: user.id,
      Token,
    });
    setuser({ ...user, Skills: selected.join(",") });
  };
  useEffect(() => {
    handleskillsmaster();
  }, [user]);
  return (
    <>
      {!show ? (
        <>
          <Select
            mode="multiple"
            placeholder="Add Your Skills Here"
            value={selected}
            onChange={setselected}
            style={{ width: "100%" }}
            options={filteredOptions.map((item) => ({
              value: item,
              label: item,
            }))}
          />
          <input
            type="button"
            value="ADD"
            className="btn"
            style={{ margin: "10px 0" }}
            onClick={() => {
              updateskill(selected);
              setshow(true);
            }}
          />
        </>
      ) : (
        <div>
          {selected.map((item) => (
            <strong style={{ color: "#000000", borderRadius: "15px", border: "1px solid #c6bbbb87", padding: "5px 10px",background:"white" }}>{item}</strong>
          ))}
        </div>
      )}
      {show && (
        <input
          type="button"
          value="EDIT"
          className="btn"
          style={{ margin: "10px 0" }}
          onClick={() => {
            setshow(false);
          }}
        />
      )}
    </>
  );
}

export default Userskills;
