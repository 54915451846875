import React, { useContext } from "react";
import axios from 'axios';
import { MyContext } from "../../../Mycontext";
import deletebutton from '../../../images/delet2.png'
import { message } from 'antd';

function DeleteCertificate({ Certificate, getCertificate, getCertificateProfile }) {
    const { user, endPointUrl, Token } = useContext(MyContext);

    // Delete Certificate
    const deleteCertificate = async () => {
        try {
            await axios.post(endPointUrl + "/user/deleteCertiDoc", {
                Token,
                C_Id: Certificate.C_Id
            });
            message.success("Certificate Deleted");
            getCertificate();
            getCertificateProfile();
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <>
            <div>
                <img id="del-btn" style={{height:'20px',width:'20px'}} src={deletebutton} alt="delete" onClick={() => deleteCertificate()} />
            </div>
        </>
    )
}

export default DeleteCertificate