import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { MyContext } from "../../Mycontext";
import axios from "axios";
import { message } from "antd";
import EditJob from "../Jobs/EditJob";
import DocumentUpload from "../Jobs/DocumentUpload";
import BiddingCard from "../Jobs/biddingCard";
import { Collapse } from "antd";
import { IoArrowBackSharp } from 'react-icons/io5';
import Messages from "../Messages";
import DisputedJobs from "../Jobs/DisputedJobs";
const { Panel } = Collapse;

export default function DetailviewJobEmployer() {
    const { id } = useParams();
    const { user, endPointUrl, Token, showMessg, setshowMessg, setRUser, setShowchat, showSummary, setShowSummary } = useContext(MyContext);
    const [detailview, setdetailview] = useState(false);
    const [employeview, setemployeview] = useState(true);
    const [detailjob, setdetailjob] = useState({});
    const [detailStatus, setdetailStatus] = useState({});
    const [activejob, setActivejob] = useState(0);
    const [showModal, setshowModal] = useState(false)
    let [Receiver, setReceiver] = useState();
    const [emp, setEmp] = useState({})
    const [disputedData, setDisputedData] = useState(false)
    const [disputeAttach, setDisputeAttach] = useState([])
    const [currentFreelancer, setCurrentFreelancer] = useState({})
    const [alreadyRaised, setAlreadyRaised] = useState(false)
    const [projectStatus, setProjectStatus] = useState();
    const [summaryMilestones, setSummaryMilestones] = useState([]);
    const [amountSum, setAmountSum] = useState('');
    const [summaryDuration, setSummaryDuration] = useState('');
    const [escrowedAmount, setescrowedAmount] = useState('');
    const [workHour, setWorkHours] = useState();
    const [workedHour, setWorkedHours] = useState();
    const [paidAmount, setPaidAmount] = useState();
    const [disputeStatus, setDisputeStatus] = useState();

    const getDispute = async (currentFr) => {
        try {
            const { data } = await axios.post(endPointUrl + "/IsDisputeRaised", {
                UserID: currentFr,
                EmployerID: detailjob.Employer_ID,
                JobID: detailjob.P_ID
            });
            if (data.status === true) {
                setDisputedData(data.data)
                setDisputeStatus(data.data.Status)
                const att = data.data.Attachment.split(',')
                setDisputeAttach(att)
                setAlreadyRaised(true)
            }
            const res = await axios.post(endPointUrl + "/getProjectbyId", {
                Token,
                P_ID: detailjob.P_ID,
            });

            setProjectStatus(res?.data?.data?.Project_status);
        } catch {

        }
    }

    const handleDisput = async () => {
        /*   getDispute() */
        try {
            const { data } = await axios.post(endPointUrl + "/getUserById", {
                Token,
                id: detailjob.Employer_ID,

            });
            if (data.status === true && data.data) {
                setEmp(data.data)

                setshowModal(!showModal)
            } else {
                console.log('emp data we recived is ', emp)
            }
        } catch {
            console.log('error in fetch data')
        }
    }

    useEffect(() => {
        getjobs()
    }, [])

    const getjobs = async () => {
        try {
            const { data } = await axios.post(endPointUrl + "/user/getJobsByJobId", {
                JobID: id,
                Token,
            });
            setdetailjob(data.data);
            getproject(data.data);
            getBid(data.data);
        } catch (err) {
            console.log(err);
        }
    };

    function handleReceiver(data, value) {
        setReceiver(data);
    };

    const publishJob = async (JobID, Status) => {
        try {
            await axios.post(endPointUrl + "/user/UpdateJobs", {
                Token,
                IsActive: Status === "Draft" ? false : true,
                Status: Status,
                JobID: JobID,
            });
            setActivejob(0);
            getjobs();
            setdetailview(!detailview);
            message.success(`Your Job is Now ${Status}`);
        } catch {
            message.error("Some Problem Occur Doing Publish");
        }
    };
    const UpdateStatus = async (P_ID) => {
        try {
            await axios.post(endPointUrl + "/admin/UpdateinProgresstoClosedproject", {
                Token,
                P_ID: P_ID,
            });
            getOngoingjobs();
            setdetailview(!detailview);
            message.success('Project Closed');
        } catch {
            message.error("error");
        }
    };

    const getproject = async (jobData) => {
        try {
            const { data } = await axios.post(
                endPointUrl + "/project/getMilestoneByPID",
                { P_ID: jobData?.P_ID, Token }
            );
            const mil = data.data;
            setSummaryMilestones(mil);
            setAmountSum(data.amountSum);
            setescrowedAmount(data.escrowedAmount);
            setWorkedHours(data.workedHours)
        } catch (e) {
            console.log('error while get project milestone => ', e);
        }
    };

    const getBid = async (jobData) => {
        try {

            const res = await axios.post(endPointUrl + "/getProjectbyId", {
                Token,
                P_ID: jobData.P_ID,
            });

            const { data } = await axios.post(
                endPointUrl + "/user/getBidByJobAndUserID",
                { UserID: res?.data?.data?.Awarded_To_ID, JobID: jobData.JobID, Token }
            );

            setSummaryDuration(`${data?.data?.Duration} ${data?.data?.DurationType === "Weekly" ? "Week" : "Month"}`)
            setWorkHours(data?.data?.MinHours);
        } catch (e) {
            console.log('error while get project milestone => ', e);
        }
    };

    return (
        <div className="jobProfileContainer">
            <div className='compo'>
                <Collapse defaultActiveKey={["1"]} style={{ fontFamily: "poppins" }}>
                    <Panel
                        header={
                            <div>
                                <IoArrowBackSharp
                                    style={{
                                        backgroundColor: "white",
                                        borderRadius: "50px",
                                        stroke: "#073b",
                                        padding: "2px",
                                        margin: "-8px 10px -8px 0"
                                    }}
                                    onClick={() => {
                                        window.history.back();
                                        if (showMessg) {
                                            setRUser(null);
                                            setShowchat(false);
                                            setshowMessg(false);
                                        } else {
                                            setShowchat(false);
                                            setshowMessg(false);
                                        }
                                    }}
                                />
                                <span>{detailjob?.Title}</span>
                            </div>
                        }
                        key="1"
                        showArrow={false}
                    >
                        <div style={{ color: "#606563", wordBreak: 'break-word' }} dangerouslySetInnerHTML={{ __html: detailjob?.Description }}></div>
                    </Panel>
                </Collapse>


                {disputedData ?
                    <div className='disputed__container'>
                        <>
                            <div>
                                <p className='disputeepara2' style={{ fontFamily: 'Poppins', fontWeight: '900', color: '#001', marginLeft: '1rem', marginTop: '1rem' }}>{disputedData.RaisedBy == 'Freelancer' ? `Freelancer have Raised a dispute for this project on ${new Date(disputedData.CreatedDate)}` : `You have Raised a dispute for this project on ${new Date(disputedData.CreatedDate)}`}</p>

                                <div style={{ marginLeft: '1rem' }}>
                                    <p style={{ color: '#001', fontFamily: 'Poppins', fontWeight: 'bold', marginBottom: 0, wordBreak: "break-word" }}>Description</p>
                                </div>
                                <div style={{ marginLeft: '1.001rem' }}>
                                    <div style={{ color: '#001', marginTop: '.3rem' }} dangerouslySetInnerHTML={{ __html: disputedData.Description }} />
                                </div>

                                <div style={{ marginLeft: '1rem' }}>
                                    <p style={{ color: '#001', fontFamily: 'Poppins', fontWeight: 'bold' }}>Attachment</p>
                                </div>
                                <div>
                                    {
                                        disputeAttach.map((item) => {
                                            return (
                                                <div style={{ width: 80, height: 60, overflow: 'hidden', marginLeft: '1rem' }}>
                                                    <img style={{ width: '100%' }} src={`${endPointUrl}/images/${item}`} />
                                                </div>
                                            )
                                        })
                                    }
                                </div>

                                <div className='disputee__para'>
                                    Admin Action : {disputedData.AdminAction || "Admin has not taken any action yet!"}
                                </div>

                                <p className='disputee__para'> Status: {disputedData.Status}
                                </p>
                            </div>
                        </>
                    </div>
                    : <></>}
                <BiddingCard disputeStatus={disputeStatus} change={handleReceiver} JobID={detailjob.JobID} ProjectType={detailjob.ProjectType} JobTitle={detailjob?.Title} setCurrentFreelancer={setCurrentFreelancer} getDispute={getDispute} />

            </div>

            <div>
                {detailjob?.ProjectType === "Fixed" && showSummary ?
                    <div className="sideComponent" style={{ marginBottom: "10px" }}>
                        <div>
                            <h4 style={{ margin: "0 0 5px 0" }}>
                                Summary
                            </h4>
                        </div>

                        <hr style={{ width: "100%", background: "" }} />
                        <div>
                            {" "}
                            <h4 style={{ margin: "20px 0 5px 0" }}>Total Amount</h4>
                            <div className="skillview">
                                ${amountSum}
                            </div>
                        </div>

                        <div>
                            {" "}
                            <h4 style={{ margin: "20px 0 5px 0" }}>Escrowed Amount</h4>
                            <div className="skillview">
                                ${escrowedAmount}
                            </div>
                        </div>

                        <hr style={{ width: "100%", background: "" }} />

                        <div className="row-view">
                            <h4 style={{ margin: "20px 0 5px 0" }}>Milestone</h4>

                            <div style={{ display: 'flex', width: "100%" }}>
                                <div style={{ width: "10%" }}>
                                    <div style={{ fontSize: '12px', fontWeight: 'bold', margin: "5px 0 5px 0" }}>#</div>
                                </div>
                                <div style={{ width: "30%" }}>
                                    {" "}
                                    <div style={{ fontSize: '12px', fontWeight: 'bold', margin: "5px 0 5px 0" }}>Amount</div>
                                </div>
                                <div style={{ width: "60%" }}>
                                    {" "}
                                    <div style={{ fontSize: '12px', fontWeight: 'bold', margin: "5px 0 5px 0" }}>Status</div>
                                </div>
                            </div>
                        </div>

                        <div className="row-view">
                            {
                                summaryMilestones.map((item, index) => {
                                    return (
                                        <div style={{ display: 'flex', width: "100%" }}>
                                            <div style={{ width: "10%" }}>
                                                <small>{index + 1}</small>
                                            </div>
                                            <div style={{ width: "30%" }}>
                                                {" "}
                                                <small>${item.Amount}</small>
                                            </div>
                                            <div style={{ width: "60%" }}>
                                                {" "}
                                                <small>{item.MilstoneStatus}</small>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <hr style={{ width: "100%", background: "" }} />
                        <div>
                            {" "}
                            <h4 style={{ margin: "20px 0 5px 0" }}>Project Type</h4>
                            <div className="skillview">
                                Fixed
                            </div>
                        </div>

                        {/* <hr style={{ width: "100%", background: "" }} />
                        <div>
                            {" "}
                            <h4 style={{ margin: "20px 0 5px 0" }}>Duration</h4>
                            <div className="skillview">
                                {summaryDuration}
                            </div>
                        </div>*/}

                        <hr style={{ width: "100%", background: "" }} />
                        <div>
                            {" "}
                            <h4 style={{ margin: "20px 0 5px 0" }}>Project Status</h4>
                            <div className="skillview">
                                {projectStatus === "Semi_Completed" ? "In Progess" : projectStatus}
                            </div>
                        </div> 
                    </div>
                    :
                    showSummary &&
                    (<div className="sideComponent" style={{ marginBottom: "10px" }}>
                        <div className="see-other-post">
                            <hr style={{ width: "100%" }} />
                            <div>
                                <h4 style={{ margin: "0 0 5px 0" }}>
                                    Summary
                                </h4>
                            </div>

                            <hr style={{ width: "100%", background: "" }} />
                            <div>
                                {" "}
                                <h4 style={{ margin: "20px 0 5px 0" }}>Escrowed Amount</h4>
                                <div className="skillview">
                                    ${escrowedAmount}
                                </div>
                            </div>

                            <hr style={{ width: "100%", background: "" }} />
                            <div>
                                {" "}
                                <h4 style={{ margin: "20px 0 5px 0" }}>Project Type</h4>
                                <div className="skillview">
                                    Hourly
                                </div>
                            </div>

                            <hr style={{ width: "100%", background: "" }} />
                            <div>
                                {" "}
                                <h4 style={{ margin: "20px 0 5px 0" }}>Mininum Hours</h4>
                                <div className="skillview">
                                    {workHour}
                                </div>
                            </div>


                            <div>
                                {" "}
                                <h4 style={{ margin: "20px 0 5px 0" }}>Actual Worked Hours</h4>
                                <div className="skillview">
                                    {workedHour}
                                </div>
                            </div>


                            <hr style={{ width: "100%", background: "" }} />
                            <div>
                                {" "}
                                <h4 style={{ margin: "20px 0 5px 0" }}>Paid Amount</h4>
                                <div className="skillview">
                                    ${escrowedAmount}
                                </div>
                            </div>

                            <hr style={{ width: "100%", background: "" }} />
                            <div>
                                {" "}
                                <h4 style={{ margin: "20px 0 5px 0" }}>Project Status</h4>
                                <div className="skillview">
                                    {projectStatus === "Semi_Completed" ? "In Progess" : projectStatus}
                                </div>
                            </div>
                        </div>
                    </div>)
                }

                <div className="sideComponent">
                    <div className="see-other-post">
                        {detailjob.IsActive && detailjob.Status === "Published" ? (
                            <div className="see-other-post">
                                <button
                                    className="btn"
                                    style={{ background: "#434343" }}
                                    onClick={() => publishJob(detailjob.JobID, "Draft")}
                                >
                                    Unpublish
                                </button>

                                {!detailjob.IsActive ? <EditJob
                                    button={true}
                                    Job={detailjob}
                                    getjobs={getjobs}
                                    setdetailview={setdetailview}
                                // handlejobClick={handlejobClick}
                                /> : <></>}

                            </div>
                        ) : null}
                        {detailStatus.Project_status === "in_Progress" && detailjob.Status != ("Published" || "Draft" || "Expired") ? (
                            <button
                                className="btn"
                                style={{ background: "#434343" }}
                                onClick={() => UpdateStatus(detailStatus.P_ID)}
                            >
                                Close Project
                            </button>
                        ) : null}

                        {detailjob.Status === "Draft" ? (
                            <>
                                <button
                                    className="btn"
                                    style={{ background: "#abab22de" }}
                                    onClick={() => publishJob(detailjob.JobID, "Published")}
                                >
                                    Publish
                                </button>

                                {
                                    <EditJob
                                        button={true}
                                        Job={detailjob}
                                        getjobs={getjobs}
                                        setdetailview={setdetailview}
                                    // handlejobClick={handlejobClick}
                                    />
                                }
                            </>
                        ) : null}
                        {/* <button
                      className="btn"
               
                    >
                     Close
                    </button> */}
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className="uploadDocs">
                        <h4 style={{ margin: "0 0 5px 0" }}>
                            {detailjob?.IsActive ? "Documents" : "Upload Documents"}{" "}
                        </h4>
                        <div className="uploadtags" style={{ maxWidth: '300px' }}>
                            {detailjob.Document
                                ?
                                <span>
                                    <a
                                        href={`${endPointUrl}/images/${detailjob.Document}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {detailjob.Document.substring(detailjob.Document.lastIndexOf(".") + 1) === "docx" || detailjob.Document.substring(detailjob.Document.lastIndexOf(".") + 1) === "pdf"
                                            ?
                                            detailjob.Document
                                            :
                                            <img style={{ width: '100%', maxHeight: '200px' }} src={`${endPointUrl}/images/${detailjob.Document}`} />
                                        }
                                    </a>
                                </span> : <></>
                            }
                        </div>
                    </div>
                    <hr style={{ width: "100%" }} />
                    <div className="row-view">
                        <div>
                            <h4 style={{ margin: "5px 0 5px 0" }}>Budget Details</h4>
                            <small>{detailjob?.Budget_From}-</small>
                            <small>{detailjob?.Budget_To}$</small>
                            {/* <small>{detailjob?.Budget_Type === "Monthly" ? "Month" : detailjob?.Budget_Type === "Weekly" ? "Week" : "Hour"}</small> */}
                        </div>
                        <div>
                            {" "}
                            <h4 style={{ margin: "5px 0 5px 0" }}>Job Type</h4>
                            <small>{detailjob?.Type}</small>
                        </div>
                    </div>
                    <hr style={{ width: "100%", background: "" }} />
                    <div>
                        {" "}
                        <h4 style={{ margin: "20px 0 5px 0" }}>Skills</h4>
                        <div className="skillview">
                            {detailjob?.SkillsRequired?.split(",").map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <h4 style={{ margin: "20px 0 5px 0" }}>Technology</h4>
                        <div className="skillview">
                            {detailjob?.Technology?.split(",").map((item, index) => (
                                <span>{item === "Other" ? `${detailjob.TechnologyOther}` : `${item}`}</span>
                            ))}
                        </div>

                    </div>

                    <div>
                        <h4 style={{ margin: "20px 0 5px 0" }}>Category</h4>
                        <div className="skillview">
                            {detailjob?.Category?.split(",").map((item, index) => (
                                <span key={index}>{item === "Other" ? `${detailjob.JobCategoryOther}` : `${item}`}</span>
                            ))}
                        </div>

                    </div>
                    <div>
                        <h4 style={{ margin: "20px 0 5px 0" }}>KeyWords</h4>
                        <div className="skillview">
                            {detailjob?.Keywords?.split(",").map((item, index) => (
                                <span key={index}>{item}</span>
                            ))}
                        </div>

                    </div>
                </div>
                {projectStatus === "Completed" ? <></> : showModal && detailjob?.Status === "Approved" ?
                    <DisputedJobs detailjob={detailjob} showpublished={showModal} setshowPublished={setshowModal} emp={emp} currentFreelancer={currentFreelancer} mode={'Employer'} />
                    : <></>}
                {projectStatus === "Completed" ? <></> : alreadyRaised ? <div style={{ width: 180, height: 500 }}>
                    <div style={{ width: 200, height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: 'rgba(128, 128, 128, 0.6)', color: '#fff', marginTop: '2rem', cursor: 'pointer', fontWeight: 600, border: '1px solid 2px solid rgba(128, 128, 128, 0.6)' }}
                    >
                        <p >Raise Dispute</p>
                    </div>
                </div> : projectStatus === "Completed" ? <></> : detailjob?.Status === "Approved" ? <div style={{ width: 180, height: 500 }}>
                    <div style={{ width: 200, height: 50, justifyContent: 'center', alignItems: 'center', display: 'flex', backgroundColor: '#07b273', color: '#fff', marginTop: '2rem', cursor: 'pointer', fontWeight: 600 }}>
                        <p onClick={handleDisput}>Raise Dispute</p>
                    </div>
                </div> : <></>

                }
            </div>

            <Messages />
        </div>
    )
}
