import { Box, Modal, Typography } from "@mui/material"
import { useContext } from "react";
import { MyContext } from "../Mycontext";
import { IoMdClose } from "react-icons/io";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    p: 4,
};

const ModalViewDocument = ({ open, setOpen, selectedItem, setSelectedItem, type }) => {
    const { endPointUrl } = useContext(MyContext);

    console.log('type', type)

    const handleClose = () => {
        setOpen(false)
        setSelectedItem('');
    };


    return (
        <div>
            <Modal
                id="modalOpen"
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <Typography style={{ textAlign: "right" }}>
                        <IoMdClose onClick={handleClose} style={{ fontSize: '30px', cursor: 'pointer' }} />
                    </Typography>
                    {selectedItem?.substring(selectedItem?.lastIndexOf(".") + 1) === "pdf" ?
                        <embed src={`${endPointUrl}/${type}/${selectedItem}`} width="100%" height="400px" type="application/pdf" />
                        :
                        <img
                            src={`${endPointUrl}/${type}/${selectedItem}`}
                            style={{ width: "100%", height: "400px", borderRadius: "10px" }}
                            alt="cert"
                            className="potfoliothumbail"
                        />
                    }
                </Box>
            </Modal>
        </div>
    )
}

export default ModalViewDocument;