import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { MyContext } from "../Mycontext";
import { useNavigate } from 'react-router';
import img from '../images/hire-freelancer.png'
import { motion } from 'framer-motion';
import placeholderImg from '../images/layer1.svg'

const NotificationComponent = ({ handleNotification }) => {
    const navigate = useNavigate();
    const { user, endPointUrl, Token, socket, setNewNotificationCount, notificationArticles, setNotificationArticles } = useContext(MyContext);

    //   console.log(articles);
    const markNotificationRead = async (Nid) => {
        try {
            const updatedArticles = notificationArticles.map(article => {
                if (article.notification.NotificationID === Nid) {
                    return { ...article, notification: { ...article.notification, IsRead: true } };
                }
                return article;
            });

            setNotificationArticles(updatedArticles);
            await axios.post(endPointUrl + "/admin/UserNotificationUpdate", { Token, NotificationID: Nid, IsRead: "true" })
        } catch (err) {
            console.log(err);
        }
    };

    const timeDifference = (current, previous) => {

        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const msPerMonth = msPerDay * 30;

        const elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed / 1000) + 's ago';
        } else if (elapsed < msPerHour) {
            return Math.round(elapsed / msPerMinute) + 'm ago';
        } else if (elapsed < msPerDay) {
            return Math.round(elapsed / msPerHour) + 'h ago';
        } else if (elapsed < msPerMonth) {
            const date = new Date(previous);
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        } else {
            const date = new Date(previous);
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('en-US', options);
        }
    };

    console.log('notificaiton ', notificationArticles)

    const getallNotifications = async () => {
        try {
            const { data } = await axios.post(`${endPointUrl}/admin/getNotifications`, { userId: user.id });
            // Counting unread notifications
            const unreadNotifications = data.notificationData.filter(item => !item.notification.IsRead);
            setNewNotificationCount(unreadNotifications.length);

            setNotificationArticles(data.notificationData);
        } catch (error) {
            // Handle errors here
            console.error("Error fetching notifications:", error);
        }
    }



    useEffect(() => {
        socket?.on('getNotificationJobAssign', data => {
            // setNewNotificationCount(prevCount => prevCount + 1)
            setNotificationArticles(prevNotifications => [data, ...prevNotifications]);
        });
        getallNotifications()
    }, [socket]);


    console.log(" not if  icatoin", notificationArticles)

    return (
        <motion.div
            className='notification'
            initial={{ opacity: 0, Height: 0, y: -20 }}
            animate={{ opacity: 1, Height: "300px", y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
        >
            <div className='notification-title'>
                <h5>Notification</h5>
                <p style={{ color: "#07b273" }}>mark all as read</p>
            </div>

            <ul>
                {notificationArticles.length > 0 ? (
                    notificationArticles.map((article, index) => (<>
                        <hr />
                        <li className={`notification-message ${article?.notification?.IsRead ? 'read' : 'notread'}`}
                            key={index}
                            onClick={() => {
                                handleNotification(false)
                                if (article.notification.IsRead === false) {
                                    setNewNotificationCount(prevCount => prevCount - 1)
                                    markNotificationRead(article.notification.NotificationID);
                                }
                                if (article.notification.Type === "Invitation" && (article.fromUser.Type == "Employer")) {
                                    navigate(`/freelancer-job-profile`,{state:{fromPage:"Invitation"}})
                                }
                                else if (article.notification.Type === "Invitation" && (article.fromUser.Type == "Freelancer")) {
                                    // alert("employer")
                                }
                                else if (article.notification.Type === "Message") {
                                    navigate(`/chat-user/${article?.notification?.ItemID}`)
                                }
                                else if (article.notification.Type === "ViewProfile") {
                                    // alert("Your Profile Is viewed By Employer")
                                }
                                else if ((article.notification.FromID === article.notification.ToID) && (article.fromUser.Type == "Freelancer")) {
                                    navigate(`/detail-view-job/${article?.notification?.ItemID}`);
                                }
                                else if ((article.notification.FromID === article.notification.ToID) && (article.fromUser.Type == "Employer")) {
                                    navigate(`/detail-view-jobE/${article?.notification?.ItemID}`);
                                }
                                else if (article.fromUser.Type == "Employer") {
                                    navigate(`/detail-view-job/${article?.notification?.ItemID}`);
                                } else if (article.fromUser.Type == "Freelancer") {
                                    navigate(`/detail-view-jobE/${article?.notification?.ItemID}`);
                                }
                            }

                            }
                        >
                            {article?.fromUser?.Photo ? (
                                <img src={`${endPointUrl + /images/ + article.fromUser.Photo}`} alt="User Photo" />
                            ) : (
                                <img src={img} alt="Default User Photo" />
                            )}
                            <div>
                                {article?.notification?.Message?.length > 60
                                    ? <span >{`${article?.notification?.Message.substring(0, 100)}...`}</span>
                                    : <span >{article?.notification?.Message}</span>}
                                <p>
                                    {timeDifference(new Date(), new Date(article.notification.createdAt))}
                                </p>
                            </div>
                        </li>

                    </>
                    ))) : (
                    <div className="no-notification-placeholder">
                        <img src={placeholderImg} alt="Placeholder" />
                        <p>No Notifications available</p>
                    </div>
                )}

            </ul>
            <div className='notification-base'>
                <h5 style={{ color: "#07b273", textAlign: "center", cursor: "pointer" }} onClick={() => {
                    handleNotification(false)
                    navigate("/all-notification")
                }}>See all</h5>
            </div>
        </motion.div>
    );
};

export default NotificationComponent;


