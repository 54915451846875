import React, { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import { MyContext } from '../../Mycontext'
import { useLocation, useNavigate } from 'react-router';
import Result from './Result';

function SKillQA() {
    const navigate = useNavigate();
    const { endPointUrl, Token, user } = useContext(MyContext);
    const [currentQuestion, setCurrentQuestion] = useState([]);
    const [ue_ID, setUE_ID] = useState(0);
    const [question_idx, setQuestion_idx] = useState(0);
    const [Q_ID, setQ_ID] = useState(0);
    const [Answer, setAnswer] = useState(0);
    const [show, setShow] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [totalTime, setTotalTime] = useState(0);
    const location = useLocation();

    const getSkilltest = async () => {
        const { data } = await axios.post(endPointUrl + "/admin/getquestionsforskilltest", {
            Token,
            SKT_ID: location.state.SKT_ID,
            User_ID: user.id
        })
        setCurrentQuestion([data.data.selectedQuestions])
        setUE_ID(data.data.userExam.UE_ID)
        setTotalTime((location.state.duration) * 60)
    };
    useEffect(() => {
        startQuizTimer((location.state.duration) * 60)//Quiz time in seconds
    }, [ue_ID])
    const updateQA = async () => {    // updates answer on every next click
        try {
            await axios.post(endPointUrl + "/updatequesAnswer", {
                Token,
                Ques_ID: Q_ID,
                User_ID: user.id,
                User_Answer: parseInt(Answer)
            });
            setAnswer(0);
        } catch { }
    };
    const submitScore = async () => {  //final score submit
        try {
            await axios.post(endPointUrl + "/updateexamScore", {
                Token,
                UE_ID: ue_ID,
                User_ID: user.id,
            })
            setShow(true);
        } catch { }
    };
    const startQuizTimer = (totalDurationInSeconds) => {
        setRemainingTime(totalDurationInSeconds);
        const timer = setInterval(() => {

            setRemainingTime((prevTime) => {
                if (prevTime > 0) {
                    return prevTime - 1;
                } else {
                    // debugger
                    handleSub();
                    clearInterval(timer);
                    return 0;
                }
            });
        }, 1000);
    };

    const handleclick = (int) => {
        setQuestion_idx(question_idx + int);
        updateQA();
    }
    const handleInput = (answer, Ques) => {
        setAnswer(answer);
        setQ_ID(Ques);
        return false;
    }
    const handleSub = async () => {
        updateQA()
        submitScore();
    }
    const progressPercentage = ((totalTime - remainingTime) / totalTime) * 100;
    useEffect(() => {
        getSkilltest();
    }, [])
    return (
        <div>
            {/* <div style={{ padding: "20px" }}>
                <button className='mil_buton' onClick={() => navigate(-1)}>Back To Quiz</button>
            </div> */}

            <div className='QA-container'>
                {show ? (<Result UE_ID={ue_ID} SKT_ID={location.state.SKT_ID} />)

                    : (Array.isArray(currentQuestion[0]) ?
                        currentQuestion[0].map((item, index) =>
                            <div key={index}>
                                {index == question_idx ?
                                    <>
                                        <section className='Question-section'>
                                            <h2>Question: {question_idx + 1} / {currentQuestion[0].length}</h2>
                                            <p>{item.Question}</p>
                                            <div className='timer'>Time Remaining : {Math.floor(remainingTime / 60)} : {remainingTime % 60} </div>
                                        </section>
                                        <div className='progress-bar-container-skilltest'>
                                            <div className='progress-bar-skilltest' style={{ width: `${progressPercentage}%` }}></div>
                                        </div>
                                        <section className='Answer-section'>
                                            <div className="radio-input-wrapper">
                                                <label className="label">
                                                    <input value={1} name="value-radio" id="value-1" className="radio-input" type="radio" onClick={(e) => handleInput(e.target.value, item.Ques_ID)} />
                                                    <div className="radio-design"></div>
                                                    <div className="label-text">{item.Option1}</div>
                                                </label>
                                                <label className="label">
                                                    <input value={2} name="value-radio" id="value-2" className="radio-input" type="radio" onClick={(e) => handleInput(e.target.value, item.Ques_ID)} />
                                                    <div className="radio-design"></div>
                                                    <div className="label-text">{item.Option2}</div>
                                                </label>
                                                <label className="label">
                                                    <input value={3} name="value-radio" id="value-3" className="radio-input" type="radio" onClick={(e) => handleInput(e.target.value, item.Ques_ID)} />
                                                    <div className="radio-design"></div>
                                                    <div className="label-text">{item.Option3}</div>
                                                </label>
                                                <label className="label">
                                                    <input value={4} name="value-radio" id="value-4" className="radio-input" type="radio" onClick={(e) => handleInput(e.target.value, item.Ques_ID)} />
                                                    <div className="radio-design"></div>
                                                    <div className="label-text">{item.Option4}</div>
                                                </label>
                                            </div>
                                            <div className='Answer-section-footer' style={{ justifyContent: index > 0 ? "space-between" : "right" }}>
                                                {index > 0 ?
                                                    <button className='btn-outline' onClick={() => handleclick(-1)}>Back</button> : null}
                                                {index < (currentQuestion[0].length - 1) ?
                                                    <button className='mil_buton' onClick={() => handleclick(1)}>Next</button>
                                                    : <button className='mil_buton' onClick={() => handleSub()}>Submit</button>}
                                            </div>

                                        </section>
                                    </> : null}
                            </div>
                        ) : null)
                }

            </div >
        </div>
    )
}

export default SKillQA