import React, { useState, useEffect, useContext } from "react";
import { MyContext } from "../../Mycontext";


function ImageModal({ showmodel,setShowModal,img}) {

   const CloseModel=()=>{
     setShowModal(!showmodel)
    
   }

   const { endPointUrl } = useContext(MyContext);
  return (
    <div
    id="myModal"
    className={showmodel ? "model-open" : "model"}
    style={{ overflow: "scroll" }}
    >
      <span className="close" onClick={CloseModel}>
          &times;
        </span>


        <img src={`${endPointUrl}/images/${img}`}></img>
    </div>
  );
}

export default ImageModal;
