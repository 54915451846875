import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios';
import { MyContext } from '../../Mycontext';
import { useParams } from "react-router-dom";

export default function GuideOne() {
    const { user, endPointUrl, Token } = useContext(MyContext);
    const [GuideIndex, setGuideIndex] = useState(0);
    const [Guide, setGuide] = useState([]);
    const { GID } = useParams();

    const getGuides = async () => {

        const { data } = await axios.post(endPointUrl + "/admin/guidedatafind", {
            Token,
        });
        setGuide(data.data);


    };
    const handleClick = (GID) => {
        document.documentElement.scrollTop = 0;
        setGuideIndex(GID);
    }
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        setGuideIndex(GID);
        getGuides();
    }, [])
    return (
        <div className='HowItWorks'>
            <div className="home-howitwork">
                <Link to="/">Home </Link>/{" "}
                <Link to="/digi-guide">DigiGuide</Link>
            </div>
            <span className="jobpostheading" style={{ backgroundColor: "#ceefe4" }}>
                <div>
                    Digilanxer Guide
                </div>

            </span>
            <div className='Digilanxer-guide-container'>
                <div className='Digilanxer-guide-sidepanel'>
                    {Guide.map((item, index) =>
                        <span onClick={() => handleClick(item?.GID)}
                            style={GuideIndex == item?.GID ? { color: "#07b273" } : null}>
                            <p>{index + 1}.</p> {item?.Guide_Title}</span>
                    )}

                </div>
                {Guide.map((item, index) =>
                    item?.GID == GuideIndex ?
                        <div className='Digilanxer-guide-main' key={item.GID}>
                            <h1>{item?.Guide_Title}</h1>
                            <div dangerouslySetInnerHTML={{ __html: item?.Description }} />
                        </div>
                        : null
                )}
            </div>

        </div>
    )
}