import React from 'react'
import { Link } from 'react-router-dom'

export default function TermsCondition() {
    return (
        <div className='HowItWorks privacy-footer'>
            <div className="home-howitwork">
                <Link to="/">Home </Link>/{" "}
                <Link to="/terms-condition">Terms and Conditions</Link>
            </div>
            <span className="jobpostheading" style={{ backgroundColor: "#ceefe4", paddingRight: "0 40px 0 40px" }}>
                <div>
                    Terms and conditions
                    {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur, perspiciatis.</p> */}
                </div>
            </span>
            <div className='footer-terms'>
                <div class="footer-terms-heading">
                    Digilanxer is an online platform that connects employers with freelancers, facilitating seamless collaboration and project delivery. However, it is important for both employers and freelancers to understand and abide by the terms and conditions set forth by Digilanxer to ensure a fair and transparent working relationship.
                </div>
                <section>
                    <div className='footer-terms-main'>
                        <h3>
                            Employers' Responsibilities
                        </h3>
                        <span>
                            Employers utilizing Digilanxer's platform must understand and acknowledge their assumed payment liabilities. This includes transferring funds to Digilanxer in respect of an invoice or utilizing the PayPal service for secure payment transactions. By accepting services from freelancers on the platform, employers also agree to abide by the terms and conditions set by Digilanxer for fair compensation and project completion.
                        </span>
                    </div>
                    <div className='footer-terms-main'>
                        <h3>
                            Freelancers' Obligations
                        </h3>
                        <span>
                            On the other hand, freelancers must also understand their responsibilities when using Digilanxer's platform. This includes delivering services as agreed upon with the employer, ensuring high-quality work, and complying with the platform's terms and conditions regarding payment processing and project delivery. Freelancers must also adhere to the PayPal service requirements to ensure secure and prompt payment for their services.
                        </span>
                    </div>
                    <div className='footer-terms-main'>
                        <h3>
                            Assumed Payment Liabilities
                        </h3>
                        <span>
                            One crucial aspect of Digilanxer's terms and conditions is the concept of assumed payment liabilities. Employers are responsible for transferring funds in respect of invoices
                            or utilizing the PayPal service for secure transactions, ensuring that freelancers are compensated fairly for their work.
                            This serves to protect both parties and maintain a professional and trustworthy environment for conducting business on the platform.

                            <ul>
                                <li>
                                     In the event that the employer is not satisfied with the freelancer's work, at least two warning emails will be sent to the freelancer, providing an opportunity for improvement
                                    . If the issues persist, a five-day notice will be given to discontinue the services.

                                </li>

                                <li>

                                     For projects of short duration, the employer will pay the Digilanxer in one installment before the commencement of the project.
                                </li>
                                <li>

                                     Payment for projects of longer duration will be made in milestones as per the project schedule and work completed.
                                </li>
                                <li>

                                     It is essential for both parties, the employer and the freelancer, to provide prior information before leaving or discontinuing the project.
                                </li>
                            </ul>
                            We believe that these guidelines will help in fostering a transparent and professional working environment. Your adherence to these guidelines is greatly appreciated.
                        </span>
                    </div>
                    <div className='footer-terms-main'>
                        <h3>
                            Employer's Acceptance of Services
                        </h3>
                        <span>
                            Another important element within Digilanxer's terms and conditions is the employer's acceptance of services. This refers to the transfer of funds by employers to Digilanxer for invoices or the use of the PayPal service to validate and authorize payment for the services provided by freelancers. Understanding and complying with this aspect of the terms and conditions is essential for both employers and freelancers to maintain a harmonious and efficient work relationship on the platform.
                        </span>
                    </div>
                </section>
            </div>
        </div>
    )
}
