import React, { useState, useContext } from "react";
import { MyContext } from "../../Mycontext";
import { message } from "antd";
import { useNavigate } from "react-router";
import axios from "axios";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function DisputedJobs({ setshowPublished, showpublished, detailjob, emp, mode, currentFreelancer }) {
  const { endPointUrl, Token, user, socket } = useContext(MyContext);
  const Navigate = useNavigate();
  const [showmodel, setshowModel] = useState(true);
  const [fileSizes, setFileSizes] = useState([]);
  const [files, setFiles] = useState([]);
  const [disputeDesc, setDisputeDesc] = useState('');
  const CloseModel = () => {
    setshowModel(false);
    setshowPublished(!showpublished);
  };
  const handleSubmit = async () => {
    if (disputeDesc.length === 0) {
      message.warning("Description is Requied")
      return;
    }
    if (disputeDesc.length <= 50) {
      message.warning("Description atleast have 200 character!")
      return;
    }

    try {
      const formData = new FormData();
      formData.append("Token", Token);
      formData.append("ProjectID", detailjob.P_ID);
      formData.append("JobID", detailjob.JobID);
      formData.append("JobTitle", detailjob.Title);
      formData.append("RaisedBy", mode == 'Freelancer' ? "Freelancer" : "Employer");
      formData.append("FreelancerID", mode == 'Freelancer' ? user.id : currentFreelancer);
      formData.append("EmployerID", emp.id);
      formData.append("Description", disputeDesc);
      formData.append("Status", "Pending");
      formData.append("AdminComments", "");
      formData.append("ResolutionDate", null);
      formData.append("Resolved", false);
      /* formData.append("files", files[0]) */
      // Append each file to the FormData object
      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }
      const response = await axios.post(
        endPointUrl + "/RaiseDispute",
        formData
      );

      /* 
      console.log('return values are ', response.data); */
      setshowPublished(!showpublished);
      message.success('Dispute raised succesfully')
    } catch (error) {
      console.error("Failed to raise dispute:", error);
      message.error("Failed to raise dispute");
    }
  };

  const handleSubmit2 = async () => {
    try {
      const formdata = new FormData();

      files.forEach((item) => formdata.append("File_Upload", item));

      try {
        const response = await axios.post(
          endPointUrl + "/RaiseDispute",
          formdata,
          {
            params: {
              Token,
            },
          }
        );
      } catch (error) {
        console.error("Error uploading documents:", error);
      }

      message.success("Dispute Raised");
    } catch (error) {
      console.error("Error uploading documents:", error);
    }
  };

  const onEditorChange = (evt, editor) => {
    const data =editor.getData();
    setDisputeDesc(data);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    setFiles(event.target.files);
    const sizes = Array.from(files).map((file) => ({
      name: file.name,
      size: file.size / (1024 * 1024),
      type: file.type,
    }));
    setFileSizes(sizes);
  };

  const renderFileIcon = (file) => {
    if (file.type && file.type.includes("image")) {
      // Check if file type includes "image"
      return (
        <img
          src={file.path}
          alt="image"
          style={{ maxWidth: "100px", maxHeight: "100px" }}
        />
      );
    } else {
      return <FaFileAlt />;
    }
  };

  return (
    <div
      id="myModal"
      className={showmodel ? "model-open" : "model"}
      style={{ overflow: "scroll" }}
    >
      <div className="modal-content publish">
        <span className="close" onClick={CloseModel}>
          &times;
        </span>



        <div className="publishButton">

          <div style={{ margin: 0, padding: 1 }}>
            <div style={{ margin: 0, padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h3 style={{}}>Job Title</h3>
              <span style={{ fontSize: "14px", fontWeight: "400", marginLeft: 12 }}>
                {detailjob.Title}
              </span>
            </div>

            <div className="viewSkills" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <h3 style={{ marginBlockStart: 0, marginBlockEnd: 0 }}>Employer Name</h3>
              <div style={{ marginLeft: 9 }}>
                <div>{emp.Name}</div>
              </div>
            </div>

          </div>

          <div className="btn" onClick={() => handleSubmit(true)}>
            Raise Dispute
          </div>

        </div>
        <hr />

        <div></div>


        <div>
          <div>
            <h3 style={{ fontWeight: "600", display: "block" }}>Description</h3>
            <CKEditor
              data={disputeDesc}
              editor = {ClassicEditor}
              // readOnly={editable}
              
              onChange={onEditorChange}
            />
          </div>

          <div>
            <h3 style={{ fontWeight: "600", display: "block" }}>Attachment</h3>
            <div>
              <input type="file" onChange={handleFileChange} multiple />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisputedJobs;
