import React, { useEffect, useState, useContext } from 'react'
import { CLIENT_ID } from '../../Config/Config'
import img1 from "../../images/Package Icon/basic.png"
import img2 from "../../images/Package Icon/professional.png"
import img3 from "../../images/Package Icon/premium.png"
import { BsArrowRight } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { MyContext } from "../../Mycontext";
import { BsArrowRightCircle } from 'react-icons/bs'
import { message } from 'antd'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { FUNDING } from "@paypal/react-paypal-js";
import Accordions from './Accordion'
import PaymentModal from './PaymentModal'
import { Button } from '@mui/material'

function Package() {
  const { endPointUrl, Token, user } = useContext(MyContext);
  const [balance, setBalance] = useState();
  const [show, setShow] = useState({
    show1: false,
    show2: false,
    show3: false
  });
  const [Orderdata, setOrderdata] = useState({
    ID: "",
    status: "",
    time: ""
  });
  const [value, setValue] = useState(10);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSecond, setOpenSecond] = useState(false);
  const [openThird, setOpenThird] = useState(false);
  const navigate = useNavigate();

  const BidBalance = async () => {
    const { data } = await axios.post(endPointUrl + "/getuserByBidBalance", {
      Token,
      id: user.id,
    })
    setBalance(data.Priceperbid);

  };
  // creates a paypal order
  const createOrder = (value, data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: "Bids",
          amount: {
            currency_code: "USD",
            value: balance * value,
            // value: 1,
          },
        },
      ],
    }).then((orderID) => {
      setValue(value);
      setOrderID(orderID);
      return orderID;
    });
  };

  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (orderData) {
      // const { payer } = details
      setOrderdata({
        ID: orderData.id,
        status: orderData.status,
        time: orderData.create_time
      })
      setSuccess(true);
    });
  };

  //capture likely error
  const onError = (data, actions) => {
    setErrorMessage("An Error occured with your payment ");
  };

  useEffect(() => {
    if (success) {
      // console.log('Order successful . Your order id is--', orderID);
      updateBidBalance(value);
      Subscription();
    }
  }, [success]);
  const Subscription = async () => {
    try {
      await axios.post(endPointUrl + "/user/subscribeUser", {
        Token,
        UserID: user.id,
        Subscription_Date: Orderdata.time,
        NumOfbids: value,
        Amount: balance * value,
        Payment_Reference: Orderdata.ID,
        Payment_Mode: "PayPal",
        Payment_Status: Orderdata.status,
        Subscription_Status: "Subscribed",
      });
      message.success("Subscription added");
      navigate("/thank-you")
    } catch {
      message.error("Subscription not added");
    }
  };
  const updateBidBalance = async (value) => {
    // console.log(value);
    sessionStorage.setItem("data", JSON.stringify({ ...user, Bid_Balance: user.Bid_Balance + value }));
    try {
      await axios.post(endPointUrl + "/updateBidBalance", {
        Token,
        id: user.id,
        value: value,
      });

    } catch {
      console.log("Subscription not added");
    }
  };

  useEffect(() => {
    BidBalance();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);


  return (
    <>
      <div style={{ margin: "20px 4%" }}>
        <span className="jobpostheading" style={{ backgroundColor: "#ceefe4" }}>
          <div id="nnn">
            Maximize your success and earnings
            <p>Try a Digilanxer Membership to enjoy greater benefits.</p>
          </div>

          <img src={require("../../images/Package Icon/banner.png")} alt="" style={{ marginTop: "20px" }} />
        </span>
      </div>
      <PayPalScriptProvider options={{ "client-id": CLIENT_ID, "enable-funding": "venmo", }}>
        <div className='Container'>

          <div className="header_Container">
            <p>A plan for everyone</p>
            <h2>Affordable Plans</h2>
          </div>
          <div className="cards_Container">
            <div className="pricing_card">
              <div className="pricing_feature">
                <div className='pricing_upper'>
                  <h2>Basic</h2>
                  <img src={img1} alt="" />
                  <div className='upper_header'>
                    <span className='pricing_rate'>${balance * 10}</span>

                  </div>
                  <span >10 Bids</span>
                </div>
                <div className='pricing_lower'>
                  <span>Bids available throughout the year</span>
                  <hr />
                  <span>No expiry</span>
                  <hr />
                  <span>All feature Access</span>
                  <hr />
                  <span>Email Notificationn</span>
                  <hr />
                  <span>Quick Support</span>
                  <hr />
                  <span>Limited Oppurtunities</span>
                  <hr />

                </div>

              </div>

              {balance ?
                // <PayPalButtons
                //   fundingSource={FUNDING.PAYPAL}
                //   style={{ layout: "vertical", color: "black", tagline: false }}
                //   createOrder={(data, actions) => createOrder(10, data, actions)}
                //   onApprove={onApprove}
                // />
                <>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{ background: "#07B274", fontWeight: "bold", "&:hover": { background: "#07B274" } }}
                      onClick={() => setOpen(true)}
                      variant='contained'
                      fullWidth
                      size='large'
                    >
                      Subscribe
                    </Button>
                  </div>
                  {open && <PaymentModal open={open} setOpen={setOpen} createOrder={createOrder} value={10} onApprove={onApprove} />}
                </>
                : null}
              {/* <button className='btn' style={{marginBottom:"20px"}}
               onClick={()=>{setShow({show1:!show.show1})}}
              //  onClick={()=>{updateBidBalance(10); navigate("/ThankU")}}
               >Choose Plan<BsArrowRight style={{strokeWidth:"inherit"}}/></button>
              {show.show1 ? 
                    <PayPalButtons
                        fundingSource={FUNDING.PAYPAL}
                        style={{ layout: "vertical",color:"white",shape:"pill" }}
                        createOrder={(data,actions) =>createOrder(10,data,actions)}
                        onApprove={onApprove}
                    />
                    : null} */}
            </div>
            <div className="pricing_card">
              <div className="pricing_feature">
                <div className='pricing_upper'>
                  <h2 >Professional</h2>
                  <img src={img2} alt="" />
                  <div className='upper_header'>
                    <span className='pricing_rate'>${balance * 30}</span>

                  </div>
                  <span >30 Bids</span>
                </div>
                <div className='pricing_lower'>
                  <span>Bids available throughout the year</span>
                  <hr />
                  <span>No expiry</span>
                  <hr />
                  <span>All feature Access</span>
                  <hr />
                  <span>Email Notificationn</span>
                  <hr />
                  <span>Quick Support</span>
                  <hr />
                  <span>Top listed jobs</span>
                  <hr />
                  <span>Preferred Freelancer Eligible</span>
                  <hr />
                  <span>Dedicated account manager to help you find and hire quality talents</span>
                  <hr />
                  <span>Unlimited Job Search</span>
                  <hr />
                </div>

              </div>
              {balance ?
                // <PayPalButtons
                //   fundingSource={FUNDING.PAYPAL}
                //   style={{ layout: "vertical", color: "black" }}
                //   createOrder={(data, actions) => createOrder(30, data, actions)}
                //   onApprove={onApprove}
                // />
                <>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{ background: "#07B274", fontWeight: "bold", "&:hover": { background: "#07B274" } }}
                      onClick={() => setOpenSecond(true)}
                      variant='contained'
                      fullWidth
                      size='large'
                    >
                      Subscribe
                    </Button>
                  </div>
                  {openSecond &&
                    <PaymentModal open={openSecond} setOpen={setOpenSecond} createOrder={createOrder} value={30} onApprove={onApprove} />
                  }</>
                : null}
            </div>
            <div className="pricing_card">
              <div className="pricing_feature">
                <div className='pricing_upper'>
                  <h2>Premium</h2>
                  <img src={img3} alt="" />
                  <div className='upper_header'>
                    <span className='pricing_rate'>${balance * 50}</span>

                  </div>
                  <span >50 Bids</span>
                </div>
                <div className='pricing_lower'>
                  <span>Bids available throughout the year</span>
                  <hr />
                  <span>No expiry</span>
                  <hr />
                  <span>All feature Access</span>
                  <hr />
                  <span>Email Notificationn</span>
                  <hr />
                  <span>Quick Support</span>
                  <hr />
                  <span>Top listed jobs</span>
                  <hr />
                  <span>Preferred Freelancer Eligible</span>
                  <hr />
                  <span>High value project bidding</span>
                  <hr />
                  <span>Dedicated team of Advisors to help yoou find and hire quality talents</span>
                  <hr />
                  <span>Unlimited Job Search</span>
                  <hr />
                </div>

              </div>
              {balance ?
                // <PayPalButtons
                //   fundingSource={FUNDING.PAYPAL}
                //   style={{ layout: "vertical", color: "black" }}
                //   createOrder={(data, actions) => createOrder(50, data, actions)}
                //   onApprove={onApprove}
                // />
                <>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      sx={{ background: "#07B274", fontWeight: "bold", "&:hover": { background: "#07B274" } }}
                      onClick={() => setOpenThird(true)}
                      variant='contained'
                      fullWidth
                      size='large'
                    >
                      Subscribe
                    </Button>
                  </div>
                  {openThird &&
                    <PaymentModal open={openThird} setOpen={setOpenThird} createOrder={createOrder} value={50} onApprove={onApprove} />
                  }</>
                : null}
            </div>
          </div>
          <div className='pricing_accordion'>
            <h2 >Frequently Asked Questions</h2>
            <Accordions />
            <div className="jobs-card-open" style={{ marginBottom: "40px" }}>
              <button className="btn-outline" style={{ marginTop: "40px" }} >Need more help<BsArrowRightCircle /></button>
            </div>
          </div>
        </div>
      </PayPalScriptProvider>


    </>
  )
}

export default Package