import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../../Mycontext";
import Invoices from "../invoince/Invoices";
import axios from "axios";
function TotalSpent() {
  const { endPointUrl, user, Token, platformRevnue, setPlatformRevenue } = useContext(MyContext);
  const [Sum, setSum] = useState({
    PaymentInEscrowSum: 0,
    TotalAmountSpentSum: 0,
    pendingPaymentSum: 0
  });
  const [invoiceShow, setInvoiceShow] = useState(false)
  const [Project, setProject] = useState({
    PaymentInEscrow: [],
    PendingPayment: [],
    TotalSpent: []
  });
  const [Projecter, setProjecter] = useState([]);
  const [PaymentActive, setPaymentActive] = useState(0);

  const getemployerfinance = async () => {
    const { data } = await axios.post(endPointUrl + '/admin/getemployerfinance', {
      Token,
      Awarded_By_ID: user.id
    })
    let escrow = data.data[0].employers.filter((item) => {
      return item.PaymentInEscrow > 0;
    })
    let pending = data.data[0].employers.filter((item) => {
      return item.PendingPayment > 0;
    })
    let total = data.data[0].employers.filter((item) => {
      return item.TotalSpent > 0;
    })
    setProjecter(total);
    setProject({ PaymentInEscrow: escrow, PendingPayment: pending, TotalSpent: total })
    setSum({ PaymentInEscrowSum: data.data[0].PaymentInEscrowSum, TotalAmountSpentSum: data.data[0].TotalAmountSpentSum, pendingPaymentSum: data.data[0].pendingPaymentSum });
  };
  const handlePaymentClick = (buttonIndex) => {
    setPaymentActive(buttonIndex);
  };
  useEffect(() => {
    getemployerfinance();
    getPlatformRevenue();
  }, [])

  const getPlatformRevenue = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/platformRevenue", { Token, Setting_ID: 1 })
      setPlatformRevenue(data.PlatformRevenue);
    } catch (err) {
      console.log(err)
    }
  }


  // Function to calculate total amount
  const calculateTotalAmount = (result) => {
    const totalAmount = (result / (100 - platformRevnue)) * 100;
    return totalAmount.toFixed(0); // Adjust the decimal places as needed
  };

  return (
    <div style={{ display: "grid" }}>
      <div className="jobMatch-inside">

        <span
          style={
            PaymentActive === 0
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(false)
            setProjecter(Project.TotalSpent)
            handlePaymentClick(0);
          }}
        >
          Payment debited
        </span>
        <p>|</p>
        <span
          style={
            PaymentActive === 1
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(false)
            setProjecter(Project.PaymentInEscrow)
            handlePaymentClick(1);
          }}
        >
          Escrowed payment
        </span>
        <p>|</p>
        <span
          style={
            PaymentActive === 3
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(false)
            setProjecter(Project.PendingPayment)
            handlePaymentClick(3);
          }}
        >
          Pending Payment
        </span>
        <p>|</p>
        <span
          style={
            PaymentActive === 4
              ? {
                color: "#07b273",
                fontWeight: "600"
              }
              : null
          }
          onClick={() => {
            setInvoiceShow(true)
            handlePaymentClick(4);
          }}
        >
          Invoices
        </span>

      </div>

      <div>
        {invoiceShow ?
          <div>
            <Invoices />
          </div>
          :
          <div>
            {Projecter.map((item, index) => <>
              <div className="current-jobs payment-container" key={index}>
                <div >
                  <span
                    className="job-name">
                    {item.jobs.Title}
                  </span>
                  <p dangerouslySetInnerHTML={{ __html: item?.jobs?.Description?.slice(0, 300) + '...' }}></p>

                  {/* <p>{item.jobs.ShortDesc}</p> */}
                  <div className="current-tags">
                    {item?.jobs?.SkillsRequired?.split(",").map((val) =>
                      val.length > 0 ? <span>{val}</span> : null
                    )}
                  </div>
                  {/* <div id="keywords" >
                  {(item?.jobs.Keywords ? item.jobs.Keywords.split(",") : []).map(
                    (skill) => {
                      return skill.length > 0 ? (
                        <span style={{ background: "none", color: "gray" }}> #{skill}</span>
                      ) : null;
                    }
                  )}
                </div> */}
                </div>
                {/* <hr /> */}
                <div className="payment-jobs-amount">
                  {PaymentActive === 0 ? <span ><p>${calculateTotalAmount(item.TotalSpent)}</p>  ( Amount Spent ) </span>
                    : PaymentActive === 1 ? <span ><p>${calculateTotalAmount(item.PaymentInEscrow)}</p>  ( Amount in Escrow ) </span>
                      : <span ><p>${calculateTotalAmount(item.PendingPayment)}</p>  ( Pending Amount ) </span>}

                </div>
              </div> <hr /> </>)
            } </div>

        }
      </div>
    </div>

  )
}

export default TotalSpent