import logo from "../images/Logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState, useRef } from "react";
import { MyContext } from "../Mycontext";
import Profile from "../images/emplyeer.png";
import notification from "../images/notification.png";
import message from '../images/message.svg';
import Sidebar from "./user-profile/Sidebar";
import NotificationComponent from './Notification';
import axios from "axios";

const Header = () => {
  const { user, endPointUrl, Token, notificationCount, setNewNotificationCount, setUnreadUsers, UnreadUsers, socket } = useContext(MyContext);
  const Navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [sidebar, setsidebar] = useState(false);
  const [notifShow, setnotifShow] = useState(false);

  useEffect(() => {
    socket?.on('getNotificationJobAssign', data => {
      setNewNotificationCount(prevCount => prevCount + 1);
    });
    socket?.on('posted_job', (data) => {
      setNewNotificationCount(prevCount => prevCount + 1);
    });

    socket?.on('getNotificationProfileViewed', (data) => {
      setNewNotificationCount(prevCount => prevCount + 1);
    });
  }, [socket]);

  const sidebarRef = useRef(null);
  const notificationRef = useRef(null);

  const handleMenu = (value) => {
    setshow(value);
  };
  const handleSidebar = (sidebar) => {
    setsidebar(!sidebar);
  };
  const handleNotification = (notifShow) => {
    setnotifShow(notifShow);
  };

  const fetchNotifications = async () => {
    const { data } = await axios.post(`${endPointUrl}/admin/UserNotificationCount`, { Token, UserID: user.id });
    setNewNotificationCount(data.data)
  };

  const getChatUsers = async () => {
    try {
      const { data } = await axios.post(endPointUrl + "/admin/chatusers", {
        Token,
        UserID: user.id,
        Type: user?.Type,
      })
      const chatData = data.data;
      const unreadCount = chatData?.filter((chat) => !chat.lastChat.IsRead && chat.lastChat.sender != user?.id);
      const unreadusersID = await unreadCount?.map((chat) => chat?.user?.id);
      setUnreadUsers(new Set(unreadusersID));
    } catch (error) {
      console.log("error while get chat users")
    }
  }

  useEffect(() => {
    if (user && user.id) {
      console.log("USERDATA", user);
      fetchNotifications();
      getChatUsers();
    }
  }, [user]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setsidebar(false);
      }

      if (notificationRef.current && !notificationRef.current.contains(event.target)) {
        setnotifShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebar, notifShow]);

  return (
    <>
      <div>
        <nav className="group-nav">
          <div className="nav">
            <div className="menu-logo">
              <span
                className="material-symbols-outlined menubar"
                onClick={() => handleMenu(!show)}
              >
                menu
              </span>
              <div className="logo">
                <Link to="/">
                  <img src={logo} alt="digilanxer logo" />
                </Link>
              </div>
            </div>
            <div className="links">
              <ul className="nav-links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/freelancers">Hire Freelancers</Link>
                </li>
                <li>
                  <Link to="/jobs">Find Jobs</Link>
                </li>
                <li>
                  <Link to="/how-it-works">How It Works</Link>
                </li>
                <li>
                  <Link to="/why-digilanxer">Why Digilanxer</Link>
                </li>
                {user?.Type === "Freelancer" ?
                  < li >
                    <Link to="/certification">Certification</Link>
                  </li> : <></>}
                {user ?
                  user.Type === "Employer" ?
                    < li >
                      <Link>Product</Link>
                      <div class="dropdown">
                        <Link class="dropdown-content">
                          <Link to="#">Work Monitoring</Link>
                          <Link to="#">Digital Marketing Tool</Link>
                        </Link>
                      </div>
                    </li> : <></>
                  : < li >
                    <div class="dropdown">
                      <Link>Product</Link>
                      <Link class="dropdown-content">
                        <Link to="#">Work Monitoring</Link>
                        <Link to="#">Digital Marketing Tool</Link>
                      </Link>
                    </div>
                  </li>}
                {user ?
                  user.Type === "Employer" ?
                    < li >
                      <Link to="/partner-with-us">Partner With Us</Link>
                    </li> : <></>
                  : < li >
                    <Link to="/partner-with-us">Partner With Us</Link>
                  </li>}
              </ul>
            </div>

            <div className="buttons" style={{ gap: "10px" }} >
              {user?.Type === "Employer" || !user?.Type ? (
                <div className="post-job" style={{ paddingBlock: "10px" }}>
                  {user?.profile_progress > 50 ?
                    <button className="mil_buton" style={{ borderRadius: "5px" }} onClick={() => {
                      if (!user?.Type) {
                        Navigate("./login")
                      } else {
                        Navigate("./job-creation")
                      }
                    }}>Post A Job</button>
                    : !user?.Type ?
                      <button className="mil_buton" style={{ borderRadius: "5px" }} onClick={() => Navigate("./login")}
                      >Post a Job</button>
                      :
                      <div className="iconTool icon_danger">
                        <button className="mil_buton disabled" style={{ borderRadius: "5px" }}
                        >Post a Job</button>
                        <div className="tooltip tooltip_dang">
                          Complete your profile atleast 50%
                        </div>
                      </div>}
                </div>
              ) : null}

              {!user?.Type ? (
                <div className="login-signup">
                  <button onClick={() => Navigate("./login")}>Login</button>
                  <button onClick={() => Navigate("./signup")}>Sign Up</button>
                </div>
              ) : (
                <>
                  <div className="search-noti"
                    style={{ gap: "10px" }}
                  >

                    <span style={{ position: "relative" }}>
                      <img src={message} alt="message"
                        style={{ cursor: "pointer" }}
                        onClick={() => Navigate("/chat-user")}
                      />
                      {UnreadUsers?.size > 0 &&
                        <div className="notification-badge" style={{ position: "absolute", top: '-5px', right: "-5px" }}>
                          {UnreadUsers?.size}
                        </div>}
                    </span>
                    <span style={{ position: "relative" }}>
                      <img
                        src={notification}
                        alt="notification"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleNotification(!notifShow)}
                      />
                      {notificationCount > 0 && (
                        <div className="notification-badge" style={{ position: "absolute", top: '-4px', right: "0", }}> {notificationCount}</div>
                      )}
                    </span>
                  </div>
                  <div
                    className="userName"
                    onClick={() => handleSidebar(sidebar)}
                  >
                    <img
                      className="header-img"
                      src={
                        user.Photo === "" || user.Photo == null
                          ? Profile
                          : `${endPointUrl}/images/${user.Photo}`
                      }
                      alt=""
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </nav >
        {
          show ? (
            <nav className="menu-bar" >
              <div className="menu-links">
                <ul className="nav-links">
                  <li onClick={() => setshow(!show)}>
                    <Link to="/">Home</Link>
                  </li>
                  <li onClick={() => setshow(!show)}>
                    <Link to="/freelancers">Hire Freelancers</Link>
                  </li>
                  <li onClick={() => setshow(!show)}>
                    <Link to="/jobs">Find Job</Link>
                  </li>
                  <li onClick={() => setshow(!show)}>
                    <Link to="/how-it-works">How Its Works</Link>
                  </li>
                  <li onClick={() => setshow(!show)}>
                    <Link to="/why-digilanxer">Why Digilanxer</Link>
                  </li>
                  {user?.Type === "Freelancer" ?
                    <li onClick={() => setshow(!show)}>
                      <Link to="/certification">Certification</Link>
                    </li> : <></>}
                  {user ?
                    user.Type === "Employer" ?
                      <li onClick={() => setshow(!show)}>
                        <Link>Product</Link>
                        <div class="dropdown">
                          <Link class="dropdown-content">
                            <Link to="#">Work Monitoring</Link>
                            <Link to="#">Digital Marketing Tool</Link>
                          </Link>
                        </div>
                      </li> : <></>
                    :
                    <li onClick={() => setshow(!show)}>
                      <Link>Product</Link>
                      <div class="dropdown">
                        <Link class="dropdown-content">
                          <Link to="#">Work Monitoring</Link>
                          <Link to="#">Digital Marketing Tool</Link>
                        </Link>
                      </div>
                    </li>}
                  {user ?
                    user.Type === "Employer" ?
                      <li onClick={() => setshow(!show)}>
                        <Link to="/partner-with-us">Partner With Us</Link>
                      </li> : <></>
                    :
                    <li onClick={() => setshow(!show)}>
                      <Link to="/partner-with-us">Partner With Uaas</Link>
                    </li>}

                </ul>
              </div>
            </nav >
          ) : null}


      </div >
      {
        sidebar ? (
          <div ref={sidebarRef} >
            <Sidebar sidebar={sidebar} handleSidebar={handleSidebar} />
          </div>
        ) : null}
      {
        notifShow ? (
          <div ref={notificationRef}>
            <NotificationComponent handleNotification={handleNotification} />
          </div>
        ) : null
      }
    </>
  );
};
export default Header;

